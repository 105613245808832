import { ensurePath, throwError } from '../utils'

import Provider from '../Provider'

export const methods = [
  'concat',
  'increment',
  'merge',
  'pop',
  'push',
  'set',
  'shift',
  'splice',
  'toggle',
  'unset',
  'unshift',
]

export default function StoreProviderFactory(devtools) {
  let asyncTimeout = null

  return Provider(
    methods.reduce((currentState, methodKey) => {
      currentState[methodKey] = function(...args) {
        const model = this.context.controller.model
        const pathArg = args.shift()
        let path = ensurePath(
          this.context.resolve.isTag(pathArg)
            ? this.context.resolve.path(pathArg)
            : pathArg
        )

        args = args.map((arg) => this.context.resolve.value(arg))

        if (pathArg.type === 'moduleState') {
          const executionPath = this.context.execution.name.split('.')
          const modulePath = executionPath.splice(0, executionPath.length - 1)

          path = modulePath.concat(path)
        }

        if (this.context.controller.flush) {
          clearTimeout(asyncTimeout)
          asyncTimeout = setTimeout(() => this.context.controller.flush())
        }

        if (methodKey === 'set' && args[0] === undefined) {
          return model.unset.apply(model, [path].concat(args))  
        }

        return model[methodKey].apply(model, [path].concat(args))
      }

      return currentState
    }, {}),
    {
      wrap: devtools
        ? (context, functionDetails) => {
            let asyncTimeout = null

            return methods.reduce((currentState, methodKey) => {
              const originFunc = context.controller.model[methodKey]

              currentState[methodKey] = (...args) => {
                let argsCopy = args.slice()
                const pathArg = argsCopy.shift()
                let path = ensurePath(
                  context.resolve.isTag(pathArg)
                    ? context.resolve.path(pathArg)
                    : pathArg
                )

                argsCopy = argsCopy.map((arg) => context.resolve.value(arg))

                if (pathArg.type === 'moduleState') {
                  const executionPath = context.execution.name.split('.')
                  const modulePath = executionPath.splice(
                    0,
                    executionPath.length - 1
                  )

                  path = modulePath.concat(path)
                }

                context.debugger.send({
                  datetime: Date.now(),
                  type: 'mutation',
                  color: '#333',
                  method: methodKey,
                  args: [path, ...argsCopy],
                })

                if (context.controller.flush) {
                  clearTimeout(asyncTimeout)
                  asyncTimeout = setTimeout(() => context.controller.flush())
                }

                try {
                  if (methodKey === 'set' && argsCopy[0] === undefined) {
                    context.controller.model.unset(path, ...argsCopy) 
                  } else {
                    originFunc.apply(context.controller.model, [
                      path,
                      ...argsCopy,
                    ])
                  }
                } catch (e) {
                  const executionName = context.execution.name
                  throwError(
                    `The sequence "${executionName}" with action "${functionDetails.name}" has an error: ${e.message}`
                  )
                }
              }

              return currentState
            }, {})
          }
        : false,
    }
  )
}
