import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { Icon, Progress, Modal, Button , notification } from 'antd';
import Dropzone from 'react-dropzone';
import arrayMove from 'array-move';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import { API_URL } from '@utils/constants';
import styles from './gallery.css';


const cx = classNames.bind(styles);

export default class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      deleteId: 0
    };
    this.onDrop = this.onDrop.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.onSortManual = this.onSortManual.bind(this);
  }

  onDrop(acceptedFiles) {
    const { dealerId, getDealer } = this.props;
    this.acceptedFiles = acceptedFiles;
    if (acceptedFiles.length > 0) {
      // submit({ productId, files: acceptedFiles, uuid });
      Promise.all(
        acceptedFiles.map(file => {
          const formData = new FormData();
          formData.append('file', file, file.fileName);
          const options = {
            method: 'POST',
            body: formData
          };
          fetch(`${API_URL}/api/v1/web/dealers/${dealerId}/image`, options)
            .then(res => res.json())
            .then(res => Promise.resolve(res));
        })
      ).then(res => {
        notification.success({
          message: 'Imagenes',
          description: 'Las imagenes se subieron correctamente'
        });
        getDealer({ id: dealerId });
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  onSortEnd({ oldIndex, newIndex }) {
    const { gallery, uuid } = this.props;
    const result = gallery.sort((a, b) => (a.sort > b.sort ? 1 : -1));
    const newData = arrayMove(result, oldIndex, newIndex);
    const { updateSortMedia } = this.props;
    const values = { data: newData };
    //updateSortMedia({ values, uuid });
  }

  onSortManual() {
    const { gallery, uuid } = this.props;
    const result = gallery.sort((a, b) => (a.sort > b.sort ? 1 : -1));
    const { updateSortMedia } = this.props;
    const values = { data: result };
    //updateSortMedia({ values, uuid });
  }

  handleClickCloseModal(id = null) {
    if (id) {
      return this.setState({
        openModal: true,
        deleteId: id
      });
    } else {
      return this.setState({openModal: !this.state.openModal})
    }
  }

  deleteFileProduct() {
    const {dealerId, getDealer} = this.props;
    const {deleteId} = this.state;

    fetch(`${API_URL}/api/v1/web/dealers/${dealerId}/image/${deleteId}`, {method: 'DELETE'})
    .then(res => res.json())
    .then(res => {
      notification.success({
        message: 'Imagenes',
        description: 'La imagen se elimino correctamente'
      });
      this.setState({
        openModal: false,
        deleteId: 0
      })
      getDealer({ id: dealerId });
    }).catch(error => {
      notification.error({
        message: 'Imagenes',
        description: 'La no pude ser eliminada.'
      });
    });
  }


  thumbs() {
    const { gallery } = this.props;
    const result = gallery;

    const SortableContainer = sortableContainer(({ children }) => {
      return <ul className={cx('content-gallery')}>{children}</ul>;
    });
    const DragHandle = sortableHandle(() => (
      <span className="none">
        {/* <Icon type="pushpin" /> */}
      </span>
    ));

    const SortableItem = sortableElement(({ value }) => (
      <li key={value.id} className={cx('list-gallery')}>
        <img
          src={value.image.url_image}
          alt="Imagen de producto"
          className={cx('file')}
        />
        <Icon
          type="delete"
          className={cx('trash')}
          onClick={() => {
            this.handleClickCloseModal(value.id);
          }}
        />
        <DragHandle />
        <p className={cx('list-number')}>{value.sort + 1}</p>
      </li>
    ));

    return (
      <aside className={cx('aside')}>
        <h4 className={cx('title')}>Imagenes guardadas (500 x 500):</h4>
        <p>
          Para cambiar el orden de las imagenes de clic en el icono de pin (
          <Icon type="pushpin" />) sin soltar y mueva la imagen hasta la
          posición deseada.
        </p>
        <SortableContainer  axis="xy" useDragHandle>
          {result
            .sort((a, b) => (a.sort > b.sort ? 1 : -1))
            .map(file => (
              <SortableItem
                key={file.sort}
                index={file.sort}
                value={file}
                disabled
              />
            ))}
        </SortableContainer>

        {result.length === 0 ? (
          <p className={cx('withoutresults')}>Sin resultados</p>
        ) : null}
      </aside>
    );
  }

  render() {
    const { loading, deleteFileProduct } = this.props;
    const {openModal} = this.state;
    const max = 5000000;
    return (
      <div>
        <h1 className={cx('title2')}>
          Solo se aceptarán imágenes con formato .jpeg y .png con un maximo de{' '}
          {max / 1000000}MB de tamaño. (500 x 500)
        </h1>
        <Dropzone
          accept="image/jpeg, image/png"
          onDrop={this.onDrop}
          minSize={0}
          maxSize={max}
        >
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragReject,
            rejectedFiles
          }) => (
            <section>
              <div {...getRootProps({ className: cx('dropzone') })}>
                <input {...getInputProps()} />
                {!isDragActive &&
                  'Arrastre algunos archivos en esta área, o haga clic para seleccionar algunos archivos'}
                {isDragActive &&
                  !isDragReject &&
                  'Favor de dejar los archivos en esta área para guardarlos'}
                {isDragReject && (
                  <p className={cx('error')}>
                    Se encontraron archivos con formato no soportado
                  </p>
                )}
                {rejectedFiles.length > 0 && rejectedFiles[0].size > max && (
                  <p className={cx('error')}>
                    Se encontraron archivos con un tamaño superior a los 5MB
                    permitidos
                  </p>
                )}
              </div>
            </section>
          )}
        </Dropzone>

        {loading === true ? (
          <div className={cx('progress-gallery')}>
            <Progress percent={100} status="active" showInfo={false} />
          </div>
        ) : null}
        {this.thumbs()}
        <Modal
          closable={openModal}
          visible={openModal}
          onCancel={() => {
            this.handleClickCloseModal()
          }}
          footer={[
            <Button
              type="primary"
              onClick={() => {
                this.deleteFileProduct();
              }}
            >
              Confirmar
            </Button>,
            <Button
              onClick={() => {
                this.handleClickCloseModal()
              }}
            >
              Cancelar
            </Button>
          ]}
        >
          <h4>¿Desea eliminar el archivo?</h4>
        </Modal>
      </div>
    );
  }
}
