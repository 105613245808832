import { connect } from '@cerebral/react';
import { Form, Input, Button, Row, Col, Tooltip, Icon, Select, InputNumber, Spin } from 'antd';
import { state, sequences } from 'cerebral';
import React, { Component } from 'react';

export default Form.create()(
  connect(
    {
      // states
        state_configuration: state.configurations.show,
        loadingLogin: state.user.loginButtonDisable,

        // sequences
        submit: sequences.update
    },
    class ConfigurationFormViewUpdate extends Component {
      constructor(props) {
        super(props);
        this.state = { };

        this.handleSubmit = this.handleSubmit.bind(this);
      }

      handleSubmit(e) {
        e.preventDefault();
        const { form, submit, state_configuration } = this.props;
        form.validateFields((err, values) => {
          if (!err) {
            submit({ id: state_configuration.id, values });
          }
        });
      }

      render() {
        const {
            state_configuration,
            form,
            loadingLogin,
        } = this.props;

        const { } = this.state;

        const { getFieldDecorator } = form;
        const FormItem = Form.Item;
        return (
          <div>
            {Object.keys(state_configuration).length === 0 ? (
              ''
            ) : (
              <Form onSubmit={this.handleSubmit} layout="horizontal">

                <Row gutter={16}>

                  <Col xs={24} sm={24} md={24}>
                    <FormItem className="move-content-right">
                      <Tooltip title="Guardar configuración" placement="left">
                        <Button
                          icon="save"
                          type="primary"
                          htmlType="submit"
                          loading={loadingLogin}
                        />
                      </Tooltip>
                      <Tooltip title="Cancelar" placement="left">
                        <a
                          style={{ marginLeft: 10 }}
                          href="/configuraciones"
                          className="ant-btn ant-btn-primary ant-btn-icon-only"
                          rel="noopener noreferrer"
                        >
                          <Icon type="arrow-left" />
                        </a>
                      </Tooltip>
                    </FormItem>
                  </Col>

                </Row>

                <Row gutter={16}>

                  <Col xs={24} sm={24} md={24} >
                    <span><b>{state_configuration.name}</b></span>
                  </Col>

                  <Col xs={24} sm={24} md={24} style={{marginTop:'1em', marginBottom:'1em'}}>
                    {state_configuration.description}
                  </Col>

                  <Col xs={24} sm={4} md={4}>
                  <FormItem label="Valor">
                      {getFieldDecorator('value', {
                        initialValue: state_configuration.value
                      })(<Input placeholder="Valor" />)}
                    </FormItem>
                  </Col>
                </Row>

              </Form>
            )}
          </div>
        );
      }
    }
  )
);