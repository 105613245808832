import Admins from './Admins';
import Attributes from './Attributes';
import Books from './Books';
import Brands from './Brands';
import Categories from './Categories';
import Configurations from './Configurations';
import ContactView from './Contact';
import Cuopons from './Coupons';
import Dealers from './Dealers';
import Events from './Events';
import Home from './Home';
import Options from './Options';
import Orders from './Orders';
import Posts from './Posts';
import Products from './Products';
import Profile from './Profile';
import Reports from './Reports';
import RequestDealerViewInitial from './RequestDealerViewInitial';
import RequestDealerViewMayoreo from './RequestDealerViewMayoreo';
import RequestDealerViewTelemarketing from './RequestDealerViewTelemarketing';
import Slides from './Slides';
import Stock from './Stock';
import Tags from './Tags';
import Template404 from './Template404';
import UserWeb from './UserWeb';

export default {
  Home,
  Template404,
  ContactView,
  ...Admins,
  ...Attributes,
  ...Books,
  ...Brands,
  ...Categories,
  ...Configurations,
  ...Cuopons,
  ...Dealers,
  ...Events,
  ...Options,
  ...Orders,
  ...Posts,
  ...Products,
  ...Profile,
  ...Reports,
  ...RequestDealerViewInitial,
  ...RequestDealerViewMayoreo,
  ...RequestDealerViewTelemarketing,
  ...Slides,
  ...Stock,
  ...Tags,
  ...UserWeb,
};
