import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Modal, Tooltip, Icon, Row, Table, Button } from 'antd';
import Moment from 'react-moment';

const style = {
  img: {
    width: '4rem',
    height: '4rem',
    objectFit: 'cover',
    objectPosition: 'center'
  }
};

export default connect(
  {
    // states
    list: state.posts_gallery.list,
    postId: state.posts_gallery.post_id,
    loading: state.user.loginButtonDisable,
    // secuences
    deleteGalleryPost: sequences.deleteGalleryPost
  },
  class PostGalleryListView extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    deleteItem(id) {
      this.id = id;
      const { deleteGalleryPost } = this.props;
      Modal.confirm({
        title: 'Confirmación',
        content: `Esta seguro que desea eliminar el registro`,
        okText: 'Aceptar',
        cancelText: 'Cancelar',
        onOk: () => deleteGalleryPost({ id })
      });
    }

    render() {
      const { list, postId, loading } = this.props;
      const columns = [
        {
          title: 'THUMB',
          dataIndex: 'thumb',
          key: 'thumb',
          render: (text, element, index) => (
            <img src={text} alt="---" style={style.img} />
          )
        },
        {
          title: 'FILE',
          dataIndex: 'file',
          key: 'file',
          render: (text, element, index) => (
            <span>
              {list[index].type === 'IMG' ? (
                <img src={text} alt="---" style={style.img} />
              ) : (
                <a href={text} target="_blank" rel="noopener noreferrer">
                  link
                </a>
              )}
            </span>
          )
        },
        {
          title: 'TIPO',
          dataIndex: 'type',
          key: 'type',
          render: (text, element, index) => (
            <span>{list[index].type === 'IMG' ? 'IMAGEN' : 'YOUTUBE'}</span>
          )
        },
        {
          title: 'FECHA CREACIÓN',
          dataIndex: 'created_at',
          key: 'created_at',
          render: text => <Moment format="DD-MM-YYYY hh:mm A">{text}</Moment>
        },
        {
          title: 'ACCIONES',
          dataIndex: 'id',
          key: 'id',
          render: (text, element, index) => (
            <Tooltip title="Eliminar posts" placement="left">
              <Button
                type="primary"
                icon="delete"
                onClick={() => this.deleteItem(list[index].id)}
              />
            </Tooltip>
          )
        }
      ];
      return (
        <div>
          <div className="move-content-right">
            <Tooltip title="Crear galeria" placement="left">
              <a
                href={`/posts/${postId}/galeria/nuevo`}
                className="ant-btn ant-btn-primary ant-btn-icon-only"
                rel="noopener noreferrer"
              >
                <Icon type="plus" />
              </a>
            </Tooltip>
            <Tooltip title="Atras" placement="left">
              <a
                style={{ marginLeft: 10 }}
                href="/posts"
                className="ant-btn ant-btn-primary ant-btn-icon-only"
                rel="noopener noreferrer"
              >
                <Icon type="arrow-left" />
              </a>
            </Tooltip>
          </div>
          <Row gutter={16} style={{ marginBottom: 20 }}>
            <Table
              loading={loading}
              bordered
              size="small"
              dataSource={list}
              columns={columns}
              pagination={false}
              scroll={{ x: 'max-content' }}
            />
          </Row>
        </div>
      );
    }
  }
);
