import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Table, Button, Modal, Tooltip, Icon, Row, Col, Input } from 'antd';
import Moment from 'react-moment';
import Pagination from '@component/Elements/Pagination';
import debounce from 'lodash/debounce';
import whitoutImage from '../../../assets/product_whitout_image.png';

const styles = {
  img: {
    width: '4rem',
    height: '4rem',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  principal: {
    color: 'green'
  }
};

export default connect(
  {
    // states
    list: state.categories.list,
    extraBySearch: state.categories.search,
    loading: state.categories.search.loading,
    categoryFatherAll: state.categories.categoryFatherAll,
    stateDelete: state.categories.delete,
    debounceTime: state.debounceTime,
    // secuences
    deleteCategory: sequences.deleteCategory,
    handleClickOpenModal: sequences.handleClickOpenModalCategories,
    handleClickCloseModal: sequences.handleClickCloseModalCategories,
    updatedFilterGeneralCategory: sequences.updatedFilterGeneralCategory
  },
  class CategoryListView extends Component {
    constructor(props) {
      super(props);
      this.state = {};

      this.handleCategoryNameSearch = this.handleCategoryNameSearch.bind(this);
      this.handleSearchDebounce = debounce(
        this.handleSearchDebounce,
        props.debounceTime
      );
    }

    getBrands(categoryBrand) {
      this.categoryBrand = categoryBrand;
      const bullet = categoryBrand.length > 0 ? ' * ' : '';
      let brands = '(';
      brands += bullet;
      categoryBrand.forEach(element => {
        brands += `${element.brand.name.toUpperCase()}`;
        brands += bullet;
      });
      brands += ')';
      return brands;
    }

    getParent(parent) {
      const { categoryFatherAll } = this.props;
      const result = categoryFatherAll.find(
        // eslint-disable-next-line no-shadow
        categoryFatherAll => categoryFatherAll.id === parent
      );
      if (typeof result !== 'undefined') {
        return result.name + this.getBrands(result.category_brand);
      }
      return '---';
    }

    handleSearchDebounce(key, value) {
      this.key = key;
      this.value = value;

      const { updatedFilterGeneralCategory } = this.props;
      updatedFilterGeneralCategory({ key, value });
    }

    handleCategoryNameSearch(e) {
      this.e = e;
      const { value } = e.target;
      this.handleSearchDebounce('categoryNameSearch', value);
    }

    render() {
      const {
        list,
        handleClickOpenModal,
        handleClickCloseModal,
        stateDelete,
        deleteCategory,
        extraBySearch,
        loading
      } = this.props;

      const columns = [
        {
          title: 'CATEGORIA',
          dataIndex: 'name',
          key: 'name',
          render: (text, element, index) => (
            <a href={`/categorias/${list[index].id}`}>{text}</a>
          )
        },
        {
          title: 'COVER',
          dataIndex: 'banner_url',
          key: 'banner_url',
          render: (text, element, index) => (
            <img
              src={text !== null ? text : whitoutImage}
              alt={list[index].alt}
              style={styles.img}
            />
          )
        },
        {
          title: 'BANNER',
          dataIndex: 'banner_big_url',
          key: 'banner_big_url',
          render: (text, element, index) => (
            <img
              src={text !== null ? text : whitoutImage}
              alt={list[index].alt}
              style={styles.img}
            />
          )
        },
        {
          title: 'ALT',
          dataIndex: 'alt',
          key: 'alt'
        },
        {
          title: 'PADRE',
          dataIndex: 'parent',
          key: 'parent',
          render: text => (
            <span>
              {text === null ? (
                <span style={styles.principal}>ES PRINCIPAL</span>
              ) : (
                this.getParent(text)
              )}
            </span>
          )
        },
        {
          title: 'MARCAS',
          dataIndex: 'category_brand',
          key: 'category_brand',
          render: text => (
            <span>
              {text.length > 0 ? ' * ' : ''}
              {text.map(value => (
                <span>
                  {value.brand.name.toUpperCase()}{' '}
                  {text.length > 0 ? ' * ' : ''}
                </span>
              ))}
            </span>
          )
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          key: 'status',
          render: text => (
            <span>{text === 'ACTIVE' ? 'ACTIVO' : 'INACTIVO'}</span>
          )
        },
        {
          title: 'FECHA CREACIÓN',
          dataIndex: 'created_at',
          key: 'created_at',
          render: text => <Moment format="DD-MM-YYYY hh:mm A">{text}</Moment>
        },
        {
          title: 'ACCIONES',
          dataIndex: 'id',
          key: 'id',
          render: (text, element, index) => (
            <Tooltip title="Eliminar categoria" placement="top">
              <Button
                type="primary"
                icon="delete"
                onClick={() => {
                  handleClickOpenModal(list[index]);
                }}
              />
            </Tooltip>
          )
        }
      ];
      return (
        <div>
          <Modal
            closable={false}
            visible={stateDelete.open}
            onCancel={() => {
              handleClickCloseModal();
            }}
            footer={[
              <Button
                type="primary"
                onClick={() => {
                  deleteCategory();
                }}
                loading={stateDelete.loader}
              >
                Confirmar
              </Button>,
              <Button
                onClick={() => {
                  handleClickCloseModal();
                }}
              >
                Cancelar
              </Button>
            ]}
          >
            <h4>¿Desea eliminar la categoria '{stateDelete.name}' ?</h4>
            <p>
              Favor de verificar que la categoria seleccionada sea la que
              realmente desea eliminar.
            </p>
          </Modal>
          <div className="move-content-right">
            <Tooltip title="Crear categoria" placement="left">
              <a
                href="/categorias/nuevo"
                className="ant-btn ant-btn-primary ant-btn-icon-only"
                rel="noopener noreferrer"
              >
                <Icon type="plus" />
              </a>
            </Tooltip>
          </div>
          <Row gutter={16} style={{ marginBottom: 20 }}>
            <Col xs={24} sm={6} md={6}>
              <Input
                placeholder="Nombre"
                allowClear
                onChange={this.handleCategoryNameSearch}
                style={{ width: '100%' }}
              />
            </Col>
          </Row>
          <Table
            loading={loading}
            bordered
            size="small"
            dataSource={list}
            columns={columns}
            pagination={false}
            scroll={{ x: 'max-content' }}
          />
          <Pagination
            list="categories.list"
            apiUrl="admin/categories"
            extraBySearch={extraBySearch}
          />
        </div>
      );
    }
  }
);
