/* eslint-disable */
import './styles/app.css';
import React from 'react';
import { render } from 'react-dom';
import App from 'cerebral';
import page from 'page';
import YupLocale from '@utils/yup-locale'; // import before the components !important
import Devtools from 'cerebral/devtools';
import Noty from 'noty';
import { Container } from '@cerebral/react';
import ComponentApp from '@component/App';
import main from './modules/main';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import esES from 'antd/lib/locale-provider/es_ES';
import 'moment/locale/es';
moment.locale('es');

// Page global config
page({ hashbang: true });

// Cerebral config
const app = App(main, {
  devtools:
    process.env.NODE_ENV === 'production'
      ? null
      : Devtools({
          // If running standalone debugger
          // Chrome extension if unable to connect
          host: 'localhost:8585',
          // Time travel
          storeMutations: true,
          // Warnings on mutating outside "state" API
          preventExternalMutations: true,
          // Warnings when strict render path usage is wrong
          verifyStrictRender: true,
          // Throw error when overwriting existing input property
          preventInputPropReplacement: false,
          bigComponentsWarning: { state: 10, signals: 10 }
        })
});

const bootstrap = app.getSequence('bootstrap');

bootstrap(); // Run initial sequence

// Global noty fonfig
Noty.overrideDefaults({
  timeout: 3000,
  progressBar: false,
  closeWith: ['click', 'button'],
  theme: 'relax'
});

// Render app
render(
  <Container app={app}>
    <ConfigProvider locale={esES}>
      <ComponentApp />
    </ConfigProvider>
  </Container>,
  document.getElementById('app')
);
