import { set } from 'cerebral/factories'; // wait
import { state, props } from 'cerebral';
import { WithRouter, setPagination } from '@modules/actions';
import notify from '@utils/notify';
import page from 'page';
import * as actions from './actions';

const AttributeListRouter = WithRouter([
  set(state.app.currentPage, 'AttributesListView'),
  actions.getAttributes,
  {
    success: [setPagination, actions.setAttributes],
    error: []
  }
]);
const AttributeNewRouter = WithRouter([
  set(state.app.currentPage, 'AttributesFormView'),
  ({ props, store }) => {
    store.set(state.attributes.father, props.params.option_id);
  }
]);
const AttributeViewUpdate = WithRouter([
  set(state.attributes.detail, {}),
  set(state.app.currentPage, 'AttributesFormViewUpdate'),
  actions.getAttributeDetail,
  {
    success: [actions.setAttributeDetail],
    error: [notify(props.message.error, 'error')]
  }
]);
const updateAttribute = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    // eslint-disable-next-line camelcase
    const { values, id, option_id } = props;

    const data = new FormData();
    data.append('option_attribute_id', id);
    data.append('option_id', option_id);
    data.append('name', values.name);
    if (typeof values.image !== 'undefined') {
      const file = values.image[0].originFileObj;
      data.append('image', file);
    }
    if (
      typeof values.hexadecimal !== 'undefined' &&
      values.hexadecimal !== null &&
      values.hexadecimal !== ''
    ) {
      data.append('hexadecimal', values.hexadecimal);
    }

    return http
      .postFile(`admin/options-attribute/update`, data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Atributo actualizado', 'success'),
      ({ get }) => {
        page.show(`/opciones/${get(state.attributes.father)}/atributos`);
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];
const saveAttribute = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path, get }) => {
    const { values } = props;
    const data = new FormData();
    if (typeof values.image !== 'undefined') {
      const file = values.image[0].originFileObj;
      data.append('image', file);
    }
    data.append('name', values.name);
    data.append('option_id', get(state.attributes.father));
    if (
      typeof values.hexadecimal !== 'undefined' &&
      values.hexadecimal !== null &&
      values.hexadecimal !== ''
    ) {
      data.append('hexadecimal', values.hexadecimal);
    }
    return http
      .postFile('admin/options-attribute', data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Atributo creado', 'success'),
      ({ get }) => {
        page.show(`/opciones/${get(state.attributes.father)}/atributos`);
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];
const deleteAttribute = [
  ({ store }) => {
    store.set(state.attributes.delete.loader, true);
  },
  ({ http, path, get, props }) => {
    return http
      .delete(
        `admin/options-attribute/${get(state.attributes.delete.id)}/delete`,
        {}
      )
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Atributo eliminado', 'success'),
      ({ get }) => {
        page.show(`/opciones/${get(state.attributes.delete.father)}/atributos`);
      },
      ({ store }) => {
        store.set(state.attributes.delete, {
          id: null,
          name: null,
          father: null,
          open: false,
          loader: false
        });
      }
    ],
    error: [
      ({ store }) => {
        store.set(state.attributes.delete, {
          id: null,
          name: null,
          father: null,
          open: false,
          loader: false
        });
      },
      notify(props.message.error, 'error')
    ]
  }
];
const handleClickOpenModalAttribute = [
  ({ props, store }) => {
    store.set(state.attributes.delete, {
      id: props.id,
      name: props.name,
      father: props.option_id,
      open: true,
      loader: false
    });
  }
];
const handleClickCloseModalAttribute = [
  ({ store }) => {
    store.set(state.attributes.delete, {
      id: null,
      name: null,
      father: null,
      open: false,
      loader: false
    });
  }
];
const updatedFilterGeneralAttributes = [
  ({ store }) => {
    store.set(state.attributes.search.loading, true);
  },
  ({ props, store }) => {
    const { value, key } = props;
    store.set(state.attributes.search[key], value);
  },
  actions.getAttributes,
  {
    success: [setPagination, actions.setAttributes,
      ({ store }) => {
        store.set(state.attributes.search.loading, false);
      }],
    error: [notify(props.message.error, 'error')]
  }
];
export default {
  routes: {
    list: AttributeListRouter,
    new: AttributeNewRouter,
    update: AttributeViewUpdate
  },
  sequences: {
    saveAttribute,
    deleteAttribute,
    handleClickOpenModalAttribute,
    handleClickCloseModalAttribute,
    updateAttribute,
    updatedFilterGeneralAttributes
  }
};
