import { state } from 'cerebral';
import { set } from 'cerebral/factories'; // wait
import _find from 'lodash/fp/find';
import _map from 'lodash/fp/map';
import _flattenDepth from 'lodash/fp/flattenDepth';
import _omit from 'lodash/fp/omit';
import flow from 'lodash/flow';
import { camelCaseKeys } from '@utils';
import routers from '../router';
import * as actionsUser from './user/actions';

/**
 * [setPagination description]
 * @param {[type]} options.props [description]
 * @param {[type]} options.store [description]
 */
export function setPagination({ props, store }) {
  const { result } = props;
  if (result && 'data' in result) {
    store.set(state.app.pagination, camelCaseKeys(_omit('data', result)));
  }
  if (result && 'result' in result) {
    store.set(
      state.app.pagination,
      camelCaseKeys(_omit('data', result.result))
    );
  }
}

/**
 * [setUrl description]
 * @param {[type]} options.props [description]
 * @param {[type]} options.store [description]
 */
export function setUrl({ props, store }) {
  const getRoute = flow(
    _map('children'),
    _flattenDepth(Infinity),
    _find({ route: props.path })
  )(routers);
  if (getRoute) {
    store.set(state.app.titlePage, getRoute.name);
  } else {
    store.set(state.app.titlePage, 'Acción');
  }
  store.set(state.app.currentPath, props.path);
}

/**
 * [WithRouter description]
 * @param {[type]} continueSequecence [description]
 */
export function WithRouter(continueSequecence) {
  return [
    actionsUser.getLocalUser,
    {
      success: [
        setUrl,
        set(state.app.isLoadingPage, true),
        // wait(800),
        ...continueSequecence,
        set(state.app.isLoadingPage, false)
      ],
      error: []
    }
  ];
}
