export default function(target, value) {
  function concat(context) {
    if (!context.resolve.isTag(target, 'state', 'moduleState')) {
      throw new Error(
        'Cerebral factory.concat: You have to use the STATE or MODULESTATE tag as first argument'
      )
    }

    context.store.concat(target, value)
  }

  concat.displayName = `factory.concat(${String(target)}, ${String(value)})`

  return concat
}
