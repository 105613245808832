import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Table, Row, Col, Input, Tooltip, Icon, Select } from 'antd';
import Pagination from '@component/Elements/Pagination';
import Moment from 'react-moment';
import debounce from 'lodash/debounce';

export default connect(
  {
    // states
    extraBySearch: state.web.search,
    loading: state.web.search.loading,
    list: state.web.list,
    debounceTime: state.debounceTime,
    tagsAll: state.web.tags,
    // secuences
    updatedFilterGeneral: sequences.updatedFilterGeneralClient,
    updatedSearchTagsIds: sequences.updatedSearchTagsIds
  },
  class WebListView extends Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.handleSearchDebounce = debounce(this.handleSearchDebounce,props.debounceTime);
      this.handleSearchByTags   = debounce( this.handleSearchByTags, props.debounceTime );
      this.handleTags = this.handleTags.bind(this);
      this.handleSearchByNameClient = this.handleSearchByNameClient.bind(this);
      this.handleSearchByEmailClient = this.handleSearchByEmailClient.bind(this);
    }

    handleSearchByNameClient(e) {
      this.e = e;
      const { value } = e.target;
      this.handleSearchDebounce('searchByNameClient', value);
    }

    handleSearchByEmailClient(e) {
      this.e = e;
      const { value } = e.target;
      this.handleSearchDebounce('searchByEmailClient', value);
    }

    handleSearchDebounce(key, value) {
      this.key = key;
      this.value = value;
      const { updatedFilterGeneral } = this.props;
      updatedFilterGeneral({ key, value });
    }

    handleTags(value) {
      this.handleSearchByTags({ value });
    }
    
    handleSearchByTags(value) {
      const { updatedSearchTagsIds } = this.props
      updatedSearchTagsIds({value: value})
    }

    render() {
      const { list, loading, extraBySearch, tagsAll } = this.props;
      const columns = [
        {
          title: 'CORREO ELECTRÓNICO',
          dataIndex: 'email',
          key: 'email',
          render: (text, element, index) => (
            <a href={`/clientes/${list[index].uuid}`}>{text}</a>
          )
        },
        {
          title: 'NOMBRE',
          dataIndex: 'first_name',
          key: 'first_name'
        },
        {
          title: 'APELLIDO',
          dataIndex: 'last_name',
          key: 'last_name'
        },
        {
          title: 'TELÉFONO',
          dataIndex: 'phone',
          key: 'phone',
          render: text => (text === null ? 'Sin registro' : text)
        },
        {
          title: 'RED SOCIAL',
          dataIndex: 'provider',
          key: 'provider',
          render: text => (text === null ? 'No' : text.toUpperCase())
        },
        {
          title: 'VERIFICACIÓN',
          dataIndex: 'verified',
          key: 'verified',
          render: text => (text === 1 ? 'Verificado' : 'Pendiente')
        },
        {
          title: 'FECHA REGISTRO',
          dataIndex: 'created_at',
          key: 'created_at',
          render: text => <Moment format="DD-MM-YYYY hh:mm A">{text}</Moment>
        }
      ];
      return (
        <div>
          <Row gutter={16} style={{ marginBottom: 20 }}>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              <Input
                placeholder="Nombre"
                allowClear
                onChange={this.handleSearchByNameClient}
                style={{ width: '100%' }}
              />
            </Col>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              <Input
                placeholder="Correo electrónico"
                allowClear
                onChange={this.handleSearchByEmailClient}
                style={{ width: '100%' }}
              />
            </Col>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Por favor seleccione algunas etiquetas"
                allowClear
                showSearch
                optionFilterProp="children"
                onChange={this.handleTags}
                filterOption={(input, option) =>
                  option.props.children[2].props.children[1]
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                } >
                {tagsAll.map(value => (
                  <Select.Option
                    key={`tags-${value.id}`}
                    value={value.id}> {' '}
                    <span data='tags'> {value.name} </span>
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col  xs={24} sm={6} md={6}>
              <Tooltip title="Descargar excel" placement="right">
                <a
                  href={`${
                    process.env.API_URL
                  }/api/v1/admin/export-normals?${Object.keys(extraBySearch)
                    .map(key => `${key}=${extraBySearch[key]}`)
                    .join('&')}`}
                  target="_blank"
                  className="ant-btn ant-btn-primary ant-btn-icon-only"
                  rel="noopener noreferrer"
                >
                  <Icon type="file-excel" />
                </a>
              </Tooltip>
            </Col>
          </Row>

          <Table
            loading={loading}
            bordered
            size="small"
            dataSource={list}
            columns={columns}
            pagination={false}
            scroll={{ x: 'max-content' }}
          />
          <Pagination
            list="web.list"
            apiUrl="admin/normals"
            extraBySearch={extraBySearch}
          />
        </div>
      );
    }
  }
);
