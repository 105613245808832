import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { sequences, state } from 'cerebral';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Tooltip,
  Icon,
  Select,
  InputNumber,
  DatePicker,
  Spin,
  Radio
} from 'antd';
import debounce from 'lodash/debounce';

import moment from 'moment';
import { createValidation } from '@utils';
import productWhitoutImage from '../../../assets/product_whitout_image.png';

const { Option } = Select;

const style = {
  miniFileCategory: {
    width: '1rem',
    height: '1rem',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  label: {
    marginTop: '9px',
    color: 'rgba(0, 0, 0, 0.85)'
  },
  spaceDown: { marginBottom: 20 }
};
export default Form.create()(
  connect(
    {
      // states
      loadingLogin: state.user.loginButtonDisable,
      categories: state.coupons.categories,
      detail: state.coupons.detail,
      tags: state.coupons.tags,
      user_tags: state.coupons.user_tags,
      events: state.coupons.events,
      modalities: state.coupons.modalities,
      clients: state.coupons.clients,
      // secuences
      submit: sequences.updateCoupon
    },
    class CouponUpdateFormView extends Component {
      constructor(props) {
        super(props);
        this.state = {
          appliesToDiscountedProducts:  props.detail.applies_to_discounted_products,
          isGlobalUser:   props.detail.is_global_user,
          typeCoupon:     props.detail.type_coupon,
          uniqueRedeemPerUser:  props.detail.unique_redeem_per_user,
          applyAllCartProducts: props.detail.apply_all_cart_products,
          data: [],
          value: props.detail.select_products,
          fetching: false,
          dataClients: [],
          valueClients: props.detail.select_clients,
          fetchingClients: false,
          ModalityNxN: props.detail.modality === 'COUPON_NxN',
          ModalityEvent: props.detail.modality === 'COUPON_EVENT',
          NxNProductsQuantity: props.detail.modality_nxn_products_quantity,
          NxNProductsPaid: props.detail.modality_nxn_products_paid,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeAppliesToDiscountedProducts = this.handleChangeAppliesToDiscountedProducts.bind(
          this
        );
        this.handleChangeApplyAllCartProducts = this.handleChangeApplyAllCartProducts.bind(
          this
        );
        this.handleChangeIsGlobalUser = this.handleChangeIsGlobalUser.bind(
          this
        );
        this.handleChangeModalityNxN = this.handleChangeModalityNxN.bind(
          this
        );
        this.handleChangeTypeCoupon = this.handleChangeTypeCoupon.bind(this);

        // search producto
        this.lastFetchId = 0;
        this.lastFetchIdClients = 0;
        this.fetchCombinations = debounce(
          this.fetchCombinations.bind(this),
          500
        );
        this.fetchCombinationsClients = debounce(
          this.fetchCombinationsClients.bind(this),
          500
        );
        this.handleChangeProducts = this.handleChangeProducts.bind(this);
        this.handleChangeClients = this.handleChangeClients.bind(this);
        // end search producto
        this.usersDefault = this.usersDefault.bind(this);
        this.categoriesDefault = this.categoriesDefault.bind(this);
        this.tagsDefault = this.tagsDefault.bind(this);
        this.userTagsDefault = this.userTagsDefault.bind(this);
        this.eventsDefault = this.eventsDefault.bind(this);
        this.handleChangeNxNProductsQuantity = this.handleChangeNxNProductsQuantity.bind(this);
        this.handleChangeNxNProductsPaid = this.handleChangeNxNProductsPaid.bind(this);
        this.handleChangeUniqueRedeemPerUser = this.handleChangeUniqueRedeemPerUser.bind(
          this
        );
      }

      // eslint-disable-next-line react/sort-comp
      handleChangeIsGlobalUser(value) {
        this.value = value;
        this.setState({ isGlobalUser: value.target.value });
      }

      handleChangeModalityNxN(value) {
        this.value = value;
        this.setState({ ModalityNxN: value == 'COUPON_NxN' });
        this.setState({ ModalityEvent: value == 'COUPON_EVENT' });
        if ( value == 'COUPON_NxN' ) {
          this.props.form.setFieldsValue({ 'type_value': 'PERCENT' });
          this.props.form.setFieldsValue({ 'value': '100' });
          this.setState({ applyAllCartProducts: 1 });
        }
        if ( value == 'COUPON_EVENT' ) {
          this.setState({ appliesToDiscountedProducts: 0 });
        }
      }

      handleChangeNxNProductsQuantity(value) {
        this.value = value;
        this.setState({ NxNProductsQuantity: value });
      }

      handleChangeNxNProductsPaid(value) {
        this.value = value;
        this.setState({ NxNProductsPaid: value });
      }

      handleChangeApplyAllCartProducts(value) {
        this.value = value;
        this.setState({ applyAllCartProducts: value.target.value });
      }

      handleChangeAppliesToDiscountedProducts(value) {
        this.value = value;
        this.setState({ appliesToDiscountedProducts: value.target.value });
      }

      handleChangeUniqueRedeemPerUser(value) {
        this.value = value;
        this.setState({ uniqueRedeemPerUser: value.target.value });
      }

      handleChangeTypeCoupon(value) {
        this.value = value;
        this.setState({ typeCoupon: value.target.value });
      }

      handleSubmit(e) {
        e.preventDefault();
        const { form, submit, detail } = this.props;
        const {
          typeCoupon,
          isGlobalUser,
          uniqueRedeemPerUser,
          appliesToDiscountedProducts,
          applyAllCartProducts
        } = this.state;
        form.validateFields((err, values) => {
          if (!err) {
            values.type_coupon = typeCoupon;
            values.is_global_user = isGlobalUser;
            values.unique_redeem_per_user = uniqueRedeemPerUser;
            values.applies_to_discounted_products = appliesToDiscountedProducts;
            values.apply_all_cart_products = applyAllCartProducts;
            values.coupon_id = detail.id;
            submit({ values });
          }
        });
      }

      handleChangeProducts(value) {
        this.value = value;
        this.setState({
          value,
          data: [],
          fetching: false
        });
      }

      handleChangeClients(value) {
        this.value = value;
        this.setState({
          valueClients: value,
          dataClients: [],
          fetchingClients: false
        });
      }

      fetchCombinations(value) {
        this.value = value;
        if (value !== '') {
          this.lastFetchId += 1;
          const fetchId = this.lastFetchId;
          this.setState({ data: [], fetching: true });
          fetch(`${process.env.API_URL}/api/v1/admin/coupons/search/${value}`, {
            headers: {
              authorization: `bearer ${localStorage.getItem(
                process.env.NAME_TOKEN
              )}`
            }
          })
            .then(response => response.json())
            .then(body => {
              if (fetchId !== this.lastFetchId) {
                return;
              }
              this.setState({ data: body, fetching: false });
            });
        }
      }

      fetchCombinationsClients(value) {
        this.value = value;
        if (value !== '') {
          this.lastFetchIdClients += 1;
          const fetchIdClients = this.lastFetchIdClients;
          this.setState({ dataClients: [], fetchingClients: true });
          fetch(
            `${process.env.API_URL}/api/v1/admin/coupons-search-client?searchByNameClient=${value}`,
            {
              headers: {
                authorization: `bearer ${localStorage.getItem(
                  process.env.NAME_TOKEN
                )}`
              }
            }
          )
            .then(response => response.json())
            .then(body => {
              if (fetchIdClients !== this.lastFetchIdClients) {
                return;
              }
              this.setState({ dataClients: body, fetchingClients: false });
            });
        }
      }

      usersDefault() {
        const { detail } = this.props;
        const data = [];
        detail.coupon_users.forEach(element => {
          data.push(element.user_id.toString());
        });
        return data;
      }

      categoriesDefault() {
        const { detail } = this.props;
        const data = [];
        detail.coupon_categories.forEach(element => {
          data.push(element.category_id);
        });
        return data;
      }

      tagsDefault() {
        const { detail } = this.props;
        const data = [];
        detail.coupon_tags.forEach(element => {
          data.push(element.tag_id.toString());
        });
        return data;
      }

      userTagsDefault() {
        const { detail } = this.props;
        const data = [];
        detail.coupon_user_tags.forEach(element => {
          data.push(element.tag_id.toString());
        });
        return data;
      }
      
      eventsDefault() {
        const { detail } = this.props;
        const data = [];
        detail.coupon_events.forEach(element => {
          data.push(element.events_id.toString());
        });
        return data;
      }

      getBrands(categoryBrand) {
        this.categoryBrand = categoryBrand;
        const bullet = categoryBrand.length > 0 ? ' * ' : '';
        let brands = '(';
        brands += bullet;
        categoryBrand.forEach(element => {
          brands += `${element.brand.name.toUpperCase()}`;
          brands += bullet;
        });
        brands += ')';
        return brands;
      }

      render() {
        const {
          form,
          loadingLogin,
          categories,
          tags,
          user_tags,
          clients,
          events,
          detail,
          modalities,
        } = this.props;
        const {
          appliesToDiscountedProducts,
          applyAllCartProducts,
          data,
          dataClients,
          fetching,
          fetchingClients,
          isGlobalUser,
          ModalityNxN,
          ModalityEvent,
          NxNProductsPaid,
          NxNProductsQuantity,
          typeCoupon,
          uniqueRedeemPerUser,
          value,
          valueClients,
        } = this.state;
        const { getFieldDecorator } = form;
        const FormItem = Form.Item;
        return (
          <div>
            <Form onSubmit={this.handleSubmit} layout="horizontal">
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24}>
                  <FormItem className="move-content-right">
                    <Tooltip title="Guardar cupon" placement="left">
                      <Button
                        icon="save"
                        type="primary"
                        htmlType="submit"
                        loading={loadingLogin}
                      />
                    </Tooltip>
                    <Tooltip title="Cancelar" placement="left">
                      <a
                        style={{ marginLeft: 10 }}
                        href="/cupones"
                        className="ant-btn ant-btn-primary ant-btn-icon-only"
                        rel="noopener noreferrer"
                      >
                        <Icon type="arrow-left" />
                      </a>
                    </Tooltip>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24}>
                  <p style={style.label}>
                    Cupón: <strong>{detail.code}</strong>
                  </p>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} sm={8} md={8}>
                  <FormItem label="Nombre">
                    {getFieldDecorator('name', {
                      rules: [
                        createValidation(
                          true,
                          'Por favor ingresa un nombre',
                          ''
                        )
                      ],
                      initialValue: detail.name
                    })(<Input placeholder="Nombre" />)}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={8}>
                  <FormItem label="Descripción">
                    {getFieldDecorator('description', {
                      rules: [
                        createValidation(
                          true,
                          'Por favor ingresa una descripción',
                          ''
                        )
                      ],
                      initialValue: detail.description
                    })(<Input placeholder="Descripción" />)}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={8}>
                  <FormItem label="Limite de usos">
                    {getFieldDecorator('units_limit', {
                      rules: [
                        {
                          required: false,
                          message: 'Por favor ingresa un limite de usos '
                        }
                      ],
                      initialValue:
                        detail.units_limit !== null ? detail.units_limit : null
                    })(
                      <InputNumber
                        placeholder="Limite de usos"
                        step={1}
                        precision={0}
                        style={{ width: '100%' }}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} sm={8} md={8}>
                  <FormItem label="Modalidad">
                    {getFieldDecorator('modality', {
                      rules: [
                        {
                          required: true,
                          message: 'Por favor seleccione una opción de modalidad'
                        }
                      ],
                      initialValue: detail.modality
                    })(
                      <Select
                        placeholder="Por favor seleccione una opción de modalidad"
                        style={{ width: '100%' }} 
                        onChange={this.handleChangeModalityNxN}  >
                        { Object.keys(modalities).map( modality_value => (
                          <Select.Option key="modality-1" value={modality_value}>
                            {modalities[modality_value]['name']}
                          </Select.Option>
                          )
                        )}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={8}>
                  <FormItem label="Tipo">
                    {getFieldDecorator('type_value', {
                      rules: [
                        {
                          required: true,
                          message: 'Por favor seleccione una opción de tipo'
                        }
                      ],
                      initialValue: detail.type_value
                    })(
                      <Select
                        placeholder="Por favor seleccione una opción de tipo"
                        style={{ width: '100%' }}
                        disabled={ModalityNxN}>
                        <Select.Option key="type-1" value="PERCENT">
                          PORCENTAJE
                        </Select.Option>
                        <Select.Option key="type-2" value="AMOUNT">
                          MONTO
                        </Select.Option>
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={8}>
                  <FormItem label="Valor">
                    {getFieldDecorator('value', {
                      rules: [
                        {
                          required: true,
                          message: 'Por favor ingresa un valor'
                        }
                      ],
                      initialValue: detail.value
                    })(
                      <InputNumber
                        placeholder="Valor"
                        step={0.5}
                        precision={2}
                        style={{ width: '100%' }}
                        disabled={ModalityNxN}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>

              {ModalityNxN ? (
              <Row gutter={16}>
                <Col xs={24} sm={8} md={8}>
                  <FormItem label="Modalidad nxn valor 1 (Total de productos)">
                    {getFieldDecorator('modality_nxn_products_quantity', {
                      rules: [
                        {
                          required: true,
                          message: 'Por favor ingresa un valor'
                        }
                      ],
                      initialValue: detail.modality_nxn_products_quantity
                    })(
                      <InputNumber
                        placeholder="Modalidad nxn valor 1 (Total de productos)"
                        step={0}
                        precision={0}
                        style={{ width: '100%' }}
                        onChange={this.handleChangeNxNProductsQuantity}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={8}>
                  <FormItem label="Modalidad nxn valor 2 (Productos a pagar)">
                    {getFieldDecorator('modality_nxn_products_paid', {
                      rules: [
                        {
                          required: true,
                          message: 'Por favor ingresa un valor'
                        }
                      ],
                      initialValue: detail.modality_nxn_products_paid
                    })(
                      <InputNumber
                        placeholder="Modalidad nxn valor 2 (Productos a pagar)"
                        step={0}
                        precision={0}
                        style={{ width: '100%' }}
                        onChange={this.handleChangeNxNProductsPaid}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={8}>
                  <FormItem label="Resultado">
                    {'Cupón ' + (NxNProductsQuantity > 0 ? NxNProductsQuantity : 'n') + 'x' + (NxNProductsPaid > 0 ? NxNProductsPaid : 'n')}
                  </FormItem>
                </Col>
              </Row>
              ) : null }

              <Row gutter={16}>
                <Col xs={24} sm={8} md={8}>
                  <FormItem label="Fecha de inicio">
                    {getFieldDecorator('start_date', {
                      rules: [
                        {
                          required: false,
                          message: 'Por favor ingresa una fecha de inicio'
                        }
                      ],
                      initialValue:
                        detail.start_date !== null
                          ? moment(detail.start_date, 'YYYY/MM/DD')
                          : null
                    })(
                      <DatePicker
                        placeholder="Fecha de inicio"
                        style={{ width: '100%' }}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={8}>
                  <FormItem label="Fecha de finalización">
                    {getFieldDecorator('end_date', {
                      rules: [
                        {
                          required: false,
                          message: 'Por favor ingresa una fecha fin'
                        }
                      ],
                      initialValue:
                        detail.end_date !== null
                          ? moment(detail.end_date, 'YYYY/MM/DD')
                          : null
                    })(
                      <DatePicker
                        placeholder="Fecha de finalización"
                        style={{ width: '100%' }}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} sm={8} md={8}>
                  <FormItem label="Mínimo de compra">
                    {getFieldDecorator('min_purchase', {
                      rules: [
                        {
                          required: false,
                          message: 'Por favor ingresa un mínimo de compra'
                        }
                      ],
                      initialValue:
                        detail.min_purchase !== null
                          ? detail.min_purchase
                          : null
                    })(
                      <InputNumber
                        placeholder="Mínimo de compra"
                        step={1}
                        precision={2}
                        style={{ width: '100%' }}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={8}>
                  <FormItem label="Máximo de compra">
                    {getFieldDecorator('max_purchase', {
                      rules: [
                        {
                          required: false,
                          message: 'Por favor ingresa un máximo de compra'
                        }
                      ],
                      initialValue:
                        detail.max_purchase !== null
                          ? detail.max_purchase
                          : null
                    })(
                      <InputNumber
                        placeholder="Máximo de compra"
                        step={1}
                        precision={2}
                        style={{ width: '100%' }}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} sm={5} md={5}>
                  <div style={style.spaceDown}>
                    <p style={style.label}>Todos los usuarios:</p>
                    <Radio.Group
                      onChange={this.handleChangeIsGlobalUser}
                      value={isGlobalUser}
                    >
                      <Radio value={1}>SI</Radio>
                      <Radio value={0}>NO</Radio>
                    </Radio.Group>
                  </div>
                </Col>
                { !ModalityEvent ? (
                  <Col xs={24} sm={5} md={5}>
                    <div style={style.spaceDown}>
                      <p style={style.label}>
                        Aplica en productos con descuento:
                      </p>
                      <Radio.Group
                        onChange={this.handleChangeAppliesToDiscountedProducts}
                        value={appliesToDiscountedProducts}
                      >
                        <Radio value={1}>SI</Radio>
                        <Radio value={0}>NO</Radio>
                      </Radio.Group>
                    </div>
                  </Col> )
                : null}
                <Col xs={24} sm={5} md={5}>
                  <div style={style.spaceDown}>
                    <p style={style.label}>Un solo uso por usuario:</p>
                    <Radio.Group
                      onChange={this.handleChangeUniqueRedeemPerUser}
                      value={uniqueRedeemPerUser}
                    >
                      <Radio value={1}>SI</Radio>
                      <Radio value={0}>NO</Radio>
                    </Radio.Group>
                  </div>
                </Col>
              </Row>

              {isGlobalUser === 0 ? (
                <Row gutter={16}>
                  <Col xs={24} sm={8} md={8}>
                    <FormItem label="Clientes">
                      {getFieldDecorator('users', {
                        rules: [
                          {
                            required: false,
                            message: 'Por favor seleccione algunos clientes'
                          }
                        ],
                        initialValue: valueClients
                      })(
                        <Select
                          mode="multiple"
                          labelInValue
                          value={valueClients}
                          placeholder="Busque por el nombre del cliente"
                          notFoundContent={
                            fetchingClients ? <Spin size="small" /> : null
                          }
                          filterOption={false}
                          style={{ width: '100%' }}
                          allowClear
                          onSearch={this.fetchCombinationsClients}
                          onChange={this.handleChangeClients}
                        >
                          {dataClients.map(value => (
                            <Select.Option
                              key={`client-${value.id}`}
                              value={value.id.toString()}
                            >
                              {value.first_name} {value.last_name}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                </Row>
              ) : null}
              {isGlobalUser === 0 ? (
                <Row gutter={16}>
                  <Col xs={24} sm={8} md={8}>
                    <FormItem label="Tags de clientes">
                      {getFieldDecorator('user_tags', {
                        rules: [
                          {
                            required: false,
                            message: 'Por favor seleccione algunos tags'
                          }
                        ],
                        initialValue: this.userTagsDefault()
                      })(
                        <Select
                          mode="multiple"
                          style={{ width: '100%' }}
                          placeholder="Por favor seleccione algunos tags"
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {user_tags.map(value => (
                            <Select.Option
                              key={`tags-${value.id}`}
                              value={value.id.toString()}
                            >
                              {value.name}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                </Row>
              ) : null}
              <Row gutter={16}>
                <Col xs={24} sm={8} md={8}>
                  <div style={style.spaceDown}>
                    <p style={style.label}>Forma de aplicar:</p>
                    <Radio.Group
                      onChange={this.handleChangeTypeCoupon}
                      value={typeCoupon}
                    >
                      <Radio value="ORDER"> TODA LA COMPRA</Radio>
                      <Radio value="ADVANCED"> AVANZADO</Radio>
                    </Radio.Group>
                  </div>
                </Col>
              </Row>
              {typeCoupon === 'ADVANCED' ? (
                <Row>
                  { !ModalityEvent ? (
                  <Col xs={24} sm={24} md={24}>

                    <Row gutter={16}>
                      <Col xs={24} sm={8} md={8}>
                        <FormItem label="Categorias">
                          {getFieldDecorator('categories', {
                            rules: [
                              {
                                required: false,
                                message:
                                  'Por favor seleccione algunas categorias'
                              }
                            ],
                            initialValue: this.categoriesDefault()
                          })(
                            <Select
                              mode="multiple"
                              style={{ width: '100%' }}
                              placeholder="Por favor seleccione algunas categorias"
                              allowClear
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children[2].props.children[1]
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {categories.map(value => (
                                <Select.Option
                                  key={`categories-${value.id}`}
                                  value={value.id}
                                >
                                  <img
                                    src={
                                      value.banner_url === null
                                        ? productWhitoutImage
                                        : value.banner_url
                                    }
                                    alt={value.alt}
                                    style={style.miniFileCategory}
                                  />{' '}
                                  <span>
                                    {value.parent_complete !== null
                                      ? `${value.parent_complete.name}->`
                                      : ''}
                                    {value.name}{' '}
                                    {value.parent_complete === null
                                      ? this.getBrands(value.category_brand)
                                      : ''}
                                  </span>
                                </Select.Option>
                              ))}
                            </Select>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                    
                    <Row gutter={16}>
                      <Col xs={24} sm={8} md={8}>
                        <FormItem label="Tags">
                          {getFieldDecorator('tags', {
                            rules: [
                              {
                                required: false,
                                message: 'Por favor seleccione algunos tags'
                              }
                            ],
                            initialValue: this.tagsDefault()
                          })(
                            <Select
                              mode="multiple"
                              style={{ width: '100%' }}
                              placeholder="Por favor seleccione algunos tags"
                              allowClear
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {tags.map(value => (
                                <Select.Option
                                  key={`tags-${value.id}`}
                                  value={value.id.toString()}
                                >
                                  {value.name}{value.type != 'PROTECTED' ? null : ( <span style={{color: '#fd0000'}}> (protegido)</span> ) }
                                </Select.Option>
                              ))}
                            </Select>
                          )}
                        </FormItem>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col xs={24} sm={8} md={8}>
                        <FormItem label="Productos">
                          {getFieldDecorator('products', {
                            rules: [
                              {
                                required: false,
                                message:
                                  'Por favor seleccione algunos productos'
                              }
                            ],
                            initialValue: value
                          })(
                            <Select
                              mode="multiple"
                              labelInValue
                              value={value}
                              defaultValue={['china']}
                              placeholder="Busque por el nombre del producto"
                              notFoundContent={
                                fetching ? <Spin size="small" /> : null
                              }
                              filterOption={false}
                              style={{ width: '100%' }}
                              allowClear
                              onSearch={this.fetchCombinations}
                              onChange={this.handleChangeProducts}
                            >
                              {data.map(d => (
                                <Option
                                  key={d.id.toString()}
                                  value={d.id.toString()} >
                                  {d.name}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </FormItem>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col xs={24} sm={8} md={8}>
                        <div style={style.spaceDown}>
                          <p style={style.label}>
                            El cupón aplica por cada producto del carrito?
                            <Tooltip
                              title="Al seleccionar SI, el descuento se aplicará para cada producto de carrito que cumpla con los filtros avanzados. Ej. Si el usuario tiene 20 piezas de labiales de 200 pesos y el cupón está configurado por 50 pesos de descuento para la categoría labiales, el descuento total sería de 1000.00 pesos. Al seleccionar NO, el descuento solo será aplicado para el producto más barato que cumpla con los filtros avanzados"
                              placement="right"
                            >
                              <Icon
                                type="info-circle"
                                style={{ color: '#EB0089' }}
                              />
                            </Tooltip>{' '}
                            :
                          </p>
                          <Radio.Group
                            onChange={this.handleChangeApplyAllCartProducts}
                            value={applyAllCartProducts}
                            disabled={ModalityNxN}
                          >
                            <Radio value={1}>SI</Radio>
                            <Radio value={0}>NO</Radio>
                          </Radio.Group>
                        </div>
                      </Col>
                    </Row>

                  </Col> ) : (
                    <Col xs={24} sm={24} md={24}>
                      <Row gutter={16}>
                        <Col xs={24} sm={8} md={8}>
                          <FormItem label="Eventos">
                            {getFieldDecorator('events', {
                              rules: [
                                {
                                  required: false,
                                  message: 'Por favor seleccione algunos eventos'
                                }
                              ],
                              initialValue: this.eventsDefault()
                            })(
                              <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Por favor seleccione algunos eventos"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {events.map(value => (
                                  <Select.Option
                                    key={`events-${value.id}`}
                                    value={value.id.toString()}
                                  >
                                    {value.title}
                                  </Select.Option>
                                ))}
                              </Select>
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>
                  ) }
                </Row>
              ) : null}
            </Form>
          </div>
        );
      }
    }
  )
);
