import React, { Component } from 'react';
import { Table, Row, Col, Tooltip, Button, Modal } from 'antd';
import Moment from 'react-moment';
import DiscountFormView from './DiscountFormView';

export default class Discounts extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.deleteItem = this.deleteItem.bind(this);
  }

  deleteItem(id, title) {
    this.id = id;
    const { deleteDiscount } = this.props;
    Modal.confirm({
      title: 'Confirmación',
      content: `Esta seguro que desea eliminar el descuento "${title}"`,
      okText: 'Aceptar',
      cancelText: 'Cancelar',
      onOk: () => deleteDiscount({ id })
    });
  }

  renderProduct(product, discount) {
    this.product = product;
    this.discount = discount;
    if (product.type === 'COMBINATION') {
      if (discount.is_global === 1) {
        return 'TODAS LAS COMBINACIONES';
      }
      return `${discount.combination.option_attribute.name} | ${discount.combination.reference}`;
    }
    return product.name;
  }

  render() {
    const { product, loaderTableDiscounts } = this.props;
    const { discounts } = product;
    discounts.sort((a, b) => (b.id - a.id))
    const columns = [
      {
        title: 'DESCRIPCIÓN',
        dataIndex: 'description',
        key: 'description'
      },
      {
        title: 'PRODUCTO',
        dataIndex: 'is_global',
        key: 'is_global',
        render: (text, element, index) => (
          <span>{this.renderProduct(product, discounts[index])}</span>
        )
      },
      {
        title: 'DESCUENTO',
        dataIndex: 'value',
        key: 'value'
      },
      {
        title: 'TIPO',
        dataIndex: 'type_value',
        key: 'type_value',
        render: text => <span>{text === 'PERCENT' ? '%' : '$'}</span>
      },
      {
        title: 'INICIO',
        dataIndex: 'start_date',
        key: 'start_date',
        render: text => (
          <span>
            {text != null ? (
              <Moment format="DD-MM-YYYY">{text}</Moment>
            ) : (
              'Sin limite'
            )}{' '}
          </span>
        )
      },
      {
        title: 'FIN',
        dataIndex: 'end_date',
        key: 'end_date',
        render: text => (
          <span>
            {text != null ? (
              <Moment format="DD-MM-YYYY">{text}</Moment>
            ) : (
              'Sin limite'
            )}{' '}
          </span>
        )
      },
      {
        title: 'ACCIONES',
        dataIndex: 'id',
        key: 'id',
        render: (text, element, index) => (
          <Tooltip title="Eliminar descuento" placement="top">
            <Button
              type="primary"
              icon="delete"
              onClick={() => {
                this.deleteItem(
                  discounts[index].id,
                  discounts[index].description == null
                    ? ''
                    : discounts[index].description
                );
              }}
            />
          </Tooltip>
        )
      }
    ];
    return (
      <Row gutter={16} type="flex">
        <Col xs={24} sm={24} md={24}>
          <DiscountFormView
            loaderTableDiscounts={loaderTableDiscounts}
            product={product}
          ></DiscountFormView>
        </Col>
        <Col xs={24} sm={24} md={24}>
          <Table
            style={{ marginTop: 20, marginBottom: 10 }}
            columns={columns}
            dataSource={discounts}
            bordered
            pagination={false}
            loading={loaderTableDiscounts}
            scroll={{ x: 'max-content' }}
          />
        </Col>
      </Row>
    );
  }
}
