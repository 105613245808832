import { state } from 'cerebral';

export function getCategories({ http, path, get }) {
  return http
    .get(
      'admin/categories',
      {},
      { per_page: get(state.app.pagination.perPage),categoryNameSearch:get(state.categories.search.categoryNameSearch) }
    )
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}

export function setCategories({ props, store }) {
  const { result } = props;
  store.set(state.categories.list, result.data);
}
export function getCategoryFatherOnlyActive({ http, path }) {
  return http
    .get('admin/categories-father-only-active', {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setCategoryFatherOnlyActive({ props, store }) {
  const { result } = props;
  store.set(state.categories.categories, result);
}
export function getCategoryFatherAll({ http, path }) {
  return http
    .get('admin/categories-father-all', {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}

export function setCategoryFatherAll({ props, store }) {
  const { result } = props;
  store.set(state.categories.categoryFatherAll, result);
}

export function getCategoriesDetail({ props, http, path }) {
  const { id } = props.params;
  return http
    .get(`admin/categories/${id}`, {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setCategoriesDetail({ props, store }) {
  const { result } = props;
  store.set(state.categories.detail, result);
}
export function getBrandsSimple({ http, path }) {
  return http
    .get('brands-simple/', {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setBrandsSimple({ props, store }) {
  const { result } = props;
  store.set(state.products.brands, result);
}