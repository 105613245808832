import { state } from 'cerebral';

export function updateUser({ path, http, props }) {
  const { values } = props;
  const data = new FormData();
  if (typeof values.photo !== 'undefined') {
    data.append('photo', values.photo[0].originFileObj);
  }
  data.append('user_id', values.user_id);
  data.append('user_uuid', values.user_uuid);
  data.append('email', values.email);
  data.append('first_name', values.first_name);
  data.append('last_name', values.last_name);
  data.append('phone', values.phone);
  data.append('password', values.password);
  return http
    .postFile(`admin/admins/update`, data)
    .then(res => path.success({ res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function getUser({ path, http, get }) {
  const u = get(state.user.payload);
  return http
    .get(`me/${u.id}`)
    .then(user => path.success({ user }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function getUserUuid({ path, http, props }) {
  const { params } = props;
  return http
    .get(`me/${params.uuid}/uuid`)
    .then(user => path.success({ user }))
    .catch(error => error.json().then(message => path.error({ message })));
}
