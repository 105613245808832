import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Table, Tooltip, Icon, Row, Col, Select, Modal, Button } from 'antd';
import Moment from 'react-moment';
import Pagination from '@component/Elements/Pagination';

export default connect(
  {
    // states
    list: state.brands.listFilters,
    basic: state.brands.filters.basic,
    brandId: state.brands.filtersBrandId,
    loader: state.brands.filters.loader,
    stateDelete: state.brands.filters.delete,
    updatedFilterBasic: sequences.updatedFilterBasic,
    deleteFilter: sequences.deleteFilterBasic,
    handleClickOpenModal: sequences.handleClickOpenModalFilterBasic,
    handleClickCloseModal: sequences.handleClickCloseModalFilterBasic
  },
  class BrandListFilterView extends Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.handleChangeFilterBasic = this.handleChangeFilterBasic.bind(this);
    }

    handleChangeFilterBasic(value) {
      this.value = value;
      const { updatedFilterBasic } = this.props;
      updatedFilterBasic({ value });
    }

    render() {
      const {
        list,
        brandId,
        basic,
        loader,
        stateDelete,
        handleClickOpenModal,
        handleClickCloseModal,
        deleteFilter
      } = this.props;
      const columns = [
        {
          title: 'TITULO',
          dataIndex: 'title',
          key: 'title',
          render: (text, element, index) => (
            <a
              href={`/marcas/${list[index].brand_id}/filtros/${list[index].id}/editar`}
            >
              {text}
            </a>
          )
        },
        {
          title: 'STATUS',
          dataIndex: 'type',
          key: 'type',
          render: (text, element, index) => (
            <span>{index === 0 ? 'Actual' : 'Pasado'}</span>
          )
        },
        {
          title: 'FECHA CREACIÓN',
          dataIndex: 'created_at',
          key: 'created_at',
          render: text => <Moment format="DD-MM-YYYY hh:mm A">{text}</Moment>
        },
        {
          title: 'ACCIONES',
          dataIndex: 'id',
          key: 'id',
          render: (text, element, index) => (
            <Tooltip title="Eliminar filtro" placement="top">
              <Button
                type="primary"
                icon="delete"
                onClick={() => {
                  handleClickOpenModal(list[index]);
                }}
              />
            </Tooltip>
          )
        }
      ];
      return (
        <div>
          <div className="move-content-right">
            <Tooltip title="Crear filtro" placement="left">
              <a
                href={`/marcas/${brandId}/filtros/nuevo`}
                className="ant-btn ant-btn-primary ant-btn-icon-only"
                rel="noopener noreferrer"
              >
                <Icon type="plus" />
              </a>
            </Tooltip>
            <Tooltip title="Atras" placement="left">
              <a
                style={{ marginLeft: 10 }}
                href="/marcas"
                className="ant-btn ant-btn-primary ant-btn-icon-only"
                rel="noopener noreferrer"
              >
                <Icon type="arrow-left" />
              </a>
            </Tooltip>
          </div>
          <Row gutter={16} style={{ marginBottom: 20 }}>
            <Col xs={24} sm={4} md={4}>
              <Select
                defaultValue={basic}
                placeholder="Tipo"
                style={{ width: '100%' }}
                onChange={this.handleChangeFilterBasic}
              >
                <Select.Option key="type-1" value="FILTER_1">
                  Filtro 1
                </Select.Option>
                <Select.Option key="type-2" value="FILTER_2">
                  Filtro 2
                </Select.Option>
                <Select.Option key="type-3" value="NEWS">
                  Novedades
                </Select.Option>
              </Select>
            </Col>
          </Row>
          <Modal
            closable={false}
            visible={stateDelete.open}
            onCancel={() => {
              handleClickCloseModal();
            }}
            footer={[
              <Button
                type="primary"
                onClick={() => {
                  deleteFilter();
                }}
                loading={stateDelete.loader}
              >
                Confirmar
              </Button>,
              <Button
                onClick={() => {
                  handleClickCloseModal();
                }}
              >
                Cancelar
              </Button>
            ]}
          >
            <h4>¿Desea eliminar el filtro '{stateDelete.name}' ?</h4>
            <p>
              Favor de verificar que el filtro seleccionado sea el que realmente
              desea eliminar.
            </p>
          </Modal>
          <Table
            bordered
            size="small"
            dataSource={list}
            columns={columns}
            pagination={false}
            loading={loader}
            scroll={{ x: 'max-content' }}
          />
          <Pagination
            list="brands.listFilters"
            extraBySearch={{ brand_id: brandId, type: basic }}
            apiUrl="admin/filter-product-brand"
          />
        </div>
      );
    }
  }
);
