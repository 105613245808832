/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Menu, Avatar, Dropdown, Icon, Badge, List } from 'antd';
import classnames from 'classnames/bind';
import { TIME_LOAD_NOTIFICATIONS } from '../../utils/constants';
import styles from './style.css';

let timeout = 0;
const cx = classnames.bind(styles);
export default connect(
  {
    // States
    user: state.user,
    title: state.app.titlePage,
    collapsed: state.collapsed,
    data: state.notifications,
    // Sequences
    logout: sequences.logout,
    toggleLefSidebar: sequences.toggleLefSidebar,
    openOrCloseLefSidebarStatus: sequences.openOrCloseLefSidebarStatus,
    getNotificationByOrders: sequences.getNotificationByOrders
  },
  class Header extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.getNotifications = this.getNotifications.bind(this);
    }

    componentDidMount() {
      this.getNotifications();
      timeout = setInterval(() => {
        this.getNotifications();
      }, TIME_LOAD_NOTIFICATIONS);
    }

    componentWillUnmount() {
      clearInterval(timeout);
    }

    // eslint-disable-next-line class-methods-use-this
    getNotifications() {
      const { getNotificationByOrders } = this.props;
      getNotificationByOrders();
    }

    render() {
      const {
        user,
        logout,
        collapsed,
        toggleLefSidebar,
        openOrCloseLefSidebarStatus,
        data
      } = this.props;
      const menu = (
        <Menu>
          <Menu.Item>
            <a rel="noopener noreferrer" href="/perfil">
              <Icon type="user" />
              <span className={cx('ml-10')}>Mi cuenta</span>
            </a>
          </Menu.Item>
          <Menu.Item>
            <a
              href="/"
              rel="noopener noreferrer"
              onClick={() => {
                logout();
              }}
            >
              <Icon type="logout" />
              <span className={cx('ml-10')}>Cerrar Sesión</span>
            </a>
          </Menu.Item>
        </Menu>
      );
      const notifications = (
        <List
          className={cx('notification-box')}
          itemLayout="horizontal"
          dataSource={data}
          selectable="false"
          renderItem={item => (
            <List.Item key={item.uuid}>
              <List.Item.Meta
                avatar={<Avatar icon="shopping" />}
                title={
                  <a href={`/ordenes/${item.uuid}`}>
                    {item.order_status_id === 1
                      ? 'Compra pendiente de pago'
                      : null}
                    {item.order_status_id === 2
                      ? 'Compra en confirmación de pedido'
                      : null}
                    {item.order_status_id === 3
                      ? 'Compra pendiente de envio'
                      : null}
                    {item.order_status_id === 4 ? 'Compra en camino' : null}
                    {item.order_status_id === 5
                      ? 'Compra pendiente de entrega'
                      : null}
                    {item.order_status_id === 6
                      ? 'Compra pendiente de devolución'
                      : null}
                    {item.order_status_id === 7
                      ? 'Compra en devolución en camino'
                      : null}
                    {item.order_status_id === 8 ? 'Compra en resolución' : null}
                    {item.order_status_id === 9 ? 'Compra recibida' : null}
                  </a>
                }
                description={`Cliente ${item.first_name} ${item.last_name}`}
              />
            </List.Item>
          )}
        />
      );

      if (user.isLogin) {
        let noty = null;
        if (user.payload.rol_admin == 'ADMIN') {
          noty = (
            <Menu.Item key="notifications">
              <Dropdown overlay={notifications} placement="bottomCenter">
                <div>
                  <Badge dot count={10}>
                    <Icon type="notification" />
                  </Badge>
                </div>
              </Dropdown>
            </Menu.Item>
          );
        }

        return (
          <div
            className={`${cx('relative')} ${cx('horizontal-menu-content')} ${
              !collapsed ? cx('open-menu') : cx('close-menu')
            }`}
          >
            {openOrCloseLefSidebarStatus()}
            <Icon
              className={`${cx('button-open-close')} trigger`}
              type={collapsed ? 'menu-unfold' : 'menu-fold'}
              onClick={toggleLefSidebar}
            />

            <Menu className={` ${cx('horizontal-menu')} `} mode="horizontal">
              {noty}
              <Menu.Item key="profile">
                <Dropdown overlay={menu} placement="bottomCenter">
                  <div>
                    <span className={cx('user-name')}>
                      {user.payload.first_name} {user.payload.last_name}
                    </span>
                    <Avatar src={user.payload.photo} />
                  </div>
                </Dropdown>
              </Menu.Item>
            </Menu>
          </div>
        );
      }
      return null;
    }
  }
);
