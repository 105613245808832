import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { sequences } from 'cerebral';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  InputNumber,
  DatePicker
} from 'antd';

import { createValidation } from '@utils';

export default Form.create()(
  connect(
    {
      // secuences
      submit: sequences.saveDiscount
    },
    class DiscountFormView extends Component {
      constructor(props) {
        super(props);
        this.state = {};
        this.handleSubmit = this.handleSubmit.bind(this);
      }

      handleSubmit(e) {
        e.preventDefault();
        const { form, submit, product } = this.props;
        form.validateFields((err, values) => {
          if (!err) {
            values.product_uuid = product.uuid;
            if (product.type === 'REGULAR') {
              values.combination = 'ALL';
            }
            submit({ values });
          }
        });
      }

      render() {
        const { form, loaderTableDiscounts, product } = this.props;

        const { getFieldDecorator } = form;
        const FormItem = Form.Item;
        return (
          <div style={{ backgroundColor: '#f1f2f6', padding: '1rem' }}>
            <Form onSubmit={this.handleSubmit} layout="horizontal">
              {product.type === 'REGULAR' ? (
                <div>
                  <Row gutter={16}>
                    <Col xs={24} sm={4} md={4}>
                      <FormItem label="Fecha inicio">
                        {getFieldDecorator('start_date', {
                          rules: [
                            {
                              required: false,
                              message: 'Por favor ingresa una fecha de inicio'
                            }
                          ]
                        })(
                          <DatePicker
                            placeholder="Fecha inicio"
                            style={{ width: '100%' }}
                          />
                        )}
                      </FormItem>
                    </Col>
                    <Col xs={24} sm={4} md={4}>
                      <FormItem label="Fecha fin">
                        {getFieldDecorator('end_date', {
                          rules: [
                            {
                              required: false,
                              message: 'Por favor ingresa una fecha fin'
                            }
                          ]
                        })(
                          <DatePicker
                            placeholder="Fecha fin"
                            style={{ width: '100%' }}
                          />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={4} md={4}>
                      <FormItem label="Tipo">
                        {getFieldDecorator('type_value', {
                          rules: [
                            {
                              required: true,
                              message: 'Por favor seleccione una opción de tipo'
                            }
                          ],
                          initialValue: 'PERCENT'
                        })(
                          <Select
                            placeholder="Por favor seleccione una opción de tipo"
                            style={{ width: '100%' }}
                          >
                            <Select.Option key="type-1" value="PERCENT">
                              PORCENTAJE
                            </Select.Option>
                            <Select.Option key="type-2" value="AMOUNT">
                              MONTO
                            </Select.Option>
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                    <Col xs={24} sm={4} md={4}>
                      <FormItem label="Valor">
                        {getFieldDecorator('value', {
                          rules: [
                            {
                              required: true,
                              message: 'Por favor ingresa un valor'
                            }
                          ]
                        })(
                          <InputNumber
                            placeholder="Valor"
                            step={0.5}
                            precision={2}
                            style={{ width: '100%' }}
                          />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={8} md={8}>
                      <FormItem label="Descripción">
                        {getFieldDecorator('description', {
                          rules: [
                            createValidation(
                              false,
                              'Por favor ingresa una descripción',
                              ''
                            )
                          ]
                        })(<Input placeholder="Descripción" />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={8} md={8}>
                      <FormItem className="move-content-right">
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={loaderTableDiscounts}
                        >
                          Guardar descuento
                        </Button>
                      </FormItem>
                    </Col>
                  </Row>
                </div>
              ) : (
                <div>
                  <Row gutter={16}>
                    <Col xs={24} sm={6} md={6}>
                      <FormItem label="Para">
                        {getFieldDecorator('combination', {
                          rules: [
                            {
                              required: true,
                              message: 'Por favor seleccione algun producto'
                            }
                          ],
                          initialValue: 'ALL'
                        })(
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Por favor seleccione algun producto"
                            showSearch
                            optionFilterProp="children"
                          >
                            <Select.Option key="all" value="ALL">
                              Todas las combinaciones
                            </Select.Option>
                            {product.product_combination.map(value => (
                              <Select.Option
                                key={`producto-${value.id}`}
                                value={value.id}
                              >
                                {value.option_attribute.name} | {value.reference}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                    <Col xs={24} sm={3} md={3}>
                      <FormItem label="Fecha inicio">
                        {getFieldDecorator('start_date', {
                          rules: [
                            {
                              required: false,
                              message: 'Por favor ingresa una fecha de inicio'
                            }
                          ]
                        })(
                          <DatePicker
                            placeholder="Fecha inicio"
                            style={{ width: '100%' }}
                          />
                        )}
                      </FormItem>
                    </Col>
                    <Col xs={24} sm={3} md={3}>
                      <FormItem label="Fecha fin">
                        {getFieldDecorator('end_date', {
                          rules: [
                            {
                              required: false,
                              message: 'Por favor ingresa una fecha fin'
                            }
                          ]
                        })(
                          <DatePicker
                            placeholder="Fecha fin"
                            style={{ width: '100%' }}
                          />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={6} md={6}>
                      <FormItem label="Tipo">
                        {getFieldDecorator('type_value', {
                          rules: [
                            {
                              required: true,
                              message: 'Por favor seleccione una opción de tipo'
                            }
                          ],
                          initialValue: 'PERCENT'
                        })(
                          <Select
                            placeholder="Por favor seleccione una opción de tipo"
                            style={{ width: '100%' }}
                          >
                            <Select.Option key="type-1" value="PERCENT">
                              PORCENTAJE
                            </Select.Option>
                            <Select.Option key="type-2" value="AMOUNT">
                              MONTO
                            </Select.Option>
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                    <Col xs={24} sm={6} md={6}>
                      <FormItem label="Valor">
                        {getFieldDecorator('value', {
                          rules: [
                            {
                              required: true,
                              message: 'Por favor ingresa un valor'
                            }
                          ]
                        })(
                          <InputNumber
                            placeholder="Valor"
                            step={0.5}
                            precision={2}
                            style={{ width: '100%' }}
                          />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={12} md={12}>
                      <FormItem label="Descripción">
                        {getFieldDecorator('description', {
                          rules: [
                            createValidation(
                              false,
                              'Por favor ingresa una descripción',
                              ''
                            )
                          ]
                        })(<Input placeholder="Descripción" />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={12} md={12}>
                      <FormItem className="move-content-right">
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={loaderTableDiscounts}
                        >
                          Guardar descuento
                        </Button>
                      </FormItem>
                    </Col>
                  </Row>
                </div>
              )}
            </Form>
          </div>
        );
      }
    }
  )
);
