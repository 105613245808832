import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { Icon, Progress, Modal, Button } from 'antd';
import Dropzone from 'react-dropzone';
import arrayMove from 'array-move';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import styles from '../files.css';

const cx = classNames.bind(styles);

export default class SlidersComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onDrop = this.onDrop.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.onSortManual = this.onSortManual.bind(this);
  }

  onDrop(acceptedFiles) {
    this.acceptedFiles = acceptedFiles;
    if (acceptedFiles.length > 0) {
      const { event_id, sliderCreateAction, slug } = this.props;
      sliderCreateAction({ event_id, files: acceptedFiles, slug });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  onSortEnd({ oldIndex, newIndex }) {
    const { gallery, event_id } = this.props;
    const result = gallery.sort((a, b) => (a.sort > b.sort ? -1 : 1));
    const newData = arrayMove(result, oldIndex, newIndex);
    const { sliderupdateSortMedia } = this.props;
    const values = { data: newData };
    newData.filter(e =>  e);
    sliderupdateSortMedia({ values, event_id });
  }

  onSortManual() {
    const { gallery, slug } = this.props;
    const result = gallery.sort((a, b) => (a.sort > b.sort ? 1 : -1));
    const { sliderupdateSortMedia } = this.props;
    const values = { data: result };
    sliderupdateSortMedia({ values, slug });
  }

  getNameFile(name) {
    this.name = name;
    const splitName = name.split('/');
    return splitName.pop();
  }

  thumbs() {
    const { gallery, handleSlidersDeleteModal } = this.props;
    const result = gallery;

    const SortableContainer = sortableContainer(({ children }) => {
      return <ul className={cx('content-gallery')}>{children}</ul>;
    });
    const DragHandle = sortableHandle(() => (
      <span className={cx('content-pin-move')}>
        <Icon type="pushpin" />
      </span>
    ));

    const SortableItem = sortableElement(({ value }) => (
      <li key={value.id} className={cx('list-gallery')}>
        <img
          src={value.url}
          alt={value.alt}
          className={cx('file')}
        />
        <Icon
          type="delete"
          className={cx('trash')}
          onClick={() => {
            handleSlidersDeleteModal(value);
          }}
        />
        <DragHandle />
        <p className={cx('list-number')}>
          {value.sort}

          <span className={cx('nameFile')}>
            {' '}
            {this.getNameFile(value.url)}
          </span>
        </p>
      </li>
    ));

    return (
      <aside className={cx('aside')}>
        <h4 className={cx('title')}>Imagenes guardadas (Ancho: 1920 x Alto: 770):</h4>
        <p>
          Para cambiar el orden de las imagenes de clic en el icono de pin (
          <Icon type="pushpin" />) sin soltar y mueva la imagen hasta la
          posición deseada.
        </p>
        <SortableContainer onSortEnd={this.onSortEnd} axis="xy" useDragHandle>
          {gallery.sort((a, b) => (a.sort > b.sort ? 1 : -1)).map(file => (
              <SortableItem key={file.sort} index={file.sort} value={file} />
            ))}
        </SortableContainer>

        {result.length === 0 ? (
          <p className={cx('withoutresults')}>Sin resultados</p>
        ) : null}
      </aside>
    );
  }

  render() {
    const {
      show,
      loading,
      stateDelete,
      handleSlidersDeleteCloseModal,
      sliderDeleteAction
    } = this.props;
    const max = 20000000;
    return (
      <div>
        <h1 className={cx('title2')}>
          Solo se aceptarán imágenes con formato .jpeg, .png, .gif con un maximo de{' '}
          {max / 1000000}MB de tamaño. (Ancho: 1920 x Alto: 770)
        </h1>
        {
          show.archived != 1 ? 
          (
        <Dropzone
          accept="image/jpeg, image/png, image/gif"
          onDrop={this.onDrop}
          minSize={0}
          maxSize={max}
        >
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragReject,
            rejectedFiles
          }) => (
            <section>
              <div {...getRootProps({ className: cx('dropzone') })}>
                <input {...getInputProps()} />
                {!isDragActive &&
                  'Arrastre algunos archivos en esta área, o haga clic para seleccionar algunos archivos'}
                {isDragActive &&
                  !isDragReject &&
                  'Favor de dejar los archivos en esta área para guardarlos'}
                {isDragReject && (
                  <p className={cx('error')}>
                    Se encontraron archivos con formato no soportado
                  </p>
                )}
                {rejectedFiles.length > 0 && rejectedFiles[0].size > max && (
                  <p className={cx('error')}>
                    Se encontraron archivos con un tamaño superior a los 5MB
                    permitidos
                  </p>
                )}
              </div>
            </section>
          )}
        </Dropzone>) : null
        }
      
        {loading === true ? (
          <div className={cx('progress-gallery')}>
            <Progress percent={100} status="active" showInfo={false} />
          </div>
        ) : null}
        {this.thumbs()}
        <Modal
          closable={false}
          visible={stateDelete.open}
          onCancel={() => {
            handleSlidersDeleteCloseModal();
          }}
          footer={[
            <Button
              type="primary"
              onClick={() => {
                sliderDeleteAction({ 'event_id': show.id });
              }}
              loading={stateDelete.loader}
            >
              Confirmar
            </Button>,
            <Button
              onClick={() => {
                handleSlidersDeleteCloseModal();
              }}
            >
              Cancelar
            </Button>
          ]}
        >
          <h4>¿Desea eliminar el archivo?</h4>
          <p>
            Favor de verificar que el archivo seleccionado sea el que realmente
            desea eliminar.
          </p>
          <br />
          <img
            src={stateDelete.file}
            alt="imagen a eliminar"
            className={cx('imagePreview')}
          />
        </Modal>
      </div>
    );
  }
}
