import { set } from 'cerebral/factories';
import { state, props } from 'cerebral';
import { WithRouter, setPagination } from '@modules/actions';
import notify from '@utils/notify';
import page from 'page';
import * as actions from './actions';

const BrandListRouter = WithRouter([
  set(state.app.currentPage, 'BrandListView'),
  actions.getBrands,
  {
    success: [setPagination, actions.setBrands],
    error: [notify(props.message.error, 'error')]
  }
]);

const BrandNewRouter = WithRouter([
  set(state.app.currentPage, 'BrandFormView')
]);
const BrandListFiltersRouter = WithRouter([
  set(state.brands.filtersBrandId, null),
  actions.getFilterBrands,
  {
    success: [setPagination, actions.setFilterBrands],
    error: [notify(props.message.error, 'error')]
  },
  set(state.app.currentPage, 'BrandListFilterView')
]);
const BrandListBannerRouter = WithRouter([
  set(state.brands.BannerBrandId, null),

  actions.getBannerBrands,
  {
    success: [setPagination, actions.setBannerBrands],
    error: [notify(props.message.error, 'error')]
  },
  set(state.app.currentPage, 'BannerListView')
]);
const BrandListBannerSecondaryRouter = WithRouter([
  set(state.brands.BannerBrandId, null),

  actions.getBannerSecondaryBrands,
  {
    success: [setPagination, actions.setBannerSecondaryBrands],
    error: [notify(props.message.error, 'error')]
  },
  set(state.app.currentPage, 'BannerSecondaryListView')
]);
const FiltersNewRouter = WithRouter([
  set(state.brands.filtersBrandId, null),
  actions.setFilterBrandsId,
  actions.getTagsSimple,
  {
    success: [setPagination, actions.setTagsSimple],
    error: [notify(props.message.error, 'error')]
  },
  set(state.app.currentPage, 'FilterFormView')
]);

const filtersUpdte = WithRouter([
  set(state.brands.detailFilter, null),
  actions.getFilterDetail,
  {
    success: [setPagination, actions.setFilterDetail],
    error: [notify(props.message.error, 'error')]
  },
  set(state.app.currentPage, 'FilterFormUpdateView')
]);
const FilterDetailRouter = WithRouter([
  set(state.brands.detailFilter, null),
  actions.getFilterDetail,
  {
    success: [setPagination, actions.setFilterDetail],
    error: [notify(props.message.error, 'error')]
  },
  set(state.app.currentPage, 'FilterDetailView')
]);
const saveBrand = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values } = props;
    const file = values.url_photo[0].originFileObj;
    const data = new FormData();
    data.append('url_photo', file);
    data.append('name', values.name);
    data.append('file', values.file);
    data.append('active', 1);
    return http
      .postFile('admin/brands', data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Marca creada', 'success'),
      () => {
        page.show('/marcas');
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];
const saveFilter = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values, brandId } = props;
    return http
      .post('admin/filter-product-brand', {
        brand_id: brandId,
        tags: values.tags,
        title: values.title,
        type: values.type
      })
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Filtro creado', 'success'),
      ({ props }) => {
        page.show(`/marcas/${props.res.brand_id}/filtros`);
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];
const updateFilter = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values, filterId } = props;
    return http
      .patch(`admin/filter-product-brand/${filterId}/update`, {
        title: values.title,
        tags: values.tags
      })
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Filtro actualizado', 'success'),
      ({ props }) => {
        page.show(`/marcas/${props.res.brand_id}/filtros`);
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];
const BrandFormViewUpdate = WithRouter([
  set(state.brands.detail, {}),
  set(state.app.currentPage, 'BrandFormViewUpdate'),
  actions.getBrandDetail,
  {
    success: [actions.setBrandDetail],
    error: [notify(props.message.error, 'error')]
  }
]);
const updateBrand = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values, id } = props;
    const data = new FormData();
    data.append('brand_id', id);
    data.append('name', values.name);
    data.append('file', values.file);
    data.append('active', values.active);
    if (typeof values.url_photo !== 'undefined') {
      const file = values.url_photo[0].originFileObj;
      data.append('url_photo', file);
    }
    return http
      .postFile('admin/brands/update', data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Marca actualizada', 'success'),
      () => {
        page.show('/marcas');
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];
const updatedFilterBasic = [
  ({ store, props, get }) => {
    store.set(state.brands.filters.loader, true);
    store.set(state.brands.filters.basic, props.value);
  },
  actions.getFilterBrands,
  {
    success: [setPagination, actions.setFilterBrands],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.brands.filters.loader, false);
  }
];
const handleClickOpenModalFilterBasic = [
  ({ props, store }) => {
    store.set(state.brands.filters.delete, {
      id: props.id,
      file: props.title,
      open: true,
      loader: false
    });
  }
];
const handleClickCloseModalFilterBasic = [
  ({ store }) => {
    store.set(state.brands.filters.delete, {
      id: null,
      file: null,
      open: false,
      loader: false
    });
  }
];

const deleteFilterBasic = [
  ({ store }) => {
    store.set(state.brands.filters.delete.loader, true);
  },
  ({ http, path, get }) => {
    return http
      .delete(
        `admin/filter-product-brand/${get(
          state.brands.filters.delete.id
        )}/delete`,
        {}
      )
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Filtro eliminado', 'success'),
      ({ store }) => {
        store.set(state.brands.filters.delete, {
          id: null,
          file: null,
          open: false,
          loader: false
        });
      },
      actions.getFilterBrands,
      {
        success: [setPagination, actions.setFilterBrands],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [
      ({ store }) => {
        store.set(state.brands.filters.delete, {
          id: null,
          file: null,
          open: false,
          loader: false
        });
      },
      notify(props.message.error, 'error')
    ]
  },
  ({ store }) => {
    store.set(state.brands.filters.delete.loader, false);
  }
];
const updateSortMediaBanners = [
  ({ props, http, path }) => {
    const { values } = props;

    return http
      .post(`admin/banner-brand-sort`, values)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Orden actualizado', 'success'),
      ({ props }) => {
        page.show(`/marcas/${props.res.brand_id}/banners`);
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];
const BannersNewRouter = WithRouter([
  set(state.brands.BannerBrandId, null),
  actions.setBannersBrandsId,
  set(state.app.currentPage, 'BannersFormView')
]);
const BannerSecondaryNewRouter = WithRouter([
  set(state.brands.BannerBrandId, null),
  actions.setBannersBrandsId,
  set(state.app.currentPage, 'BannerSecondaryFormView')
]);

const saveBannerBrand = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values } = props;
    const data = new FormData();
    data.append('brand_id', values.brand_id);
    data.append('type', values.type);
    data.append('cta', values.cta);
    if (values.type === 'IMAGE') {
      const file = values.url[0].originFileObj;
      data.append('url', file);
      const thumb = values.thumb[0].originFileObj;
      data.append('thumb', thumb);
      data.append('alt', values.alt);
    } else {
      data.append('code', values.code);
    }
    return http
      .postFile('admin/banner-brand', data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Banner creado', 'success'),
      ({ props }) => {
        page.show(`/marcas/${props.res.brand_id}/banners`);
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];
const saveBannerSecondaryBrand = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values } = props;
    const data = new FormData();
    data.append('brand_id', values.brand_id);
    const file = values.url[0].originFileObj;
    data.append('url', file);
    const thumb = values.thumb[0].originFileObj;
    data.append('thumb', thumb);
    data.append('alt', values.alt);
    return http
      .postFile('admin/banner-secondary-brand', data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Banner actualizado', 'success'),
      ({ props }) => {
        page.show(`/marcas/${props.res.brand_id}/banner-secundario`);
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];
const handleClickOpenModalBanners = [
  ({ props, store }) => {
    store.set(state.brands.deleteBanners, {
      id: props.id,
      file: props.url,
      open: true,
      loader: false
    });
  }
];
const handleClickCloseModalBanners = [
  ({ store }) => {
    store.set(state.brands.deleteBanners, {
      id: null,
      file: null,
      open: false,
      loader: false
    });
  }
];
const deleteFileBanner = [
  ({ store }) => {
    store.set(state.brands.deleteBanners.loader, true);
  },
  ({ http, path, get }) => {
    return http
      .delete(
        `admin/banner-brand/${get(state.brands.deleteBanners.id)}/delete`,
        {}
      )
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Banner eliminado', 'success'),
      ({ store }) => {
        store.set(state.brands.deleteBanners, {
          id: null,
          file: null,
          open: false,
          loader: false
        });
      },
      ({ props }) => {
        page.show(`/marcas/${props.res.brand_id}/banners`);
      }
    ],
    error: [
      ({ store }) => {
        store.set(state.brands.deleteBanners, {
          id: null,
          file: null,
          open: false,
          loader: false
        });
      },
      notify(props.message.error, 'error')
    ]
  },
  ({ store }) => {
    store.set(state.brands.deleteBanners.loader, false);
  }
];
const deleteFileBannerSecondary = [
  ({ store }) => {
    store.set(state.brands.deleteBanners.loader, true);
  },
  ({ http, path, get }) => {
    return http
      .delete(
        `admin/banner-secondary-brand/${get(
          state.brands.deleteBanners.id
        )}/delete`,
        {}
      )
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Banner eliminado', 'success'),
      ({ store }) => {
        store.set(state.brands.deleteBanners, {
          id: null,
          file: null,
          open: false,
          loader: false
        });
      },
      ({ props }) => {
        page.show(`/marcas/${props.res.brand_id}/banner-secundario`);
      }
    ],
    error: [
      ({ store }) => {
        store.set(state.brands.deleteBanners, {
          id: null,
          file: null,
          open: false,
          loader: false
        });
      },
      notify(props.message.error, 'error')
    ]
  },
  ({ store }) => {
    store.set(state.brands.deleteBanners.loader, false);
  }
];
export default {
  routes: {
    list: BrandListRouter,
    new: BrandNewRouter,
    filtersUpdte,
    update: BrandFormViewUpdate,
    listFilters: BrandListFiltersRouter,
    filtersNew: FiltersNewRouter,
    filtersDetail: FilterDetailRouter,
    listBanners: BrandListBannerRouter,
    BannersNew: BannersNewRouter,
    listBannerSecondary: BrandListBannerSecondaryRouter,
    BannerSecondaryNew: BannerSecondaryNewRouter
  },
  sequences: {
    saveBrand,
    updateBrand,
    saveFilter,
    updateFilter,
    updatedFilterBasic,
    handleClickOpenModalFilterBasic,
    handleClickCloseModalFilterBasic,
    deleteFilterBasic,
    updateSortMediaBanners,
    saveBannerBrand,
    handleClickOpenModalBanners,
    handleClickCloseModalBanners,
    deleteFileBanner,
    deleteFileBannerSecondary,
    saveBannerSecondaryBrand
  }
};
