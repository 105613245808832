export default function(target) {
  function shift(context) {
    if (!context.resolve.isTag(target, 'state', 'moduleState')) {
      throw new Error(
        'Cerebral factory.shift: You have to use the STATE or MODULESTATE tag as first argument'
      )
    }

    context.store.shift(target)
  }

  shift.displayName = `factory.shift(${String(target)})`

  return shift
}
