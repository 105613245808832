import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { Form, Input, Button, Row, Col, Tooltip, Icon } from 'antd';
import { state, sequences } from 'cerebral';
import { createValidation } from '@utils';

export default Form.create()(
  connect(
    {
      // states
      loadingLogin: state.user.loginButtonDisable,
      detail: state.options.detail,
      // sequences
      submit: sequences.updateOption
    },
    class OptionsFormViewUpdate extends Component {
      constructor(props) {
        super(props);
        this.state = {};
        this.handleSubmit = this.handleSubmit.bind(this);
      }

      handleSubmit(e) {
        e.preventDefault();
        const { form, submit, detail } = this.props;
        form.validateFields((err, values) => {
          if (!err) {
            submit({ id: detail.id, values });
          }
        });
      }

      render() {
        const { detail, form, loadingLogin } = this.props;
        const { getFieldDecorator } = form;
        const FormItem = Form.Item;
        return (
          <div>
            {Object.keys(detail).length === 0 ? (
              ''
            ) : (
              <Form onSubmit={this.handleSubmit} layout="horizontal">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24}>
                    <FormItem className="move-content-right">
                      <Tooltip title="Guardar opción" placement="left">
                        <Button
                          icon="save"
                          type="primary"
                          htmlType="submit"
                          loading={loadingLogin}
                        />
                      </Tooltip>
                      <Tooltip title="Cancelar" placement="left">
                        <a
                          style={{ marginLeft: 10 }}
                          href="/opciones"
                          className="ant-btn ant-btn-primary ant-btn-icon-only"
                          rel="noopener noreferrer"
                        >
                          <Icon type="arrow-left" />
                        </a>
                      </Tooltip>
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={8} md={8}>
                    <FormItem label="Nombre">
                      {getFieldDecorator('name', {
                        rules: [
                          createValidation(
                            true,
                            'Por favor ingresa un nombre',
                            ''
                          )
                        ],
                        initialValue: detail.name
                      })(<Input placeholder="Nombre" />)}
                    </FormItem>
                  </Col>
                </Row>
              </Form>
            )}
          </div>
        );
      }
    }
  )
);
