import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Table, Button, Modal, Tooltip, Icon, Row, Col, Input } from 'antd';
import Moment from 'react-moment';
import Pagination from '@component/Elements/Pagination';
import debounce from 'lodash/debounce';
import productWhitoutImage from '../../../assets/product_whitout_image.png';

const style = {
  color: {
    width: 30,
    height: 30,
    border: 'solid 1px'
  }
};
export default connect(
  {
    // states
    stateDelete: state.attributes.delete,
    extraBySearch: state.attributes.search,
    list: state.attributes.list,
    father: state.attributes.father,
    debounceTime: state.debounceTime,
    // secuences
    deleteAttribute: sequences.deleteAttribute,
    handleClickOpenModal: sequences.handleClickOpenModalAttribute,
    handleClickCloseModal: sequences.handleClickCloseModalAttribute,
    updatedFilterGeneralAttributes: sequences.updatedFilterGeneralAttributes
  },
  class AttributesListView extends Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.handleSearchBySearchName = this.handleSearchBySearchName.bind(this);
      this.handleSearchDebounce = debounce(
        this.handleSearchDebounce,
        props.debounceTime
      );
    }

    handleSearchBySearchName(e) {
      this.e = e;
      const { value } = e.target;
      this.handleSearchDebounce('searchName', value);
    }

    handleSearchDebounce(key, value) {
      this.key = key;
      this.value = value;
      const { father } = this.props;
      const { updatedFilterGeneralAttributes } = this.props;
      updatedFilterGeneralAttributes({ key, value, option_id: father });
    }

    render() {
      const {
        list,
        stateDelete,
        deleteAttribute,
        handleClickCloseModal,
        handleClickOpenModal,
        father,
        extraBySearch
      } = this.props;
      const columns = [
        {
          title: 'Tag',
          dataIndex: 'name',
          key: 'name',
          render: (text, element, index) => (
            <a
              href={`/opciones/${list[index].option_id}/atributos/${list[index].id}`}
            >
              {text}
            </a>
          )
        },
        {
          title: 'Imagen',
          dataIndex: 'image',
          key: 'image',
          render: (text, element, index) => (
            <img
              src={text !== null && text !== '' ? text : productWhitoutImage}
              alt="atributo"
              style={style.color}
            />
          )
        },
        {
          title: 'FECHA CREACIÓN',
          dataIndex: 'created_at',
          key: 'created_at',
          render: text => <Moment format="DD-MM-YYYY hh:mm A">{text}</Moment>
        },
        {
          title: 'ACCIONES',
          dataIndex: 'id',
          key: 'id',
          render: (text, element, index) => (
            <Tooltip title="Eliminar atributo" placement="top">
              <Button
                type="primary"
                icon="delete"
                onClick={() => {
                  handleClickOpenModal(list[index]);
                }}
              />
            </Tooltip>
          )
        }
      ];
      return (
        <div>
          <Modal
            closable={false}
            visible={stateDelete.open}
            onCancel={() => {
              handleClickCloseModal();
            }}
            footer={[
              <Button
                type="primary"
                onClick={() => {
                  deleteAttribute();
                }}
                loading={stateDelete.loader}
              >
                Confirmar
              </Button>,
              <Button
                onClick={() => {
                  handleClickCloseModal();
                }}
              >
                Cancelar
              </Button>
            ]}
          >
            <h4>¿Desea eliminar el atributo '{stateDelete.name}' ?</h4>
            <p>
              Favor de verificar que el atributo seleccionado sea el que
              realmente desea eliminar.
            </p>
          </Modal>
          <div className="move-content-right">
            <Tooltip title="Crear atributo" placement="left">
              <a
                href={`/opciones/${father}/atributos/nuevo`}
                className="ant-btn ant-btn-primary ant-btn-icon-only"
                rel="noopener noreferrer"
              >
                <Icon type="plus" />
              </a>
            </Tooltip>
            <Tooltip title="Atras" placement="left">
              <a
                style={{ marginLeft: 10 }}
                href="/opciones"
                className="ant-btn ant-btn-primary ant-btn-icon-only"
                rel="noopener noreferrer"
              >
                <Icon type="arrow-left" />
              </a>
            </Tooltip>
          </div>
          <Row gutter={16} style={{ marginBottom: 20 }}>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              <Input
                placeholder="Nombre"
                allowClear
                onChange={this.handleSearchBySearchName}
                style={{ width: '100%' }}
              />
            </Col>
          </Row>
          <Table
            bordered
            size="small"
            dataSource={list}
            columns={columns}
            pagination={false}
            scroll={{ x: 'max-content' }}
          />
          <Pagination
            list="attributes.list"
            apiUrl={`admin/options-attribute/${father}/option`}
            extraBySearch={extraBySearch}
          />
        </div>
      );
    }
  }
);
