import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Modal, Tooltip, Icon, Row, Col, Select, Table, Button } from 'antd';
import Moment from 'react-moment';
import Pagination from '@component/Elements/Pagination';
import whitoutImage from '../../../assets/product_whitout_image.png';

const style = {
  miniFile: {
    width: '1rem',
    height: '1rem',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  thumb: {
    width: '200px',
    height: '150px',
    maxWidth: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    padding: '1rem',
    display: 'block',
    margin: 'auto'
  },
  p: {
    textAlign: 'center',
    marginBottom: '9rem',
    marginTop: '5rem'
  },
  img: {
    width: '4rem',
    height: '4rem',
    objectFit: 'cover',
    objectPosition: 'center'
  }
};
export default connect(
  {
    // states
    list: state.posts.list,
    brandId: state.posts.brand_id,
    brands: state.posts.brands,
    loader: state.posts.loader,
    // secuences
    deletePost: sequences.deletePost,
    updateBrandBySelectAndGetPosts: sequences.updateBrandBySelectAndGetPosts
  },
  class PostListView extends Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.handleChangeBrand = this.handleChangeBrand.bind(this);
      this.deleteItem = this.deleteItem.bind(this);
    }

    deleteItem(id, title) {
      this.id = id;
      const { deletePost } = this.props;
      Modal.confirm({
        title: 'Confirmación',
        content: `Esta seguro que desea eliminar el post "${title}"`,
        okText: 'Aceptar',
        cancelText: 'Cancelar',
        onOk: () => deletePost({ id })
      });
    }

    handleChangeBrand(value) {
      this.value = value;
      const { updateBrandBySelectAndGetPosts } = this.props;
      updateBrandBySelectAndGetPosts({ value });
    }

    render() {
      const { list, brandId, brands, loader } = this.props;
      const columns = [
        {
          title: 'Titulo',
          dataIndex: 'title',
          key: 'title',
          render: (text, element, index) => (
            <a href={`/posts/${list[index].id}`}>{text}</a>
          )
        },

        {
          title: 'Principal',
          dataIndex: 'img_thumb',
          key: 'img_thumb',
          render: (text, element, index) => (
            <img src={text} alt="---" style={style.img} />
          )
        },
        {
          title: 'Thumb',
          dataIndex: 'img_thumb_mini',
          key: 'img_thumb_mini',
          render: (text, element, index) => (
            <img
              src={text !== null ? text : whitoutImage}
              alt="---"
              style={style.img}
            />
          )
        },
        {
          title: 'MARCA',
          dataIndex: 'brand.name',
          key: 'brand.name',
          render: text => <span>{text.toUpperCase()}</span>
        },
        {
          title: 'Tipo',
          dataIndex: 'type',
          key: 'type'
        },
        {
          title: 'FECHA CREACIÓN',
          dataIndex: 'created_at',
          key: 'created_at',
          render: text => <Moment format="DD-MM-YYYY hh:mm A">{text}</Moment>
        },
        {
          title: 'ACCIONES',
          dataIndex: 'id',
          key: 'id',
          render: (text, element, index) => (
            <span>
              <Tooltip title="Eliminar posts" placement="top">
                <Button
                  type="primary"
                  icon="delete"
                  onClick={() =>
                    this.deleteItem(list[index].id, list[index].title)
                  }
                  style={{ marginRight: 5 }}
                />
              </Tooltip>
              <Tooltip title="Galeria post" placement="top">
                <a
                  className="ant-btn ant-btn-primary ant-btn-icon-only"
                  href={`/posts/${text}/galeria`}
                >
                  <Icon type="file-image" />
                </a>
              </Tooltip>
            </span>
          )
        }
      ];
      return (
        <div>
          <div className="move-content-right">
            <Tooltip title="Crear post" placement="left">
              <a
                href="/posts/nuevo"
                className="ant-btn ant-btn-primary ant-btn-icon-only"
                rel="noopener noreferrer"
              >
                <Icon type="plus" />
              </a>
            </Tooltip>
          </div>
          <Row gutter={16} style={{ marginBottom: 20 }}>
            <Col xs={24} sm={6} md={6}>
              <Select
                placeholder="Marca"
                style={{ width: '100%' }}
                onChange={this.handleChangeBrand}
              >
                {brands.map(value => (
                  <Select.Option key={`brand-${value.id}`} value={value.id}>
                    <img
                      src={value.url_photo}
                      alt={value.name}
                      style={style.miniFile}
                    />{' '}
                    {value.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginBottom: 20 }}>
            <Table
              loading={loader}
              bordered
              size="small"
              dataSource={list}
              columns={columns}
              pagination={false}
              scroll={{ x: 'max-content' }}
            />
            <Pagination
              list="posts.list"
              apiUrl="admin/post"
              extraBySearch={{ brand_id: brandId }}
            />
          </Row>
        </div>
      );
    }
  }
);
