export default {
  debounceTime: 600,
  collapsed: false,
  version: '0.0.1',
  synchronizer: false,
  app: {
    currentPage: 'Home',
    titlePage: '',
    currentPath: '/',
    isLoadingPage: false,
    showSplash: true,
    slides: [],
    books: [],
    contacts: [],
    updateDealer: {},
    requestDealers: {
      data: []
    },
    isSearching: false,
    dealers: {
      data: []
    },
    dealersSearch: {
      query_search: ''
    },
    pagination: {
      currentPage: 1,
      firstPageUrl: null,
      from: 1,
      lastPage: 1,
      lastPageUrl: null,
      nextPageUrl: null,
      path: null,
      perPage: 20,
      prevPageUrl: null,
      to: 1,
      total: 0
    }
  },
  tags: {
    action: 'create',
    submit: false,
    list: [],
    detail: {},
    delete: {
      open: false,
      id: null,
      name: null,
      type: null,
      loader: false
    }
  },
  tags_users: {
    action: 'create',
    submit: false,
    list: [],
    detail: {},
    delete: {
      open: false,
      id: null,
      type: null,
      name: null,
      loader: false
    }
  },
  posts: {
    brand_id: null,
    brands: [],
    list: [],
    loader: false,
    detail: {}
  },
  posts_gallery: {
    post_id: null,
    list: []
  },
  options: {
    list: [],
    detail: {},
    delete: {
      open: false,
      id: null,
      name: null,
      loader: false
    }
  },
  attributes: {
    father: null,
    list: [],
    detail: {},
    delete: {
      open: false,
      id: null,
      name: null,
      father: null,
      loader: false
    },
    search: {
      loading: false,
      searchName: ''
    }
  },
  categories: {
    list: [],
    categories: [],
    categoryFatherAll: [],
    detail: {},
    delete: {
      open: false,
      id: null,
      name: null,
      loader: false
    },
    search: {
      loading: false,
      categoryNameSearch: ''
    }
  },
  brands: {
    action: 'create',
    submit: false,
    list: [],
    listFilters: [],
    tags: [],
    filtersBrandId: null,
    BannerBrandId: null,
    listBanners: [],
    listBannerSecondary: null,
    deleteBanners: {
      open: false,
      id: null,
      file: null,
      loader: false
    },
    filters: {
      loader: false,
      basic: 'FILTER_1',
      delete: {
        open: false,
        id: null,
        name: null,
        loader: false
      }
    },
    detailFilter: {},
    global: [],
    categories: [],
    detail: {},
    delete: {
      open: false,
      id: null,
      name: null,
      loader: false
    }
  },
  product_files: {
    product_id: null,
    loading: false,
    delete: {
      open: false,
      id: null,
      file: null,
      loader: false
    }
  },
  products: {
    list: [],
    tags: [],
    categories: [],
    brands: [],
    optionsAttributes: [],
    delete: {
      open: false,
      id: null,
      name: null,
      loader: false
    },
    detail: {},
    search: {
      loading: false,
      searchNameProduct: '',
      searchReferenceProduct: '',
      searchCategoriesIds: [],
      sortProduct: 'ASC',
      brand_id: null
    },
    openTab: 'general',
    combinationsDisabled: true,
    combinations: {
      optionsAttributesInSelect: [],
      editMode: false,
      loading: false,
      update: {
        loader: false
      },
      delete: {
        loader: false
      },
      gallery: [],
      selectGallery: [],
      selectGalleryComplete: [],
      productCombination: {},
    },
    

    // update multiple products
    update_multiple_list: [],     // Products lists selecteds
    update_multiple_fields: [],   // Products attributes selecteds
    update_multiple_products_modal_button: false,         // modal products table selecteds
    update_multiple_products_modal_button_fields: false,  // modal products attributeds
    edit_rows_table_columns: [],      // rows for table in modal editable
    edit_rows_table_read: [],         // rows for table in modal onlyread
    edit_rows_table_read_preview: {}, // rows for table in modal onlyread after edit
    edit_row: [{
      name: '',
      price: 0,
      instructions: '',
      description: '',
      show: 1,
      type: '',
      brand: '',
      categories: '',
    }],
    edit_row_name: '',
    edit_row_price: '',
    edit_row_description: '',
    edit_row_instructions: '',
    edit_row_show: '',
    edit_row_type: '',

    save_multiples: {
      open: false,
      loader: false
    },
  },
  admins: {
    action: 'create',
    submit: false,
    list: [],
    delete: {
      open: false,
      id: null,
      name: null,
      loader: false
    },
    detail: {}
  },
  web: {
    list: [],
    detail: {},
    tags: [],
    search: {
      loading: false,
      searchByNameClient: '',
      searchByEmailClient: '',
      searchTagsIds: '',
    }
  },
  user: {
    isLogin: false,
    loginButtonDisable: false,
    jwt: null,
    payload: {
      name: 'Invitate',
      last_name: null
    }
  },
  orders: {
    list: [],
    status: [],
    detail: {},
    search: {
      loading: false,
      searchByShippingNumberOrder: '',
      searchByNameOrder: '',
      sortOrder: 'DESC',
      searchByStatusOrder: '',
      searchByTotalOrder: '',
      searchByInitDateStart: '',
      searchByInitDateEnd: ''
    }
  },
  notifications: [],
  dashboard: {
    loaded: false,
    coupons_redeemed_for_the_month_amount: 'Calculando...',
    coupons_redeemed_for_the_month_total: 'Calculando...',
    expiry_orders_amount: 'Calculando...',
    expiry_orders_total: 'Calculando...',
    pending_payment_orders_amount: 'Calculando...',
    pending_payment_orders_total: 'Calculando...',
    products_alert_stock_combinations: [],
    products_alert_stock_regular: [],
    registered_users_per_month_amount: 'Calculando...',
    sales_of_the_day_amount: {calm_la_girls: 'Calculando...', calm_hayan: 'Calculando...', calm_la_colors: 'Calculando...', calm_jlash: 'Calculando...',},
    sales_of_the_day_total: {calm_la_girls: 'Calculando...', calm_hayan: 'Calculando...', calm_la_colors: 'Calculando...', calm_jlash: 'Calculando...',},
    sales_of_the_month_amount: {calm_la_girls: 'Calculando...', calm_hayan: 'Calculando...', calm_la_colors: 'Calculando...', calm_jlash: 'Calculando...',},
    sales_of_the_month_total: {calm_la_girls: 'Calculando...', calm_hayan: 'Calculando...', calm_la_colors: 'Calculando...', calm_jlash: 'Calculando...',},
    sales_years: ["Todos"],
    stock_syncs: 'Calculando...',
    total_coupons_redeemed_amount: 'Calculando...',
    total_coupons_redeemed_total: 'Calculando...',
    total_sales_amount: {calm_la_girls: 'Calculando...', calm_hayan: 'Calculando...', calm_la_colors: 'Calculando...', calm_jlash: 'Calculando...',},
    total_sales_total: {calm_la_girls: 'Calculando...', calm_hayan: 'Calculando...', calm_la_colors: 'Calculando...', calm_jlash: 'Calculando...',},
    users: 'Calculando...',
  },
  dashboardDates: {
    sales_month_date: null
  },
  dashboardBrandSalesSelected: {
    monthBrandId: 0,
    dayBrandId:   0,
    totalBrandId: 0
  },
  reviews: [],
  coupons: {
    error: null,
    detail: {},
    list: [],
    categories: [],
    tags: [],
    user_tags: [],
    events: [],
    modalities: [],
    products: [],
    clients: [],
    search: {
      loading: false,
      searchByName: '',
      searchByStartDate1: '',
      searchByStartDate2: '',
      searchByEndDate1: '',
      searchByEndDate2: '',
      code: '',
      sort: 'DESC'
    }
  },
  discounts: {
    delete: {
      loader: false
    }
  },
  campaign: {
    list: [],
    search: {
      loading: false,
      searchByName: '',
      searchByStartDate1: '',
      searchByStartDate2: '',
      searchByEndDate1: '',
      searchByEndDate2: '',
      code: '',
      sort: 'DESC'
    }
  },
  dealersInitial: {
    list: [],
    detail: {},
    search: {
      loading: false,
      status: '',
      city: '',
      state: ''
    }
  },
  dealersTelemarketing: {
    list: [],
    detail: {},
    search: {
      loading: false,
      status: '',
      city: '',
      state: ''
    }
  },
  dealersMayoreo: {
    list: [],
    detail: {},
    search: {
      loading: false,
      status: '',
      city: '',
      state: ''
    }
  },
  kit: {
    loading: false
  },
  syncCodes: false,
  recoverPassword: {
    open: false,
    email: '',
    loader: false
  },
  brandList: [],
  reports: {
    reports_types: [],
    brands: [],
    categories: [],
    clients: [],
    report_settings: {},
    list: [],
    total_results: [],
    export_in_process: false,
    report_in_process: false,
  },
  events: {
    list: [],
    data: [],
    show: [],
    tickets_types: [],
    refund_ticket: {
      id: null,
      loader: false,
      name: null,
      ticket: null,
      open: false,
    },
    notification: {
      id: null,
      loader: false,
      open: false,
    },
    tickets_notification: {
      id: null,
      loader: false,
      open: false,
    },
    event_spot: {
      id: null,
      loader: false,
      name: null,
      open: false,
    },
    event_spot_row: {
      id: null,
      loader: false,
      name: null,
      open: false,
    },
    event_spot_column: {
      id: null,
      loader: false,
      name: null,
      number: null,
      open: false,
    },
    event_spot_delete_row: {
      id: null,
      loader: false,
      name: null,
      open: false,
    },
    event_spot_delete_column: {
      id: null,
      loader: false,
      name: null,
      open: false,
    },
    sold_refund_ticket: {
      id: null,
      loader: false,
      name: null,
      ticket: null,
      open: false,
    },
    refund_ticket_confirmation: {
      id: null,
      loader: false,
      open: false,
    },
    terms: {
      show: [],
      openTab: 'general',
    },
    edit_id: null,
    openTab: 'general',
    delete: {
      id: null,
      loader: false,
      name: null,
      open: false,
      orders_count: null,
    },
    archive: {
      id: null,
      loader: false,
      name: null,
      open: false,
      orders_count: null,
    },
    frequent_questions: {
      loading: false,
      editMode: false,
      update: {
        loader: false
      },
      delete: {
        loader: false
      },
    },
    tickets: {
      loading: false,
      editMode: false,
      update: {
        loader: false
      },
      delete: {
        loader: false
      },
    },
    payment: {
      loading: false,
      editMode: false,
    },
    reports: {
      report_settings: {},
      export_in_process: false,
    }
  },
  events_archiveds: {
    list: [],
    data: [],
    show: [],
    terms: {
      show: [],
      openTab: 'general',
    },
    edit_id: null,
    openTab: 'general',
    delete: {
      id: null,
      loader: false,
      name: null,
      open: false,
      orders_count: null,
    },
    archive: {
      id: null,
      loader: false,
      name: null,
      open: false,
      orders_count: null,
    },
    frequent_questions: {
      loading: false,
      editMode: false,
      update: {
        loader: false
      },
      delete: {
        loader: false
      },
    },
    tickets: {
      loading: false,
      editMode: false,
      update: {
        loader: false
      },
      delete: {
        loader: false
      },
    },
    payment: {
      loading: false,
      editMode: false,
    },
    reports: {
      report_settings: {},
      export_in_process: false,
    }
  },
  configurations: {
    list: [],
    show: null,
  },
};