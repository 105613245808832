import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import {sequences} from 'cerebral';
import { Row, Col, Button, Icon, Form, Input, Select } from 'antd';
import mexico from '@utils/mexico-states.js';

const { Option } = Select;

const DealerConnect = connect(
  {
    submit: sequences.submitDealer
  },
  class DealersForm extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isSubmit: false
      };
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
      e.preventDefault();
      this.setState({isSubmit: true})
      const { form, submit } = this.props;
      form.validateFields((err, values) => {
        if (!err) {
          console.log("estos son los valores", values)
          submit({ values });
        } else {
          this.setState({isSubmit: false});
        }
      });
    }

    render() {
      const { form } = this.props;
      const { isSubmit } = this.state;
      const FormItem = Form.Item;
      const { getFieldDecorator } = form;
      return (
        <Form onSubmit={this.handleSubmit}>
          <h3>Nuevo Distribuidor</h3>
          {/* Name  */}
          <FormItem label="Nombre">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Este campo es requerido'
                }
              ]
            })(<Input placeholder="La olmeca" />)}
          </FormItem>
          {/* Email  */}
          <FormItem label="Email de contacto">
            {getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  message: 'Este campo es requerido'
                }
              ]
            })(<Input placeholder="email@email.com" />)}
          </FormItem>
          {/* salesman  */}
          <FormItem label="Nombre del vendedor">
            {getFieldDecorator('salesman_name', {
              rules: [
                {
                  required: true,
                  message: 'Este campo es requerido'
                }
              ]
            })(<Input placeholder="Juan Perez" />)}
          </FormItem>
          {/* Address  */}
          <FormItem label="Dirección de contacto">
            {getFieldDecorator('address', {
              rules: [
                {
                  required: true,
                  message: 'Este campo es requerido'
                }
              ]
            })(<Input placeholder="Gill Castellanos Diaz" />)}
          </FormItem>
          {/* Phone  */}
          <FormItem label="Telefono de contacto">
            {getFieldDecorator('phone', {
              rules: [
                {
                  required: true,
                  message: 'Este campo es requerido'
                }
              ]
            })(<Input placeholder="99312230303" maxLength={10} />)}
          </FormItem>
          {/* Phone  */}
          <FormItem label="Telefono de celular">
            {getFieldDecorator('phone2', {
              rules: [
                {
                  required: true,
                  message: 'Este campo es requerido'
                }
              ]
            })(<Input placeholder="99312230303" maxLength={10} />)}
          </FormItem>
          {/* Zip Code  */}
          <FormItem label="Código Postal">
            {getFieldDecorator('zip_code', {
              rules: [
                {
                  required: true,
                  message: 'Este campo es requerido'
                }
              ]
            })(<Input placeholder="85720" />)}
          </FormItem>
          {/* City  */}
          <FormItem label="Ciudad">
            {getFieldDecorator('city', {
              rules: [
                {
                  required: true,
                  message: 'Este campo es requerido'
                }
              ]
            })(<Input placeholder="Villahermosa" />)}
          </FormItem>
          {/* Estado  */}
          <FormItem label="Estado">
            {getFieldDecorator('state', {
              rules: [
                {
                  required: true,
                  message: 'Este campo es requerido'
                }
              ]
            })(
              <Select placeholder="Seleccione un estado">
                {mexico.map(el => (
                  <Option value={el.name}>{el.name}</Option>
                ))}
              </Select>
            )}
          </FormItem>
          {/** Action */}
          <Form.Item>
            <Button size="large" style={{width: "200px"}} type="primary" htmlType="submit" loading={isSubmit}>
              Crear
            </Button>
          </Form.Item>
        </Form>
      );
    }
  }
);

export default Form.create()(DealerConnect);
