import { set } from 'cerebral/factories';
import { state } from 'cerebral';
import { WithRouter } from './actions';
/**
 * Template: 404
 * Route: '*'
 */
// eslint-disable-next-line import/prefer-default-export
export const Template404 = WithRouter([
  set(state.app.currentPage, 'Template404')
]);
