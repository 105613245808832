import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';

import { Row, Col, Button, Card, Icon, Modal } from 'antd';

function typeMultimedia(type) {
  let typeName = '';
  switch (type) {
    case 'image':
      typeName = 'Imagen';
      break;
    case 'video':
      typeName = 'Youtube';
      break;
    default:
      typeName = 'Sin registro';
      break;
  }
  return typeName;
}

export default connect(
  {
    slides: state.app.slides,
    remove: sequences.removeSlide
  },
  class SlidesView extends Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.deleteItem = this.deleteItem.bind(this);
    }

    deleteItem(id) {
      const { remove } = this.props;
      Modal.confirm({
        title: 'Confirm',
        content: '¿Estas seguro de eliminar este slide?',
        okText: 'Aceptar',
        cancelText: 'Cancelar',
        onOk: () => remove({ id })
      });
    }

    render() {
      const { slides } = this.props;
      return (
        <div>
          <Row
            gutter={16}
            style={{ paddingBottom: 20 }}
            type="flex"
            justify="space-between"
          >
            <Col span={6}>
              <h3>Contenido del Slide principal</h3>
            </Col>
            <Col span={4}>
              <a
                className="ant-btn ant-btn-primary"
                href="/slides/new"
                style={{ marginRight: 5 }}
              >
                <Icon type="plus" />
                Agregar nuevo
              </a>
            </Col>
          </Row>
          <Row type="flex">
            {slides.map(slide => (
              <Card
                style={{ width: 300, margin: 10 }}
                hoverable
                key={slide.id}
                cover={
                  slide.type === 'image' ? (
                    <img alt="example" src={slide.media} />
                  ) : (
                    <a
                      href={`${slide.media}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{textAlign:'center',marginTop:15}}
                    >
                      Video Youtube
                    </a>
                  )
                }
                actions={[
                  <Icon
                    type="delete"
                    key="delete"
                    onClick={() => this.deleteItem(slide.id)}
                  />
                ]}
              >
                <ul className="list-card">
                  <li>
                    <strong>Tipo:</strong>{' '}
                    <span>{typeMultimedia(slide.type)}</span>
                  </li>
                  <li>
                    <strong>Creado:</strong> <span>2018-10-02</span>
                  </li>
                </ul>
              </Card>
            ))}
          </Row>
        </div>
      );
    }
  }
);
