/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Col, Row, Icon, Tooltip, Modal, Button } from 'antd';
import classNames from 'classnames/bind';
import styles from './BannerSecondaryListView.css';

const cx = classNames.bind(styles);
export default connect(
  {
    // states
    list: state.brands.listBannerSecondary,
    brandId: state.brands.BannerBrandId,
    stateDelete: state.brands.deleteBanners,
    // secuences
    deleteFileBanner: sequences.deleteFileBannerSecondary,
    handleClickOpenModal: sequences.handleClickOpenModalBanners,
    handleClickCloseModal: sequences.handleClickCloseModalBanners
  },
  class BannerSecondaryListView extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
      const {
        list,
        brandId,
        stateDelete,
        handleClickOpenModal,
        handleClickCloseModal,
        deleteFileBanner
      } = this.props;

      return (
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24}>
            <div className="move-content-right">
              <Tooltip title="Crear filtro" placement="left">
                <a
                  href={`/marcas/${brandId}/banner-secundario/nuevo`}
                  className="ant-btn ant-btn-primary ant-btn-icon-only"
                  rel="noopener noreferrer"
                >
                  <Icon type="plus" />
                </a>
              </Tooltip>
              <Tooltip title="Atras" placement="left">
                <a
                  style={{ marginLeft: 10 }}
                  href="/marcas"
                  className="ant-btn ant-btn-primary ant-btn-icon-only"
                  rel="noopener noreferrer"
                >
                  <Icon type="arrow-left" />
                </a>
              </Tooltip>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24}>
            {list === null ? (
              <p className={cx('without-results')}>Sin resultados</p>
            ) : (
              <div>
                <img alt={list.alt} src={list.url} className={cx('img')} />
                <Icon
                  type="delete"
                  className={cx('trash')}
                  onClick={() => {
                    handleClickOpenModal(list);
                  }}
                />
              </div>
            )}
          </Col>

          <Modal
            closable={false}
            visible={stateDelete.open}
            onCancel={() => {
              handleClickCloseModal();
            }}
            footer={[
              <Button
                type="primary"
                onClick={() => {
                  deleteFileBanner();
                }}
                loading={stateDelete.loader}
              >
                Confirmar
              </Button>,
              <Button
                onClick={() => {
                  handleClickCloseModal();
                }}
              >
                Cancelar
              </Button>
            ]}
          >
            <h4>¿Desea eliminar el archivo?</h4>
            <p>
              Favor de verificar que el archivo seleccionado sea el que
              realmente desea eliminar.
            </p>
            <br />
            <img
              src={stateDelete.file}
              alt="imagen a eliminar"
              className={cx('preview')}
            />
          </Modal>
        </Row>
      );
    }
  }
);
