export default function(target, value) {
  function unshift(context) {
    if (!context.resolve.isTag(target, 'state', 'moduleState')) {
      throw new Error(
        'Cerebral factory.unshift: You have to use the STATE or MODULESTATE tag as first argument'
      )
    }

    context.store.unshift(target, value)
  }

  unshift.displayName = `factory.unshift(${String(target)}, ${String(value)})`

  return unshift
}
