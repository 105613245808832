import Provider from '../Provider'
import { isComputedValue } from '../utils'

export default Provider(
  (context) =>
    Object.assign(
      (tag, overrides) => {
        const value = context.resolve.value(tag)
        if (isComputedValue(value)) {
          return value.getValue(overrides || context.props)
        }

        return value
      },
      {
        path(tag) {
          return context.resolve.path(tag)
        },
      }
    ),
  {
    wrap: false,
  }
)
