import { set } from 'cerebral/factories';
import { state, props } from 'cerebral';
import { WithRouter, setPagination } from '@modules/actions';
import notify from '@utils/notify';
import page from 'page';
import * as actions from './actions';

const PostListRouter = WithRouter([
  ({ store, props }) => {
    store.set(state.posts.brand_id, null);
    store.set(state.posts.list, []);
  },

  actions.getBrands,
  {
    success: [actions.setBrands],
    error: []
  },
  actions.getPosts,
  {
    success: [setPagination, actions.setPosts],
    error: [notify(props.message.error, 'error')]
  },
  set(state.app.currentPage, 'PostListView')
]);
const PostListGalleryRouter = WithRouter([
  ({ store, props }) => {
    store.set(state.posts_gallery.post_id, props.params.id);
    store.set(state.posts_gallery.list, []);
  },
  actions.getPostsGallery,
  {
    success: [setPagination, actions.setPostsGallery],
    error: [notify(props.message.error, 'error')]
  },
  set(state.app.currentPage, 'PostGalleryListView')
]);
const updateBrandBySelectAndGetPosts = [
  ({ store, props }) => {
    store.set(state.posts.loader, true);
    store.set(state.posts.brand_id, props.value);
  },
  actions.getPosts,
  {
    success: [setPagination, actions.setPosts],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.posts.loader, false);
  }
];
const deletePost = [
  ({ http, path, props }) => {
    return http
      .delete(`admin/post/${props.id}`, {})
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Post eliminado', 'success'),
      actions.getPosts,
      {
        success: [setPagination, actions.setPosts],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];

const PostNewRouter = WithRouter([
  actions.getBrands,
  {
    success: [actions.setBrands],
    error: []
  },
  set(state.app.currentPage, 'PostsFormView')
]);
const savePosts = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values } = props;
    const data = new FormData();
    if (typeof values.img_thumb !== 'undefined') {
      const file = values.img_thumb[0].originFileObj;
      data.append('img_thumb', file);
    } 
    if (typeof values.img_thumb_mini !== 'undefined') {
      const file2 = values.img_thumb_mini[0].originFileObj;
      data.append('img_thumb_mini', file2);
    }
    data.append('type', values.type);
    data.append('brand_id', values.brand_id);
    data.append('description', values.description);
    data.append('short_description', values.short_description);
    data.append('title', values.title);
    return http
      .postFile('admin/post', data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Post creado', 'success'),
      () => {
        page.show('/posts');
      }
    ],
    error: [notify(props.message.error, 'error')]
  },

  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];
const PostFormViewUpdate = WithRouter([
  set(state.posts.detail, {}),
  actions.getBrands,
  {
    success: [actions.setBrands],
    error: []
  },
  actions.getPostDetail,
  {
    success: [actions.setPostDetail],
    error: [notify(props.message.error, 'error')]
  },
  set(state.app.currentPage, 'PostsFormUpdateView')
]);
const updatePosts = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values } = props;
    const data = new FormData();
    if (typeof values.img_thumb !== 'undefined') {
      const file = values.img_thumb[0].originFileObj;
      data.append('img_thumb', file);
    }
    if (typeof values.img_thumb_mini !== 'undefined') {
      const file2 = values.img_thumb_mini[0].originFileObj;
      data.append('img_thumb_mini', file2);
    }
    data.append('type', values.type);
    data.append('brand_id', values.brand_id);
    data.append('description', values.description);
    data.append('short_description', values.short_description);
    data.append('title', values.title);
    data.append('post_id', values.post_id);
    return http
      .postFile(`admin/post/update`, data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Posts actualizado', 'success'),
      () => {
        page.show('/posts');
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];
const deleteGalleryPost = [
  ({ http, path, props }) => {
    return http
      .delete(`admin/post-gallery/${props.id}`, {})
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Registro eliminado', 'success'),
      ({ props }) => {
        page.show(`/posts/${props.res.post_id}/galeria`);
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];
const PostGalleryNewRouter = WithRouter([
  ({ store, props }) => {
    store.set(state.posts_gallery.post_id, props.params.id);
  },
  set(state.app.currentPage, 'PostGalleryFormView')
]);
const saveGalleryPosts = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values } = props;
    const data = new FormData();
    data.append('type', values.type);
    if (values.type === 'IMG') {
      const file = values.file[0].originFileObj;
      data.append('file', file);
    }
    if (values.type === 'CODE_YOUTUBE') {
      data.append('file', values.file);
    }
    data.append('blog_id', values.blog_id);
    const thumb = values.thumb[0].originFileObj;
    data.append('thumb', thumb);

    return http
      .postFile('admin/post-gallery', data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Registro creado', 'success'),

      ({ props }) => {
        page.show(`/posts/${props.res.post_id}/galeria`);
      }
    ],
    error: [notify(props.message.error, 'error')]
  },

  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];
export default {
  routes: {
    list: PostListRouter,
    gallery_list: PostListGalleryRouter,
    new: PostNewRouter,
    update: PostFormViewUpdate,
    gallery_list_new: PostGalleryNewRouter
  },
  sequences: {
    updateBrandBySelectAndGetPosts,
    deletePost,
    savePosts,
    updatePosts,
    deleteGalleryPost,
    saveGalleryPosts
  }
};
