import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state } from 'cerebral';
import classnames from 'classnames/bind';
import { Menu as MenuAntd, Icon } from 'antd';
import { routers, dealer1, dealer2, dealer3 } from '../../router';
import style from './style.css';

const { SubMenu } = MenuAntd;

const cx = classnames.bind(style);

export default connect(
  {
    path: state.app.currentPath,
    user: state.user
  },
  class Menu extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    menu(menuBase, index) {
      this.menuBase = menuBase;
      this.index = index;

      if (menuBase.haveChildren === false) {
        return (
          <MenuAntd.Item key={`${menuBase.children[0].name}-${index}`}>
            <a href={menuBase.children[0].route}>
              <Icon type={menuBase.icon} className={cx('icon')} />
              <span className="nav-text">{menuBase.children[0].name}</span>
            </a>
          </MenuAntd.Item>
        );
      }
      return (
        <SubMenu
          className="left-nav-submenu"
          key={`${menuBase.name}-${index}`}
          title={
            <span>
              <Icon type={menuBase.icon} />
              <span>{menuBase.name}</span>
            </span>
          }
        >
          {menuBase.children.map((subMenuBase, indexSub) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuAntd.Item key={`${subMenuBase.name}-${indexSub}`}>
              <a href={subMenuBase.route}>
                <span className="nav-text">{subMenuBase.name}</span>
              </a>
            </MenuAntd.Item>
          ))}
        </SubMenu>
      );
    }

    render() {
      const { path, user } = this.props;

      let r = routers;

      if (user.isLogin) {
        if (user.payload.rol_admin == 'DEALER_1') {
          r = dealer1;
        }
        if (user.payload.rol_admin == 'DEALER_2') {
          r = dealer2;
        }
        if (user.payload.rol_admin == 'DEALER_3') {
          r = dealer3;
        }
        return (
          <MenuAntd
            theme="dark"
            className={cx('theme')}
            mode="inline"
            defaultSelectedKeys={[path]}
          >
            {r.map((menuBase, index) => {
              return this.menu(menuBase, index);
            })}
          </MenuAntd>
        );
      }
      return null;
    }
  }
);
