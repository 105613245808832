import CouponsListView from './CouponsListView';
import CampaignListView from './CampaignListView';
import CouponFormView from './CouponFormView';
import CouponUpdateFormView from './CouponUpdateFormView';

export default {
  CampaignListView,
  CouponsListView,
  CouponFormView,
  CouponUpdateFormView
};
