/* eslint-disable */
export const LOGIN_ERROR = 'Credenciales no válidas';
export const ME_ERROR = 'El token no es válido, inicie sesión para continuar';
export const LOADING_TEXT = 'Cargando...';
export const API_URL = process.env.API_URL;
export const NAME_TOKEN = process.env.NAME_TOKEN;
export const TIME_LOAD_NOTIFICATIONS = 60000;// esta segundos -> 1 min

export default {
  LOGIN_ERROR,
  ME_ERROR,
  LOADING_TEXT,
  API_URL,
  NAME_TOKEN,
  TIME_LOAD_NOTIFICATIONS
};
