import { API_URL, NAME_TOKEN } from '@utils/constants';
import { set } from 'cerebral/factories'; // wait
import { state, props } from 'cerebral';
import { WithRouter, setPagination } from '@modules/actions';
import * as actions from './actions';
import axios from 'axios';
import notify from '@utils/notify';
import page from 'page';
import { get, gt } from 'lodash';

const EventsListRoute = WithRouter([
  set(state.app.currentPage, 'EventsListView'),
  actions.getEvents, {
      success: [setPagination, actions.setEvents],
      error: []
  },
]);

const EventsListArchivedsRoute = WithRouter([
  set(state.app.currentPage, 'EventsListArchivedsView'),
  actions.getEventsArchiveds, {
      success: [setPagination, actions.setEventsArchiveds],
      error: []
  },
]);

const EventsShowRoute = WithRouter([
  set(state.app.currentPage, 'EventsShowView'),
  actions.getEvent, {
      success: [ actions.setEvent ],
      error: []
  },
]);

const EventsTermsConditionsRoute = WithRouter([
  set(state.app.currentPage, 'EventsTermsAndConditionsView'),
  actions.getEventTermsConditions, {
      success: [ actions.setEventTermsConditions ],
      error: []
  },
]);

const EventsCreateRoute = WithRouter([
  set(state.app.currentPage, 'EventsCreateView'),
]);

const createEvent = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values } = props;
    const data = new FormData();
    if (typeof values.media_bg_url !== 'undefined') {
      data.append(
        'media_bg_url',
        values.media_bg_url[0].originFileObj
      );
    }

    if (typeof values.media_description_url !== 'undefined') {
      data.append(
        'media_description_url',
        values.media_description_url[0].originFileObj
      );
    }

    if (typeof values.media_location_url !== 'undefined') {
      data.append(
        'media_location_url',
        values.media_location_url[0].originFileObj
      );
    }

    if (typeof values.media_location_mobile_url !== 'undefined') {
      data.append(
        'media_location_mobile_url',
        values.media_location_mobile_url[0].originFileObj
      );
    }

    if (typeof values.media_title_url !== 'undefined') {
      data.append(
        'media_title_url',
        values.media_title_url[0].originFileObj
      );
    }

    data.append('title', values.title);
    data.append('slug', values.slug);
    data.append('location_title', values.location_title);

    data.append('video_title', values.video_title);
    data.append('status', values.status);

    data.append('event_hall', values.event_hall);
    if ( values.event_address ) {
      data.append('event_address', values.event_address);
    } else {
      data.append('event_address', '');
    }
    if ( values.event_iframe_gmaps ) {
      data.append('event_iframe_gmaps', values.event_iframe_gmaps);
    } else {
      data.append('event_iframe_gmaps', '');
    }
    if ( values.reference ) {
      data.append('reference', values.reference);
    } else {
      // data.append('reference', '');
    }
    if ( values.event_url ) {
      data.append('event_url', values.event_url != 'null' ? values.event_url : '' );
    } else {
      data.append('event_url', '');
    }
    if ( values.event_url_title ) {
      data.append('event_url_title', values.event_url_title != 'null' ? values.event_url_title : '' );
    } else {
      data.append('event_url_title', '');
    }
    
    if (values.start_at) {
      data.append('start_at', values.start_at.format('YYYY-MM-DD HH:mm:ss') );
    }
    data.append(
      'video_code',
      values.video_code == 'null' ? '' : values.video_code
    );
    if (values.description !== null) {
      data.append('description', values.description);
    }

    return http
      .postFile(`admin/events/event/`, data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Evento actualizado', 'success'),
      ({ props }) => {
        page.show(`/eventos/`);
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];

const updateEvent = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values, event } = props;
    const data = new FormData();
    if (typeof values.media_bg_url !== 'undefined') {
      data.append(
        'media_bg_url',
        values.media_bg_url[0].originFileObj
      );
    }

    if (typeof values.media_description_url !== 'undefined') {
      data.append(
        'media_description_url',
        values.media_description_url[0].originFileObj
      );
    }

    if (typeof values.media_location_url !== 'undefined') {
      data.append(
        'media_location_url',
        values.media_location_url[0].originFileObj
      );
    }

    if (typeof values.media_location_mobile_url !== 'undefined') {
      data.append(
        'media_location_mobile_url',
        values.media_location_mobile_url[0].originFileObj
      );
    }

    if (typeof values.media_title_url !== 'undefined') {
      data.append(
        'media_title_url',
        values.media_title_url[0].originFileObj
      );
    }

    data.append('title', values.title);
    data.append('slug', values.slug);
    data.append('location_title', values.location_title);

    data.append('video_title', values.video_title);
    data.append('status', values.status);
    data.append('start_at', values.start_at.format('YYYY-MM-DD HH:mm:ss') );

    data.append('event_hall', values.event_hall);
    if ( values.event_address ) {
      data.append('event_address', values.event_address);
    } else {
      data.append('event_address', '');
    }
    if ( values.event_iframe_gmaps ) {
      data.append('event_iframe_gmaps', values.event_iframe_gmaps);
    } else {
      data.append('event_iframe_gmaps', '');
    }
    if ( values.reference ) {
      data.append('reference', values.reference);
    } else {
      // data.append('reference', '');
    }
    if ( values.event_url ) {
      data.append('event_url', values.event_url != 'null' ? values.event_url : '' );
    } else {
      data.append('event_url', '');
    }
    if ( values.event_url_title ) {
      data.append('event_url_title', values.event_url_title != 'null' ? values.event_url_title : '' );
    } else {
      data.append('event_url_title', '');
    }

    data.append(
      'video_code',
      values.video_code == 'null' ? '' : values.video_code
    );
    if (values.description !== null) {
      data.append('description', values.description);
    }

    return http
      .postFile(`admin/events/event/` + event.id, data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Evento actualizado', 'success'),
      ({ props }) => {
        page.show(`/eventos/`);
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];

const deleteEvent = [
  ({ store }) => {
    store.set(state.events.delete.loader, true);
  },
  ({ http, path, get }) => {
    return http
      .post(`admin/events/event/destroy/${get(state.events.delete.id)}`, {})
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Evento eliminado', 'success'),
      ({ store }) => {
        store.set(state.events.delete, {
          id: null,
          loader: false,
          name: null,
          open: false,
          orders_count: null,
        });
      },
      () => {
        page.show('/eventos');
      }
    ],
    error: [
      ({ store }) => {
        store.set(state.events.delete, {
          id: null,
          loader: false,
          name: null,
          open: false,
          orders_count: null,
        });
      },
      notify(props.message.error, 'error')
    ]
  }
];

const archiveEvent = [
  ({ store }) => {
    store.set(state.events.delete.loader, true);
  },
  ({ http, path, get }) => {
    return http
      .post(`admin/events/event/add-archive/${get(state.events.delete.id)}`, {})
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Evento archivado', 'success'),
      ({ store }) => {
        store.set(state.events.delete, {
          id: null,
          loader: false,
          name: null,
          open: false,
          orders_count: null,
        });
      },
      () => {
        page.show('/eventos');
      }
    ],
    error: [
      ({ store }) => {
        store.set(state.events.delete, {
          id: null,
          loader: false,
          name: null,
          open: false,
          orders_count: null,
        });
      },
      notify(props.message.error, 'error')
    ]
  }
];

const dearchiveEvent = [
  ({ store }) => {
    store.set(state.events_archiveds.archive.loader, true);
  },
  ({ http, path, get }) => {
    return http
      .post(`admin/events/event/remove-archive/${get(state.events_archiveds.archive.id)}`, {})
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Evento regresado al listado', 'success'),
      ({ store }) => {
        store.set(state.events_archiveds.archive, {
          id: null,
          loader: false,
          name: null,
          open: false,
          orders_count: null,
        });
      },
      () => {
        page.show('/eventos-archivados');
      }
    ],
    error: [
      ({ store }) => {
        store.set(state.events_archiveds.archive, {
          id: null,
          loader: false,
          name: null,
          open: false,
          orders_count: null,
        });
      },
      notify(props.message.error, 'error')
    ]
  }
];

// banners
const sliderDeleteAction = [
  ({ store }) => {
    store.set(state.events.delete.loader, true);
  },
  ({ props, http, path, get }) => {
    const {event_id} = props;
    const image_id = get(state.events.delete.id);
    return http
      .post(`admin/events/event/destroy-media/${event_id}/${image_id}`, {})
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Archivo eliminado', 'success'),
      ({ store, get, http, path }) => {
        store.set(state.events.delete, {
          id: null,
          file: null,
          open: false,
          loader: false
        });
      },
      actions.getEvent,
      {
        success: [
          actions.setEvent,
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [
      notify(props.message.error, 'error'),
      ({ store }) => {
        store.set(state.events.delete, {
          id: null,
          file: null,
          open: false,
          loader: false
        });
      }
    ]
  }
];

const sliderDeleteLocationAction = [
  ({ store }) => {
    store.set(state.events.delete.loader, true);
  },
  ({ props, http, path, get }) => {
    const {event_id} = props;
    const image_id = get(state.events.delete.id);
    return http
      .post(`admin/events/event/destroy-media-location/${event_id}/${image_id}`, {})
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Archivo eliminado', 'success'),
      ({ store, get, http, path }) => {
        store.set(state.events.delete, {
          id: null,
          file: null,
          open: false,
          loader: false
        });
      },
      actions.getEvent,
      {
        success: [
          actions.setEvent,
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [
      notify(props.message.error, 'error'),
      ({ store }) => {
        store.set(state.events.delete, {
          id: null,
          file: null,
          open: false,
          loader: false
        });
      }
    ]
  }
];

const sliderCreateAction = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  actions.uploadSlider,
  {
    success: [
      notify('Archivos subidos', 'success'),
      actions.getEvent,
      {
        success: [
          actions.setEvent,
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];

const sliderCreateLocationAction = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  actions.uploadSliderLocation,
  {
    success: [
      notify('Archivos subidos', 'success'),
      actions.getEvent,
      {
        success: [
          actions.setEvent,
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];

const sliderupdateSortMedia = [
  ({ props, http, path }) => {
    const { values, event_id } = props;
    return http
      .post(`admin/events/event/sort-media/${event_id}`, { data: values.data })
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Orden actualizado', 'success'),
      actions.getEvent,
      {
        success: [
          actions.setEvent,
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];

const sliderupdateLocationSortMedia = [
  ({ props, http, path }) => {
    const { values, event_id } = props;
    return http
      .post(`admin/events/event/sort-media/${event_id}`, { data: values.data })
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Orden actualizado', 'success'),
      actions.getEvent,
      {
        success: [
          actions.setEvent,
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];

const handleClickOpenDeleteEventModal = [
  ({ props, store }) => {
    store.set(state.events.delete, {
      id: props.id,
      loader: false,
      name: props.title,
      open: true,
      orders_count: props.payments_count,
    });
  }
];

const handleClickCloseDeleteEventModal = [
  ({ store }) => {
    store.set(state.events.delete, {
      id: null,
      loader: false,
      name: null,
      open: false,
      orders_count: null,
    });
  }
];

const handleClickOpenArchivedEventModal = [
  ({ props, store }) => {
    store.set(state.events_archiveds.archive, {
      id: props.id,
      loader: false,
      name: props.title,
      open: true,
      orders_count: props.payments_count,
    });
  }
];

const handleClickCloseArchivedEventModal = [
  ({ store }) => {
    store.set(state.events_archiveds.archive, {
      id: null,
      loader: false,
      name: null,
      open: false,
      orders_count: null,
    });
  }
];

const handleSlidersDeleteModal = [
  ({ props, store }) => {
    store.set(state.events.delete, {
      id: props.id,
      file: props.url,
      open: true,
      loader: false
    });
  }
];

const handleSlidersLocationDeleteModal = [
  ({ props, store }) => {
    store.set(state.events.delete, {
      id: props.id,
      file: props.url,
      open: true,
      loader: false
    });
  }
];

const handleSlidersDeleteCloseModal = [
  ({ store }) => {
    store.set(state.events.delete, {
      id: null,
      file: null,
      open: false,
      loader: false
    });
  }
];

const handleSlidersLocationDeleteCloseModal = [
  ({ store }) => {
    store.set(state.events.delete, {
      id: null,
      file: null,
      open: false,
      loader: false
    });
  }
];

// frequent questions
const modeCreateFrequentQuestion = [
  ({ props, get, store }) => {
    store.set(state.events.frequent_questions.createMode, props.value);
  }
];

const modeUpdateFrequentQuestion = [
  ({ props, get, store }) => {
    store.set(state.events.frequent_questions.editMode, props.value);
  }
];

const createFrequentQuestion = [
  ({ store }) => {
    store.set(state.events.frequent_questions.update.loader, true);
  },
  ({ props, http, path }) => {
    const {
      name,
      event_id,
      content
    } = props;

    return http
      .post(`admin/events/event/create-frequent-question/` + event_id, {
        title: name,
        description: content,
      })
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Pregunta frecuente creada', 'success'),
      actions.getEvent,
      {
        success: [
          actions.setEvent,
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.events.frequent_questions.update.loader, false);
  }
];

const updateFrequentQuestion = [
  ({ store }) => {
    store.set(state.events.frequent_questions.update.loader, true);
  },
  ({ props, http, path }) => {
    const {
      name,
      frequent_question_id,
      event_id,
      content
    } = props;

    return http
      .post(`admin/events/event/update-frequent-question/` + event_id + `/` + frequent_question_id, {
        title: name,
        description: content,
      })
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Pregunta frecuente actualizada', 'success'),
      actions.getEvent,
      {
        success: [
          actions.setEvent,
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.events.frequent_questions.update.loader, false);
  }
];

const deleteFrequentQuestion = [
  ({ store }) => {
    store.set(state.events.frequent_questions.delete.loader, true);
  },
  ({ props, http, path }) => {
    const {event_id, frequent_question_id} = props;
    return http
      .post(`admin/events/event/destroy-frequent-question/` + event_id + `/` + frequent_question_id)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Pregunta frecuente eliminada', 'success'),
      actions.getEvent,
      {
        success: [
          actions.setEvent,
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.events.frequent_questions.delete.loader, false);
  }
];

// tickets
const modeCreateTickets = [
  ({ props, get, store }) => {
    store.set(state.events.tickets.createMode, props.value);
  }
];

const modeUpdateTickets = [
  ({ props, get, store }) => {
    store.set(state.events.tickets.editMode, props.value);
  }
];

const createTickets = [
  ({ store }) => {
    store.set(state.events.tickets.update.loader, true);
  },
  ({ props, http, path, get }) => {
    const {
      type,
      price,
      quantity,
      spots_sites,
    } = props;

    return http
      .post(`admin/events/event/create-ticket/` + get(state.events.show.id), {
        type: type,
        quantity: quantity,
        price: price,
        spots_sites: spots_sites,
      })
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Tipo de boleto creado', 'success'),
      actions.getEvent,
      {
        success: [
          actions.setEvent,
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.events.tickets.update.loader, false);
  }
];

const updateTickets = [
  ({ store }) => {
    store.set(state.events.tickets.update.loader, true);
  },
  ({ props, http, path }) => {
    const {
      type,
      event_id,
      ticket_id,
      price,
      quantity,
    } = props;

    return http
      .post(`admin/events/event/update-ticket/` + event_id + `/` + ticket_id, {
        type: type,
        quantity: quantity,
        price: price,
      })
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Tipo de boleto actualizado', 'success'),
      actions.getEvent,
      {
        success: [
          actions.setEvent,
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.events.tickets.update.loader, false);
  }
];

const sendCovidNotification = [
  ({ store }) => {
    store.set(state.events.notification.loader, true);
  },
  ({ props, http, path, get }) => {
    const {id} = get(state.events.notification);
    return http
      .post(`admin/events/event/covid-notification/` + id)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Correos de notificación enviados', 'success'),
      ({ store }) => {
        store.set(state.events.notification, {
          id: null,
          loader: false,
          open: false,
        });
      }
      
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.events.notification, {
      id: null,
      loader: false,
      open: false,
    });
  }
];

const sendTicketsNotification = [
  ({ store }) => {
    store.set(state.events.tickets_notification.loader, true);
  },
  ({ props, http, path, get }) => {
    const {id} = get(state.events.tickets_notification);
    return http
      .post(`admin/events/event/tickets-notification/` + id)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Correos de notificación enviados', 'success'),
      ({ store }) => {
        store.set(state.events.tickets_notification, {
          id: null,
          loader: false,
          open: false,
        });
      }
      
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.events.tickets_notification, {
      id: null,
      loader: false,
      open: false,
    });
  }
];

const deleteTickets = [
  ({ store }) => {
    store.set(state.events.tickets.delete.loader, true);
  },
  ({ props, http, path }) => {
    const {event_id, ticket_id} = props;
    return http
      .post(`admin/events/event/destroy-ticket/` + event_id + `/` + ticket_id)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Tipo de boleto eliminado', 'success'),
      actions.getEvent,
      {
        success: [
          actions.setEvent,
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.events.tickets.delete.loader, false);
  }
];

// Payments details
const eventsGetExcel = [
  ({ store }) => { store.set(state.events.reports.export_in_process, true); },
  ({ props, http, path, store }) => {
    const { values } = props;
    const data = new FormData();

    data.append('type', 'Excel');
    if ( values.event_id ) {
      data.append('event_id', values.event_id);
    }

    const token = localStorage.getItem(NAME_TOKEN);
    axios({
      url: `${API_URL}/api/v1` + `/admin/reports/events-payments/` + values.event_id,
      method: 'POST',
      data: data,
      responseType: 'blob', // important
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/vnd.ms-excel; charset=UTF-8'
      }
    }).then((response) => {
      var date = new Date();
      var friendly_date = date.getFullYear() + '_' + date.getMonth() + '_' + date.getDate();
      var outputFilename = `reporte_ventas_de_evento_${values.slug}_${friendly_date}.xlsx`;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      store.set(state.events.reports.export_in_process, false);
      path.success({ res })
    }).catch( function() {
      store.set(state.events.reports.export_in_process, false);
    })
  },
  {
    success: [
      notify('Reporte generado', 'success'),
    ],
    error: [notify(props.message.error, 'error')]
  },
];

const eventsGetExcelRefunds = [
  ({ store }) => { store.set(state.events.reports.export_in_process, true); },
  ({ props, http, path, store }) => {
    const { values } = props;
    const data = new FormData();

    data.append('type', 'Excel');
    if ( values.event_id ) {
      data.append('event_id', values.event_id);
    }

    const token = localStorage.getItem(NAME_TOKEN);
    axios({
      url: `${API_URL}/api/v1` + `/admin/reports/events-payments-refunds/` + values.event_id,
      method: 'POST',
      data: data,
      responseType: 'blob', // important
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/vnd.ms-excel; charset=UTF-8'
      }
    }).then((response) => {
      var date = new Date();
      var friendly_date = date.getFullYear() + '_' + date.getMonth() + '_' + date.getDate();
      var outputFilename = `reporte_rembolsos_de_evento_${values.slug}_${friendly_date}.xlsx`;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      store.set(state.events.reports.export_in_process, false);
      path.success({ res })
    }).catch( function() {
      store.set(state.events.reports.export_in_process, false);
    })
  },
  {
    success: [
      notify('Reporte generado', 'success'),
    ],
    error: [notify(props.message.error, 'error')]
  },
];

const updateEventTermsAndConditions = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values } = props;
    const data = new FormData();

    data.append('value', values.value);
    data.append('active', values.active);

    return http
      .postFile(`admin/events/event-terms-conditions/update`, data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Terminos y condiciones de eventos actualizados', 'success'),
      ({ props }) => {
        page.show(`/eventos-terminos-y-condiciones/`);
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];

const handleOpenEventNotificationModal = [
  ({ props, store }) => {
    store.set(state.events.notification, {
      id: props.id,
      loader: false,
      open: true,
    });
  }
];

const handleCloseEventNotificationModal = [
  ({ store }) => {
    store.set(state.events.notification, {
      id: null,
      loader: false,
      open: false,
    });
  }
]

const handleOpenEventTicketsNotificationModal = [
  ({ props, store }) => {
    store.set(state.events.tickets_notification, {
      id: props.id,
      loader: false,
      open: true,
    });
  }
];

const handleCloseEventTicketsNotificationModal = [
  ({ store }) => {
    store.set(state.events.tickets_notification, {
      id: null,
      loader: false,
      open: false,
    });
  }
]

// refunds tickets
const handleShowModalTicketRefund = [
  ({ props, store }) => {
    store.set(state.events.refund_ticket, {
      id: props.id,
      loader: false,
      name: props.full_name,
      ticket: props.details[0],
      open: true,
    });
  }
];

const handleCloseModalTicketRefund = [
  ({ store }) => {
    store.set(state.events.refund_ticket, {
      id: null,
      loader: false,
      name: null,
      open: false,
    });
  }
];

const handleShowModalSoldTicketRefund = [
  ({ props, store }) => {
    store.set(state.events.sold_refund_ticket, {
      id: props.id,
      loader: false,
      name: props.full_name,
      payment_event: props,
      open: true,
    });
  }
];

const handleCloseModalSoldTicketRefund = [
  ({ store }) => {
    store.set(state.events.sold_refund_ticket, {
      id: null,
      loader: false,
      payment_event: null,
      name: null,
      open: false,
    });
  }
];

const handleShowModalTicketRefundType = [
  ({ props, store, get }) => {
    store.set(state.events.refund_ticket, {
      id: null,
      loader: false,
      ticket: get(state.events.refund_ticket.ticket),
      name: null,
      open: false,
    });
    store.set(state.events.refund_ticket_confirmation, {
      id: props.id,
      loader: false,
      open: true,
    });
  }
];

const handleCloseModalTicketRefundType = [
  ({ store }) => {
    store.set(state.events.refund_ticket_confirmation, {
      id: null,
      loader: false,
      open: false,
    });
  }
];

const refundTicket = [
  ({ store }) => {
    store.set(state.events.refund_ticket.loader, true);
  },
  ({ props, http, path }) => {
    const {event_id, payment_event_id, event_ticket_id} = props;

    var data = new FormData();
    data.append('event_ticket_id', event_ticket_id);

    return http
      .postFile(`admin/events/event/refund-ticket-type/` + event_id + `/` + payment_event_id, data )
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Orden actualizada', 'success'),
      actions.getEvent,
      {
        success: [
          actions.setEvent,
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.events.tickets.delete.loader, false);
    store.set(state.events.refund_ticket_confirmation, {
      id: null,
      loader: false,
      open: false,
    });
    store.set(state.events.refund_ticket, {
      id: null,
      loader: false,
      ticket: null,
      name: null,
      open: false,
    });
  }
];

const payRefundTicket = [
  ({ store }) => {
    store.set(state.events.sold_refund_ticket.loader, true);
  },
  ({ get, http, path }) => {
    const payment_event = get(state.events.sold_refund_ticket).payment_event;
    
    return http
      .get(`admin/events/event/refund-ticket-paid/` + payment_event.event_id + `/` + payment_event.id )
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Orden marcada como rembolsada', 'success'),
      ({ props }) => {
        page.show(`/eventos/`);
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.events.sold_refund_ticket, {
      id: null,
      loader: false,
      payment_event: null,
      name: null,
      open: false,
    });
  }
];

const handleShowModalCreateSpotRows = [
  ({ props, store, get }) => {
    store.set(state.events.event_spot, {
      id: get(state.events.show.id),
      loader: false,
      name: null,
      open: true,
    });
  }
];

const handleShowModalAsignSpotRows = [
  ({ props, store, get }) => {
    store.set(state.events.event_spot_row, {
      id: get(state.events.show.id),
      name: props.label,
      loader: false,
      open: true,
    });
  }
];

const handleShowModalAsignSpotColumn = [
  ({ props, store, get }) => {
    store.set(state.events.event_spot_column, {
      id: get(state.events.show.id),
      name: props.label,
      number: props.number,
      loader: false,
      open: true,
    });
  }
];
const handleShowModalDeleteSpotColumn = [
  ({ props, store, get }) => {
    store.set(state.events.event_spot_delete_column, {
      id: get(state.events.show.id),
      name: props.label,
      number: props.number,
      loader: false,
      open: true,
    });
  }
];
const handleShowModalDeleteSpotRow = [
  ({ props, store, get }) => {
    store.set(state.events.event_spot_delete_row, {
      id: get(state.events.show.id),
      name: props.label,
      loader: false,
      open: true,
    });
  }
];
const handleCloseModalCreateSpotRows  = [
  ({ store }) => {
    store.set(state.events.event_spot, {
      id: null,
      loader: false,
      name: null,
      open: false,
    });
  }
];
const handleCloseModalAsignSpotRows  = [
  ({ store }) => {
    store.set(state.events.event_spot_row, {
      id: null,
      loader: false,
      name: null,
      open: false,
    });
  }
];
const handleCloseModalAsignSpotColumn  = [
  ({ store }) => {
    store.set(state.events.event_spot_column, {
      id: null,
      loader: false,
      name: null,
      number: null,
      open: false,
    });
  }
];
const handleCloseModalDeleteSpotColumn  = [
  ({ store }) => {
    store.set(state.events.event_spot_delete_column, {
      id: null,
      loader: false,
      name: null,
      number: null,
      open: false,
    });
  }
];
const handleCloseModalDeleteSpotRow  = [
  ({ store }) => {
    store.set(state.events.event_spot_delete_row, {
      id: null,
      loader: false,
      name: null,
      open: false,
    });
  }
];

const submitModalSpotRows  = [
  ({ store }) => {
    store.set(state.events.event_spot.loader, true);
  },
  ({ props, http, path, get }) => {
    const { row_label, number_rows, event_ticket_id } = props;
    const event = get(state.events.show);

    var data = new FormData();
    for (let index = 0; index < number_rows; index++) {
      data.append('matriz[' + row_label + '][' + (index+1) + ']', event_ticket_id);
    }

    return http
      .postFile(`admin/events/event/create-spot/` + event.id + `/`, data )
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Fila de lugares registrada', 'success'),
      actions.getEvent,
      {
        success: [
          actions.setEvent,
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.events.event_spot, {
      id: null,
      loader: false,
      name: null,
      open: false,
    });
  }
];
const submitModalAsignSpot  = [
  ({ store }) => {
    store.set(state.events.event_spot_row.loader, true);
  },
  ({ props, http, path, get }) => {
    const { event_ticket_id, apply_all } = props;
    const event = get(state.events.show);
    const label = get(state.events.event_spot_row.name);

    var data = new FormData();
    data.append('label', label);
    data.append('event_ticket_id', event_ticket_id);
    data.append('apply_all', apply_all == 0 ? false : true);

    return http
      .postFile(`admin/events/event/asign-spots-row/` + event.id + `/`, data )
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Fila actualizada', 'success'),
      actions.getEvent,
      {
        success: [
          actions.setEvent,
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.events.event_spot_row, {
      id: null,
      loader: false,
      name: null,
      open: false,
    });
  }
];
const submitModalAsignSpotColumn  = [
  ({ store }) => {
    store.set(state.events.event_spot_column.loader, true);
  },
  ({ props, http, path, get }) => {
    const { event_ticket_id } = props;
    const event = get(state.events.show);

    const label = get(state.events.event_spot_column.name);
    const number = get(state.events.event_spot_column.number);

    var data = new FormData();
    data.append('label', label);
    data.append('number', number);
    data.append('event_ticket_id', event_ticket_id);
    return http
      .postFile(`admin/events/event/asign-spots-column/` + event.id + `/`, data )
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Lugar actualizado', 'success'),
      actions.getEvent,
      {
        success: [
          actions.setEvent,
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.events.event_spot_column, {
      id: null,
      loader: false,
      name: null,
      number: null,
      open: false,
    });
  }
];
const submitModalDeleteSpotColumn  = [
  ({ store }) => {
    store.set(state.events.event_spot_delete_column.loader, true);
  },
  ({ props, http, path, get }) => {
    const { event_ticket_id } = props;
    const event = get(state.events.show);

    const label = get(state.events.event_spot_delete_column.name);
    const number = get(state.events.event_spot_delete_column.number);

    var data = new FormData();
    data.append('label', label);
    data.append('number', number);
    data.append('event_ticket_id', event_ticket_id);
    return http
      .postFile(`admin/events/event/destroy-spots-column/` + event.id + `/`, data )
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Lugar actualizado', 'success'),
      actions.getEvent,
      {
        success: [
          actions.setEvent,
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.events.event_spot_delete_column, {
      id: null,
      loader: false,
      name: null,
      number: null,
      open: false,
    });
  }
];
const submitModalDeleteSpotRow  = [
  ({ store }) => {
    store.set(state.events.event_spot_delete_row.loader, true);
  },
  ({ props, http, path, get }) => {
    const event = get(state.events.show);
    const label = get(state.events.event_spot_delete_row.name);

    var data = new FormData();
    data.append('label', label);

    return http
      .postFile(`admin/events/event/destroy-spots-row/` + event.id + `/`, data )
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Fila actualizada', 'success'),
      actions.getEvent,
      {
        success: [
          actions.setEvent,
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.events.event_spot_delete_row, {
      id: null,
      loader: false,
      name: null,
      open: false,
    });
  }
];

export default {
    routes: {
      archiveds: EventsListArchivedsRoute,
      create: EventsCreateRoute,
      list: EventsListRoute,
      show: EventsShowRoute,
      terms_conditions: EventsTermsConditionsRoute,
    },
    sequences: {
      updateEvent,
      createEvent,

      archiveEvent,
      deleteEvent,
      handleClickOpenDeleteEventModal,
      handleClickCloseDeleteEventModal,

      dearchiveEvent,
      handleClickOpenArchivedEventModal,
      handleClickCloseArchivedEventModal,

      handleSlidersDeleteModal,
      handleSlidersDeleteCloseModal,
      sliderDeleteAction,
      sliderCreateAction,
      sliderupdateSortMedia,

      handleSlidersLocationDeleteModal,
      handleSlidersLocationDeleteCloseModal,
      sliderDeleteLocationAction,
      sliderCreateLocationAction,
      sliderupdateLocationSortMedia,

      modeUpdateFrequentQuestion,
      modeCreateFrequentQuestion,
      createFrequentQuestion,
      updateFrequentQuestion,
      deleteFrequentQuestion,

      modeUpdateTickets,
      modeCreateTickets,
      createTickets,
      updateTickets,
      deleteTickets,
      handleShowModalSoldTicketRefund,
      handleCloseModalSoldTicketRefund,
      payRefundTicket,

      eventsGetExcel,
      eventsGetExcelRefunds,

      updateEventTermsAndConditions,

      refundTicket,
      handleShowModalTicketRefund,
      handleCloseModalTicketRefund,
      handleShowModalTicketRefundType,
      handleCloseModalTicketRefundType,

      handleOpenEventNotificationModal,
      handleCloseEventNotificationModal,
      sendCovidNotification,

      handleOpenEventTicketsNotificationModal,
      handleCloseEventTicketsNotificationModal,
      sendTicketsNotification,

      handleShowModalCreateSpotRows,
      handleCloseModalCreateSpotRows,
      submitModalSpotRows,

      handleShowModalAsignSpotRows,
      handleCloseModalAsignSpotRows,
      submitModalAsignSpot,
      
      handleShowModalAsignSpotColumn,
      handleCloseModalAsignSpotColumn,
      submitModalAsignSpotColumn,
      
      handleShowModalDeleteSpotColumn,
      handleCloseModalDeleteSpotColumn,
      submitModalDeleteSpotColumn,
      
      handleShowModalDeleteSpotRow,
      handleCloseModalDeleteSpotRow,
      submitModalDeleteSpotRow,
      
    }
  };
  