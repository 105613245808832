import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';

import FrequentQuestionsComponent from './Tabs/FrequentQuestionsComponent';
import GeneralComponent from './Tabs/GeneralComponent';
import SlidersComponent from './Tabs/SlidersComponent';
import SpotsComponent from './Tabs/SpotsComponent';
import SlidersLocationsComponent from './Tabs/SlidersLocationsComponent';
import TicketsComponent from './Tabs/TicketsComponent';
import TicketsSoldsComponent from './Tabs/TicketsSoldsComponent';
import TicketsPendingsRefundComponent from './Tabs/TicketsPendingsRefundComponent';

import {
  Button,
  Form,
  Icon,
  Tabs,
  Tooltip,
  Modal
} from 'antd';

const { TabPane } = Tabs;

export default Form.create()(
  connect(
    {
      // states
      galleryDelete: state.events.delete,
      loadingLogin: state.user.loginButtonDisable,
      openTab: state.events.openTab,
      show: state.events.show,
      sold_refund: state.events.sold_refund_ticket,
      FrequentQuestionStates: state.events.frequent_questions,
      TicketsStates: state.events.tickets,
      refund: state.events.refund_ticket,
      refund_confirm: state.events.refund_ticket_confirmation,

      state_reports:    state.events.reports,
      state_modal_covid:state.events.notification,
      state_modal_tickets_notification:state.events.tickets_notification,
      event_spot:       state.events.event_spot,
      event_spot_column:state.events.event_spot_column,
      event_spot_row:   state.events.event_spot_row,
      event_spot_delete_column:state.events.event_spot_delete_column,
      event_spot_delete_row:   state.events.event_spot_delete_row,

      // sequences
      handleSlidersDeleteModal: sequences.handleSlidersDeleteModal,
      handleSlidersDeleteCloseModal: sequences.handleSlidersDeleteCloseModal,
      sliderDeleteAction: sequences.sliderDeleteAction,
      sliderCreateAction: sequences.sliderCreateAction,
      sliderupdateSortMedia: sequences.sliderupdateSortMedia,
      
      handleSlidersLocationDeleteModal: sequences.handleSlidersLocationDeleteModal,
      handleSlidersLocationDeleteCloseModal: sequences.handleSlidersLocationDeleteCloseModal,
      sliderDeleteLocationAction: sequences.sliderDeleteLocationAction,
      sliderCreateLocationAction: sequences.sliderCreateLocationAction,
      sliderupdateLocationSortMedia: sequences.sliderupdateLocationSortMedia,
      
      modeCreateFrequentQuestion: sequences.modeCreateFrequentQuestion,
      modeUpdateFrequentQuestion: sequences.modeUpdateFrequentQuestion,
      createFrequentQuestion: sequences.createFrequentQuestion,
      updateFrequentQuestion: sequences.updateFrequentQuestion,
      deleteFrequentQuestion: sequences.deleteFrequentQuestion,
      
      modeCreateTickets: sequences.modeCreateTickets,
      modeUpdateTickets: sequences.modeUpdateTickets,
      createTickets: sequences.createTickets,
      updateTickets: sequences.updateTickets,
      deleteTickets: sequences.deleteTickets,
      refundTicket: sequences.refundTicket,
      handleShowModalSoldTicketRefund: sequences.handleShowModalSoldTicketRefund,
      handleCloseModalSoldTicketRefund: sequences.handleCloseModalSoldTicketRefund,
      payRefundTicket: sequences.payRefundTicket,
      
      submit: sequences.updateEvent,
      updateTab: sequences.updateTab,

      submitExcel: sequences.eventsGetExcel,
      submitExcelRefund: sequences.eventsGetExcelRefunds,
      
      handleShowModalTicketRefund: sequences.handleShowModalTicketRefund,
      handleCloseModalTicketRefund: sequences.handleCloseModalTicketRefund,
      handleShowModalTicketRefundType: sequences.handleShowModalTicketRefundType,
      handleCloseModalTicketRefundType: sequences.handleCloseModalTicketRefundType,
      
      handleShowModalCreateSpotRows: sequences.handleShowModalCreateSpotRows,
      handleCloseModalCreateSpotRows: sequences.handleCloseModalCreateSpotRows,
      submitModalSpotRows: sequences.submitModalSpotRows,
      
      handleShowModalAsignSpotRows: sequences.handleShowModalAsignSpotRows,
      handleCloseModalAsignSpotRows: sequences.handleCloseModalAsignSpotRows,
      submitModalAsignSpot: sequences.submitModalAsignSpot,
      
      handleShowModalAsignSpotColumn: sequences.handleShowModalAsignSpotColumn,
      handleCloseModalAsignSpotColumn: sequences.handleCloseModalAsignSpotColumn,
      submitModalAsignSpotColumn: sequences.submitModalAsignSpotColumn,
      
      handleShowModalDeleteSpotColumn: sequences.handleShowModalDeleteSpotColumn,
      handleCloseModalDeleteSpotColumn: sequences.handleCloseModalDeleteSpotColumn,
      submitModalDeleteSpotColumn: sequences.submitModalDeleteSpotColumn,
      
      handleShowModalDeleteSpotRow: sequences.handleShowModalDeleteSpotRow,
      handleCloseModalDeleteSpotRow: sequences.handleCloseModalDeleteSpotRow,
      submitModalDeleteSpotRow: sequences.submitModalDeleteSpotRow,

      handleOpenEventNotificationModal: sequences.handleOpenEventNotificationModal,
      handleCloseEventNotificationModal: sequences.handleCloseEventNotificationModal,
      sendCovidNotification: sequences.sendCovidNotification,

      handleOpenEventTicketsNotificationModal: sequences.handleOpenEventTicketsNotificationModal,
      handleCloseEventTicketsNotificationModal: sequences.handleCloseEventTicketsNotificationModal,
      sendTicketsNotification: sequences.sendTicketsNotification,
    },
    class EventsShowView extends Component {
      constructor(props) {
        super(props);
        this.state = {};

        this.onChangeTab = this.onChangeTab.bind(this);
        this.handleSubmitAll = this.handleSubmitAll.bind(this);

      }

      onChangeTab(key) {
        const { updateTab } = this.props;
        updateTab({ tab: key });
      }

      handleSubmitAll(e) {
        e.preventDefault();
        const {
          form,
          show,
          submit,
        } = this.props;
        form.validateFields((err, values) => {
          if (!err) {
              submit({ event: show, values });
          }
        });
      }

      render() {
        const { 
          form,
          galleryDelete,
          loadingLogin,
          openTab,
          show,
          refund,
          event_spot,
          event_spot_column,
          event_spot_row,
          event_spot_delete_column,
          event_spot_delete_row,
          refund_confirm,
          sold_refund,

          handleSlidersDeleteModal,
          handleSlidersDeleteCloseModal,
          handleShowModalTicketRefund,
          handleCloseModalTicketRefund,
          handleShowModalTicketRefundType,
          handleCloseModalTicketRefundType,
          sliderDeleteAction,
          sliderCreateAction,
          sliderupdateSortMedia,

          handleSlidersLocationDeleteModal,
          handleSlidersLocationDeleteCloseModal,
          sliderDeleteLocationAction,
          sliderCreateLocationAction,
          sliderupdateLocationSortMedia,

          FrequentQuestionStates,
          modeCreateFrequentQuestion,
          modeUpdateFrequentQuestion,
          deleteFrequentQuestion,
          createFrequentQuestion,
          updateFrequentQuestion,

          handleShowModalSoldTicketRefund,
          handleCloseModalSoldTicketRefund,
          payRefundTicket,
          TicketsStates,
          modeCreateTickets,
          modeUpdateTickets,
          deleteTickets,
          refundTicket,
          createTickets,
          updateTickets,

          submitExcel,
          submitExcelRefund,
          state_reports,

          state_modal_covid,
          handleOpenEventNotificationModal,
          handleCloseEventNotificationModal,
          sendCovidNotification,

          state_modal_tickets_notification,
          handleOpenEventTicketsNotificationModal,
          handleCloseEventTicketsNotificationModal,
          sendTicketsNotification,

          handleShowModalCreateSpotRows,
          handleCloseModalCreateSpotRows,
          submitModalSpotRows,

          handleShowModalAsignSpotRows,
          handleCloseModalAsignSpotRows,
          submitModalAsignSpot,

          handleShowModalDeleteSpotColumn,
          handleCloseModalDeleteSpotColumn,
          submitModalDeleteSpotColumn,
          
          handleShowModalDeleteSpotRow,
          handleCloseModalDeleteSpotRow,
          submitModalDeleteSpotRow,

          handleShowModalAsignSpotColumn,
          handleCloseModalAsignSpotColumn,
          submitModalAsignSpotColumn,
        } = this.props;
        
        return (
          <div>
            <div className="move-content-right">
              {openTab === 'general' ? (

                <Tooltip title="Guardar cambios" placement="left">
                  <Button
                    icon="save"
                    type="primary"
                    htmlType="submit"
                    loading={loadingLogin}
                    onClick={this.handleSubmitAll}
                    style={{ display: (show.archived != 1 ? 'block' : 'none') }}
                  />
                </Tooltip>
              ) : (
                <Button disabled icon="save" type="primary" htmlType="submit" />
              )}

              <Tooltip title="Cancelar" placement="left">
                <a
                  style={{ marginLeft: 10 }}
                  href="/eventos"
                  className="ant-btn ant-btn-primary ant-btn-icon-only"
                  rel="noopener noreferrer"
                >
                  <Icon type="arrow-left" />
                </a>
              </Tooltip>

              <Tooltip title="Enviar notificación de Covid 19" placement="left">
                <Button
                  style={{ marginLeft: 10, marginRight: 10, width: 'auto', paddingRight: '10px'}}
                  onClick={() => {
                    handleOpenEventNotificationModal( show );
                  }}
                  className="ant-btn ant-btn-primary ant-btn-icon-only"
                  rel="noopener noreferrer"
                >
                  <Icon type="mail" style={{ marginLeft: 10, marginRight: 10 }} />
                  Notificación Covid 19
                </Button>
              </Tooltip>

            </div>

            <Tabs defaultActiveKey={openTab} onChange={this.onChangeTab}>

              <TabPane tab="General" key="general">
                <Form layout="horizontal">
                  <GeneralComponent
                    form={form}
                    show={show}
                  />
                </Form>
              </TabPane>

              <TabPane tab="Sliders" key="slides">
                <SlidersComponent
                  gallery={show.media_banners}
                  stateDelete={galleryDelete}
                  event_id={show.id}
                  slug={show.slug}
                  show={show}

                  sliderupdateSortMedia={sliderupdateSortMedia}
                  handleSlidersDeleteModal={handleSlidersDeleteModal}
                  handleSlidersDeleteCloseModal={handleSlidersDeleteCloseModal}
                  sliderDeleteAction={sliderDeleteAction}
                  sliderCreateAction={sliderCreateAction}                  
                />
              </TabPane>

              <TabPane tab="Imagenes de ubicación" key="slides-location">
                <SlidersLocationsComponent
                  gallery={show.media_sliders_location}
                  stateDelete={galleryDelete}
                  event_id={show.id}
                  slug={show.slug}
                  show={show}

                  handleSlidersDeleteCloseModal={handleSlidersLocationDeleteCloseModal}
                  sliderDeleteAction={sliderDeleteLocationAction}
                  sliderCreateAction={sliderCreateLocationAction}
                  sliderupdateSortMedia={sliderupdateLocationSortMedia}
                  handleSlidersDeleteModal={handleSlidersLocationDeleteModal}
                />
              </TabPane>
              
              <TabPane tab="Preguntas frecuentes" key="frequent_questions">
                <FrequentQuestionsComponent
                  form={form}
                  show={show}

                  FrequentQuestionStates={FrequentQuestionStates}
                  modeUpdateFrequentQuestion={modeUpdateFrequentQuestion}
                  modeCreateFrequentQuestion={modeCreateFrequentQuestion}
                  createFrequentQuestion={createFrequentQuestion}
                  updateFrequentQuestion={updateFrequentQuestion}
                  deleteFrequentQuestion={deleteFrequentQuestion}
                />
              </TabPane>

              <TabPane tab={ 'Boletos' } key="tickets">
                <TicketsComponent
                  form={form}
                  show={show}

                  TicketsStates={TicketsStates}
                  modeUpdateTickets={modeUpdateTickets}
                  modeCreateTickets={modeCreateTickets}
                  createTickets={createTickets}
                  updateTickets={updateTickets}
                  deleteTickets={deleteTickets}
                ></TicketsComponent>
              </TabPane>
              
              <TabPane tab={ 'Asientos' } key="event_solds">
                <SpotsComponent
                  form={form}
                  show={show}
                  event_spot={event_spot}
                  event_spot_row={event_spot_row}
                  event_spot_column={event_spot_column}
                  event_spot_delete_column={event_spot_delete_column}
                  event_spot_delete_row={event_spot_delete_row}

                  handleShowModalCreateSpotRows={handleShowModalCreateSpotRows}
                  handleCloseModalCreateSpotRows={handleCloseModalCreateSpotRows}
                  submitModalSpotRows={submitModalSpotRows}

                  handleShowModalAsignSpotRows={handleShowModalAsignSpotRows}
                  handleCloseModalAsignSpotRows={handleCloseModalAsignSpotRows}
                  submitModalAsignSpot={submitModalAsignSpot}

                  handleShowModalAsignSpotColumn={handleShowModalAsignSpotColumn}
                  handleCloseModalAsignSpotColumn={handleCloseModalAsignSpotColumn}
                  submitModalAsignSpotColumn={submitModalAsignSpotColumn}

                  handleShowModalDeleteSpotColumn={handleShowModalDeleteSpotColumn}
                  handleCloseModalDeleteSpotColumn={handleCloseModalDeleteSpotColumn}
                  submitModalDeleteSpotColumn={submitModalDeleteSpotColumn}
                  
                  handleShowModalDeleteSpotRow={handleShowModalDeleteSpotRow}
                  handleCloseModalDeleteSpotRow={handleCloseModalDeleteSpotRow}
                  submitModalDeleteSpotRow={submitModalDeleteSpotRow}
                />
              </TabPane>
              
              <TabPane tab={ 'Boletos vendidos (' + (show.payments_payeds_count) + ')' } key="tickets_solds">
                <TicketsSoldsComponent
                  form={form}
                  show={show}
                  state_reports={state_reports}
                  sold_refund={sold_refund}
                  submitExcel={submitExcel}
                  state_modal_tickets_notification={state_modal_tickets_notification}
                  handleOpenEventTicketsNotificationModal={handleOpenEventTicketsNotificationModal}
                  handleCloseEventTicketsNotificationModal={handleCloseEventTicketsNotificationModal}
                  sendTicketsNotification={sendTicketsNotification}
                  handleShowModalSoldTicketRefund={handleShowModalSoldTicketRefund}
                  handleCloseModalSoldTicketRefund={handleCloseModalSoldTicketRefund}
                  payRefundTicket={payRefundTicket}
                />
              </TabPane>

              {
                show.payments_with_refunds_count > 0 ? (
                  <TabPane tab={ 'Reembolsos (' + (show.payments_with_refunds_count) + ')' } key="tickets_refunds">
                    <TicketsPendingsRefundComponent
                      form={form}
                      show={show}
                      refund={refund}
                      refund_confirm={refund_confirm}
                      state_reports={state_reports}
                      sold_refund={sold_refund}
                      submitExcelRefund={submitExcelRefund}
                      handleShowModalTicketRefund={handleShowModalTicketRefund}
                      handleCloseModalTicketRefund={handleCloseModalTicketRefund}
                      handleShowModalTicketRefundType={handleShowModalTicketRefundType}
                      handleCloseModalTicketRefundType={handleCloseModalTicketRefundType}
                      refundTicket={refundTicket}
                      handleShowModalSoldTicketRefund={handleShowModalSoldTicketRefund}
                      handleCloseModalSoldTicketRefund={handleCloseModalSoldTicketRefund}
                      payRefundTicket={payRefundTicket}
                    />
                  </TabPane>
                ) : null
              }
            </Tabs>

            <Modal
              closable={false}
              visible={state_modal_covid.open}
              onCancel={() => {
                handleCloseEventNotificationModal();
              }}
              footer={[
              <Button
                  type="primary"
                  onClick={() => {
                    sendCovidNotification();
                  }}
                  loading={state_modal_covid.loader} >
                  Confirmar
                </Button>,
                <Button
                  onClick={() => {
                    handleCloseEventNotificationModal();
                  }}
                >
                  Cancelar
                </Button>
              ]}
              >
              <h4>¿Enviar correo de notificación Covid 19?</h4>
              <p> Esta acción enviará a todos los clientes de este evento un correo de notificación </p>
            </Modal>
          </div>
        );
      }
    }
  )
);
