import OptionsListView from './OptionsListView';
import OptionsFormView from './OptionsFormView';
import OptionsFormViewUpdate from './OptionsFormViewUpdate';


export default {
  OptionsListView,
  OptionsFormView,
  OptionsFormViewUpdate
}
