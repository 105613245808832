import React, {Component} from 'react';
import {connect} from '@cerebral/react';
import {state} from 'cerebral';
import {Table} from 'antd';
import Pagination from '@component/Elements/Pagination';

export default connect(
  {
    list: state.app.contacts
  },
  class ContactList extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: true
      }
    }

    render() {
      const {list} = this.props;

      const columns = [
        {
          title: 'Nombre',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email'
        },
        {
          title: 'Mensaje',
          dataIndex: 'message',
          key: 'message'
        },
        {
          title: 'Telefono',
          dataIndex: 'phone',
          key: 'phone'
        },
       /*  {
          title: 'Acciones',
          dataIndex: 'contact_time',
          key: 'contact_time'
        } */
      ];

      return (
        <div>
          <Table
            bordered
            dataSource={list}
            columns={columns}
            pagination={false}
          />
          <Pagination list="app.contacts" apiUrl="web/contacts" />
        </div>
      );
    }
  }
)
