import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { Form, Button, Upload, Input, Icon } from 'antd';
import {sequences} from 'cerebral';
import normFile from '@utils/normFile';

const ComponentCerebral = connect(
  {
    submit: sequences.submitBook
  },
  class BooksForm extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isSubmit: false
      };
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
      e.preventDefault();
      this.setState({isSubmit: true})
      const { form, submit } = this.props;
      form.validateFields((err, values) => {
        if (!err) {
          console.log("estos son los valores", values)
          submit({ values });
        } else {
          this.setState({isSubmit: false});
        }
      });
    }

    render() {
      const { form } = this.props;
      const { isSubmit } = this.state;
      const FormItem = Form.Item;
      const { getFieldDecorator } = form;

      return (
        <div>
          <Form onSubmit={this.handleSubmit}>
            {/* Name  */}
            <FormItem label="Nombre del libro">
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: 'Este campo es requerido'
                  }
                ]
              })(<Input placeholder="Un libro magnifico" />)}
            </FormItem>
            {/** Imagen thumb */}
            <FormItem label="Imagen del libro">
              {getFieldDecorator('cover', {
                valuePropName: 'fileList',
                getValueFromEvent: normFile,
                rules: [
                  {
                    required: true,
                    message: 'Por favor seleccione una imagen'
                  }
                ]
              })(
                <Upload
                  name="thumb"
                  beforeUpload={() => false}
                  accept="image/jpeg, image/png"
                >
                  <Button>
                    <Icon type="upload" /> Subir imagen
                  </Button>
                </Upload>
              )}
            </FormItem>
            {/** Imagen thumb */}
            <FormItem label="PDF">
              {getFieldDecorator('pdf', {
                valuePropName: 'fileList',
                getValueFromEvent: normFile,
                rules: [
                  {
                    required: true,
                    message: 'Por favor selecciona un pdf'
                  }
                ]
              })(
                <Upload
                  name="thumb"
                  beforeUpload={() => false}
                  accept=".pdf"
                >
                  <Button>
                    <Icon type="upload" /> Añadir PDF
                  </Button>
                </Upload>
              )}
            </FormItem>
            {/** Action */}
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isSubmit}>
                Crear
              </Button>
            </Form.Item>
          </Form>
        </div>
      );
    }
  }
);

export default Form.create()(ComponentCerebral);
