import { state } from 'cerebral';
import { setPagination } from '@modules/actions';

const submitPagination = [
  ({ props, http, path, get }) => {
    const pagination = get(state`app.pagination`);
    const { apiUrl, p, perPage, extraBySearch } = props;
    let extraString = {};
    if (typeof extraBySearch !== 'undefined') {
      extraString = extraBySearch;
    }

    let page = 1;
    let perPageNew = pagination.perPage;

    if (p) {
      page = p;
    }
    if (perPage) {
      perPageNew = perPage;
    }
    return http
      .get(apiUrl, {}, { page, per_page: perPageNew, ...extraString })
      .then(res => path.success({ result: res }))
      .catch(error => path.error({ error }));
  },
  {
    success: [
      ({ props, store }) => {
        window.scrollTo(0, 0);
        if (typeof props.result.data !== 'undefined') {
          store.set(state`${props.list}`, props.result.data);
        } else {
          store.set(state`${props.list}`, props.result.result.data);
        }
      },
      setPagination
    ],
    error: []
  }
];

export default { submitPagination };
