import { state } from 'cerebral';

export function getDashboardData({ http, path, get }) {
  return http
    .get(
      'admin/dashboard',
      {},
      {
        sales_month_date: get(state.dashboardDates.sales_month_date),
        coupons_redeemed_for_the_month_date: get(
          state.dashboardDates.coupons_redeemed_for_the_month_date
        ),
        users_date: get(state.dashboardDates.users_date),
      }
    )
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}

export function getDashboardDataMonth({ http, path, get }) {
  return http
    .get(
      'admin/dashboard',
      {},
      {
        month: true,
        sales_month_date: get(state.dashboardDates.sales_month_date),
      }
    )
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}

export function getDashboardDataYear({ http, path, get }) {
  return http
    .get(
      'admin/dashboard',
      {},
      {
        year: true,
        sales_year_date: get(state.dashboardDates.sales_year_date),
      }
    )
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}

export function setDashboardData({ props, store }) {
  const { result } = props;
  store.set(state.dashboard, result);
}

export function setDashboardMonthData({ props, store }) {
  const { result } = props;
  store.merge(state.dashboard, result);
}

export function setDashboardYearData({ props, store }) {
  const { result } = props;
  store.merge(state.dashboard, result);
}

export function getBrands({ http, path, get }) {
  return http
    .get(
      'brands-simple/',
      {},
      {
        brandList: get(state.brandList),
      }
    )
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}

export function setBrandList({ props, store }) {
  const result = [...props.result];
  result.unshift({id: 0, name: 'Todas las marcas'});
  store.set(state.brandList, result);
}
