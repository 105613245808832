export default function(target, ...args) {
  function splice(context) {
    if (!context.resolve.isTag(target, 'state', 'moduleState')) {
      throw new Error(
        'Cerebral factory.splice: You have to use the STATE or MODULESTATE tag as first argument'
      )
    }

    context.store.splice(target, ...args)
  }

  splice.displayName = `factory.splice(${String(target)}, ${args
    .map((arg) => {
      return String(arg)
    })
    .join(',')})`

  return splice
}
