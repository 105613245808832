export default function(target) {
  function pop(context) {
    if (!context.resolve.isTag(target, 'state', 'moduleState')) {
      throw new Error(
        'Cerebral factory.pop: You have to use the STATE or MODULESTATE tag as first argument'
      )
    }

    context.store.pop(target)
  }

  pop.displayName = `factory.pop(${String(target)})`

  return pop
}
