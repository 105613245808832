import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Checkbox, Tooltip, Button, Icon, Divider } from 'antd';
import classNames from 'classnames/bind';
import arrayMove from 'array-move';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';

import styles from './CombinationGalleryView.css';

const cx = classNames.bind(styles);

export default connect(
  {
    // states
    all: state.products.combinations.gallery,
    select: state.products.combinations.selectGallery,
    selectComplete: state.products.combinations.selectGalleryComplete,
    loadingLogin: state.user.loginButtonDisable,
    productCombination: state.products.combinations.productCombination,
    // sequences
    updateGalleryCombination: sequences.updateGalleryCombination,
    updateStateCombinationSelect: sequences.updateStateCombinationSelect,
    updateSortMediaCombination: sequences.updateSortMediaCombination
  },
  class CombinationGalleryView extends Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.onChangeOption = this.onChangeOption.bind(this);
      this.onSortEnd = this.onSortEnd.bind(this);
    }

    onChangeOption(checkedValues) {
      this.checkedValues = checkedValues;
      const { updateStateCombinationSelect } = this.props;
      updateStateCombinationSelect({ value: checkedValues });
    }

    // eslint-disable-next-line class-methods-use-this
    onSortEnd({ oldIndex, newIndex }) {
      const { selectComplete, productCombination } = this.props;
      const result = selectComplete.sort((a, b) => (a.sort > b.sort ? 1 : -1));
      const newData = arrayMove(result, oldIndex, newIndex);
      const values = {
        data: newData,
        product_combination_id: productCombination.id
      };

      const { updateSortMediaCombination } = this.props;

      updateSortMediaCombination({ values });
    }

    getNameFile(name) {
      this.name = name;
      const splitName = name.split('/');
      return splitName.pop();
    }

    render() {
      const {
        all,
        productCombination,
        select,
        loadingLogin,
        updateGalleryCombination,
        selectComplete
      } = this.props;

      const SortableContainer = sortableContainer(({ children }) => {
        return <ul className={cx('content-gallery')}>{children}</ul>;
      });
      const DragHandle = sortableHandle(() => (
        <span className={cx('content-pin-move')}>
          <Icon type="pushpin" />
        </span>
      ));

      const SortableItem = sortableElement(({ value }) => (
        <li key={value.id} className={cx('list-gallery')}>
          <img
            src={value.product_image.image.url_image}
            alt="Imagen de producto"
            className={cx('file')}
          />
          <DragHandle />
          <p className={cx('list-number')}>
            {value.sort + 1}
            <span className={cx('nameFile')}>
              {' '}
              {this.getNameFile(value.product_image.image.url_image)}
            </span>
          </p>
        </li>
      ));
      return (
        <div>
          <div className="move-content-right">
            <Tooltip title="Guardar selección" placement="left">
              <Button
                icon="save"
                type="primary"
                htmlType="submit"
                loading={loadingLogin}
                onClick={() => {
                  updateGalleryCombination({
                    values: select,
                    id: productCombination.id
                  });
                }}
              />
            </Tooltip>
            <Tooltip title="Cancelar" placement="left">
              <a
                style={{ marginLeft: 10 }}
                href={`/productos/${productCombination.product.uuid}`}
                className="ant-btn ant-btn-primary ant-btn-icon-only"
                rel="noopener noreferrer"
              >
                <Icon type="arrow-left" />
              </a>
            </Tooltip>
          </div>
          <h4>COMBINACIÓN ({productCombination.option_attribute.name})</h4>
          <p>Por favor seleccione imagenes de la lista y de clic en guardar</p>
          <Checkbox.Group
            style={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap'
            }}
            onChange={this.onChangeOption}
            defaultValue={select}
          >
            {all.map(file => (
              <div className={cx('content-file')}>
                <Checkbox value={file.id}>
                  <img
                    src={file.image.url_image}
                    alt="imagen"
                    className={cx('image')}
                  />
                  <span className={cx('nameFile','pink')}>{this.getNameFile(file.image.url_image)}</span>
                </Checkbox>
              </div>
            ))}
          </Checkbox.Group>
          <Divider></Divider>
          <p>
            Para cambiar el orden de las imagenes de clic en el icono de pin (
            <Icon type="pushpin" />) sin soltar y mueva la imagen hasta la
            posición deseada.
          </p>

          <SortableContainer onSortEnd={this.onSortEnd} axis="xy" useDragHandle>
            {selectComplete
              .sort((a, b) => (a.sort > b.sort ? 1 : -1))
              .map(file => (
                <SortableItem
                  key={`sort-${file.sort}`}
                  index={file.sort}
                  value={file}
                />
              ))}
          </SortableContainer>
          {selectComplete.length === 0 ? (
            <p className={cx('withoutresults')}>Sin resultados</p>
          ) : null}
        </div>
      );
    }
  }
);
