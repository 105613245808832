import React, { Component } from 'react';
import {
  Row,
  Col,
  Select,
  Tooltip,
  Button,
  Table,
  Modal,
  Input,
  InputNumber,
  Icon,
  Spin
} from 'antd';
import debounce from 'lodash/debounce';
import { formatNumberAmount } from '@utils';

const { Option } = Select;
const { confirm } = Modal;
const IVA = 1.16;
const style = {
  siderbarRight: {
    backgroundColor: '#f1f2f6',
    borderRadius: 5,
    padding: '2rem 1rem',
    height: '97%'
  },
  label: {
    marginBottom: 5
  }
};

export default class Combinations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      combinations: [],
      reference: '',
      percent: 0,
      price: 0,
      quantity: 0,
      typePercent: 'NONE',
      name: '',
      edit: false,
      priceMoreIva: 0,
      data: [],
      value: [],
      fetching: false
    };

    this.showConfirm = this.showConfirm.bind(this);
    this.calcNewPrice = this.calcNewPrice.bind(this);
    this.calcNewPriceMoreIva = this.calcNewPriceMoreIva.bind(this);
    this.onChangePriceMoreIva = this.onChangePriceMoreIva.bind(this);
    this.setByUpdate = this.setByUpdate.bind(this);
    this.onChangeReference = this.onChangeReference.bind(this);
    this.onChangePercent = this.onChangePercent.bind(this);
    this.onChangeQuantitty = this.onChangeQuantitty.bind(this);
    this.onChangeTypePercent = this.onChangeTypePercent.bind(this);
    // by select
    this.lastFetchId = 0;
    this.fetchCombinations = debounce(this.fetchCombinations.bind(this), 500);
    this.handleChangeCombinations = this.handleChangeCombinations.bind(this);
  }

  onChangeTypePercent(value) {
    this.setState({
      typePercent: value
    });
  }

  onChangeReference(e) {
    this.setState({
      reference: e.target.value
    });
  }

  onChangePercent(value) {
    const { typePercent } = this.state;
    if (typePercent === 'AMOUNT') {
      // eslint-disable-next-line no-restricted-globals
      const withIva = isNaN(parseFloat(value, 10))
        ? 0
        : parseFloat(value * IVA, 10);
      this.setState({
        // eslint-disable-next-line no-restricted-globals
        price: isNaN(parseFloat(value, 10)) ? 0 : parseFloat(value, 10),
        priceMoreIva: withIva.toFixed(2)
      });
    } else {
      this.setState({
        // eslint-disable-next-line no-restricted-globals
        percent: isNaN(parseFloat(value, 10)) ? 0 : parseFloat(value, 10)
      });
    }
  }

  onChangeQuantitty(value) {
    this.setState({
      // eslint-disable-next-line no-restricted-globals
      quantity: isNaN(parseFloat(value, 10)) ? 0 : parseFloat(value, 10)
    });
  }

  onChangePriceMoreIva(value) {
    this.value = value;
    // eslint-disable-next-line no-restricted-globals
    const withIva = isNaN(parseFloat(value, 10)) ? 0 : parseFloat(value, 10);
    this.setState({
      priceMoreIva: withIva.toFixed(2),
      // eslint-disable-next-line no-restricted-globals
      price: isNaN(parseFloat(value, 10)) ? 0 : parseFloat(value / IVA, 10)
    });
  }

  // eslint-disable-next-line class-methods-use-this
  setByUpdate(data) {
    this.setState({
      reference: data.reference,
      percent: data.percent,
      price: data.price,
      priceMoreIva: (data.price * IVA).toFixed(2),
      quantity: data.quantity,
      typePercent: data.type_percent,
      name: data.option_attribute.name,
      productCombinationId: data.id,
      edit: true
    });
    const { modeUpdateCombination } = this.props;
    modeUpdateCombination({ value: true });
  }

  handleChangeCombinations(value) {
    this.value = value;
    this.setState({
      value,
      data: [],
      fetching: false
    });
    const data = [];
    value.forEach(element => {
      data.push(element.key);
    });
    this.setState({ combinations: data });

    const { updateOptionsAttributesInSelect } = this.props;
    updateOptionsAttributesInSelect({ values: data });
  }

  fetchCombinations(value) {
    this.value = value;
    if (value != '') {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.setState({ data: [], fetching: true });
      fetch(
        `${process.env.API_URL}/api/v1/admin/options-attribute-simple-list-by-search/${value}`,
        {
          headers: {
            authorization: `bearer ${localStorage.getItem(
              process.env.NAME_TOKEN
            )}`
          }
        }
      )
        .then(response => response.json())
        .then(body => {
          if (fetchId !== this.lastFetchId) {
            return;
          }
          this.setState({ data: body, fetching: false });
        });
    }
  }

  showConfirm(data) {
    this.data = data;
    const { deleteCombination } = this.props;
    confirm({
      title: `¿Esta seguro que desear eliminar la combinación "${data.option_attribute.name}"?`,
      content: '',
      onOk() {
        deleteCombination({ data });
      },
      onCancel() {}
    });
  }

  calcNewPrice() {
    const { detail } = this.props;
    const { percent, typePercent, price } = this.state;
    if (typePercent === 'NONE') {
      return `$${formatNumberAmount(detail.price, '.', '.', ',')}`;
    }
    if (typePercent === 'AMOUNT') {
      return `$${formatNumberAmount(price, '.', '.', ',')}`;
    }
    if (typePercent === 'POSITIVE') {
      return `$${formatNumberAmount(
        parseFloat(detail.price) +
          (parseFloat(percent) * parseFloat(detail.price)) / 100,
        '.',
        '.',
        ','
      )}`;
    }
    return `$${formatNumberAmount(
      parseFloat(detail.price) -
        (parseFloat(percent) * parseFloat(detail.price)) / 100,
      '.',
      '.',
      ','
    )}`;
  }

  calcNewPriceMoreIva() {
    const { detail } = this.props;
    const { percent, typePercent, price } = this.state;
    if (typePercent === 'NONE') {
      return `$${formatNumberAmount(
        parseFloat(detail.price) * IVA,
        '.',
        '.',
        ','
      )}`;
    }
    if (typePercent === 'AMOUNT') {
      return `$${formatNumberAmount(parseFloat(price) * IVA, '.', '.', ',')}`;
    }
    if (typePercent === 'POSITIVE') {
      return `$${formatNumberAmount(
        (parseFloat(detail.price) +
          (parseFloat(percent) * parseFloat(detail.price)) / 100) *
          IVA,
        '.',
        '.',
        ','
      )}`;
    }
    return `$${formatNumberAmount(
      (parseFloat(detail.price) -
        (parseFloat(percent) * parseFloat(detail.price)) / 100) *
        IVA,
      '.',
      '.',
      ','
    )}`;
  }

  render() {
    const {
      combinationsList,
      combinationOptions,
      generateCombinationsSubmit,
      productId,
      disabledTableCombination,
      updateCombination,
      loaderCombination,
      detail,
      galleryProduct
    } = this.props;

    const {
      reference,
      percent,
      typePercent,
      edit,
      name,
      productCombinationId,
      quantity,
      price,
      fetching,
      data,
      value,
      combinations,
      priceMoreIva
    } = this.state;

    const columns = [
      {
        title: 'COMBINACION',
        dataIndex: 'option_attribute.name',
        key: 'first_name'
      },
      {
        title: 'PRECIO',
        dataIndex: 'price',
        key: 'price',
        render: (text, element, index) => (
          <span>${formatNumberAmount(text, '.', '.', ',')}</span>
        )
      },
      {
        title: 'PRECIO + IVA',
        dataIndex: 'price_tax',
        key: 'price_tax',
        render: (text, element, index) => (
          <span>${formatNumberAmount(text, '.', '.', ',')}</span>
        )
      },
      {
        title: 'CANTIDAD',
        dataIndex: 'quantity',
        key: 'quantity'
      },
      {
        title: '# REFERENCIA ',
        dataIndex: 'reference',
        key: 'reference',
        render: (text, element, index) => (
          <span>{text === null || text === '' ? 'Sin referencia' : text}</span>
        )
      },
      {
        title: 'ACCIONES',
        dataIndex: 'id',
        key: 'id',
        render: (text, element, index) => (
          <div>
            <Tooltip title="Eliminar combinación" placement="top">
              <Button
                type="primary"
                icon="delete"
                onClick={() => {
                  this.showConfirm(combinationsList[index]);
                }}
              />
            </Tooltip>
            <Tooltip title="Editar combinación" placement="top">
              <Button
                type="primary"
                icon="edit"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  this.setByUpdate(combinationsList[index]);
                }}
              />
            </Tooltip>
            {galleryProduct.length === 0 ? (
              <Tooltip
                title="Es necesario dar de alta imagenes en la galeria"
                placement="top"
              >
                <Button
                  disabled
                  type="primary"
                  icon="picture"
                  style={{ marginLeft: 10 }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Galeria" placement="top">
                <a
                  href={`/productos/${detail.uuid}/${combinationsList[index].id}`}
                  className="ant-btn ant-btn-primary ant-btn-icon-only"
                  rel="noopener noreferrer"
                  style={{ marginLeft: 10 }}
                >
                  <Icon type="picture" />
                </a>
              </Tooltip>
            )}
          </div>
        )
      }
    ];
    return (
      <Row gutter={16} type="flex">
        <Col xs={24} sm={17} md={17}>
          <Row gutter={16}>
            <Col xs={24} sm={8} md={12}>
              <Select
                mode="multiple"
                labelInValue
                value={value}
                placeholder="Por favor seleccione algunas combinación"
                notFoundContent={fetching ? <Spin size="small" /> : null}
                filterOption={false}
                style={{ width: '100%' }}
                allowClear
                onSearch={this.fetchCombinations}
                onChange={this.handleChangeCombinations}
              >
                {data.map(d => (
                  <Option key={d.id.toString()} value={d.id.toString()}>
                    {' '}
                    {d.name} ({d.option.name})
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} sm={8} md={12}>
              <Tooltip title="Generar combinaciones" placement="left">
                <Button
                  icon="plus"
                  type="primary"
                  htmlType="submit"
                  loading={combinationOptions.loading}
                  onClick={() => {
                    generateCombinationsSubmit({ combinations, productId });
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24}>
              <Table
                style={{ marginTop: 20, marginBottom: 10 }}
                columns={columns}
                dataSource={combinationsList}
                bordered
                pagination={false}
                loading={disabledTableCombination}
                scroll={{ x: 'max-content' }}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={7} md={7}>
          <div style={style.siderbarRight}>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24}>
                <p style={{ textAlign: 'center' }}>
                  <strong>EDICIÓN DE COMBINACIÓN:</strong>
                </p>
              </Col>
              {edit === true ? (
                <div>
                  <Col xs={24} sm={24} md={24}>
                    <p style={{ textAlign: 'center' }}>({name})</p>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <p style={style.label}>Referencia:</p>
                    <Input
                      placeholder="Referencia"
                      style={{ width: '100%', marginBottom: 10 }}
                      value={reference}
                      onChange={this.onChangeReference}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <p style={style.label}>Cantidad:</p>
                    <InputNumber
                      placeholder="Cantidad"
                      step={1}
                      precision={0}
                      style={{ width: '100%', marginBottom: 10 }}
                      value={quantity}
                      onChange={this.onChangeQuantitty}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <p style={style.label}>Opcion de precio:</p>
                    <Select
                      placeholder="Por favor seleccione una opción de precio"
                      style={{ width: '100%', marginBottom: 10 }}
                      value={typePercent}
                      onChange={this.onChangeTypePercent}
                    >
                      <Select.Option key="type-percent-1" value="NONE">
                        NINGUNO
                      </Select.Option>
                      <Select.Option key="type-percent-4" value="AMOUNT">
                        PRECIO DIRECTO
                      </Select.Option>
                      <Select.Option key="type-percent-2" value="POSITIVE">
                        AÑADIR PORCENTAJE
                      </Select.Option>
                      <Select.Option key="type-percent-3" value="NEGATIVE">
                        RESTAR PORCENTAJE
                      </Select.Option>
                    </Select>
                  </Col>
                  {typePercent !== 'NONE' ? (
                    <Col xs={24} sm={24} md={24}>
                      {typePercent !== 'AMOUNT' ? (
                        <Row>
                          <Col>
                            <p style={style.label}>
                              {typePercent !== 'AMOUNT'
                                ? 'Porcentaje'
                                : 'Precio'}
                              :
                            </p>
                            <InputNumber
                              placeholder={
                                typePercent !== 'AMOUNT'
                                  ? 'Porcentaje'
                                  : 'Precio'
                              }
                              step={0.1}
                              precision={6}
                              style={{ width: '100%', marginBottom: 10 }}
                              value={typePercent === 'AMOUNT' ? price : percent}
                              onChange={this.onChangePercent}
                            />
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col xs={12} sm={12} md={12}>
                            <p style={style.label}>
                              {typePercent !== 'AMOUNT'
                                ? 'Porcentaje'
                                : 'Precio'}
                              :
                            </p>
                            <InputNumber
                              placeholder={
                                typePercent !== 'AMOUNT'
                                  ? 'Porcentaje'
                                  : 'Precio'
                              }
                              step={0.1}
                              precision={6}
                              style={{ width: '100%', marginBottom: 10 }}
                              value={typePercent === 'AMOUNT' ? price : percent}
                              onChange={this.onChangePercent}
                            />
                          </Col>
                          <Col xs={12} sm={12} md={12}>
                            <p style={style.label}>Precio + IVA</p>
                            <InputNumber
                              placeholder="Precio + IVA"
                              step={0.1}
                              precision={6}
                              style={{ width: '100%', marginBottom: 10 }}
                              value={priceMoreIva}
                              onChange={this.onChangePriceMoreIva}
                            />
                          </Col>
                        </Row>
                      )}
                    </Col>
                  ) : null}
                  <Col xs={24} sm={24} md={24}>
                    <p style={style.label}>
                      <strong>Precio base:</strong> $
                      {formatNumberAmount(detail.price, '.', '.', ',')}
                    </p>
                    <p style={style.label}>
                      <strong>Precio base generado:</strong>{' '}
                      {this.calcNewPrice()}
                    </p>
                    <p>
                      <strong>Precio base generado + IVA:</strong>{' '}
                      {this.calcNewPriceMoreIva()}
                    </p>
                  </Col>
                  <Col xs={24} sm={24} md={24} style={{ marginBottom: 10 }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <Tooltip title="Guardar cambios" placement="top">
                        <Button
                          icon="save"
                          type="primary"
                          htmlType="submit"
                          loading={loaderCombination}
                          onClick={() => {
                            const percentOrPrice =
                              typePercent === 'AMOUNT' ? price : percent;
                            updateCombination({
                              productCombinationId,
                              reference,
                              quantity,
                              typePercent,
                              percentOrPrice
                            });
                            const { modeUpdateCombination } = this.props;
                            modeUpdateCombination({ value: false });
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Cancelar" placement="top">
                        <Button
                          icon="close-circle"
                          type="primary"
                          htmlType="submit"
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            this.setState({
                              reference: '',
                              percent: 0,
                              price: 0,
                              quantity: 0,
                              typePercent: 'NONE',
                              name: '',
                              productCombinationId: null,
                              edit: false
                            });
                            const { modeUpdateCombination } = this.props;
                            modeUpdateCombination({ value: false });
                          }}
                        />
                      </Tooltip>
                    </div>
                  </Col>
                </div>
              ) : (
                <Col xs={24} sm={24} md={24}>
                  <p>Es necesario seleccionar un producto</p>
                </Col>
              )}
            </Row>
          </div>
        </Col>
      </Row>
    );
  }
}
