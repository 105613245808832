import { throwError, DEPRECATE, isObject } from './utils'
import { Primitive } from 'function-tree'
import { Compute } from './Compute'
import { Reaction } from './Reaction'

class Module {
  constructor(moduleDescription) {
    this.moduleDescription = moduleDescription
  }

  create(controller, path) {
    const stringPath = path.join('.')
    const moduleStub = {
      app: controller,
      path: stringPath,
      name: path.slice().pop(),
    }

    const module =
      typeof this.moduleDescription === 'function'
        ? this.moduleDescription(moduleStub)
        : Object.assign({}, this.moduleDescription)

    function initializeComputed(object, nestedPath) {
      Object.keys(object).forEach((key) => {
        if (typeof object[key] === 'function') {
          object[key] = new Compute(object[key]).create(
            controller,
            path,
            path.concat(nestedPath, key).join('.')
          )
        } else if (object[key] instanceof Compute) {
          object[key].create(
            controller,
            path,
            path.concat(nestedPath, key).join('.')
          )
        } else if (isObject(object[key])) {
          initializeComputed(object[key], nestedPath.concat(key))
        }

        if (controller.devtools && object[key] instanceof Compute) {
          controller.devtools.registerComputedState(
            object[key],
            path.concat(nestedPath, key)
          )
        }
      })
    }

    initializeComputed(module.state || {}, [])

    /* Convert arrays to actually runable sequences */
    if (module.signals) {
      DEPRECATE(
        'module.signals',
        'use the property "sequences" when adding sequences to a module'
      )
    }

    const sequences = module.sequences || module.signals

    module.sequences = Object.keys(sequences || {}).reduce(
      (currentSequences, sequenceKey) => {
        const sequence = sequences[sequenceKey]
        if (
          !(
            sequence &&
            (Array.isArray(sequence) ||
              typeof sequence === 'function' ||
              sequence instanceof Primitive)
          )
        ) {
          throwError(
            `Sequence with name "${sequenceKey}" is not correctly defined. Please check that the sequence is either a sequence, an array or a function.`
          )
        }
        currentSequences[sequenceKey] = {
          sequence: sequence,
          run(payload) {
            return controller.runSequence(
              path.concat(sequenceKey).join('.'),
              sequence,
              payload
            )
          },
        }

        return currentSequences
      },
      {}
    )

    /* Instantiate submodules */
    module.modules = Object.keys(module.modules || {}).reduce(
      (registered, moduleKey) => {
        const subModule =
          module.modules[moduleKey] instanceof Module
            ? module.modules[moduleKey]
            : new Module(module.modules[moduleKey])
        registered[moduleKey] = subModule.create(
          controller,
          path.concat(moduleKey)
        )

        return registered
      },
      {}
    )

    /* Instantiate watcher */
    module.reactions = Object.keys(module.reactions || {}).reduce(
      (registered, reactionKey) => {
        if (!(module.reactions[reactionKey] instanceof Reaction)) {
          throw new Error(
            `You are not using a Reaction in module on key "${reactionKey}"`
          )
        }

        registered[reactionKey] = module.reactions[reactionKey].create(
          controller,
          path,
          path.concat(reactionKey).join('.')
        )

        return registered
      },
      {}
    )

    return module
  }
}

export default Module
