import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Form, Input, Button, Row, Col, Icon, Tooltip, Select } from 'antd';
import { createValidation } from '@utils';

export default Form.create()(
  connect(
    {
      // states
      loadingLogin: state.user.loginButtonDisable,
      tags: state.brands.tags,
      brandId: state.brands.filtersBrandId,
      // secuences
      submit: sequences.saveFilter
    },
    class FilterFormView extends Component {
      constructor(props) {
        super(props);
        this.state = {};
        this.handleSubmit = this.handleSubmit.bind(this);
      }

      handleSubmit(e) {
        e.preventDefault();
        const { form, submit, brandId } = this.props;
        form.validateFields((err, values) => {
          if (!err) {
            submit({ values, brandId });
          }
        });
      }

      render() {
        const { form, loadingLogin, tags, brandId } = this.props;
        const { getFieldDecorator } = form;
        const FormItem = Form.Item;
        return (
          <div>
            <Form onSubmit={this.handleSubmit} layout="horizontal">
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24}>
                  <FormItem className="move-content-right">
                    <Tooltip title="Guardar filtro" placement="left">
                      <Button
                        icon="save"
                        type="primary"
                        htmlType="submit"
                        loading={loadingLogin}
                      />
                    </Tooltip>
                    <Tooltip title="Cancelar" placement="left">
                      <a
                        style={{ marginLeft: 10 }}
                        href={`/marcas/${brandId}/filtros`}
                        className="ant-btn ant-btn-primary ant-btn-icon-only"
                        rel="noopener noreferrer"
                      >
                        <Icon type="arrow-left" />
                      </a>
                    </Tooltip>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={8} md={12}>
                  <FormItem label="Tipo">
                    {getFieldDecorator('type', {
                      rules: [
                        createValidation(true, 'Por seleccione una opción', '')
                      ]
                    })(
                      <Select
                        defaultValue="FILTER_1"
                        placeholder="Tipo"
                        style={{ width: '100%' }}
                      >
                        <Select.Option key="type-1" value="FILTER_1">
                          Filtro 1
                        </Select.Option>
                        <Select.Option key="type-2" value="FILTER_2">
                          Filtro 2
                        </Select.Option>
                        <Select.Option key="type-3" value="NEWS">
                          Novedades
                        </Select.Option>
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={8} md={12}>
                  <FormItem label="Titulo">
                    {getFieldDecorator('title', {
                      rules: [
                        createValidation(
                          true,
                          'Por favor ingresa un titulo',
                          ''
                        )
                      ]
                    })(<Input placeholder="Titulo" />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={8} md={12}>
                  <FormItem label="Tags">
                    {getFieldDecorator('tags', {
                      rules: [
                        {
                          required: true,
                          message: 'Por favor seleccione algunos tags'
                        }
                      ]
                    })(
                      <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Por favor seleccione algunos tags"
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {tags.map(value => (
                          <Select.Option
                            key={`tags-${value.id}`}
                            value={value.id.toString()}
                          >
                            {value.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>
        );
      }
    }
  )
);
