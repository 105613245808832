import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Upload,
  Icon,
  Tooltip
} from 'antd';
import normFile from '@utils/normFile';
import ReactQuill from 'react-quill';
import { createValidation } from '@utils';
import whitoutImage from '../../../assets/product_whitout_image.png';

const style = {
  miniFile: {
    width: '1rem',
    height: '1rem',
    objectFit: 'cover',
    objectPosition: 'center'
  }
};
export default Form.create()(
  connect(
    {
      // states
      brands: state.posts.brands,
      detail: state.posts.detail,
      loadingLogin: state.user.loginButtonDisable,
      // secuences
      submit: sequences.updatePosts
    },
    class PostsFormUpdateView extends Component {
      constructor(props) {
        super(props);
        this.state = {};
        this.handleSubmit = this.handleSubmit.bind(this);
      }

      handleSubmit(e) {
        e.preventDefault();
        const { form, submit, detail } = this.props;
        form.validateFields((err, values) => {
          if (!err) {
            values.post_id = detail.id;
            submit({ values });
          }
        });
      }

      render() {
        const { form, loadingLogin, brands, detail } = this.props;
        const { getFieldDecorator } = form;
        const FormItem = Form.Item;
        const modules = {
          toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
              { list: 'ordered' },
              { list: 'bullet' },
              { indent: '-1' },
              { indent: '+1' }
            ],
            ['link', 'image', 'video'],
            ['clean']
          ],
          clipboard: {
            matchVisual: false
          }
        };
        const formats = [
          'header',
          'font',
          'size',
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'list',
          'bullet',
          'indent',
          'link',
          'image',
          'video'
        ];
        return (
          <div>
            <Form onSubmit={this.handleSubmit} layout="horizontal">
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24}>
                  <FormItem className="move-content-right">
                    <Tooltip title="Guardar post" placement="left">
                      <Button
                        icon="save"
                        type="primary"
                        htmlType="submit"
                        loading={loadingLogin}
                      />
                    </Tooltip>
                    <Tooltip title="Cancelar" placement="left">
                      <a
                        style={{ marginLeft: 10 }}
                        href="/posts"
                        className="ant-btn ant-btn-primary ant-btn-icon-only"
                        rel="noopener noreferrer"
                      >
                        <Icon type="arrow-left" />
                      </a>
                    </Tooltip>
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={12}>
                  <FormItem label="Marca">
                    {getFieldDecorator('brand_id', {
                      rules: [
                        {
                          required: true,
                          message: 'Por favor seleccione una opción'
                        }
                      ],
                      initialValue: detail.brand_id
                    })(
                      <Select placeholder="Marca" style={{ width: '100%' }}>
                        {brands.map(value => (
                          <Select.Option
                            key={`brand-${value.id}`}
                            value={value.id}
                          >
                            <img
                              src={value.url_photo}
                              alt={value.name}
                              style={style.miniFile}
                            />{' '}
                            {value.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={12}>
                  <FormItem label="Tipo">
                    {getFieldDecorator('type', {
                      rules: [
                        {
                          required: true,
                          message: 'Por favor seleccione una opción'
                        }
                      ], initialValue: detail.type
                    })(
                      <Select placeholder="Tipo" style={{ width: '100%' }}>
                        <Select.Option value="BLOG">BLOG</Select.Option>
                        <Select.Option value="TUTORIAL">TUTORIAL</Select.Option>
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={12}>
                  <FormItem label="Titulo">
                    {getFieldDecorator('title', {
                      rules: [
                        createValidation(
                          true,
                          'Por favor ingresa un titulo',
                          ''
                        )
                      ],
                      initialValue: detail.title
                    })(<Input placeholder="Titulo" />)}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={12}>
                  <FormItem label="Descripción corta">
                    {getFieldDecorator('short_description', {
                      rules: [
                        createValidation(
                          true,
                          'Por favor ingresa una descripción corta',
                          ''
                        )
                      ],
                      initialValue: detail.short_description
                    })(<Input placeholder="Descripción corta" />)}
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <FormItem label="Descripción extensa">
                    {getFieldDecorator('description', {
                      rules: [
                        createValidation(
                          true,
                          'Por favor ingresa una descripción extensa',
                          ''
                        )
                      ],
                      initialValue: detail.description
                    })(
                      <ReactQuill
                        placeholder="..."
                        modules={modules}
                        formats={formats}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={12}>
                  <Row>
                    <Col xs={24} sm={5} md={5}>
                      <p
                        style={{
                          color: 'rgba(0, 0, 0, 0.85)',
                          fontSize: 14,
                          marginTop: 10
                        }}
                      >
                        Principal actual
                      </p>
                      <img
                        src={detail.img_thumb}
                        alt="--"
                        style={{
                          width: '5rem',
                          height: '5rem',
                          objectFit: 'cover',
                          objectPosition: 'center'
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={19} md={19}>
                      <FormItem label="Principal  (535 x 590)">
                        {getFieldDecorator('img_thumb', {
                          valuePropName: 'fileList',
                          getValueFromEvent: normFile,
                          rules: [
                            {
                              required: false,
                              message: 'Por favor seleccione una imagen'
                            }
                          ]
                        })(
                          <Upload
                            name="img_thumb"
                            beforeUpload={() => false}
                            accept="image/jpeg, image/png"
                          >
                            <Button>
                              <Icon type="upload" /> Subir imagen
                            </Button>
                          </Upload>
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} sm={5} md={5}>
                      <p
                        style={{
                          color: 'rgba(0, 0, 0, 0.85)',
                          fontSize: 14,
                          marginTop: 10
                        }}
                      >
                        Thumb actual
                      </p>
                      <img
                        src={
                          detail.img_thumb_mini !== null
                            ? detail.img_thumb_mini
                            : whitoutImage
                        }
                        alt="--"
                        style={{
                          width: '5rem',
                          height: '5rem',
                          objectFit: 'cover',
                          objectPosition: 'center'
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={19} md={19}>
                      <FormItem label="Thumb (350 x 300)">
                        {getFieldDecorator('img_thumb_mini', {
                          valuePropName: 'fileList',
                          getValueFromEvent: normFile,
                          rules: [
                            {
                              required: false,
                              message: 'Por favor seleccione una imagen'
                            }
                          ]
                        })(
                          <Upload
                            name="img_thumb_mini"
                            beforeUpload={() => false}
                            accept="image/jpeg, image/png"
                          >
                            <Button>
                              <Icon type="upload" /> Subir imagen
                            </Button>
                          </Upload>
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        );
      }
    }
  )
);
