import { ensurePath, throwError, DEPRECATE } from '../utils'
import Provider from '../Provider'
import { methods } from './State'

export default function ModuleProviderFactory(devtools) {
  return Provider(
    methods.reduce((currentState, methodKey) => {
      currentState[methodKey] = function(path = [], ...args) {
        path = ensurePath(path)
        DEPRECATE(
          'module.*',
          'use the new STORE provider, store.set(state.isAwesome, true)'
        )
        const executionPath = this.context.execution.name.split('.')
        const modulePath = executionPath.splice(0, executionPath.length - 1)

        return this.context.state[methodKey](modulePath.concat(path), ...args)
      }

      return currentState
    }, {}),
    {
      wrap: devtools
        ? (context, functionDetails) => {
            return methods.reduce((currentState, methodKey) => {
              if (methodKey === 'get' || methodKey === 'compute') {
                currentState[methodKey] = (path) => {
                  DEPRECATE(
                    'module.get',
                    'use the new GET provider, get(moduleState.foo)'
                  )
                  path = ensurePath(path)
                  const executionPath = context.execution.name.split('.')
                  const modulePath = executionPath.splice(
                    0,
                    executionPath.length - 1
                  )

                  path = modulePath.concat(path)

                  return context.state[methodKey](path)
                }
              } else {
                const originFunc = context.state[methodKey]

                currentState[methodKey] = (...args) => {
                  DEPRECATE(
                    'module.*',
                    'use the new STORE provider, store.set(moduleState.isAwesome, true)'
                  )
                  const argsCopy = args.slice()
                  let path = ensurePath(argsCopy.shift())
                  const executionPath = context.execution.name.split('.')
                  const modulePath = executionPath.splice(
                    0,
                    executionPath.length - 1
                  )

                  path = modulePath.concat(path)

                  context.debugger.send({
                    datetime: Date.now(),
                    type: 'mutation',
                    color: '#333',
                    method: `module.${methodKey}`,
                    args: [path, ...argsCopy],
                  })

                  try {
                    originFunc.apply(context.state, [path, ...argsCopy])
                  } catch (e) {
                    const executionPath = context.execution.name
                    throwError(
                      `The sequence "${executionPath}" with action "${
                        functionDetails.name
                      }" has an error: ${e.message}`
                    )
                  }
                }
              }

              return currentState
            }, {})
          }
        : false,
    }
  )
}
