function equalsFactory(target) {
  function equals({ path, resolve }) {
    if (!resolve.isTag(target, 'state', 'props', 'moduleState')) {
      throw new Error(
        'Cerebral factory.equals: You have to use the STATE, PROPS or MODULESTATE tag as first argument'
      )
    }

    const targetValue = resolve.value(target)

    return path[targetValue] ? path[targetValue]() : path.otherwise()
  }

  equals.displayName = `factory.equals(${String(target)})`

  return equals
}

export default equalsFactory
