import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { sequences, state } from 'cerebral';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Tooltip,
  Icon,
  Divider,
  Upload
} from 'antd';
import NumericInput from '@component/NumericInput';
import normFile from '@utils/normFile';
import { createValidation } from '@utils';

export default Form.create()(
  connect(
    {
      // states
      detail: state.user.payload,
      loadingLogin: state.user.loginButtonDisable,
      // secuences
      submit: sequences.updateProfile
    },
    class ProfileView extends Component {
      constructor(props) {
        super(props);
        this.state = {
          phone: props.detail.phone,
          password: '',
          confirmDirty: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.password = this.password.bind(this);
        this.handleConfirmBlur = this.handleConfirmBlur.bind(this);
        this.compareToFirstPassword = this.compareToFirstPassword.bind(this);
      }

      onChange(value) {
        this.setState({ phone: value });
      }

      password(e) {
        this.setState({ password: e.target.value });
      }

      handleSubmit(e) {
        e.preventDefault();
        const { form, submit, detail } = this.props;
        form.validateFields((err, values_) => {
          if (!err) {
            const values = {
              user_uuid: detail.uuid,
              user_id: detail.id,
              ...values_
            };
            submit({ values });
          }
        });
      }

      compareToFirstPassword(rule, value, callback) {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
          callback('La confirmación de password no coincide');
        } else {
          callback();
        }
      }

      handleConfirmBlur(e) {
        const { value } = e.target;
        const { confirmDirty } = this.state;
        this.setState({ confirmDirty: confirmDirty || !!value });
      }

      render() {
        const { form, detail, loadingLogin } = this.props;
        const { phone, password } = this.state;
        const { getFieldDecorator } = form;
        const FormItem = Form.Item;
        return (
          <div>
            {Object.keys(detail).length === 0 ? null : (
              <Form onSubmit={this.handleSubmit} layout="horizontal">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24}>
                    <FormItem className="move-content-right">
                      <Tooltip title="Guardar perfil" placement="left">
                        <Button
                          icon="save"
                          type="primary"
                          htmlType="submit"
                          loading={loadingLogin}
                        />
                      </Tooltip>
                      <Tooltip title="Cancelar" placement="left">
                        <a
                          style={{ marginLeft: 10 }}
                          href="/perfil"
                          className="ant-btn ant-btn-primary ant-btn-icon-only"
                          rel="noopener noreferrer"
                        >
                          <Icon type="arrow-left" />
                        </a>
                      </Tooltip>
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <p>
                      <Icon type="idcard" />{' '}
                      <strong>INFORMACIÓN GENERAL </strong>
                    </p>
                  </Col>
                  <Col xs={24} sm={8} md={12}>
                    <FormItem label="Email">
                      {getFieldDecorator('email', {
                        rules: [
                          createValidation(
                            true,
                            'Por favor ingresa tu email',
                            'email'
                          )
                        ],
                        initialValue: detail.email
                      })(<Input placeholder="Email" />)}
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={8} md={12}>
                    <FormItem label="Nombre">
                      {getFieldDecorator('first_name', {
                        rules: [
                          createValidation(
                            true,
                            'Por favor ingresa tu nombre',
                            ''
                          )
                        ],
                        initialValue: detail.first_name
                      })(<Input placeholder="Nombre" />)}
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={8} md={12}>
                    <FormItem label="Apellido">
                      {getFieldDecorator('last_name', {
                        rules: [
                          createValidation(
                            true,
                            'Por favor ingresa tu apellido',
                            ''
                          )
                        ],
                        initialValue: detail.last_name
                      })(<Input placeholder="Apellido" />)}
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={8} md={12}>
                    <FormItem label="Teléfono">
                      {getFieldDecorator('phone', {
                        rules: [
                          createValidation(
                            true,
                            'Por favor ingresa tu teléfono',
                            ''
                          )
                        ],
                        initialValue: phone
                      })(
                        <NumericInput
                          maxLength={10}
                          placeholder="Teléfono"
                          value={phone}
                          onChange={this.onChange}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={8} md={12}>
                    <FormItem label="Foto de perfil">
                      {getFieldDecorator('photo', {
                        valuePropName: 'fileList',
                        getValueFromEvent: normFile,
                        rules: [
                          {
                            required: false,
                            message: 'Por favor seleccione una imagen de perfil'
                          }
                        ]
                      })(
                        <Upload
                          name="photo"
                          beforeUpload={() => false}
                          accept="image/jpeg, image/png"
                        >
                          <Button>
                            <Icon type="upload" /> Imagen de perfil
                          </Button>
                        </Upload>
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Divider></Divider>
                    <p>
                      <Icon type="safety" /> <strong>SEGURIDAD </strong>
                    </p>
                  </Col>
                  <Col xs={24} sm={8} md={12}>
                    <FormItem label="Password">
                      {getFieldDecorator('password', {
                        rules: [
                          createValidation(
                            false,
                            'Por favor ingresa tu password'
                          )
                        ],
                        initialValue: ''
                      })(
                        <Input
                          type="password"
                          placeholder="Password"
                          onChange={this.password}
                        />
                      )}
                    </FormItem>
                  </Col>
                  {password !== '' ? (
                    <Col xs={24} sm={8} md={12}>
                      <FormItem label="Confirmación de password">
                        {getFieldDecorator('password_confirmation', {
                          rules: [
                            {
                              required: true,
                              message:
                                'Por favor ingresa tu confirmación de password'
                            },
                            {
                              validator: this.compareToFirstPassword
                            }
                          ],
                          initialValue: ''
                        })(
                          <Input
                            type="password"
                            placeholder="Confirmación de password"
                            onBlur={this.handleConfirmBlur}
                          />
                        )}
                      </FormItem>
                    </Col>
                  ) : null}
                </Row>
              </Form>
            )}
          </div>
        );
      }
    }
  )
);
