import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { sequences } from 'cerebral';
import { Button, Icon, Input, Upload, Select, Form, Row } from 'antd';
import normFile from '@utils/normFile';
import { createValidation } from '@utils';

const { Option } = Select;

export default Form.create()(
  connect(
    {
      // Signals
      submit: sequences.submitSlide
    },
    class SlidesForm extends Component {
      constructor(props) {
        super(props);
        this.state = {
          typeMedia: 'image',
          isSubmit: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
      }

      handleSubmit(e) {
        e.preventDefault();
        this.setState({ isSubmit: true });
        const { form, submit } = this.props;
        form.validateFields((err, values) => {
          if (!err) {
            // eslint-disable-next-line react/destructuring-assignment
            submit({ values, type: this.state.typeMedia });
          } else {
            this.setState({ isSubmit: false });
          }
        });
      }

      render() {
        const { form } = this.props;
        const { isSubmit, typeMedia } = this.state;
        const { getFieldDecorator } = form;
        const FormItem = Form.Item;
        return (
          <Form onSubmit={this.handleSubmit}>
            <FormItem label="Tipo de multimedia">
              <Select
                defaultValue="Imagen"
                onChange={e => this.setState({ typeMedia: e })}
              >
                <Option value="video">Youtube ID</Option>
                <Option value="image">Imagen</Option>
              </Select>
            </FormItem>
            {typeMedia === 'image' ? (
              <Row>
                <FormItem label="Seleccione una imagen para el slide principal (1365x772)">
                  {getFieldDecorator('media', {
                    valuePropName: 'fileList',
                    getValueFromEvent: normFile,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor seleccione una imagen'
                      }
                    ]
                  })(
                    <Upload
                      name="media"
                      beforeUpload={() => false}
                      accept="image/jpeg, image/png, image/gif"
                    >
                      <Button>
                        <Icon type="upload" /> Subir imagen
                      </Button>
                    </Upload>
                  )}
                </FormItem>
                <FormItem label="Imagen móvil">
                  {getFieldDecorator('thumb', {
                    valuePropName: 'fileList',
                    getValueFromEvent: normFile,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor seleccione una imagen'
                      }
                    ]
                  })(
                    <Upload
                      name="thumb"
                      beforeUpload={() => false}
                      accept="image/jpeg, image/png,image/gif"
                    >
                      <Button>
                        <Icon type="upload" /> Subir imagen
                      </Button>
                    </Upload>
                  )}
                </FormItem>
              </Row>
            ) : (
              <FormItem label="ID del video de youtube">
                {getFieldDecorator(
                  'mediaYoutube',
                  {
                    rules: [
                      createValidation(
                        true,
                        'Por favor ingrese el id del video de youtube',
                        ''
                      )
                    ]
                  },
                  ''
                )(<Input placeholder="Nombre" />)}
              </FormItem>
            )}

            <FormItem label="CTA del contendor">
              {getFieldDecorator(
                'cta',
                {
                  rules: [
                    createValidation(
                      false,
                      'Por favor ingrese el id del video de youtube',
                      ''
                    )
                  ],
                  initialValue: ''
                },
                ''
              )(
                <Input
                  name="cta"
                  addonAfter={<Icon type="setting" />}
                  placeholder="CTA del contendor"
                />
              )}
            </FormItem>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isSubmit}>
                Crear
              </Button>
            </Form.Item>
          </Form>
        );
      }
    }
  )
);
