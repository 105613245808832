import _camelCase from 'lodash/fp/camelCase';

const defaultColors = [
  '#d73d32',
  '#7e3794',
  '#4285f4',
  '#67ae3f',
  '#d61a7f',
  '#ff4080'
];

/**
 * [_stringAsciiPRNG description]
 * Reference: https://en.wikipedia.org/wiki/Linear_congruential_generator
 * @param  {[type]} value [description]
 * @param  {[type]} m     [description]
 * @return {[type]}       [description]
 */
function _stringAsciiPRNG(value, m) {
  // Xn+1 = (a * Xn + c) % m
  // 0 < a < m
  // 0 <= c < m
  // 0 <= X0 < m

  const charCodes = [...value].map(letter => letter.charCodeAt(0));
  const len = charCodes.length;

  const a = (len % (m - 1)) + 1;
  const c = charCodes.reduce((current, next) => current + next) % m;

  let random = charCodes[0] % m;
  for (let i = 0; i < len; i++) random = (a * random + c) % m;

  return random;
}

/**
 * [getRandomColor description]
 * @param  {[type]} value  [description]
 * @param  {[type]} colors [description]
 * @return {[type]}        [description]
 */
export function getRandomColor(value, colors = defaultColors) {
  // if no value is passed, always return transparent color otherwise
  // a rerender would show a new color which would will
  // give strange effects when an interface is loading
  // and gets rerendered a few consequent times
  if (!value) return 'transparent';

  // value based random color index
  // the reason we don't just use a random number is to make sure that
  // a certain value will always get the same color assigned given
  // a fixed set of colors
  const colorIndex = _stringAsciiPRNG(value, colors.length);
  return colors[colorIndex];
}

/**
 * [defaultInitials description]
 * @param  {[type]} name                [description]
 * @param  {[type]} options.maxInitials [description]
 * @return {[type]}                     [description]
 */
export function defaultInitials(name, { maxInitials }) {
  return name
    .split(/\s/)
    .map(part => part.substring(0, 1).toUpperCase())
    .filter(v => !!v)
    .slice(0, maxInitials)
    .join('');
}

/**
 * [camelCaseKeys description]
 * @param  {[type]} obj [description]
 * @return {[type]}     [description]
 */
export function camelCaseKeys(obj) {
  return Object.keys(obj).reduce(
    (ccObj, field) => ({
      ...ccObj,
      [_camelCase(field)]: obj[field]
    }),
    {}
  );
}

export function createValidation(isRequired, message, type) {
  return {
    required: isRequired,
    message,
    type
  };
}
export function formatNumberAmount(nStr, inD, outD, sep,digits=2) {
  // eslint-disable-next-line use-isnan
  // eslint-disable-next-line no-restricted-globals
  if (typeof nStr === 'undefined' || isNaN(nStr) || nStr === null) {
    return '0.00';
  }
  // eslint-disable-next-line no-param-reassign
  nStr = parseFloat(nStr).toFixed(digits);
  // eslint-disable-next-line no-param-reassign
  nStr += '';
  const dpos = nStr.indexOf(inD);
  let nStrEnd = '';
  if (dpos !== -1) {
    nStrEnd = outD + nStr.substring(dpos + 1, nStr.length);
    // eslint-disable-next-line no-param-reassign
    nStr = nStr.substring(0, dpos);
  }
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(nStr)) {
    // eslint-disable-next-line no-param-reassign
    nStr = nStr.replace(rgx, `$1${sep}$2`);
  }
  return nStr + nStrEnd;
}
