import { state } from 'cerebral';

export function getBrands({ http, path, get }) {
  return http
    .get('admin/brands', {}, { per_page: get(state.app.pagination.perPage) })
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}

export function setBrands({ props, store }) {
  const { result } = props;
  store.set(state.brands.list, result.data);
}
export function getFilterBrands({ http, path, get, props }) {
  return http
    .get(
      'admin/filter-product-brand',
      {},
      {
        per_page: get(state.app.pagination.perPage),
        brand_id:
          get(state.brands.filtersBrandId) == null
            ? props.params.brand_id
            : get(state.brands.filtersBrandId),
        type: get(state.brands.filters.basic)
      }
    )
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}

export function setFilterBrands({ props, store }) {
  const { result } = props;
  store.set(state.brands.listFilters, result.result.data);
  store.set(state.brands.filtersBrandId, result.brand_id);
}
export function setFilterBrandsId({ props, store }) {
  store.set(state.brands.filtersBrandId, props.params.brand_id);
}
export function getBrandDetail({ props, http, path }) {
  const { id } = props.params;
  return http
    .get(`admin/brands/${id}`, {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setBrandDetail({ props, store }) {
  const { result } = props;
  store.set(state.brands.detail, result);
}
export function getTagsSimple({ http, path }) {
  return http
    .get('admin/tags-simple', {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setTagsSimple({ props, store }) {
  const { result } = props;
  store.set(state.brands.tags, result);
}
export function getFilterDetail({ props, http, path }) {
  return http
    .get(`admin/filter-product-brand/${props.params.filter_id}`, {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setFilterDetail({ props, store }) {
  const { result } = props;
  store.set(state.brands.detailFilter, result);
}
export function getBannerBrands({ http, path, get, props }) {
  return http
    .get(
      'admin/banner-brand',
      {},
      {
        per_page: get(state.app.pagination.perPage),
        brand_id:
          get(state.brands.BannerBrandId) == null
            ? props.params.brand_id
            : get(state.brands.BannerBrandId)
      }
    )
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function getBannerSecondaryBrands({ http, path, get, props }) {
  return http
    .get(
      'admin/banner-secondary-brand',
      {},
      {
        brand_id:
          get(state.brands.BannerBrandId) == null
            ? props.params.brand_id
            : get(state.brands.BannerBrandId)
      }
    )
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}

export function setBannerBrands({ props, store }) {
  const { result } = props;
  store.set(state.brands.listBanners, result.result.data);
  store.set(state.brands.BannerBrandId, result.brand_id);
}
export function setBannerSecondaryBrands({ props, store }) {
  const { result } = props;
  store.set(state.brands.listBannerSecondary, result.result);
  store.set(state.brands.BannerBrandId, result.brand_id);
}
export function setBannersBrandsId({ props, store }) {
  store.set(state.brands.BannerBrandId, props.params.brand_id);
}
