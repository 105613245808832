import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { Result } from 'antd';

export default connect(
  {},
  class Template404 extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
      return (
        <Result
          status="404"
          title="404"
          subTitle="PÁGINA NO ENCONTRADA"
          extra={<a href="/">Volver a Inicio</a>}
        />
      );
    }
  }
);
