
import { state } from 'cerebral';

export function getUser({ path, http, props }) {
    return http
      .get(`me/${props.user.id}`)
      .then(res => path.success({ user: res }))
      .catch(error => error.json().then(message => path.error({ message })));
}

export function setUser({ store, props, getToken }) {
  store.set(state.user.jwt, props.jwt);
  store.set(state.user.payload, props.user);
  getToken.set(process.env.NAME_TOKEN, props.jwt);
  getToken.set(process.env.OBJ_USER,  JSON.stringify(props.user));
}
export function clearUser({ store }) {
  store.set(state.user, {
    isLogin: false,
    loginButtonDisable: false,
    jwt: null,
    payload: {
      name: 'Invitate',
      last_name: null
    }
  });
  localStorage.removeItem(process.env.NAME_TOKEN);
  localStorage.removeItem(process.env.OBJ_USER);
}

export function getLocalUser({path, props, getToken}) {
  const jwt = 'jwt' in props ? props.jwt : getToken.get(process.env.NAME_TOKEN);
  const user = 'user' in props ? props.user : JSON.parse(getToken.get(process.env.OBJ_USER));
  if(jwt !==null && user !==null && typeof jwt === 'string' && typeof user === 'object'){
    return path.success({jwt,user})
  }
  return path.error();
}
export function validateRolUser({path, props}) {

  if(props.user.rol_id === 1){
    return path.error({});
  }
  return path.success({});
}
export default {
  getUser,
  setUser,
}
