import React from 'react';
import { connect } from '@cerebral/react';
import { state } from 'cerebral';
import classnames from 'classnames/bind';
import { Layout } from 'antd';
import Menu from '@component/Menu';
import Logo from '../../assets/logo.png';
import style from './style.css';

const cx = classnames.bind(style);
const { Sider } = Layout;

export default connect(
  {
    path: state.app.currentPath,
    collapsed: state.collapsed
  },
  function LeftMenu(props) {
    return (
      <Sider
        trigger={null}
        collapsible
        collapsed={props.collapsed}
        className={`${cx('sidebar')} ${
          !props.collapsed ? cx('open-menu') : null
        }`}
      >
        <div className={cx('content-logo')}>
          <a href="/">
            <img src={Logo} alt="logo" className={cx('logo')} />
          </a>
        </div>
        <Menu />
      </Sider>
    );
  }
);
