import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Table, Button, Modal, Tooltip, Icon } from 'antd';
import Moment from 'react-moment';
import Pagination from '@component/Elements/Pagination';

export default connect(
  {
    // states
    stateDelete: state.tags_users.delete,
    list: state.tags_users.list,
    // secuences
    deleteTag: sequences.deleteTag,
    handleClickOpenModal: sequences.handleClickOpenModalTagUser,
    handleClickCloseModal: sequences.handleClickCloseModalTagUser
  },
  function TagUserListView(props) {
    const {
      list,
      stateDelete,
      deleteTag,
      handleClickCloseModal,
      handleClickOpenModal
    } = props;
    const columns = [
      {
        title: 'Tag',
        dataIndex: 'name',
        key: 'name',
        render: (text, element, index) => (
          <a style={{color: '#EB0089'}} href={`/tags-usuarios/${list[index].id}`}>{text} </a>
        )
      },
      {
        title: 'FECHA CREACIÓN',
        dataIndex: 'created_at',
        key: 'created_at',
        render: text => <Moment format="DD-MM-YYYY hh:mm A">{text}</Moment>
      },
      {
        title: 'ACCIONES',
        dataIndex: 'id',
        key: 'id',
        render: (text, element, index) => (
          <span>
            <Tooltip title="Eliminar tag" placement="top">
              <Button
                type="primary"
                icon="delete"
                onClick={() => {
                  handleClickOpenModal(list[index]);
                }}
              />
            </Tooltip>
          </span>
        )
      }
    ];

    return (
      <div>
        <Modal
          closable={false}
          visible={stateDelete.open}
          onCancel={() => {
            handleClickCloseModal();
          }}
          footer={[
            <Button
              type="primary"
              onClick={() => {
                deleteTag(stateDelete);
              }}
              loading={stateDelete.loader}
            >
              Confirmar
            </Button>,
            <Button
              onClick={() => {
                handleClickCloseModal();
              }}
            >
              Cancelar
            </Button>
          ]}
        >
          <h4>¿Desea eliminar el tag '{stateDelete.name}' ?</h4>
          <p>
            Favor de verificar que el tag seleccionado sea el que realmente
            desea eliminar.
          </p>
        </Modal>
        <div className="move-content-right">
          <Tooltip title="Crear tag" placement="left">
            <a
              href="/tags-usuarios/nuevo"
              className="ant-btn ant-btn-primary ant-btn-icon-only"
              rel="noopener noreferrer"
            >
              <Icon type="plus" />
            </a>
          </Tooltip>
        </div>
        <Table
          bordered
          size="small"
          dataSource={list}
          columns={columns}
          pagination={false}
          scroll={{ x: 'max-content' }}
        />
        <Pagination list="tags.list" apiUrl="admin/tags" />
      </div>
    );
  }
);
