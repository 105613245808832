import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state } from 'cerebral';
import { Card } from 'antd';

export default connect(
  {
    // states
    detail: state.brands.detailFilter
  },
  class FilterDetailView extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
      const { detail } = this.props;
      return (
        <div>
          <h3>
            <strong>Detalle de filtro</strong>
          </h3>
          <p>
            <strong>Titulo:</strong> {detail.data.title}
          </p>
          <p>
            <strong>Tags seleccionados:</strong>
          </p>
          {detail.data.custom_filter_tag.map(value => (
            <Card style={{ width: 300, marginBottom: 10, textAlign: 'center' }}>
              {value.tag.name}
            </Card>
          ))}
        </div>
      );
    }
  }
);
