import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Tabs, Form, Button, Tooltip, Icon } from 'antd';
import Skeleton from '@component/Skeleton';
import General from './forms/General';
import Discounts from './forms/discounts/Discounts';
import Gallery from './forms/Gallery';
import Combinations from './forms/Combinations';
import Kit from './forms/Kit/Kit';

const { TabPane } = Tabs;
export default Form.create()(
  connect(
    {
      // states
      openTab: state.products.openTab,
      loadingLogin: state.user.loginButtonDisable,
      detail: state.products.detail,
      tagsAll: state.products.tags,
      categoriesAll: state.products.categories,
      brandsAll: state.products.brands,

      // secuencies
      submit: sequences.updateProduct,
      updateTypeProduct: sequences.updateTypeProduct,
      updateTypeProductCustom: sequences.updateTypeProductCustom,
      updateTab: sequences.updateTab,
      // GALERIA NORMAL
      loadingGalleryNormal: state.product_files.loading,
      galleryStateDeleteNormal: state.product_files.delete,
      submitGalleryNormal: sequences.uploadFileProductNormal,
      handleClickOpenModalGalleryNormal:
        sequences.handleClickOpenModalGalleryNormalProducts,
      handleClickCloseModalGalleryNormal:
        sequences.handleClickCloseModalGalleryNormalProducts,
      deleteGalleryNormalFileProduct: sequences.deleteGalleryNormalFileProduct,
      updateSortMedia: sequences.updateSortMedia,
      // COMBINATIONS
      combinationsDisabled: state.products.combinationsDisabled,
      optionsAttributes: state.products.optionsAttributes,
      combinationOptions: state.products.combinations,
      generateCombinations: sequences.generateCombinations,
      disabledTableCombination: state.products.combinations.delete.loader,
      deleteCombination: sequences.deleteCombination,
      updateCombination: sequences.updateCombination,
      loaderCombination: state.products.combinations.update.loader,
      updateOptionsAttributesInSelect:
        sequences.updateOptionsAttributesInSelect,
      optionsAttributesInSelect:
        state.products.combinations.optionsAttributesInSelect,
      sendMessageByView: sequences.sendMessageByView,
      modeUpdateCombination: sequences.modeUpdateCombination,
      editModeCombination: state.products.combinations.editMode,
      // discounts
      loaderTableDiscounts: state.discounts.delete.loader,
      deleteDiscount: sequences.deleteDiscount
    },
    class ProductFormViewUpdate extends Component {
      constructor(props) {
        super(props);

        const { detail } = props;
        // eslint-disable-next-line camelcase
        const { is_kit, product_item_kit } = detail;
        this.state = {
          isKit: is_kit.toString(),
          listKits: product_item_kit
        };
        this.handleSubmitAll = this.handleSubmitAll.bind(this);
        this.onChangeTab = this.onChangeTab.bind(this);
        this.onChangeKit = this.onChangeKit.bind(this);
        this.addProductInKit = this.addProductInKit.bind(this);
        this.removeProductInKit = this.removeProductInKit.bind(this);
      }

      componentDidMount() {
        const { detail, updateTypeProductCustom } = this.props;
        if (detail.type === 'COMBINATION') {
          updateTypeProductCustom({ status: false });
        } else {
          updateTypeProductCustom({ status: true });
        }
      }

      onChangeTab(key) {
        const { updateTab } = this.props;
        updateTab({ tab: key });
      }

      onChangeKit(value) {
        this.value = value;
        this.setState({ isKit: value });
      }

      addProductInKit(value) {
        this.value = value;
        const { listKits } = this.state;
        const newList = listKits.concat([value]);
        this.setState({ listKits: newList });
      }

      removeProductInKit(value) {
        this.value = value;
        const { listKits } = this.state;
        const newList = listKits.filter(item => item !== value);
        this.setState({ listKits: newList });
      }

      handleSubmitAll(e) {
        e.preventDefault();
        const {
          form,
          detail,
          submit,
          optionsAttributesInSelect,
          sendMessageByView,
          editModeCombination
        } = this.props;
        form.validateFields((err, values) => {
          if (!err) {
            if (values.type === 'COMBINATION') {
              /*   if (detail.product_combination.length === 0) {
                sendMessageByView({
                  msg:
                    'Es necesario generar al menos una combinación en la sección de combinaciones'
                });
              } else { */
              // validaciones de combinaciones
              // eslint-disable-next-line no-lonely-if
              if (
                optionsAttributesInSelect.length === 0 &&
                editModeCombination === false
              ) {
                const { listKits } = this.state;
                values.kits_items = listKits;
                submit({ productId: detail.id, values, uuid: detail.uuid });
              } else {
                if (optionsAttributesInSelect.length > 0) {
                  sendMessageByView({
                    msg:
                      'Tienes combinaciones pendientes de crear en la sección de combinaciones'
                  });
                }
                if (editModeCombination === true) {
                  sendMessageByView({
                    msg:
                      'Tienes una combinación en modo edición en la sección de combinaciones'
                  });
                }
              }
              // }
            } else {
              const { listKits } = this.state;
              values.kits_items = listKits;
              submit({ productId: detail.id, values, uuid: detail.uuid });
            }
          }
        });
      }

      // eslint-disable-next-line class-methods-use-this

      render() {
        const {
          detail,
          categoriesAll,
          tagsAll,
          brandsAll,
          loadingGalleryNormal,
          submitGalleryNormal,
          galleryStateDeleteNormal,
          handleClickOpenModalGalleryNormal,
          handleClickCloseModalGalleryNormal,
          deleteGalleryNormalFileProduct,
          form,
          loadingLogin,
          openTab,
          updateTypeProductCustom,
          combinationsDisabled,
          optionsAttributes,
          combinationOptions,
          generateCombinations,
          disabledTableCombination,
          deleteCombination,
          updateCombination,
          loaderCombination,
          updateOptionsAttributesInSelect,
          modeUpdateCombination,
          updateSortMedia,
          deleteDiscount,
          loaderTableDiscounts,
          updateTab
        } = this.props;
        const { isKit, listKits } = this.state;

        if (Object.keys(detail).length === 0) {
          return <Skeleton />;
        }
        if (detail.type === 'REGULAR') {
          if (
            openTab !== 'general' &&
            openTab !== 'gallery' &&
            openTab !== 'discounts'
          ) {
            updateTab({ tab: 'general' });
          }
        }
        return (
          <div>
            <div className="move-content-right">
              {openTab === 'general' ? (
                <Tooltip title="Guardar producto" placement="left">
                  <Button
                    icon="save"
                    type="primary"
                    htmlType="submit"
                    loading={loadingLogin}
                    onClick={this.handleSubmitAll}
                  />
                </Tooltip>
              ) : (
                <Button disabled icon="save" type="primary" htmlType="submit" />
              )}
              <Tooltip title="Cancelar" placement="left">
                <a
                  style={{ marginLeft: 10 }}
                  href="/productos"
                  className="ant-btn ant-btn-primary ant-btn-icon-only"
                  rel="noopener noreferrer"
                >
                  <Icon type="arrow-left" />
                </a>
              </Tooltip>
            </div>
            <Tabs defaultActiveKey={openTab} onChange={this.onChangeTab}>
              <TabPane tab="General" key="general">
                <Form layout="horizontal">
                  <General
                    form={form}
                    detail={detail}
                    brandsAll={brandsAll}
                    categoriesAll={categoriesAll}
                    tagsAll={tagsAll}
                    updateTypeProductCustom={updateTypeProductCustom}
                    isKit={isKit}
                    listKits={listKits}
                    onChangeKit={this.onChangeKit}
                  />
                </Form>
              </TabPane>
              <TabPane tab="Galeria" key="gallery">
                <Gallery
                  gallery={detail.product_image}
                  productId={detail.id}
                  uuid={detail.uuid}
                  loading={loadingGalleryNormal}
                  submit={submitGalleryNormal}
                  stateDelete={galleryStateDeleteNormal}
                  handleClickOpenModal={handleClickOpenModalGalleryNormal}
                  handleClickCloseModal={handleClickCloseModalGalleryNormal}
                  deleteFileProduct={deleteGalleryNormalFileProduct}
                  updateSortMedia={updateSortMedia}
                />
              </TabPane>
              {combinationsDisabled === false ? (
                <TabPane tab="Combinaciones" key="combination">
                  <Combinations
                    galleryProduct={detail.product_image}
                    select={optionsAttributes}
                    combinationsList={detail.product_combination}
                    combinationOptions={combinationOptions}
                    generateCombinationsSubmit={generateCombinations}
                    productId={detail.id}
                    detail={detail}
                    disabledTableCombination={disabledTableCombination}
                    deleteCombination={deleteCombination}
                    updateCombination={updateCombination}
                    loaderCombination={loaderCombination}
                    updateOptionsAttributesInSelect={
                      updateOptionsAttributesInSelect
                    }
                    modeUpdateCombination={modeUpdateCombination}
                  />
                </TabPane>
              ) : null}

              <TabPane tab="Descuentos" key="discounts">
                <Discounts
                  product={detail}
                  deleteDiscount={deleteDiscount}
                  loaderTableDiscounts={loaderTableDiscounts}
                ></Discounts>
              </TabPane>
              {isKit === '1' ? (
                <TabPane tab="Kits" key="kits">
                  <Kit
                    listKits={listKits}
                    addProductInKit={this.addProductInKit}
                    removeProductInKit={this.removeProductInKit}
                  />
                </TabPane>
              ) : null}
            </Tabs>
          </div>
        );
      }
    }
  )
);
