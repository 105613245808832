import React, { Component } from 'react';
import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Upload,
  Button,
  Icon,
  Radio,
} from 'antd';

import 'react-quill/dist/quill.snow.css';
import { createValidation } from '@utils';
import moment from 'moment';
import normFile from '@utils/normFile';
import productWhitoutImage from '../../../../assets/product_whitout_image.png';
import ReactQuill from 'react-quill';

const style = {
  imageProductDefault: {
    maxWidth: '100%',
    width: '15rem',
    display: 'block',
    margin: 'auto',
    marginTop: '1rem',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  siderbarRight: {
    backgroundColor: '#f1f2f6',
    borderRadius: 5,
    padding: '2rem 1rem',
    height: '97%'
  }
};

export default class GeneralComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {

    const {
      form,
      show,
    } = this.props;
    
    const { getFieldDecorator } = form;
    const FormItem = Form.Item;
    const modules = {
      toolbar: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' }
        ],
        ['link', 'image', 'video'],
        ['clean']
      ],
      clipboard: {
        matchVisual: false
      }
    };
    const formats = [
      'header',
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'video'
    ];
    return (
      <Row gutter={16} type="flex">
        <Col xs={24} sm={17} md={17}>

          <Row gutter={16}>
            <Col xs={24} sm={8} md={12}>
              <FormItem label="Ruta de navegación">
                {getFieldDecorator('slug', {
                  rules: [
                    createValidation(true, 'Por favor ingresa un slug pare el evento', '')
                  ],
                  initialValue: show.slug
                })(<Input placeholder="ruta de navegación" disabled={show.archived == 1} />)}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
          
            <Col xs={24} sm={8} md={12}>
              <FormItem label="Título">
                {getFieldDecorator('title', {
                  rules: [
                    createValidation(true, 'Por favor ingresa un título de evento', '')
                  ],
                  initialValue: show.title
                })(<Input placeholder="Título" disabled={show.archived == 1} />)}
              </FormItem>
            </Col>

            <Col xs={24} sm={8} md={12}>
              <FormItem label="Estatus">
                {getFieldDecorator('status', {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor seleccione una opción'
                    }
                  ],
                  initialValue: show.status
                })(
                  <Select
                    placeholder="Por favor seleccione una opción"
                    style={{ width: '100%' }}
                    disabled={show.archived == 1} 
                  >
                    {show.status_list.map((item) => {
                      return (
                        <Select.Option key="show-1" value={item.value}>
                          {item.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          
          <Row gutter={16}>

            <Col xs={24} sm={8} md={12}>
              <FormItem label="Encabezado para mapa de ubicación">
                {getFieldDecorator('location_title', {
                  rules: [
                    createValidation(true, 'Ingresa un encabezado para el mapa de ubicación', '')
                  ],
                  initialValue: show.location_title
                })(<Input placeholder="Encabezado" disabled={show.archived == 1} />)}
              </FormItem>
            </Col>

            <Col xs={24} sm={8} md={12}>
              <FormItem label="Fecha">
                {getFieldDecorator('start_at', {
                  rules: [{
                    required: true,
                    message: 'Por favor ingresa una fecha'
                  }],
                  initialValue:
                    show.start_at !== null
                      ? moment(show.start_at, 'YYYY/MM/DD')
                      : null
                })(<DatePicker
                  placeholder="fecha"
                  style={{ width: '100%' }}
                  disabled={show.archived == 1} > </DatePicker>)}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={16} type="flex">

            <Col xs={24} sm={24} md={24}>
              { show.archived != 1 ?
                ( <FormItem label="Descripción">
                {getFieldDecorator('description', {
                  rules: [
                    createValidation(
                      false,
                      'Por favor ingresa la descripción',
                      ''
                    )
                  ],
                  initialValue: show.description
                })(
                  <ReactQuill
                    placeholder="..."
                    modules={modules}
                    formats={formats}
                  />
                )}
              </FormItem> ) : (<FormItem label="Descripción">
                {getFieldDecorator('description', {
                  rules: [
                    createValidation(
                      false,
                      'Por favor ingresa la descripción',
                      ''
                    )
                  ],
                  initialValue: show.description
                })(
                  <ReactQuill
                  readOnly
                    placeholder="..."
                    modules={modules}
                    formats={formats}
                  />
                )}
              </FormItem>)
              }
            </Col>

          </Row>

          <Row gutter={16}>

            <Col xs={24} sm={8} md={12}>
              <FormItem label="Título de sección de Video">
                {getFieldDecorator('video_title', {
                  rules: [
                    createValidation(true, 'Por favor ingresa un slug pare el evento', '')
                  ],
                  initialValue: show.video_title
                })(<Input placeholder="título de sección de de video" disabled={show.archived == 1} />)}
              </FormItem>
            </Col>

            <Col xs={24} sm={8} md={12}>
              <FormItem label="Iframe google maps">
                {getFieldDecorator('event_iframe_gmaps', {
                  rules: [
                    createValidation(false, '', '')
                  ],
                  initialValue: show.event_iframe_gmaps
                })(<Input placeholder="Ej. <iframe src='google.com'></iframe>" disabled={show.archived == 1} />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={8} md={12}>
                <FormItem label="# Referencia">
                  {getFieldDecorator('reference', {
                    rules: [
                      createValidation(
                        false,
                        'Por favor ingresa una referencia',
                        ''
                      )
                    ],
                    initialValue: show.reference
                  })(<Input placeholder="Referencia" />)}
                </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>

            <Col xs={24} sm={8} md={12}>
              <FormItem label="Nombre de salón de eventos">
                {getFieldDecorator('event_hall', {
                  rules: [
                    createValidation(true, 'Por favor ingresa el nombre del salón de eventos', '')
                  ],
                  initialValue: show.event_hall
                })(<Input placeholder="título de sección de de video" disabled={show.archived == 1} />)}
              </FormItem>
            </Col>

            <Col xs={24} sm={8} md={12}>
              <FormItem label="Dirección de salón de eventos">
                {getFieldDecorator('event_address', {
                  rules: [
                    createValidation(false, 'Por favor ingresa un slug pare el evento', '')
                  ],
                  initialValue: show.event_address
                })(<Input placeholder="Calle, número, CP. ciudad" disabled={show.archived == 1} />)}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={16}>

            <Col xs={24} sm={8} md={12}>
              <FormItem label="Enlace externo">
                {getFieldDecorator('event_url', {
                  rules: [
                    createValidation(false, 'Por favor ingresa un enlace externo', '')
                  ],
                  initialValue: show.event_url
                })(<Input placeholder="Ej. https://www.cosmeticosalamoda.com" disabled={show.archived == 1} />)}
              </FormItem>
            </Col>

            <Col xs={24} sm={8} md={12}>
              <FormItem label="Texto en enlace">
                {getFieldDecorator('event_url_title', {
                  rules: [
                    createValidation(false, 'Por favor ingresa un texto para el enlace', '')
                  ],
                  initialValue: show.event_url_title
                })(<Input placeholder="Ej. Ver más" disabled={show.archived == 1} />)}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={16}>
            {show.event_url ? (<Col xs={24} sm={8} md={12}>
              Ejemplo de enlace: <a href={show.event_url} target="_blank">{ show.event_url_title ?  show.event_url_title : 'sin definir'}</a>
            </Col>) : null }
          </Row>

        </Col>

        <Col xs={24} sm={7} md={7}>

          <div style={style.siderbarRight}>

            <Row gutter={16}>
              <strong style={{margin: '0 auto 2em', display: 'table', alignContent: 'center',fontSize: '18px'}}>MEDIA</strong>
              <Col xs={24} sm={24} md={24}>
                <p style={style.pLabel}> Imagen Título (Altura: 115 x Ancho: 716):
                  {show.media_title === null ? (
                    <img
                      src={productWhitoutImage}
                      alt="sin imagen"
                      style={style.imageProductDefault}
                    />
                  ) : (
                    <img
                      src={show.media_title.url}
                      alt="imagen de título"
                      style={style.imageProductDefault}
                    />
                  )}
                </p>
                <FormItem>
                  {getFieldDecorator('media_title_url', {
                    valuePropName: 'fileList',
                    getValueFromEvent: normFile,
                    rules: [
                      {
                        required: false,
                        message: 'Por favor seleccione una imagen'
                      }
                    ]
                  })(
                    <Upload
                      name="media_title_url"
                      beforeUpload={() => false}
                      accept="image/jpeg, image/png"
                      style={{ width: '100%'}}
                      disabled={show.archived == 1}
                    >
                      { show.archived != 1 ? (<Button style={{ width: '100%'}}> <Icon type="upload"/> Subir imagen </Button>) : null }
                    </Upload>
                  )}
                </FormItem>
              </Col>
            </Row>
            <hr/>

            <Row gutter={16}>
              <Col xs={24} sm={24} md={24}>
                <p style={style.pLabel}> Imagen Descripción (Altura: 978 x Ancho: 673):
                  {show.media_description === null ? (
                    <img
                      src={productWhitoutImage}
                      alt="sin imagen"
                      style={style.imageProductDefault}
                    />
                  ) : (
                    <img
                      src={show.media_description.url}
                      alt="imagen de título"
                      style={style.imageProductDefault}
                    />
                  )}
                </p>
                <FormItem>
                  {getFieldDecorator('media_description_url', {
                    valuePropName: 'fileList',
                    getValueFromEvent: normFile,
                    rules: [
                      {
                        required: false,
                        message: 'Por favor seleccione una imagen'
                      }
                    ]
                  })(
                    <Upload
                      name="media_description_url"
                      beforeUpload={() => false}
                      accept="image/jpeg, image/png"
                      style={{ width: '100%' }}
                      disabled={show.archived == 1}
                    >
                      { show.archived != 1 ? (<Button style={{ width: '100%'}}> <Icon type="upload"/> Subir imagen </Button>) : null }
                    </Upload>
                  )}
                </FormItem>
              </Col>
            </Row>
            <hr/>

            <Row gutter={16}>
              <Col xs={24} sm={24} md={24}>
                <p style={style.pLabel}> Imagen Mapa (Altura: 650 x Ancho: 1280):
                  {show.media_location === null ? (
                    <img
                      src={productWhitoutImage}
                      alt="sin imagen"
                      style={style.imageProductDefault}
                    />
                  ) : (
                    <img
                      src={show.media_location.url}
                      alt="imagen de título"
                      style={style.imageProductDefault}
                    />
                  )}
                </p>
                <FormItem>
                  {getFieldDecorator('media_location_url', {
                    valuePropName: 'fileList',
                    getValueFromEvent: normFile,
                    rules: [
                      {
                        required: false,
                        message: 'Por favor seleccione una imagen'
                      }
                    ]
                  })(
                    <Upload
                      name="media_location_url"
                      beforeUpload={() => false}
                      accept="image/jpeg, image/png"
                      style={{ width: '100%' }}
                      disabled={show.archived == 1}
                    >
                      { show.archived != 1 ? (<Button style={{ width: '100%'}}> <Icon type="upload"/> Subir imagen </Button>) : null }
                    </Upload>
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} sm={24} md={24}>
                <p style={style.pLabel}> Imagen Mapa Mobile (Altura: 460 x Ancho: 300):
                  {show.media_location_mobile === null ? (
                    <img
                      src={productWhitoutImage}
                      alt="sin imagen"
                      style={style.imageProductDefault}
                    />
                  ) : (
                    <img
                      src={show.media_location_mobile.url}
                      alt="imagen de título"
                      style={style.imageProductDefault}
                    />
                  )}
                </p>
                <FormItem>
                  {getFieldDecorator('media_location_mobile_url', {
                    valuePropName: 'fileList',
                    getValueFromEvent: normFile,
                    rules: [
                      {
                        required: false,
                        message: 'Por favor seleccione una imagen'
                      }
                    ]
                  })(
                    <Upload
                      name="media_location_mobile_url"
                      beforeUpload={() => false}
                      accept="image/jpeg, image/png"
                      style={{ width: '100%' }}
                      disabled={show.archived == 1}
                    >
                      { show.archived != 1 ? (<Button style={{ width: '100%'}}> <Icon type="upload"/> Subir imagen </Button>) : null }
                    </Upload>
                  )}
                </FormItem>
              </Col>
            </Row>
            <hr/>

            <Row gutter={16}>
              <Col xs={24} sm={24} md={24}>
                <p style={style.pLabel}> Imagen fondo para video (Altura: 720 x Ancho: 1920):
                  {show.media_bg === null ? (
                    <img
                      src={productWhitoutImage}
                      alt="sin imagen"
                      style={style.imageProductDefault}
                    />
                  ) : (
                    <img
                      src={show.media_bg.url}
                      alt="imagen de título"
                      style={style.imageProductDefault}
                    />
                  )}
                </p>
                <FormItem>
                  {getFieldDecorator('media_bg_url', {
                    valuePropName: 'fileList',
                    getValueFromEvent: normFile,
                    rules: [
                      {
                        required: false,
                        message: 'Por favor seleccione una imagen'
                      }
                    ]
                  })(
                    <Upload
                      name="media_bg_url"
                      beforeUpload={() => false}
                      accept="image/jpeg, image/png"
                      style={{ width: '100%' }}
                      disabled={show.archived == 1}
                    >
                      { show.archived != 1 ? (<Button style={{ width: '100%'}}> <Icon type="upload"/> Subir imagen </Button>) : null }
                    </Upload>
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} sm={24} md={24}>
                <FormItem label="Código de video Youtube">
                  {getFieldDecorator('video_code', {
                    rules: [
                      createValidation(true, 'Por favor ingresa un slug pare el evento', '')
                    ],
                    initialValue: show.media_video ? show.media_video.url_code : ''
                  })(<Input placeholder="Ej. KnuJY-XdLWs" disabled={show.archived == 1}/>)}
                </FormItem>
              </Col>
              <Col xs={24} sm={24} md={24}>
                { show.media_video ? (
                  <FormItem label="Vista previa">
                    <iframe 
                      frameBorder="0"
                      scrolling="no"
                      marginHeight="0" 
                      marginWidth="0"
                      width="100%"
                      height="300"
                      type="text/html"
                      src={'https://www.youtube.com/embed/' + (show.media_video.url_code) + '?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=https://youtubeembedcode.com'}></iframe>
                  </FormItem>
                ) : null }
                
              </Col>
            </Row>
          
          </div>
        
        </Col>

      </Row>
    );
  }
}
