import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Table, Button, Icon, Row, Col, Modal } from 'antd';
import moment from 'moment';

export default connect(
  {
    books: state.app.books,
    remove: sequences.removeBooks
  },
  class BooksList extends Component {
    constructor(props){
      super(props);
      this.state = {
        modal: false
      }

      this.deleteItem = this.deleteItem.bind(this);
    }

    deleteItem(id) {
      const { remove } = this.props;
      Modal.confirm({
        title: 'Confirm',
        content: 'Estas seguro de eliminar este libro',
        okText: 'Aceptar',
        cancelText: 'Cancelar',
        onOk: () => remove({id})
      });
    }

    render() {
      const { books } = this.props;
      const columns = [
        {
          title: 'Libro',
          dataIndex: 'name',
          render: (value, row, index) => (
            <div className="book">
              <figure className="book__image">
                <img src={row.cover[0]} alt="" />
              </figure>
              <section>
                <h4>{row.name.toUpperCase()}</h4>
                <span>
                  Creado: {moment(row.created_at).format('DD-MM-YYYY')}
                </span>
              </section>
            </div>
          )
        },
        {
          title: 'Url',
          dataIndex: 'url',
          render: (value, row, index) => (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={row.pdf}
            >
              Ver el pdf
            </a>
          )
        },
        {
          title: 'Acciones',
          dataIndex: 'actions',
          render: (value, row, index) => (
            <div>
              <Button type="link" onClick={() => this.deleteItem(row.id)}>
                <Icon type="delete" />
              </Button>
            </div>
          )
        }
      ];
      return (
        <div>
          <Row
            gutter={16}
            style={{ paddingBottom: 20 }}
            type="flex"
            justify="space-between"
          >
            <Col span={6}>
              <h3>Lista de Libros</h3>
            </Col>
            <Col span={4}>
              <a
                className="ant-btn ant-btn-primary"
                href="/books/new"
                style={{ marginRight: 5 }}
              >
                <Icon type="plus" />
                Agregar nuevo
              </a>
            </Col>
          </Row>
          <Table columns={columns} dataSource={books} pagination={false}  scroll={{ x: 'max-content' }}/>
          <Modal>

          </Modal>
        </div>
      );
    }
  }
);
