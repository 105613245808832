/* eslint-disable */
import { setLocale } from 'yup';

/**
 * Reference : https://github.com/jquense/yup/blob/master/src/locale.js
 */

export default setLocale({
  mixed: {
    default: 'No valido',
    required: 'Este campo es requerido',
    oneOf: '${path} debe ser uno de los siguientes valores: ${values}',
    notOneOf: '${path} no debe ser uno de los siguientes valores: ${values}',
  },
  string: {
    length: '${path} debe ser exactamente ${length} caracteres',
    min: '${path} debe tener al menos ${min} caracteres',
    max: '${path} debe tener como máximo ${max} caracteres',
    matches: '${path} debe coincidir con lo siguiente: "${regex}"',
    email: '${path} debe ser un correo valido',
    url: '${path} debe ser una URL válida',
    trim: '${path} debe ser una cadena sin espacios',
    lowercase: '${path} debe ser una cadena de minúsculas',
    uppercase: '${path} debe ser una cadena de mayúsculas'
  },
  number: {
    min: '${path} debe ser mayor o igual que ${min}',
    max: '${path} debe ser menor o igual que ${max}',
    lessThan: '${path} debe ser menor que ${less}',
    moreThan: '${path} debe ser mayor que ${more}',
    notEqual: '${path} no debe ser igual a ${notEqual}',
    positive: '${path} debe ser un número positivo',
    negative: '${path} debe ser un número negativo',
    integer: '${path} debe ser un entero'
  }
});
