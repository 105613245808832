import React, { Component } from 'react';
import Moment from 'react-moment';
import {
  Row,
  Col,
  Tooltip,
  Button,
  Table,
  Modal,
  Select,
} from 'antd';

const style = {
  siderbarRight: {
    backgroundColor: '#f1f2f6',
    borderRadius: 5,
    padding: '2rem 1rem',
    height: '97%'
  },
  label: {
    marginBottom: 5
  },
  subtitle: {
    margin: '1em auto 5px',
    display: 'block',
    fontSize: '15px',
  }
};
const { Option } = Select;

export default class TicketsPendingsRefundComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment_event: null,
      ticket_selected: null,
      view: false,
    };

    this.setByView      = this.setByView.bind(this);
    this.setTicketTypeRefundSelected = this.setTicketTypeRefundSelected.bind(this);
    this.setMoneyFormat = this.setMoneyFormat.bind(this);
    this.handleExcel    = this.handleExcel.bind(this);
    this.getTicketTypesEnableds = this.getTicketTypesEnableds.bind(this);
    this.getTotalRefunds = this.getTotalRefunds.bind(this);
  }

  setByView(data) {
    this.setState({
      payment_event: data,
      view: true,
    });
  }

  setTicketTypeRefundSelected(data_id, option) {
    this.setState({
      ticket_selected: option.props["data-value"],
    });
  }

  setMoneyFormat(value) {
    const options1 = { style: 'currency', currency: 'MXN' };
    const moneyFormat = new Intl.NumberFormat('es-MX', options1);
    return moneyFormat.format(value);
  }

  getTotalRefunds(paymnt_total, ticket_amount, quantity) {
    return paymnt_total - ( ticket_amount * quantity );
  }

  getTicketTypesEnableds(ticket, quantity, only_name = true) {
    const { show } = this.props;
    return show.tickets.map(function(item) {
        if( item.quantity_pending > 0 && item.quantity_pending >= quantity && parseFloat(item.price) < parseFloat(ticket.amount) ) {
          return only_name ? item.type : item;
        }
      }).filter(item => !!item )

  }

  handleExcel(e) {
    e.preventDefault();
    if (!this.props.state_reports.export_in_process) {
      const { show, submitExcelRefund } = this.props;
      let values = {
        event_id: show.id,
        slug: show.slug
      };
      submitExcelRefund({values});
    }
  }

  render() {
    const {
      disabledTableTicketsSolds,
      handleShowModalTicketRefund,
      handleCloseModalTicketRefund,
      handleShowModalTicketRefundType,
      handleCloseModalTicketRefundType,
      payRefundTicket,
      handleCloseModalSoldTicketRefund,
      handleShowModalSoldTicketRefund,
      refundTicket,
      show,
      state_reports,
      types,
      refund,
      refund_confirm,
      sold_refund,
    } = this.props;

    const {
      view,
      payment_event,
      ticket_selected,
    } = this.state;

    const columns = [
      {
        title: 'ACCIONES',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        render: (text, element, index) => (
          <div>
            <Tooltip title="Ver detalle de venta" placement="top">
              <Button
                type="primary"
                icon="eye"
                style={{ marginLeft: 10, display: (show.archived != 1 ? 'inline-block' : 'none') }}
                onClick={() => {
                  this.setByView(element);
                }}
              />
            </Tooltip>
          </div>
        )
      },
      {
        title: 'Cliente',
        dataIndex: 'full_name',
        key: 'ticket_refund_full_name',
        width: 300,
        render: (text, element, index) => (
          <span>{text}</span>
        )
      },
      {
        title: 'Estatus',
        dataIndex: 'payment_status_label',
        key: 'ticket_refund_payment_status_label',
        width: 250,
        render: (text, element, index) => (
          <span style={{color: element == 'pending_refund' ? '#fd0000' : '#EB0089' }}>{text}</span>
        )
      },
      {
        title: 'Boletos',
        dataIndex: 'details_count',
        key: 'ticket_refund_details_count',
        width: 250,
        render: (text, element, index) => (
          <span>{text} {(parseInt(text) > 1 ? 'boletos' : 'boleto' )} { element.details.length > 0 ? ('tipo "' + element.details[0].type + '"') : ''}</span>
        )
      },
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'ticket_refund_total',
        width: 150,
        render: (text, element, index) => (
          <span>{ this.setMoneyFormat(text) }</span>
        )
      },
      {
        title: 'Fecha de compra',
        dataIndex: 'payment_date',
        key: 'payment_date',
        width: 300,
        render: (text, element, index) => (
          <Moment format="DD-MM-YYYY hh:mm A">{element.payment_date}</Moment>
        )
      },
    ];

    return (
      <Row gutter={16} type="flex">
        <Col xs={12} sm={12} md={12}>
          <Button
            type="excel"
            style={{ display: 'block' }}
            onClick={this.handleExcel}
              disabled={state_reports.export_in_process}
            >
            {false ? 'DESCARGANDO' : 'EXPORTAR A EXCEL'}
          </Button>
        </Col>
        <Col xs={24} sm={17} md={17}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24}>
              <Table
                rowKey="pending-refund-table"
                style={{ marginTop: 20, marginBottom: 10 }}
                columns={columns}
                dataSource={show.payments_with_refunds}
                bordered
                pagination={false}
                loading={disabledTableTicketsSolds}
                scroll={{ x: 'max-content' }}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={7} md={7}>
          <div style={style.siderbarRight}>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24}>
                <p style={{ textAlign: 'center' }}>
                  <h4>DETALLE DE VENTA</h4>
                </p>
              </Col>
              { view === true ? (

                <div>
                  <Col xs={24} sm={24} md={24}>
                    <strong style={style.subtitle}>DATOS DEL CLIENTE</strong>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <b style={style.label}>Nombre</b>
                    <p style={style.label}>{payment_event.full_name}</p>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <b style={style.label}>Contacto</b>
                    <p style={style.label}>Correo: {payment_event.email}</p>
                    <p style={style.label}>Teléfono: {payment_event.phone}</p>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <strong style={style.subtitle}>DATOS DEL BOLETO</strong>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <b style={style.label}>Boletos tipo {payment_event.details.length > 0 ? payment_event.details[0].type : ''}</b>
                    <p style={style.label}>Cantidad: {payment_event.quantity}</p>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <b style={style.label}>Códigos de boletos</b>
                    {payment_event.details.map(detail => (
                      <p style={style.label}>.- Boleto {payment_event.details.length > 0 ? payment_event.details[0].type : ''}: {detail.uuid} {detail.spot ? (<span><b>Asiento:</b> {detail.spot.title}</span> ) : null}</p>
                    ))}
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <strong style={style.subtitle}>DETALLE DE COMPRA</strong>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <p style={style.label}>Evento: <b>{show.title}</b></p>
                    <p style={style.label}>Método de pago: {payment_event.order_payment ? payment_event.order_payment.name : ''}</p>
                    <p style={style.label}>últimos dígitos de tarjeta: {payment_event.card_last4}</p>
                    { ['pending_refund_ticket_change', 'refund', 'pending_refund'].indexOf(payment_event.payment_status) >= 0 ? (<p style={{color: (payment_event.pending_refund > 0 ? '#deb300' : ''), 'marginBottom': '5px'}}>Pendiente de rembolso: {this.setMoneyFormat(payment_event.pending_refund)}</p>) : null }
                    <a href={payment_event.transaction_url} style={style.label} target="_blank">Ver detalle de transacción</a>
                  </Col>
                  <Col xs={24} sm={24} md={24} style={{ marginBottom: 10 }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      { payment_event.payment_status != 'refund' ? 
                      (
                        <Tooltip title="Marcar como rembolsado" placement="top">
                          <Button
                            icon="transaction"
                            type="primary"
                            htmlType="submit"
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                                handleShowModalSoldTicketRefund(payment_event);
                            }}
                          />
                        </Tooltip>
                      ) : null}
                      {payment_event.payment_status == 'pending_refund' && this.getTicketTypesEnableds(payment_event.details[0], payment_event.quantity).length > 0 ? (
                          <Tooltip title="Actualizar boleto" placement="top">
                            <Button
                              icon="file-protect"
                              type="primary"
                              htmlType="submit"
                              style={{ marginLeft: 10 }}
                              onClick={() => {
                                handleShowModalTicketRefund(payment_event);
                              }}
                            />
                          </Tooltip>
                        ) : null
                      }
                      <Tooltip title="Cancelar" placement="top">
                        <Button
                          icon="close-circle"
                          type="primary"
                          htmlType="submit"
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            this.setState({
                              payment_event: null,
                              view: false,
                            });
                          }}
                        />
                      </Tooltip>
                    </div>
                  </Col>
                </div>
              ) : (
                <Col xs={24} sm={24} md={24}>
                  <p>Es necesario seleccionar un boleto</p>
                </Col>
              )}
            </Row>
          </div>
        </Col>

        <Modal
          closable={false}
          visible={sold_refund.open}
          onCancel={() => {
            handleCloseModalSoldTicketRefund();
          }}
          footer={[
            <Button
              type="primary"
              onClick={() => {
                payRefundTicket();
              }}
              loading={sold_refund.loader} >
              Confirmar
            </Button>,
            <Button
              onClick={() => {
                handleCloseModalSoldTicketRefund();
              }}
            >
              Cancelar
            </Button>
          ]} >
          <h4>¿Actualizar la orden de compra como rembolsada?</h4>
          <p>
            Favor de verificar que la orden de compra seleccionada sea la que
            realmente desea actualizar.{' '}
          </p>
        </Modal>

        <Modal
          closable={false}
          visible={refund.open}
          onCancel={() => {
            handleCloseModalTicketRefund();
          }}
          footer={[
            <Button
              type="primary"
              onClick={() => {
                handleShowModalTicketRefundType();
              }}
              loading={refund.loader} >
              Confirmar
            </Button>,
            <Button
              onClick={() => {
                handleCloseModalTicketRefund();
              }}
            >
              Cancelar
            </Button>
          ]} >
          <h4>¿Actualizar la orden de compra para el cliente '{refund.name}' ?</h4>
          <p>
            Favor de verificar que la orden de compra seleccionada sea la que
            realmente desea actualizar.{' '}
            <span style={{ color: 'orange' }}>
              Solo se pueden actualizar ordenes de compra con precios de boleto superiores a los boletos disponibles. Asi mismo, las ordenes de compra deben estar pendientes de rembolso.
            </span>
          </p>
        </Modal>

        <Modal
          closable={false}
          visible={refund_confirm.open}
          onCancel={() => {
            handleCloseModalTicketRefundType();
          }}
          footer={[
            <Button
              type="primary"
              onClick={() => {
                refundTicket({event_id: show.id, payment_event_id: payment_event.id, event_ticket_id: (ticket_selected ? ticket_selected.id : null) });
              }}
              loading={refund.loader} >
              Actualizar
            </Button>,
            <Button
              onClick={() => {
                handleCloseModalTicketRefundType();
              }}
            >
              Cancelar
            </Button>
          ]} >
          <h4>Selecciona el boleto a asignar</h4>
          { refund.ticket ? (
            <span>
              <Select
                placeholder="Seleccionar tipo de boleto"
                style={{ width: '100%' }}
                onSelect={this.setTicketTypeRefundSelected}  >
                { this.getTicketTypesEnableds(refund.ticket, payment_event.quantity, false).map(ticket => (
                  <Select.Option key={'refund-ticket-' + ticket.id} value={ticket.id} data-value={ticket}>{ticket.type} - {this.setMoneyFormat(ticket.price)}</Select.Option>
                ))}
              </Select>
              <span style={{ 'display' : 'block', 'marginTop': '1em'}}> Total a rembolsar: { ticket_selected ?
                this.setMoneyFormat( this.getTotalRefunds(payment_event.total, ticket_selected.price ? ticket_selected.price : 0, payment_event.quantity) )
                : '$ 0.00'}
              </span>
          </span>
          ) : null }
        </Modal>

      </Row>
    );
  }
}
