import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import {
  Row,
  Col,
  Card,
  Tooltip,
  Button,
  Table,
  Divider,
  Tag,
  Icon,
  Modal,
  Collapse
  // Rate
} from 'antd';
import Moment from 'react-moment';
import classNames from 'classnames/bind';
import { formatNumberAmount } from '@utils';
import FormStatus from './FormStatus/FormStatus';
import styles from './styles.css';

const cx = classNames.bind(styles);
const { Panel } = Collapse;

export default connect(
  {
    // states
    detail: state.orders.detail,
    statusList: state.orders.status,
    loadingLogin: state.user.loginButtonDisable,
    // sequences
    updatedStatusOrder: sequences.updatedStatusOrder
  },
  class OrderDetail extends Component {
    constructor(props) {
      super(props);
      this.state = {
        visibleModal: false,
        DataModal: null
      };
    }

    // eslint-disable-next-line class-methods-use-this
    truncString(str, max, add) {
      const add_ = add || '...';
      return typeof str === 'string' && str.length > max
        ? str.substring(0, max) + add_
        : str;
    }

    render() {
      const {
        detail,
        statusList,
        updatedStatusOrder,
        loadingLogin
      } = this.props;
      const { visibleModal, DataModal } = this.state;
      const columns = [
        {
          title: 'PRODUCTO',
          dataIndex: 'product_name',
          key: 'product_name',
          render: (text, element, index) => (
            <span>
              {text} | Código: {detail.order_detail[index].product_reference}
              {detail.order_detail[index].is_gift === 1 ||
              detail.order_detail[index].is_gift === true ? (
                <strong style={{ color: '#eb0989' }}>
                  <br />
                  De: {detail.order_detail[index].gift_from}
                  <br />
                  Mensaje: {detail.order_detail[index].gift_message}
                </strong>
              ) : null}
            </span>
          )
        },
        {
          title: 'CANTIDAD',
          dataIndex: 'product_quantity',
          key: 'product_quantity',
          render: (text, element, index) => (
            <span style={{ fontSize: '2rem' }}>{text}</span>
          )
        },
        {
          title: 'PRECIO UNITARIO',
          dataIndex: 'product_price',
          key: 'product_price',
          render: (text, element, index) => (
            <span>${formatNumberAmount(text, '.', '.', ',')}</span>
          )
        },
        {
          title: 'Descuentos',
          dataIndex: 'product_quantity_discount',
          key: 'product_quantity_discount',
          render: (text, element, index) => (
            <span>${formatNumberAmount(text, '.', '.', ',')}</span>
          )
        },
        {
          title: 'TOTAL',
          dataIndex: 'id',
          key: 'id',
          render: (text, element, index) => (
            <span>
              $
              {formatNumberAmount(
                detail.order_detail[index].product_total_paid,
                '.',
                '.',
                ','
              )}
            </span>
          )
        }
      ];
      const columnsComment = [
        {
          title: 'ESTATUS',
          dataIndex: 'order_status.name',
          key: 'order_status.name'
        },
        {
          title: 'COMENTARIO',
          dataIndex: 'comment',
          key: 'comment'
        },
        {
          title: 'FECHA CREACIÓN',
          dataIndex: 'created_at',
          key: 'created_at',
          render: text => <Moment format="DD-MM-YYYY hh:mm A">{text}</Moment>
        }
      ];
      return (
        <div>
          <div className="move-content-right">
            <Tooltip title="Atras" placement="left">
              <a
                style={{ marginLeft: 10 }}
                href="/ordenes"
                className="ant-btn ant-btn-primary ant-btn-icon-only"
                rel="noopener noreferrer"
              >
                <Icon type="arrow-left" />
              </a>
            </Tooltip>
          </div>
          <Row gutter={16} className={cx('mb-20')} type="flex">
            <Col xs={24} sm={8} md={8}>
              <Card title="Detalle de orden" className={cx('h-100')}>
                <Row
                  gutter={16}
                  className={cx('mb-10')}
                  type="flex"
                  align="middle"
                >
                  <Col>
                    <Tooltip title="Fecha creación">
                      <Button type="primary" icon="calendar" />
                    </Tooltip>
                  </Col>
                  <Col>
                    <Moment format="DD/MM/YYYY hh:mm A">
                      {detail.created_at}
                    </Moment>
                  </Col>
                </Row>
                <Row
                  gutter={16}
                  className={cx('mb-10')}
                  type="flex"
                  align="middle"
                >
                  <Col>
                    <Tooltip title="Método de pago">
                      <Button type="primary" icon="dollar" />
                    </Tooltip>
                  </Col>
                  <Col style={{ color: '#f7289a' }}>
                    {detail.order_payment_method.name}
                  </Col>
                </Row>
                <Row
                  gutter={16}
                  className={cx('mb-10')}
                  type="flex"
                  align="middle"
                >
                  <Col>
                    <Tooltip title="Estatus">
                      <Button type="primary" icon="info-circle" />
                    </Tooltip>
                  </Col>
                  <Col>{detail.order_status.name}</Col>
                </Row>
                <Row
                  gutter={16}
                  className={cx('mb-10')}
                  type="flex"
                  align="middle"
                >
                  <Col>
                    <Tooltip title="Número de orden">
                      <Button type="primary" icon="number" />
                    </Tooltip>
                  </Col>
                  <Col>{detail.shipping_number}</Col>
                </Row>
                {detail.order_payment_method_id === 1 &&
                detail.installments !== '' &&
                detail.installments !== null ? (
                  <Row
                    gutter={16}
                    className={cx('mb-10')}
                    type="flex"
                    align="middle"
                  >
                    <Col>
                      <Tooltip title="Meses sin intereses">
                        <Button type="primary" icon="credit-card" />
                      </Tooltip>
                    </Col>
                    <Col>{detail.installments}</Col>
                  </Row>
                ) : null}

                <Row
                  gutter={16}
                  className={cx('mb-10')}
                  type="flex"
                  align="middle"
                >
                  <Col>
                    <Tooltip title="Proveedor de envío">
                      <Button type="primary" icon="reconciliation" />
                    </Tooltip>
                  </Col>
                  <Col>
                    {detail.shipment_career === null ||
                    detail.shipment_career === ''
                      ? 'Pendiente'
                      : detail.shipment_career}
                  </Col>
                </Row>
                <Row
                  gutter={16}
                  className={cx('mb-10')}
                  type="flex"
                  align="middle"
                >
                  <Col>
                    <Tooltip title="Número de rastreo">
                      <Button type="primary" icon="environment" />
                    </Tooltip>
                  </Col>
                  <Col>
                    {detail.delivery_number === null ||
                    detail.delivery_number === ''
                      ? 'Pendiente'
                      : ( <a href={detail.tracking_url} target="_blank">{detail.delivery_number}</a>) }
                  </Col>
                </Row>
                <Row
                  gutter={16}
                  className={cx('mb-10')}
                  type="flex"
                  align="middle"
                >
                  <Col>
                    <Tooltip title="Folio odoo">
                      <Button type="primary" icon="number" />
                    </Tooltip>
                  </Col>
                  <Col>
                    {detail.folio_odoo === null || detail.folio_odoo === ''
                      ? 'No disponible'
                      : detail.folio_odoo}
                  </Col>
                </Row>

                {detail.gift_amount > 0 ? (
                  <Row
                    gutter={16}
                    className={cx('mb-10')}
                    type="flex"
                    align="middle"
                  >
                    <Col>
                      <Tooltip title="Esta orden contiene productos de regalo">
                        <Button type="primary" icon="gift" />
                      </Tooltip>
                    </Col>
                    <Col>Esta orden contiene productos de regalo</Col>
                  </Row>
                ) : null}
              </Card>
            </Col>
            <Col xs={24} sm={8} md={8}>
              <Card title="Detalle de cliente" className={cx('h-100')}>
                <Row
                  gutter={16}
                  className={cx('mb-10')}
                  type="flex"
                  align="middle"
                >
                  <Col>
                    <Tooltip title="Nombre completo">
                      <Button type="primary" icon="smile" />
                    </Tooltip>
                  </Col>
                  <Col>
                    {detail.first_name} {detail.last_name}
                  </Col>
                </Row>
                <Row
                  gutter={16}
                  className={cx('mb-10')}
                  type="flex"
                  align="middle"
                >
                  <Col>
                    <Tooltip title="Télefono">
                      <Button type="primary" icon="phone" />
                    </Tooltip>
                  </Col>
                  <Col>{detail.phone}</Col>
                </Row>
                <Row
                  gutter={16}
                  className={cx('mb-10')}
                  type="flex"
                  align="middle"
                >
                  <Col>
                    <Tooltip title="Correo electrónico">
                      <Button type="primary" icon="mail" />
                    </Tooltip>
                  </Col>
                  <Col> {detail.email}</Col>
                </Row>
                <Row
                  gutter={16}
                  className={cx('mb-10')}
                  type="flex"
                  align="middle"
                >
                  <Col>
                    <Tooltip title="Tipo de usuario">
                      <Button type="primary" icon="solution" />
                    </Tooltip>
                  </Col>
                  <Col>
                    {detail.type === 'CLIENT'
                      ? 'Cliente registrado'
                      : 'Cliente sin cuenta'}
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} sm={8} md={8}>
              {detail.order_delivery_address == null ? (
                <Card title="Dirección de entrega" className={cx('h-100')}>
                  <Row
                    gutter={16}
                    className={cx('mb-10')}
                    type="flex"
                    align="middle"
                  >
                    <Col>
                      <Tooltip title="Dirección">
                        <Button type="primary" icon="home" />
                      </Tooltip>
                    </Col>
                    <Col>
                      {detail.order_address1} {detail.order_city}{' '}
                      {detail.order_state} {detail.order_country}. Referencia:{' '}
                      {detail.order_address2 === null
                        ? 'N/A'
                        : detail.order_address2}
                    </Col>
                  </Row>
                  <Row
                    gutter={16}
                    className={cx('mb-10')}
                    type="flex"
                    align="middle"
                  >
                    <Col>
                      <Tooltip title="Código postal">
                        <Button type="primary" icon="tag" />
                      </Tooltip>
                    </Col>
                    <Col>{detail.order_post_code}</Col>
                  </Row>
                  <Row
                    gutter={16}
                    className={cx('mb-10')}
                    type="flex"
                    align="middle"
                  >
                    <Col>
                      <Tooltip title="Teléfono">
                        <Button type="primary" icon="phone" />
                      </Tooltip>
                    </Col>
                    <Col>
                      Celular: {detail.order_phone_mobile}
                      {detail.order_phone === null ? null : (
                        <span>
                          <br />
                          Casa:{detail.order_phone}
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row
                    gutter={16}
                    className={cx('mb-10')}
                    type="flex"
                    align="middle"
                  >
                    <Col>
                      <Tooltip title="Persona que recibira">
                        <Button type="primary" icon="user" />
                      </Tooltip>
                    </Col>
                    <Col>
                      {detail.order_first_name} {detail.order_last_name}
                    </Col>
                  </Row>
                </Card>
              ) : (
                <Card title="Dirección de entrega" className={cx('h-100')}>
                  <Row
                    gutter={16}
                    className={cx('mb-10')}
                    type="flex"
                    align="middle"
                  >
                    <Col>
                      <Tooltip title="Dirección">
                        <Button type="primary" icon="home" />
                      </Tooltip>
                    </Col>
                    <Col>
                      <strong>Calle:</strong>{' '}
                      {detail.order_delivery_address.street}{' '}
                      <strong>Núm Ext:</strong>{' '}
                      {detail.order_delivery_address.num_ext}{' '}
                      <strong>Núm Int:</strong>{' '}
                      {detail.order_delivery_address.num_int}.{' '}
                      {detail.order_delivery_address.colony} ,{' '}
                      {detail.order_delivery_address.municipality_or_city} ,{' '}
                      {detail.order_delivery_address.state}.{' '}
                      <strong>Referencia:</strong>{' '}
                      {detail.order_delivery_address.reference == null
                        ? 'N/A'
                        : detail.order_delivery_address.reference}
                    </Col>
                  </Row>
                  <Row
                    gutter={16}
                    className={cx('mb-10')}
                    type="flex"
                    align="middle"
                  >
                    <Col>
                      <Tooltip title="Código postal">
                        <Button type="primary" icon="tag" />
                      </Tooltip>
                    </Col>
                    <Col>{detail.order_delivery_address.post_code}</Col>
                  </Row>
                  <Row
                    gutter={16}
                    className={cx('mb-10')}
                    type="flex"
                    align="middle"
                  >
                    <Col>
                      <Tooltip title="Teléfono">
                        <Button type="primary" icon="phone" />
                      </Tooltip>
                    </Col>
                    <Col>
                      Celular: {detail.order_delivery_address.phone_mobile}
                      {detail.order_delivery_address.phone === null ? null : (
                        <span>
                          <br />
                          Casa: {detail.order_delivery_address.phone}
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row
                    gutter={16}
                    className={cx('mb-10')}
                    type="flex"
                    align="middle"
                  >
                    <Col>
                      <Tooltip title="Persona que recibira">
                        <Button type="primary" icon="user" />
                      </Tooltip>
                    </Col>
                    <Col>
                      {detail.order_delivery_address.first_name}{' '}
                      {detail.order_delivery_address.last_name}
                    </Col>
                  </Row>
                </Card>
              )}
            </Col>
          </Row>
          <Row gutter={16} style={{ marginBottom: 20 }}>
            {detail.data_invoice != null ? (
              <Col span={24} style={{ marginTop: 10 }}>
                <Card title="Datos para facturación" className={cx('h-100')}>
                  <Row gutter={16} className={cx('mb-10')}>
                    <Col xs={24} sm={12} md={12}>
                      <Row
                        gutter={16}
                        className={cx('mb-10')}
                        type="flex"
                        align="middle"
                      >
                        <Col>
                          <Tooltip title="Nombre completo">
                            <Button type="primary" icon="user" />
                          </Tooltip>
                        </Col>
                        <Col>{detail.data_invoice.full_name}</Col>
                      </Row>
                      <Row
                        gutter={16}
                        className={cx('mb-10')}
                        type="flex"
                        align="middle"
                      >
                        <Col>
                          <Tooltip title="RFC">
                            <Button type="primary" icon="info-circle" />
                          </Tooltip>
                        </Col>
                        <Col>{detail.data_invoice.rfc}</Col>
                      </Row>
                      <Row
                        gutter={16}
                        className={cx('mb-10')}
                        type="flex"
                        align="middle"
                      >
                        <Col>
                          <Tooltip title="Razón social">
                            <Button type="primary" icon="idcard" />
                          </Tooltip>
                        </Col>
                        <Col>{detail.data_invoice.entity_type}</Col>
                      </Row>
                      <Row
                        gutter={16}
                        className={cx('mb-10')}
                        type="flex"
                        align="middle"
                      >
                        <Col>
                          <Tooltip title="Uso CFDI">
                            <Button type="primary" icon="profile" />
                          </Tooltip>
                        </Col>
                        <Col>{detail.data_invoice.use_cfdi}</Col>
                      </Row>
                      <Row
                        gutter={16}
                        className={cx('mb-10')}
                        type="flex"
                        align="middle"
                      >
                        <Col>
                          <Tooltip title="Calle">
                            <Button type="primary" icon="home" />
                          </Tooltip>
                        </Col>
                        <Col>{detail.data_invoice.street} </Col>
                      </Row>
                      <Row
                        gutter={16}
                        className={cx('mb-10')}
                        type="flex"
                        align="middle"
                      >
                        <Col>
                          <Tooltip title="Número exterior / interior">
                            <Button type="primary" icon="number" />
                          </Tooltip>
                        </Col>
                        <Col>
                          {detail.data_invoice.num_ext} /{' '}
                          {detail.data_invoice.num_int}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} sm={12} md={12}>
                      <Row
                        gutter={16}
                        className={cx('mb-10')}
                        type="flex"
                        align="middle"
                      >
                        <Col>
                          <Tooltip title="Colonia">
                            <Button type="primary" icon="info-circle" />
                          </Tooltip>
                        </Col>
                        <Col>{detail.data_invoice.colony}</Col>
                      </Row>
                      <Row
                        gutter={16}
                        className={cx('mb-10')}
                        type="flex"
                        align="middle"
                      >
                        <Col>
                          <Tooltip title="Ciudad">
                            <Button type="primary" icon="info-circle" />
                          </Tooltip>
                        </Col>
                        <Col>{detail.data_invoice.city}</Col>
                      </Row>
                      <Row
                        gutter={16}
                        className={cx('mb-10')}
                        type="flex"
                        align="middle"
                      >
                        <Col>
                          <Tooltip title="Estado">
                            <Button type="primary" icon="info-circle" />
                          </Tooltip>
                        </Col>
                        <Col>{detail.data_invoice.state}</Col>
                      </Row>
                      <Row
                        gutter={16}
                        className={cx('mb-10')}
                        type="flex"
                        align="middle"
                      >
                        <Col>
                          <Tooltip title="País">
                            <Button type="primary" icon="info-circle" />
                          </Tooltip>
                        </Col>
                        <Col>{detail.data_invoice.country}</Col>
                      </Row>
                      <Row
                        gutter={16}
                        className={cx('mb-10')}
                        type="flex"
                        align="middle"
                      >
                        <Col>
                          <Tooltip title="Código postal">
                            <Button type="primary" icon="tag" />
                          </Tooltip>
                        </Col>
                        <Col>{detail.data_invoice.post_code}</Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ) : null}

            <Col span={24}>
              <Card
                title={`Orden (#${detail.shipping_number})`}
                className={cx('h-100')}
              >
                <Table
                  columns={columns}
                  dataSource={detail.order_detail}
                  bordered
                  pagination={false}
                  scroll={{ x: 'max-content' }}
                  footer={() => (
                    <div className={cx('text-right')}>
                      <p className={cx('mb-0')}>
                        <strong>Sub total: </strong>{' '}
                        <span>
                          $
                          {formatNumberAmount(
                            detail.total_without_tax,
                            '.',
                            '.',
                            ','
                          )}
                        </span>
                      </p>
                      <p className={cx('mb-0')}>
                        <strong>IVA: </strong>{' '}
                        <span>
                          ${formatNumberAmount(detail.tax, '.', '.', ',')}
                        </span>
                      </p>
                      <p className={cx('mb-20')}>
                        <strong>
                          Descuentos<span style={{ color: 'red' }}>*</span>:
                        </strong>{' '}
                        <span>
                          $
                          {formatNumberAmount(
                            detail.total_discounts,
                            '.',
                            '.',
                            ','
                          )}
                        </span>
                      </p>
                      <p className={cx('mb-0')}>
                        <strong>Envio:</strong>{' '}
                        <span>
                          $
                          {formatNumberAmount(
                            detail.total_shipping_amount,
                            '.',
                            '.',
                            ','
                          )}
                        </span>
                      </p>
                      {detail.gift_amount > 0 && (
                        <p className={cx('mb-0')}>
                          <strong>Envoltorio de regalo:</strong>{' '}
                          <span>
                            $
                            {formatNumberAmount(
                              detail.gift_amount,
                              '.',
                              '.',
                              ','
                            )}
                          </span>
                        </p>
                      )}

                      <p className={cx('mb-0')}>
                        <strong>Total: </strong>{' '}
                        <span>
                          $
                          {formatNumberAmount(
                            detail.total_paid_amount,
                            '.',
                            '.',
                            ','
                          )}
                        </span>
                      </p>
                      <p style={{ color: 'red', marginTop: 20 }}>
                        Incluye descuentos de productos y de cupones.
                      </p>
                    </div>
                  )}
                />
                {detail.order_coupon != null ? (
                  <Tag className={cx('discount-applied-order')}>
                    <strong style={{ color: 'red' }}>Cupón aplicado:</strong>
                    <p>
                      {detail.order_coupon.name} (Código:{' '}
                      {detail.order_coupon.code}) .{' '}
                      {detail.order_coupon.type_value === 'PERCENT' ? '' : '$'}{' '}
                      {detail.order_coupon.value}{' '}
                      {detail.order_coupon.type_value === 'PERCENT' ? '%' : ''}{' '}
                      ($
                      {formatNumberAmount(
                        detail.order_coupon.amount,
                        '.',
                        '.',
                        ','
                      )}
                      )
                    </p>
                  </Tag>
                ) : null}
              </Card>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginBottom: 20 }}>
            <Col span={24}>
              <Card
                title="Estatus e historial de orden"
                className={cx('h-100')}
              >
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24}>
                    <Table
                      columns={columnsComment}
                      dataSource={detail.order_comment}
                      bordered
                      pagination={false}
                      scroll={{ x: 'max-content' }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    {detail.payments_orders !== null ? (
                      <Card
                        title="Información para pago de orden"
                        style={{ marginTop: 10 }}
                      >
                        {detail.payments_orders.order_type === 'spei' ? (
                          <p>
                            <strong>Clabe de pago SPEI: </strong>{' '}
                            {detail.payments_orders.reference_key}
                          </p>
                        ) : (
                          <p>
                            <strong>Referencia de pago OXXO: </strong>{' '}
                            {detail.payments_orders.reference_key}
                          </p>
                        )}
                      </Card>
                    ) : (
                      <div>
                        {detail.charges != null ? (
                          <Card
                            title="Información de pago"
                            style={{ marginTop: 10 }}
                          >
                            <p>
                              <strong># Autorización: </strong>
                              {detail.charges.payment_id}
                            </p>
                            {detail.charges.last4 !== 'n/a' &&
                              detail.charges.last4 !== 'N/A' && (
                                <div>
                                  <p>
                                    <strong>Tarjeta: </strong> XXXX-XXXX-XXXX-
                                    {detail.charges.last4}
                                  </p>
                                  <p>
                                    <strong>Marca: </strong>
                                    {detail.charges.payment_brand.toUpperCase()}
                                  </p>
                                </div>
                              )}
                          </Card>
                        ) : (
                          <Card
                            title="Información para pago de orden"
                            style={{ marginTop: 10 }}
                          >
                            <p>
                              Orden expirada (Paso la fecha limite para su
                              pago).
                            </p>
                          </Card>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
                <Divider />
                <FormStatus
                  status={statusList}
                  select={detail.order_status_id}
                  orderUuid={detail.uuid}
                  updatedStatusOrder={updatedStatusOrder}
                  loadingLogin={loadingLogin}
                  deliveryNumber={detail.delivery_number}
                  paymentMethod={detail.order_payment_method}
                  refNumber={detail.charges ? detail.charges.payment_id : '' }
                  trackingNumber={detail.tracking_number}
                />
              </Card>
            </Col>
            {detail.logs_odoo.length > 0 && (
              <Col xs={24} sm={24} md={24}>
                <Collapse accordion style={{ marginTop: 10 }}>
                  <Panel header="Logs Odoo" key="1">
                    <Table
                      columns={[
                        {
                          title: 'Error',
                          dataIndex: 'error',
                          key: 'error',
                          render: text => (
                            <span> {this.truncString(text, 50, '...')}</span>
                          )
                        },
                        {
                          title: 'Tipo',
                          dataIndex: 'type',
                          key: 'type'
                        },
                        {
                          title: 'Información enviada',
                          dataIndex: 'data',
                          key: 'data',
                          render: text => (
                            <span> {this.truncString(text, 50, '...')}</span>
                          )
                        },
                        {
                          title: 'FECHA CREACIÓN',
                          dataIndex: 'created_at',
                          key: 'created_at',
                          render: text => (
                            <Moment format="DD-MM-YYYY hh:mm A">{text}</Moment>
                          )
                        },
                        {
                          title: 'ACCIONES',
                          dataIndex: 'id',
                          key: 'id',
                          render: (text, element, index) => (
                            <Tooltip
                              title="Ver información completa"
                              placement="top"
                            >
                              <Button
                                type="primary"
                                icon="eye"
                                onClick={() => {
                                  this.setState({
                                    DataModal: detail.logs_odoo[index],
                                    visibleModal: true
                                  });
                                }}
                              />
                            </Tooltip>
                          )
                        }
                      ]}
                      dataSource={detail.logs_odoo}
                      bordered
                      pagination={false}
                      scroll={{ x: 'max-content' }}
                    />
                  </Panel>
                </Collapse>
                <Modal
                  title="Detalle log"
                  visible={visibleModal}
                  footer={null}
                  onCancel={() => {
                    this.setState({ visibleModal: false, DataModal: null });
                  }}
                >
                  {DataModal != null && (
                    <div>
                      <p>
                        <strong>Error:</strong>
                      </p>
                      <p>{DataModal.error}</p>
                      <p>
                        <strong>Información enviada:</strong>
                      </p>
                      <p>{DataModal.data}</p>
                    </div>
                  )}
                </Modal>
              </Col>
            )}
          </Row>
        </div>
      );
    }
  }
);
