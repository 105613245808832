import { set } from 'cerebral/factories'; // wait
import { state, props } from 'cerebral';
import { WithRouter, setPagination } from '@modules/actions';
import notify from '@utils/notify';
import page from 'page';
import * as actions from './actions';
// START RENDER VIEWS
const OrderListRouter = WithRouter([
  set(state.orders.search, {
    loading: false,
    searchByShippingNumberOrder: '',
    searchByNameOrder: '',
    sortOrder: 'DESC',
    searchByStatusOrder: '',
    searchByTotalOrder: '',
    searchByInitDateStart: '',
    searchByInitDateEnd: ''
  }),
  set(state.app.currentPage, 'OrderListView'),
  actions.getStatus,
  {
    success: [actions.setStatus],
    error: [notify(props.message.error, 'error')]
  },
  actions.getOrders,
  {
    success: [setPagination, actions.setOrders],
    error: [notify(props.message.error, 'error')]
  }
]);
const OrderDetailRouter = WithRouter([
  set(state.app.currentPage, 'OrderDetail'),
  actions.getStatus,
  {
    success: [actions.setStatus],
    error: [notify(props.message.error, 'error')]
  },
  actions.getOrderDetail,
  {
    success: [actions.setOrderDetail],
    error: [notify(props.message.error, 'error')]
  }
]);
// END RENDER VIEWS

// START SEARCHES
const updatedFilterGeneral = [
  ({ store }) => {
    store.set(state.orders.search.loading, true);
  },
  ({ props, store }) => {
    const { value, key } = props;
    store.set(state.orders.search[key], value);
  },
  actions.getOrders,
  {
    success: [
      setPagination,
      actions.setOrders,
      ({ store }) => {
        store.set(state.orders.search.loading, false);
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];
//  END SEARCHES

// EVENTS
const updatedStatusOrder = [
  ({ props, store, http, path }) => {
    store.set(state.user.loginButtonDisable, true);
    return http
      .post(`admin/orders-status`, props.values)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Orden actualizada', 'success'),
      () => {
        page.show(window.location.pathname);
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ props, store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];
// ACTIONS
export default {
  routes: {
    list: OrderListRouter,
    detail: OrderDetailRouter
  },
  sequences: {
    updatedFilterGeneral,
    updatedStatusOrder
  }
};
