export default function(target) {
  function toggle(context) {
    if (!context.resolve.isTag(target, 'state', 'moduleState')) {
      throw new Error(
        'Cerebral factory.toggle: You have to use the STATE or MODULESTATE tag as first argument'
      )
    }

    context.store.toggle(target)
  }

  toggle.displayName = `factory.toggle(${String(target)})`

  return toggle
}
