import { state } from 'cerebral';

export function getAdmins({http, path,get}) {
  return http
    .get('admin/admins', {}, {per_page: get(state.app.pagination.perPage)})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}

export function setAdmins({props, store}) {
  const { result } = props;
  store.set(state.admins.list, result.data)
}

export function getAdminDetail({ props, http, path }) {
  const { uuid } = props.params;
  return http
    .get(`admin/admins/${uuid}`, {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setAdminDetail({ props, store }) {
  const { result } = props;
  store.set(state.admins.detail, result);
}
export function getDealers({http, path,get}) {
  return http
    .get('web/request_dealers', {}, {per_page: get(state.app.pagination.perPage)})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}

export function setDealers({props, store}) {
  const { result } = props;
  store.set(state.app.requestDealers.data,result.data)
}
export function getContact({http, path,get}) {
  return http
    .get('web/contacts', {}, {per_page: get(state.app.pagination.perPage)})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}

export function setContact({props, store}) {
  const { result } = props;
  store.set(state.app.contacts,result.result.data)
}
export function getDealersNormal({http, path,get}) {
  return http
    .get('web/dealers', {}, {per_page: get(state.app.pagination.perPage), query_search: get(
      state.app.dealersSearch.query_search
    ),})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}

export function setDealersNormal({props, store}) {
  const { result } = props;
  store.set(state.app.dealers.data,result.data)
}