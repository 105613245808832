import AttributesListView from './AttributesListView';
import AttributesFormView from './AttributesFormView';
import AttributesFormViewUpdate from './AttributesFormViewUpdate';


export default {
  AttributesListView,
  AttributesFormView,
  AttributesFormViewUpdate
}
