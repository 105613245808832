import React, { Component } from 'react';
import {
  Select,
  InputNumber,
  Row,
  Col,
  Form,
  Input,
  Upload,
  Icon,
  Button
} from 'antd';
import normFile from '@utils/normFile';
import ReactQuill from 'react-quill';
import { createValidation } from '@utils';
import 'react-quill/dist/quill.snow.css';
import productWhitoutImage from '../../../../assets/product_whitout_image.png';

const IVA = 1.16;
const style = {
  imageProductDefault: {
    maxWidth: '100%',
    width: '15rem',
    display: 'block',
    margin: 'auto',
    marginTop: '1rem',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  pLabel: {
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: 14,
    marginTop: 10
  },
  miniFileCategory: {
    width: '1rem',
    height: '1rem',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  siderbarRight: {
    backgroundColor: '#f1f2f6',
    borderRadius: 5,
    padding: '2rem 1rem',
    height: '97%'
  }
};

export default class General extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: props.detail.type,
      price: props.detail.price,
      priceMoreIva: (props.detail.price * IVA).toFixed(2)
    };
    this.brandsDefault = this.brandsDefault.bind(this);
    this.categoriesDefault = this.categoriesDefault.bind(this);
    this.tagsDefault = this.tagsDefault.bind(this);
    this.handleChangeTypeProduct = this.handleChangeTypeProduct.bind(this);
    this.changePrice = this.changePrice.bind(this);
    this.changePriceMoreIva = this.changePriceMoreIva.bind(this);
  }

  getBrands(categoryBrand) {
    this.categoryBrand = categoryBrand;
    const bullet = categoryBrand.length > 0 ? ' * ' : '';
    let brands = '(';
    brands += bullet;
    categoryBrand.forEach(element => {
      brands += `${element.brand.name.toUpperCase()}`;
      brands += bullet;
    });
    brands += ')';
    return brands;
  }

  brandsDefault() {
    const { detail } = this.props;
    const data = [];
    detail.product_brand.forEach(element => {
      data.push(element.brand_id);
    });
    return data;
  }

  categoriesDefault() {
    const { detail } = this.props;
    const data = [];
    detail.product_category.forEach(element => {
      data.push(element.category_id);
    });
    return data;
  }

  tagsDefault() {
    const { detail } = this.props;
    const data = [];
    detail.product_tag.forEach(element => {
      data.push(element.tag_id.toString());
    });
    return data;
  }

  handleChangeTypeProduct(value) {
    const { updateTypeProductCustom } = this.props;
    this.setState({ type: value });
    if (value === 'COMBINATION') {
      updateTypeProductCustom({ status: false });
    } else {
      updateTypeProductCustom({ status: true });
    }
  }

  changePrice(value) {
    this.value = value;
    // eslint-disable-next-line no-restricted-globals
    const withIva = isNaN(parseFloat(value, 10))
      ? 0
      : parseFloat(value * IVA, 10);
    this.setState({
      // eslint-disable-next-line no-restricted-globals
      price: isNaN(parseFloat(value, 10)) ? 0 : parseFloat(value, 10),
      priceMoreIva: withIva.toFixed(2)
    });
  }

  changePriceMoreIva(value) {
    this.value = value;
    const { form } = this.props;
    // eslint-disable-next-line no-restricted-globals
    const withIva = isNaN(parseFloat(value, 10)) ? 0 : parseFloat(value, 10);
    this.setState({
      // eslint-disable-next-line no-restricted-globals
      price: isNaN(parseFloat(value, 10)) ? 0 : parseFloat(value / IVA, 10),
      priceMoreIva: withIva.toFixed(2)
    });

    form.setFieldsValue({
      // eslint-disable-next-line no-restricted-globals
      price: isNaN(parseFloat(value, 10)) ? 0 : parseFloat(value / IVA, 10)
    });
  }

  render() {
    const {
      form,
      detail,
      categoriesAll,
      tagsAll,
      brandsAll,
      isKit,
      onChangeKit
    } = this.props;
    const { type, price, priceMoreIva } = this.state;
    const { getFieldDecorator } = form;
    const FormItem = Form.Item;
    const modules = {
      toolbar: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' }
        ],
        ['link', 'image', 'video'],
        ['clean']
      ],
      clipboard: {
        matchVisual: false
      }
    };
    const formats = [
      'header',
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'video'
    ];
    return (
      <Row gutter={16} type="flex">
        <Col xs={24} sm={17} md={17}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24}>
              <FormItem label="Nombre">
                {getFieldDecorator('name', {
                  rules: [
                    createValidation(true, 'Por favor ingresa un nombre', '')
                  ],
                  initialValue: detail.name
                })(<Input placeholder="Nombre" />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={12} md={12}>
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <FormItem label="Precio Base">
                    {getFieldDecorator('price', {
                      rules: [
                        {
                          required: true,
                          message: 'Por favor ingresa un precio base'
                        }
                      ],
                      initialValue: price
                    })(
                      <InputNumber
                        disabled={parseInt(isKit, 10)}
                        placeholder="Precio"
                        step={0.1}
                        precision={6}
                        style={{ width: '94%', marginRight: 5 }}
                        onChange={this.changePrice}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={12} sm={12} md={12}>
                  <FormItem label="Precio + IVA">
                    <InputNumber
                      disabled={parseInt(isKit, 10)}
                      placeholder="Precio"
                      step={0.1}
                      precision={6}
                      style={{ width: '94%', marginLeft: 5 }}
                      value={priceMoreIva}
                      onChange={this.changePriceMoreIva}
                    />
                  </FormItem>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={8} md={12}>
              <FormItem label="Visible">
                {getFieldDecorator('show', {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor seleccione una opción'
                    }
                  ],
                  initialValue: detail.show
                })(
                  <Select
                    placeholder="Por favor seleccione una opción"
                    style={{ width: '100%' }}
                  >
                    <Select.Option key="show-1" value={1}>
                      VISIBLE
                    </Select.Option>
                    <Select.Option key="show-2" value={0}>
                      NO VISIBLE
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <FormItem label="Ingredientes">
                {getFieldDecorator('ingredients', {
                  rules: [
                    createValidation(
                      false,
                      'Por favor ingresa los ingredientes',
                      ''
                    )
                  ],
                  initialValue: detail.ingredients
                })(
                  <ReactQuill
                    placeholder="..."
                    modules={modules}
                    formats={formats}
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <FormItem label="Instrucciones">
                {getFieldDecorator('instructions', {
                  rules: [
                    createValidation(
                      false,
                      'Por favor ingresa las instrucciones',
                      ''
                    )
                  ],
                  initialValue: detail.instructions
                })(
                  <ReactQuill
                    placeholder="..."
                    modules={modules}
                    formats={formats}
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24} sm={8} md={12}>
              <FormItem label="Descripción corta">
                {getFieldDecorator('short_description', {
                  rules: [
                    createValidation(
                      false,
                      'Por favor ingresa una descripción corta',
                      ''
                    )
                  ],
                  initialValue: detail.short_description
                })(<Input placeholder="Descripción corta" />)}
              </FormItem>
            </Col>
            
            <Col xs={24} sm={8} md={12}>
              <FormItem label="Código de video youtube">
                {getFieldDecorator('video_youtube', {
                  rules: [
                    createValidation(
                      false,
                      'Por favor ingresa un código de video youtube',
                      ''
                    )
                  ],
                  initialValue: detail.video_youtube
                })(<Input placeholder="Código de video youtube" />)}
              </FormItem>
            </Col>

            <Col xs={12} sm={8} md={12}>
              <FormItem label="Peso (Kg)">
                {getFieldDecorator('weight', { initialValue: detail.weight })(
                  <InputNumber
                    placeholder="Peso (Kg)"
                    step={0.1}
                    precision={2}
                    style={{ width: '100%', marginRight: 5 }}
                  />
                )}
              </FormItem>
            </Col>
            
            <Col xs={24} sm={24} md={24}>
              <FormItem label="Descripción extensa">
                {getFieldDecorator('description', {
                  rules: [
                    createValidation(
                      false,
                      'Por favor ingresa una descripción extensa',
                      ''
                    )
                  ],
                  initialValue: detail.description
                })(
                  <ReactQuill
                    placeholder="..."
                    modules={modules}
                    formats={formats}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={7} md={7}>
          <div style={style.siderbarRight}>
            <FormItem label="Tipo">
              {getFieldDecorator('type', {
                rules: [
                  {
                    required: true,
                    message: 'Por favor seleccione una opción de tipo'
                  }
                ],
                initialValue: detail.type
              })(
                <Select
                  placeholder="Por favor seleccione una opción de tipo"
                  style={{ width: '100%' }}
                  onChange={this.handleChangeTypeProduct}
                >
                  <Select.Option key="type-1" value="REGULAR">
                    REGULAR
                  </Select.Option>
                  <Select.Option key="type-2" value="COMBINATION">
                    COMBINACIÓN
                  </Select.Option>
                </Select>
              )}
            </FormItem>

            <FormItem label="Es kit">
              {getFieldDecorator('is_kit', {
                rules: [
                  {
                    required: true,
                    message: 'Por favor seleccione una opción'
                  }
                ],
                initialValue: isKit
              })(
                <Select
                  placeholder="Por favor seleccione una opción"
                  style={{ width: '100%' }}
                  onChange={e => {
                    onChangeKit(e);
                  }}
                >
                  <Select.Option key="is_kit-1" value="1">
                    SI
                  </Select.Option>
                  <Select.Option key="is_kit-2" value="0">
                    NO
                  </Select.Option>
                </Select>
              )}
            </FormItem>
            {type === 'REGULAR' ? (
              <span>
                <FormItem label="Referencia">
                  {getFieldDecorator('reference', {
                    rules: [
                      createValidation(
                        true,
                        'Por favor ingresa una referencia',
                        ''
                      )
                    ],
                    initialValue: detail.reference
                  })(<Input placeholder="Referencia" />)}
                </FormItem>
                <FormItem label="Cantidad">
                  {getFieldDecorator('quantity', {
                    rules: [
                      {
                        required: true,
                        message: 'Por favor ingresa una cantidad'
                      }
                    ],
                    initialValue: detail.quantity
                  })(
                    <InputNumber
                      placeholder="Cantidad"
                      step={1}
                      precision={0}
                      style={{ width: '100%' }}
                    />
                  )}
                </FormItem>
              </span>
            ) : null}
            <p style={style.pLabel}>
              Imagen actual (255 x 255):
              {detail.image_default_url === null ? (
                <img
                  src={productWhitoutImage}
                  alt="sin imagen principal"
                  style={style.imageProductDefault}
                />
              ) : (
                <img
                  src={detail.image_default_url}
                  alt="imagen principal"
                  style={style.imageProductDefault}
                />
              )}
            </p>
            <FormItem>
              {getFieldDecorator('image_default_url', {
                valuePropName: 'fileList',
                getValueFromEvent: normFile,
                rules: [
                  {
                    required: false,
                    message: 'Por favor seleccione una imagen'
                  }
                ]
              })(
                <Upload
                  name="image_default_url"
                  beforeUpload={() => false}
                  accept="image/jpeg, image/png"
                  style={{ width: '100%' }}
                >
                  <Button style={{ width: '100%' }}>
                    <Icon type="upload" /> Subir imagen
                  </Button>
                </Upload>
              )}
            </FormItem>
            <FormItem label="Sort">
              {getFieldDecorator('sort', {
                rules: [
                  {
                    required: false,
                    message: 'Por favor ingresa un sort'
                  }
                ],
                initialValue: detail.sort
              })(
                <InputNumber
                  placeholder="Sort"
                  step={1}
                  precision={0}
                  style={{ width: '100%' }}
                />
              )}
            </FormItem>
            <FormItem label="Marcas">
              {getFieldDecorator('brands', {
                rules: [
                  {
                    required: false,
                    message: 'Por favor seleccione algunas marcas'
                  }
                ],
                initialValue: this.brandsDefault()
              })(
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Por favor seleccione algunas marcas"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children[2].props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {brandsAll.map(value => (
                    <Select.Option key={`marcas-${value.id}`} value={value.id}>
                      <img
                        src={value.url_photo}
                        alt={value.name}
                        style={style.miniFileCategory}
                      />{' '}
                      <span>{value.name}</span>
                    </Select.Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <FormItem label="Categorias">
              {getFieldDecorator('categories', {
                rules: [
                  {
                    required: false,
                    message: 'Por favor seleccione algunas categorias'
                  }
                ],
                initialValue: this.categoriesDefault()
              })(
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Por favor seleccione algunas categorias"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children[2].props.children[1]
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {categoriesAll.map(value => (
                    <Select.Option
                      key={`categories-${value.id}`}
                      value={value.id}
                    >
                      <img
                        src={
                          value.banner_url === null
                            ? productWhitoutImage
                            : value.banner_url
                        }
                        alt={value.alt}
                        style={style.miniFileCategory}
                      />{' '}
                      <span>
                        {value.parent_complete !== null
                          ? `${value.parent_complete.name}->`
                          : ''}
                        {value.name}
                        {value.parent_complete === null
                          ? this.getBrands(value.category_brand)
                          : ''}
                      </span>
                    </Select.Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <FormItem label="Tags">
              {getFieldDecorator('tags', {
                rules: [
                  {
                    required: false,
                    message: 'Por favor seleccione algunos tags'
                  }
                ],
                initialValue: this.tagsDefault()
              })(
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder="Por favor seleccione algunos tags"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {tagsAll.map(value => (
                    <Select.Option
                      key={`tags-${value.id}`}
                      value={value.id.toString()}
                    >
                      {value.name}{value.type != 'PROTECTED' ? null : ( <span style={{color: '#fd0000'}}> (protegido)</span> ) }
                    </Select.Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </div>
        </Col>
      </Row>
    );
  }
}
