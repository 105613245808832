export default [
  { id: 'AGS', name: 'AGUASCALIENTES' },
  { id: 'BC', name: 'BAJA CALIFORNIA' },
  { id: 'BCS', name: 'BAJA CALIFORNIA SUR' },
  { id: 'CHI', name: 'CHIHUAHUA' },
  { id: 'CHS', name: 'CHIAPAS' },
  { id: 'CMP', name: 'CAMPECHE' },
  { id: 'CMX', name: 'CIUDAD DE MEXICO' },
  { id: 'COA', name: 'COAHUILA' },
  { id: 'COL', name: 'COLIMA' },
  { id: 'DGO', name: 'DURANGO' },
  { id: 'GRO', name: 'GUERRERO' },
  { id: 'GTO', name: 'GUANAJUATO' },
  { id: 'HGO', name: 'HIDALGO' },
  { id: 'JAL', name: 'JALISCO' },
  { id: 'MCH', name: 'MICHOACAN' },
  { id: 'MEX', name: 'ESTADO DE MEXICO' },
  { id: 'MOR', name: 'MORELOS' },
  { id: 'NAY', name: 'NAYARIT' },
  { id: 'NL', name: 'NUEVO LEON' },
  { id: 'OAX', name: 'OAXACA' },
  { id: 'PUE', name: 'PUEBLA' },
  { id: 'QR', name: 'QUINTANA ROO' },
  { id: 'QRO', name: 'QUERETARO' },
  { id: 'SIN', name: 'SINALOA' },
  { id: 'SLP', name: 'SAN LUIS POTOSI' },
  { id: 'SON', name: 'SONORA' },
  { id: 'TAB', name: 'TABASCO' },
  { id: 'TLX', name: 'TLAXCALA' },
  { id: 'TMS', name: 'TAMAULIPAS' },
  { id: 'VER', name: 'VERACRUZ' },
  { id: 'YUC', name: 'YUCATAN' },
  { id: 'ZAC', name: 'ZACATECAS' }
];
