import { state } from 'cerebral';

export function getBrands({ http, path }) {
  return http
    .get('brands-simple/', {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setBrands({ props, store }) {
  const { result } = props;
  store.set(state.posts.brands, result);
}
export function getPosts({ http, path, get }) {
  return http
    .get(
      'admin/post',
      {},
      {
        per_page: get(state.app.pagination.perPage),
        brand_id: get(state.posts.brand_id)
      }
    )
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setPosts({ props, store }) {
  const { result } = props;
  store.set(state.posts.list, result.data);
}
export function getPostsGallery({ http, path, get }) {
  return http
    .get(
      'admin/post-gallery',
      {},
      {
        blog_id: get(state.posts_gallery.post_id)
      }
    )
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setPostsGallery({ props, store }) {
  const { result } = props;
  store.set(state.posts_gallery.list, result);
}

export function getPostDetail({ props, http, path }) {
  const { id } = props.params;
  return http
    .get(`admin/post/${id}`, {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setPostDetail({ props, store }) {
  const { result } = props;
  store.set(state.posts.detail, result);
}
