import { state } from 'cerebral';

export function getCoupons({ http, path, get }) {
	return http
		.get(
			'admin/coupons',
			{},
			{
				per_page: get(state.app.pagination.perPage),
				searchByName: get(state.coupons.search.searchByName),
				searchByStartDate1: get(state.coupons.search.searchByStartDate1),
				searchByStartDate2: get(state.coupons.search.searchByStartDate2),
				searchByEndDate1: get(state.coupons.search.searchByEndDate1),
				searchByEndDate2: get(state.coupons.search.searchByEndDate2),
				code: get(state.coupons.search.code),
				sort: get(state.coupons.search.sort)
			}
		)
		.then(res => path.success({ result: res }))
		.catch(error => error.json().then(message => path.error({ message })));
}
export function setCoupons({ props, store }) {
	const { result } = props;
	store.set(state.coupons.list, result.data);
}

export function getCampaign({ http, path, get }) {
	return http
		.get(
			'admin/coupons-register',
			{},
			{
				per_page: get(state.app.pagination.perPage),
				searchByName: get(state.campaign.search.searchByName),
				searchByStartDate1: get(state.campaign.search.searchByStartDate1),
				searchByStartDate2: get(state.campaign.search.searchByStartDate2),
				searchByEndDate1: get(state.campaign.search.searchByEndDate1),
				searchByEndDate2: get(state.campaign.search.searchByEndDate2),
				code: get(state.campaign.search.code),
				sort: get(state.campaign.search.sort)
			}
		)
		.then(res => path.success({ result: res }))
		.catch(error => error.json().then(message => path.error({ message })));
}
export function setCampaign({ props, store }) {
	const { result } = props;
	store.set(state.campaign.list, result.data);
}

export function getCategoriesSimple({ http, path }) {
	return http
		.get('admin/categories-simple-product', {})
		.then(res => path.success({ result: res }))
		.catch(error => error.json().then(message => path.error({ message })));
}
export function setCategoriesSimple({ props, store }) {
	const { result } = props;
	store.set(state.coupons.categories, result);
}

export function getTagsSimple({ http, path }) {
  return http
    .get('admin/tags-simple', {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setTagsSimple({ props, store }) {
  const { result } = props;
  store.set(state.coupons.tags, result);
}
export function getUserTagsSimple({ http, path }) {
  return http
    .get('admin/tags-simple/USERS/')
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setUserTagsSimple({ props, store }) {
  const { result } = props;
  store.set(state.coupons.user_tags, result);
}

export function getCouponModalitySimple({ http, path }) {
	return http
		.get('admin/coupons/coupons-modalities-simple', {})
		.then(res => path.success({ result: res }))
		.catch(error => error.json().then(message => path.error({ message })));
}
export function setCouponModalitySimple({ props, store }) {
	const { result } = props;
	store.set(state.coupons.modalities, result);
}

export function getEventsSimple({ http, path }) {
	return http
		.get('admin/events/simple', {})
		.then(res => path.success({ result: res }))
		.catch(error => error.json().then(message => path.error({ message })));
}
export function setEventsSimple({ props, store }) {
	const { result } = props;
	store.set(state.coupons.events, result);
}

export function getClients({ http, path }) {
	return http
		.get('admin/coupons/get/clients', {})
		.then(res => path.success({ result: res }))
		.catch(error => error.json().then(message => path.error({ message })));
}
export function setClients({ props, store }) {
	const { result } = props;
	store.set(state.coupons.clients, result);
}

export function getCouponDetail({ props, http, path }) {
	const { id } = props.params;
	return http
		.get(`admin/coupons/${id}`, {})
		.then(res => path.success({ result: res }))
		.catch(error => error.json().then(message => path.error({ message })));
}
export function setCouponDetail({ props, store }) {
  const { result } = props;

  var temp_array = [];
  result.coupon_user_tags.map( function(item) {
    if (!temp_array[item.tag_id]) { temp_array[item.tag_id] = item }
  });

  result.coupon_user_tags = temp_array;
  store.set(state.coupons.detail, result);
}