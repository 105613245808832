import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Upload,
  Icon,
  Tooltip,
  Select
} from 'antd';
import normFile from '@utils/normFile';
import { createValidation } from '@utils';

export default Form.create()(
  connect(
    {
      // states
      loadingLogin: state.user.loginButtonDisable,
      brandId: state.brands.BannerBrandId,
      // secuences
      submit: sequences.saveBannerBrand
    },
    class BannersFormView extends Component {
      constructor(props) {
        super(props);
        this.state = {
          type: 'IMAGE'
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);
      }

      handleSubmit(e) {
        e.preventDefault();
        const { form, submit, brandId } = this.props;
        const { type } = this.state;
        form.validateFields((err, values) => {
          if (!err) {
            values.brand_id = brandId;
            values.type = type;
            submit({ values });
          }
        });
      }

      handleChangeType(value) {
        this.value = value;
        this.setState({ type: value });
      }

      render() {
        const { form, loadingLogin, brandId } = this.props;
        const { type } = this.state;
        const { getFieldDecorator } = form;
        const FormItem = Form.Item;
        return (
          <div>
            <Form onSubmit={this.handleSubmit} layout="horizontal">
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24}>
                  <FormItem className="move-content-right">
                    <Tooltip title="Guardar categoria" placement="left">
                      <Button
                        icon="save"
                        type="primary"
                        htmlType="submit"
                        loading={loadingLogin}
                      />
                    </Tooltip>
                    <Tooltip title="Cancelar" placement="left">
                      <a
                        style={{ marginLeft: 10 }}
                        href={`/marcas/${brandId}/banners`}
                        className="ant-btn ant-btn-primary ant-btn-icon-only"
                        rel="noopener noreferrer"
                      >
                        <Icon type="arrow-left" />
                      </a>
                    </Tooltip>
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={12}>
                  <FormItem label="Tipo">
                    {getFieldDecorator('type', {
                      rules: [
                        createValidation(
                          true,
                          'Por favor seleccione una opción',
                          ''
                        )
                      ],
                      initialValue: 'IMAGE'
                    })(
                      <Select
                        placeholder="Status"
                        style={{ width: '100%' }}
                        onChange={this.handleChangeType}
                      >
                        <Select.Option key="type-1" value="IMAGE">
                          IMAGEN
                        </Select.Option>
                        <Select.Option key="type-2" value="VIDEO">
                          VIDEO
                        </Select.Option>
                      </Select>
                    )}
                  </FormItem>
                </Col>
                {type === 'IMAGE' ? (
                  <Col xs={24} sm={8} md={12}>
                    <FormItem label="Imagen (1500x600)">
                      {getFieldDecorator('url', {
                        valuePropName: 'fileList',
                        getValueFromEvent: normFile,
                        rules: [
                          {
                            required: true,
                            message: 'Por favor seleccione una imagen'
                          }
                        ]
                      })(
                        <Upload
                          name="url"
                          beforeUpload={() => false}
                          accept="image/jpeg, image/png, image/gif"
                        >
                          <Button>
                            <Icon type="upload" /> Subir imagen
                          </Button>
                        </Upload>
                      )}
                    </FormItem>
                  </Col>
                ) : null}
                {type === 'IMAGE' ? (
                  <Col xs={24} sm={24} md={24}>
                    <Row gutter={16}>
                      <Col xs={24} sm={8} md={12}>
                        <FormItem label="Imagen móvil">
                          {getFieldDecorator('thumb', {
                            valuePropName: 'fileList',
                            getValueFromEvent: normFile,
                            rules: [
                              {
                                required: true,
                                message: 'Por favor seleccione una imagen'
                              }
                            ]
                          })(
                            <Upload
                              name="thumb"
                              beforeUpload={() => false}
                              accept="image/jpeg, image/png, image/gif"
                            >
                              <Button>
                                <Icon type="upload" /> Subir imagen
                              </Button>
                            </Upload>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>
                ) : null}
              </Row>
              {type === 'IMAGE' ? (
                <Row gutter={16}>
                  <Col xs={24} sm={8} md={12}>
                    <FormItem label="Alt">
                      {getFieldDecorator('alt', {
                        rules: [
                          createValidation(true, 'Por favor ingrese un alt', '')
                        ]
                      })(<Input placeholder="Alt" />)}
                    </FormItem>
                  </Col>
                </Row>
              ) : (
                <Row gutter={16}>
                  <Col xs={24} sm={8} md={12}>
                    <FormItem label="Código Youtube ">
                      {getFieldDecorator('code', {
                        rules: [
                          createValidation(
                            true,
                            'Por favor ingrese Código de Youtube',
                            ''
                          )
                        ]
                      })(<Input placeholder="Código Youtube " />)}
                    </FormItem>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={24} sm={8} md={12}>
                  <FormItem label="CTA del contendor">
                    {getFieldDecorator('cta', {
                      rules: [
                        createValidation(
                          false,
                          'Por favor ingrese el id del video de youtube',
                          ''
                        )
                      ],
                      initialValue: ''
                    })(<Input placeholder="CTA del contendor" />)}
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>
        );
      }
    }
  )
);
