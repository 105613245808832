import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Form, Input, Button, Row, Col, Upload, Icon, Tooltip } from 'antd';
import normFile from '@utils/normFile';
import { createValidation } from '@utils';

export default Form.create()(
  connect(
    {
      // states
      loadingLogin: state.user.loginButtonDisable,
      // secuences
      submit: sequences.saveBrand
    },
    class BrandFormView extends Component {
      constructor(props) {
        super(props);
        this.state = {};
        this.handleSubmit = this.handleSubmit.bind(this);
      }

      handleSubmit(e) {
        e.preventDefault();
        const { form, submit } = this.props;
        form.validateFields((err, values) => {
          if (!err) {
            submit({ values });
          }
        });
      }

      render() {
        const { form, loadingLogin } = this.props;
        const { getFieldDecorator } = form;
        const FormItem = Form.Item;
        return (
          <div>
            <Form onSubmit={this.handleSubmit} layout="horizontal">
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24}>
                  <FormItem className="move-content-right">
                    <Tooltip title="Guardar marca" placement="left">
                      <Button
                        icon="save"
                        type="primary"
                        htmlType="submit"
                        loading={loadingLogin}
                      />
                    </Tooltip>
                    <Tooltip title="Cancelar" placement="left">
                      <a
                        style={{ marginLeft: 10 }}
                        href="/marcas"
                        className="ant-btn ant-btn-primary ant-btn-icon-only"
                        rel="noopener noreferrer"
                      >
                        <Icon type="arrow-left" />
                      </a>
                    </Tooltip>
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={12}>
                  <FormItem label="Nombre">
                    {getFieldDecorator('name', {
                      rules: [
                        createValidation(
                          true,
                          'Por favor ingresa un nombre',
                          ''
                        )
                      ]
                    })(<Input placeholder="Nombre" />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={8} md={12}>
                  <FormItem label="Imagen">
                    {getFieldDecorator('url_photo', {
                      valuePropName: 'fileList',
                      getValueFromEvent: normFile,
                      rules: [
                        {
                          required: true,
                          message: 'Por favor seleccione una imagen'
                        }
                      ]
                    })(
                      <Upload
                        name="url_photo"
                        beforeUpload={() => false}
                        accept="image/jpeg, image/png"
                      >
                        <Button>
                          <Icon type="upload" /> Subir imagen
                        </Button>
                      </Upload>
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>
        );
      }
    }
  )
);
