import {
  getChangedProps,
  getStateTreeProp,
  isComputedValue,
  noop,
  throwError,
} from './utils'

import Reaction from './Reaction'
import { Tag } from 'function-tree'
import Watch from './Watch'

class View extends Watch {
  constructor({
    dependencies = {},
    mergeProps,
    props,
    controller,
    displayName,
    onUpdate,
  }) {
    super('View')
    if (typeof dependencies === 'function') {
      throwError(
        'You can not use a function to define dependencies. Use tags or a function on the specific property you want to dynamically create'
      )
    }

    Object.keys(dependencies).forEach((key) => {
      if (!(dependencies[key] instanceof Tag)) {
        throwError(
          `The dependency "${key}" on component "${displayName}" is not a tag, it has to be a tag`
        )
      }
    })

    this.dependencies = dependencies
    this.mergeProps = mergeProps
    this.controller = controller
    this._displayName = displayName
    this._hasWarnedBigComponent = false
    this.isUnmounted = false
    this.updateComponent = onUpdate || noop
    this.props = props
    this.propKeys = Object.keys(props || {})
    this._verifyPropsWarned = false
    this.dynamicDependencies = []
    this.reactions = []
    this.computedWithProps = {}
    this.dynamicComputedWithProps = {}

    this.createReaction = this.createReaction.bind(this)

    if (controller.devtools && controller.devtools.warnStateProps) {
      this.verifyProps(props)
    }
  }

  /*
    A method to ensure objects and arrays from state tree are not passed as props
  */
  verifyProps(props) {
    const key = getStateTreeProp(props)

    if (key && !this._verifyPropsWarned) {
      console.warn(
        `You are passing an ${
          Array.isArray(props[key]) ? 'array' : 'object'
        } to the component "${
          this._displayName
        }" on prop "${key}" which is from the Cerebral state tree. You should not do this, but rather connect it directly to this component. This will optimize the component and avoid any rerender issues.`
      )
      this._verifyPropsWarned = true
    }
  }

  /*
    Updates the dependencymap which causes new renders from state
  */
  createDependencyMap() {
    const getters = this.controller.createContext(this.props)
    const computedWithProps = {}
    const dependencies = Object.keys(this.dependencies)
      .map((key) => {
        const tag = this.dependencies[key]
        const value = tag.getValue(getters)

        // When we have computed that is looking at props we need to make
        // sure that we prepare to clone it, as this will destroy the computed
        // when component unmounts
        if (isComputedValue(value) && value.propsTags.length) {
          computedWithProps[tag.getPath(getters)] = value
        }

        return tag
      })
      .concat(this.dynamicDependencies)

    // If not part of dependencies or dynamic (meaning path has changed), we have to destroy it
    Object.keys(this.computedWithProps).forEach((key) => {
      if (
        !(key in computedWithProps) &&
        !(key in this.dynamicComputedWithProps)
      ) {
        this.computedWithProps[key].destroy()
        delete this.computedWithProps[key]
      }
    })

    // We add any new from the dependencies
    Object.keys(computedWithProps).forEach((key) => {
      if (!this.computedWithProps[key]) {
        this.computedWithProps[key] = computedWithProps[key].clone()
      }
    })

    // We add any collected from dynamic (already cloned)
    Object.keys(this.dynamicComputedWithProps).forEach((key) => {
      if (!this.computedWithProps[key]) {
        this.computedWithProps[key] = this.dynamicComputedWithProps[key]
      }
    })

    return this.controller.createDependencyMap(dependencies, this.props)
  }

  onUpdate(...args) {
    if (this.isUnmounted) {
      return
    }

    this.updateComponent(...args)
  }

  mount() {
    this.create(this.controller, [], this._displayName)
    this.update(this.props)
  }

  unMount() {
    Object.keys(this.computedWithProps).forEach((key) => {
      this.computedWithProps[key].destroy()
    })

    this.reactions.forEach((reaction) => reaction.destroy())

    this.isUnmounted = true
    this.destroy()
  }

  onPropsUpdate(props, nextProps) {
    if (this.controller.devtools) {
      this.verifyProps(nextProps)
    }

    const propsChanges = getChangedProps(props, nextProps)

    if (propsChanges.length) {
      this.updateFromProps(propsChanges, nextProps)

      return true
    }

    return false
  }

  /*
    Called by component when props are passed from parent and they
    have changed. In this situation both tags and depndency trackers might
    be affected. Tags are just updated and dependency trackers are matched
    on props changed
  */
  updateFromProps(_, props) {
    this.update(props)
  }

  /*
    Called by Container when the components state dependencies
    has changed. In this scenario we need to run any dependencyTrackers
    that matches the state changes. There is no need to update the tags
    as their declared state deps can not change
  */
  updateFromState(_, props, __) {
    this.update(props)
  }

  /*
    Run update, re-evaluating the tags and computed, if neccessary
  */
  update(props) {
    const previousdependencyMap = this.dependencyMap

    this.props = props
    this.dependencyMap = this.createDependencyMap()

    const prevDepsMap = Object.assign({}, previousdependencyMap)
    const nextDepsMap = Object.assign({}, this.dependencyMap)
    this.controller.dependencyStore.updateEntity(this, prevDepsMap, nextDepsMap)

    if (this.controller.devtools) {
      this.controller.devtools.updateWatchMap(this, nextDepsMap, prevDepsMap)
    }
  }

  /*
    Creates a getter to track inline dependencies
  */
  createDynamicGetter(props, getters) {
    this.dynamicDependencies = []
    this.dynamicComputedWithProps = {}

    return Object.assign(
      (tag) => {
        const value = tag.getValue(getters)

        this.dynamicDependencies.push(tag)

        // When we have computed that is looking at props we need to make
        // sure that we create a clone, as we will destroy the computed when
        // unmounting
        if (isComputedValue(value) && value.propsTags.length) {
          const path = tag.getPath(getters)

          if (this.computedWithProps[path]) {
            this.dynamicComputedWithProps[path] = this.computedWithProps[path]

            return this.computedWithProps[path].getValue(props)
          } else {
            this.dynamicComputedWithProps[path] = value.clone()

            return this.dynamicComputedWithProps[path].getValue(props)
          }
        }

        return isComputedValue(value) ? value.getValue(props) : value
      },
      {
        path: (tag) => {
          return tag.getPath(getters)
        },
      }
    )
  }

  /*
    Creates a reaction
  */
  createReaction(reactionName, dependencies, cb) {
    const reaction = Reaction(dependencies, cb)
      .create(this.controller, this.modulePath, `${this.name}.${reactionName}`)
      .initialize()

    this.reactions.push(reaction)

    return reaction
  }

  /*
    Runs whenever the component has an update and renders.
    Extracts the actual values from dependency trackers and/or tags
  */
  getProps(getters, props = {}, includeProps = true) {
    const dependenciesProps = Object.keys(this.dependencies).reduce(
      (currentProps, key) => {
        const tag = this.dependencies[key]
        const value = tag.getValue(getters)

        if (isComputedValue(value)) {
          const path = tag.getPath(getters)

          if (this.computedWithProps[path]) {
            currentProps[key] = this.computedWithProps[path].getValue(props)
          } else {
            currentProps[key] = value.getValue(props)
          }
        } else {
          currentProps[key] = value
        }

        return currentProps
      },
      {}
    )

    if (
      this.controller.devtools &&
      this.controller.devtools.bigComponentsWarning &&
      !this._hasWarnedBigComponent &&
      Object.keys(this.dependencies).length >=
        this.controller.devtools.bigComponentsWarning
    ) {
      console.warn(
        `Component named ${this._displayName} has a lot of dependencies, consider refactoring or adjust this option in devtools`
      )
      this._hasWarnedBigComponent = true
    }

    if (this.mergeProps) {
      return this.mergeProps(dependenciesProps, props, (tag) => {
        if (!(tag instanceof Tag)) {
          throwError('You are not passing a tag to the mergeProp get function')
        }
        const value = tag.getValue(getters)

        if (isComputedValue(value)) {
          return value.getValue(props)
        }

        return value
      })
    }

    dependenciesProps.get = this.createDynamicGetter(props, getters)
    dependenciesProps.reaction = this.createReaction

    return Object.assign({}, includeProps ? props : {}, dependenciesProps)
  }

  /*
    Should be used by view layers to render with callback reveiving
    the props based on dependencies. Also allows dynamic getter to
    evaluate what has been gotten
  */
  render(props = {}, cb = () => {}, includeProps) {
    const getters = this.controller.createContext(props)
    const viewProps = this.getProps(getters, props, includeProps)
    this.executedCount++
    if (this.controller.devtools) {
      this.controller.devtools.sendWatchMap([], [], 0, 0)
    }
    const result = cb(viewProps)

    if (this.dynamicDependencies.length) {
      this.update(props)
    }

    return result
  }
}

export default View
