class Watch {
  constructor(type) {
    const id = Watch.nextWatchId++
    
    this.id = 'Watch_' + id
    this.rawId = id
    this.name = null
    this.type = type
    this.executedCount = 0
    this.controller = null
    this.modulePath = ''
    this.dependencyMap = null
  }

  create(controller, modulePath, name) {
    this.name = name
    this.controller = controller
    this.modulePath = modulePath

    return this
  }

  registerDependencies() {
    this.dependencyMap = this.createDependencyMap()
    this.controller.dependencyStore.addEntity(this, this.dependencyMap)
    if (this.controller.devtools) {
      this.controller.devtools.updateWatchMap(this, this.dependencyMap)
      this.controller.devtools.sendWatchMap([], [], 0, 0)
    }
  }

  destroy() {
    if (this.dependencyMap) {
      this.controller.dependencyStore.removeEntity(this, this.dependencyMap)
      if (this.controller.devtools) {
        this.controller.devtools.updateWatchMap(this, null, this.dependencyMap)
        this.controller.devtools.sendWatchMap([], [], 0, 0)
      }
    }
  }

  toJSON() {
    return {
      id: this.id,
      executedCount: this.executedCount,
      type: this.type,
      name: this.name,
    }
  }
}

Watch.nextWatchId = 0

export default Watch
