import { set } from 'cerebral/factories'; // wait
import { state, props } from 'cerebral';
import { WithRouter, setPagination } from '@modules/actions';
import notify from '@utils/notify';
import page from 'page';
import * as actions from './actions';

const OptionListRouter = WithRouter([
  set(state.app.currentPage, 'OptionsListView'),
  actions.getOptions,
  {
    success: [setPagination, actions.setOptions],
    error: []
  }
]);
const OptionNewRouter = WithRouter([set(state.app.currentPage, 'OptionsFormView')]);
const saveOption = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values } = props;
    return http
      .post('admin/options', values)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Opción creada', 'success'),
      () => {
        page.show('/opciones');
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];
const OptionFormViewUpdate = WithRouter([
  set(state.tags.detail, {}),
  set(state.app.currentPage, 'OptionsFormViewUpdate'),
  actions.getOptionDetail,
  {
    success: [actions.setOptionDetail],
    error: [notify(props.message.error, 'error')]
  }
]);
const updateOption = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values, id } = props;
    return http
      .patch(`admin/options/${id}/update`, values)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Opción actualizada', 'success'),
      () => {
        page.show('/opciones');
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];

const deleteOption = [
  ({ store }) => {
    store.set(state.options.delete.loader, true);
  },
  ({ http, path, get }) => {
    return http
      .delete(`admin/options/${get(state.options.delete.id)}/delete`, {})
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      ({ store }) => {
        store.set(state.options.delete, {
          id: null,
          name: null,
          open: false,
          loader: false
        });
      },
      notify('Opción eliminada', 'success'),
      () => {
        page.show('/opciones');
      }
    ],
    error: [
      ({ store }) => {
        store.set(state.options.delete, {
          id: null,
          name: null,
          open: false,
          loader: false
        });
      },
      notify(props.message.error, 'error')
    ]
  }
];
const handleClickOpenModalOption = [
  ({ props, store }) => {
    store.set(state.options.delete, {
      id: props.id,
      name: props.name,
      open: true,
      loader: false
    });

  }
];
const handleClickCloseModalOption = [
  ({ store }) => {
    store.set(state.options.delete, {
      id: null,
      name: null,
      open: false,
      loader: false
    });
  }
];

export default {
  routes: {
    list: OptionListRouter,
    new: OptionNewRouter,
    update: OptionFormViewUpdate
  },
  sequences: {
    handleClickOpenModalOption,
    handleClickCloseModalOption,
    deleteOption,
    saveOption,
    updateOption
  }
};
