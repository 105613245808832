export const routers = [
  {
    name: null,
    icon: 'dashboard',
    haveChildren: false,
    children: [
      {
        name: 'Panel',
        route: '/'
      }
    ]
  },
  {
    name: null,
    icon: 'file-excel',
    haveChildren: false,
    children: [
      {
        name: 'Stock',
        route: '/stock'
      }
    ]
  },
  {
    name: 'Usuarios',
    icon: 'team',
    haveChildren: true,
    children: [
      {
        name: 'Administradores',
        route: '/administradores'
      },
      {
        name: 'Clientes',
        route: '/clientes'
      },
      {
        name: 'Tags de usuarios',
        route: '/tags-usuarios'
      }
    ]
  },
  {
    name: null,
    icon: 'shop',
    haveChildren: false,
    children: [
      {
        name: 'Marcas',
        route: '/marcas'
      }
    ]
  },
  {
    name: null,
    icon: 'profile',
    haveChildren: false,
    children: [
      {
        name: 'Tutoriales',
        route: '/posts'
      }
    ]
  },
  {
    name: 'Catálogo',
    icon: 'tag',
    haveChildren: true,
    children: [
      {
        name: 'Productos',
        route: '/productos'
      },
      {
        name: 'Categorias',
        route: '/categorias'
      },
      {
        name: 'Tags',
        route: '/tags'
      },
      {
        name: 'Opciones',
        route: '/opciones'
      }
    ]
  },
  {
    name: null,
    icon: 'thunderbolt',
    haveChildren: false,
    children: [
      {
        name: 'Cupones',
        route: '/cupones'
      }
    ]
  },
  {
    name: null,
    icon: 'gift',
    haveChildren: false,
    children: [
      {
        name: 'Campañas',
        route: '/campaign'
      }
    ]
  },
  {
    name: 'Ventas',
    icon: 'shopping-cart',
    haveChildren: true,
    children: [
      {
        name: 'Ordenes',
        route: '/ordenes'
      }
    ]
  },
  {
    name: 'Administración',
    icon: 'appstore',
    haveChildren: true,
    children: [
      {
        name: 'Slide Principal',
        route: '/slides'
      },
      {
        name: 'Libros',
        route: '/books'
      },
      {
        name: 'Distribuidores',
        route: '/dealers'
      },
      {
        name: 'Contacto',
        route: '/contact'
      },
      {
        name: 'Configuraciones',
        route: '/configuraciones'
      }
    ]
  },
  {
    name: 'Nuevos distribuidores',
    icon: 'team',
    haveChildren: true,
    children: [
      {
        name: 'Minorista ',
        route: '/dealers-request'
      },
      {
        name: 'Telemarketing',
        route: '/dealers-request-telemarketing'
      },
      {
        name: 'Mayoreo',
        route: '/dealers-request-mayoreo'
      }
    ]
  },
  {
    name: null,
    icon: 'file-text',
    haveChildren: false,
    children: [{
      name: 'Reportes',
      route: '/reports'
    }]
  },
  {
    name: 'Eventos',
    icon: 'audit',
    haveChildren: true,
    children: [
      {
        name: 'Listado',
        route: '/eventos'
      },
      {
        name: 'Archivados',
        route: '/eventos-archivados'
      },
      {
        name: 'Terminos y condiciones',
        route: '/eventos-terminos-y-condiciones'
      },
    ]
  },
];
export const dealer1 = [
  {
    name: null,
    icon: 'dashboard',
    haveChildren: false,
    children: [
      {
        name: 'Panel',
        route: '/'
      }
    ]
  },
  {
    name: 'Nuevos distribuidores',
    icon: 'team',
    haveChildren: true,
    children: [
      {
        name: 'Minorista ',
        route: '/dealers-request'
      }
    ]
  }
];
export const dealer2 = [
  {
    name: null,
    icon: 'dashboard',
    haveChildren: false,
    children: [
      {
        name: 'Panel',
        route: '/'
      }
    ]
  },
  {
    name: 'Nuevos distribuidores',
    icon: 'team',
    haveChildren: true,
    children: [
      {
        name: 'Telemarketing',
        route: '/dealers-request-telemarketing'
      }
    ]
  }
];
export const dealer3 = [
  {
    name: null,
    icon: 'dashboard',
    haveChildren: false,
    children: [
      {
        name: 'Panel',
        route: '/'
      }
    ]
  },
  {
    name: 'Nuevos distribuidores',
    icon: 'team',
    haveChildren: true,
    children: [
      {
        name: 'Mayoreo',
        route: '/dealers-request-mayoreo'
      }
    ]
  }
];
