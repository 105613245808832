import ProductListView from './ProductListView';
import ProductFormViewUpdate from './ProductFormViewUpdate';
import ProductFormViewUpdateMultiple from './ProductFormViewUpdateMultiple';
import ProductFormViewNew from './ProductFormViewNew';
import CombinationGalleryView from './CombinationGalleryView';

export default {
  ProductListView,
  ProductFormViewNew,
  ProductFormViewUpdate,
  ProductFormViewUpdateMultiple,
  CombinationGalleryView,
};
