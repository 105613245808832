import Watch from './Watch'
import { isComputedValue } from './utils'

export class Reaction extends Watch {
  constructor(dependencies, cb) {
    super('Reaction')
    this.dependencies = dependencies
    this.cb = cb
    this.getter = null
    this.context = null
  }

  initialize() {
    this.context = this.controller.createContext()
    this.getter = (tag) => tag.getValue(this.context)
    this.controller.dependencyStore.updateEntity(
      this,
      null,
      this.createDependencyMap()
    )

    return this
  }

  createDependencyMap() {
    return this.controller.createDependencyMap(
      Object.keys(this.dependencies).map((key) => this.dependencies[key]),
      null,
      this.modulePath
    )
  }

  onUpdate() {
    this.executedCount++
    if (this.controller.devtools) {
      this.controller.devtools.sendWatchMap([], [], 0, 0)
    }
    this.cb(
      Object.keys(this.dependencies).reduce(
        (currentDeps, key) => {
          currentDeps[key] = this.dependencies[key].getValue(this.context)

          if (isComputedValue(currentDeps[key])) {
            currentDeps[key] = currentDeps[key].getValue()
          }

          return currentDeps
        },
        {
          get: this.getter,
        }
      )
    )
  }
}

export default (dependencies, cb) => {
  if (!cb) {
    cb = dependencies
    dependencies = {}
  }

  return new Reaction(dependencies, cb)
}
