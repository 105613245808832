import React, { Component } from 'react';
import {
  Row,
  Col,
  Tooltip,
  Button,
  Table,
  Modal,
  Input,
} from 'antd';

const { confirm } = Modal;
const { TextArea } = Input;

const style = {
  siderbarRight: {
    backgroundColor: '#f1f2f6',
    borderRadius: 5,
    padding: '2rem 1rem',
    height: '97%'
  },
  label: {
    marginBottom: 5
  }
};

export default class FrequentQuestionsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      content: '',
      frequent_question_id: '',
      edit: false,
      create: false,
    };

    this.showConfirm = this.showConfirm.bind(this);
    this.setByUpdate = this.setByUpdate.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.createFrequentQuestion = this.createFrequentQuestion.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  setByUpdate(data) {
    this.setState({
      name: data.title,
      content: data.description,
      frequent_question_id: data.id,
      edit: true,
      create: false,
    });
    const { modeUpdateFrequentQuestion } = this.props;
    modeUpdateFrequentQuestion({ value: true });
  }

  createFrequentQuestion() {
    this.setState({
      create: true,
      edit: false,
      name: '',
      content: '',
      frequent_question_id: '',
    });
    const { modeCreateFrequentQuestion } = this.props;
    modeCreateFrequentQuestion({ value: true });
  }

  showConfirm(data) {
    this.data = data;
    const {deleteFrequentQuestion} = this.props;
    confirm({
      title: `¿Esta seguro que desear eliminar la fregunta frecuente "${data.title}"?`,
      content: '',
      onOk() {
        const event_id = data.event_id
        const frequent_question_id = data.id
        deleteFrequentQuestion({ event_id, frequent_question_id });
      },
      onCancel() {}
    });
  }

  onChangeTitle(element) {
    this.setState({
      name: element.target.value
    });
  }

  onChangeDescription(element) {
    this.setState({
      content: element.target.value
    });
  }

  render() {
    const {
      disabledTableFrequentQuestion,
      createFrequentQuestion,
      updateFrequentQuestion,
      loaderFrequentQuestion,
      FrequentQuestionStates,
      show,
    } = this.props;

    const {
      edit,
      create,
      name,
      content,
      frequent_question_id,
    } = this.state;

    const columns = [
      {
        title: 'ACCIONES',
        dataIndex: 'id',
        key: 'id',
        width: 150,
        render: (text, element, index) => (
          <div>
            <Tooltip title="Eliminar pregunta frecuente" placement="top">
              <Button
                type="primary"
                icon="delete"
                style={{ display: (show.archived != 1 ? 'inline-block' : 'none') }}
                onClick={() => {
                  this.showConfirm(element);
                }}
              />
            </Tooltip>
            <Tooltip title="Editar pregunta frecuente" placement="top">
              <Button
                type="primary"
                icon="edit"
                style={{ marginLeft: 10, display: (show.archived != 1 ? 'inline-block' : 'none') }}
                onClick={() => {
                  this.setByUpdate(element);
                }}
              />
            </Tooltip>
          </div>
        )
      },
      {
        title: 'Pregunta frecuente',
        dataIndex: 'title',
        key: 'frequent_question_title',
        width: 300,
      },
      {
        title: 'Descripción',
        dataIndex: 'description',
        key: 'frequent_question_description',
        width: 600,
      },
    ];

    return (
      <Row gutter={16} type="flex">
        <Col xs={24} sm={17} md={17}>
          <Row gutter={16}>
            <Col xs={24} sm={8} md={12}>
              <Tooltip title="Generar preguntas frecuentes" placement="left">
                <Button
                  icon="plus"
                  type="primary"
                  htmlType="submit"
                  style={{ display: (show.archived != 1 ? 'block' : 'none') }}
                  loading={FrequentQuestionStates.loading}
                  onClick={() => {
                    this.createFrequentQuestion();
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24}>
              <Table
                rowKey="frequent-questions-table"
                style={{ marginTop: 20, marginBottom: 10 }}
                columns={columns}
                dataSource={show.frequent_questions}
                bordered
                pagination={false}
                loading={disabledTableFrequentQuestion}
                scroll={{ x: 'max-content' }}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={7} md={7}>
          <div style={style.siderbarRight}>
            <Row gutter={16}>
            { create !== true  ? (
              <Col xs={24} sm={24} md={24}>
                <p style={{ textAlign: 'center' }}>
                  <strong>EDICIÓN DE PREGUNTA FRECUENTE:</strong>
                </p>
              </Col>
            ) : (
              <Col xs={24} sm={24} md={24}>
                <p style={{ textAlign: 'center' }}>
                  <strong>CREACIÓN DE PREGUNTA FRECUENTE:</strong>
                </p>
              </Col>
            ) }

              { edit === true || create === true ? (
                <div>

                  <Col xs={24} sm={24} md={24}>
                    <p style={style.label}>Título:</p>
                    <Input
                      placeholder="Pregunta frecuente"
                      style={{ width: '100%', marginBottom: 10 }}
                      value={name}
                      onChange={this.onChangeTitle}
                    />
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <p style={style.label}>Descripción:</p>
                    <TextArea
                      placeholder="Descripción"
                      style={{ width: '100%', marginBottom: 10 }}
                      value={content}
                      onChange={this.onChangeDescription}
                      rows={6}
                    />
                  </Col>

                  <Col xs={24} sm={24} md={24} style={{ marginBottom: 10 }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <Tooltip title="Guardar cambios" placement="top">
                      { create !== true  ? (
                        <Button
                          icon="save"
                          type="primary"
                          htmlType="submit"
                          loading={loaderFrequentQuestion}
                          onClick={() => {
                            const event_id = show.id;
                            updateFrequentQuestion({
                              frequent_question_id,
                              name,
                              content,
                              event_id,
                            });
                            const { modeUpdateFrequentQuestion } = this.props;
                            modeUpdateFrequentQuestion({ value: false });
                          }}
                        />
                      ) : (
                        <Button
                          icon="save"
                          type="primary"
                          htmlType="submit"
                          loading={loaderFrequentQuestion}
                          onClick={() => {
                            const event_id = show.id;
                            createFrequentQuestion({
                              name,
                              content,
                              event_id,
                            });
                            const { modeCreateFrequentQuestion } = this.props;
                            modeCreateFrequentQuestion({ value: false });
                          }}
                        />
                      ) }
                      </Tooltip>
                      <Tooltip title="Cancelar" placement="top">
                        <Button
                          icon="close-circle"
                          type="primary"
                          htmlType="submit"
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            this.setState({
                              title: '',
                              description: '',
                              frequent_question_id: null,
                              edit: false,
                              create: false
                            });
                            const { modeUpdateFrequentQuestion, modeCreateFrequentQuestion } = this.props;
                            modeUpdateFrequentQuestion({ value: false });
                            modeCreateFrequentQuestion({value: false});
                          }}
                        />
                      </Tooltip>
                    </div>
                  </Col>
                </div>
              ) : (
                <Col xs={24} sm={24} md={24}>
                  <p>Es necesario seleccionar una pregunta frecuente</p>
                </Col>
              )}
            </Row>
          </div>
        </Col>
      </Row>
    );
  }
}
