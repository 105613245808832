import TagFormView from './TagFormView';
import TagFormViewUpdate from './TagFormViewUpdate';
import TagListView from './TagListView';
import TagUserListView from './TagUserListView';
import TagUsersFormView from './TagUsersFormView';


export default {
  TagFormView,
  TagFormViewUpdate,
  TagListView,
  TagUserListView,
  TagUsersFormView,
}
