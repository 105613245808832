/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Card, Col, Row, Icon, Divider, Tooltip, Modal, Button } from 'antd';
import Pagination from '@component/Elements/Pagination';
import classNames from 'classnames/bind';
import arrayMove from 'array-move';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';

import styles from './BannerListView.css';

const cx = classNames.bind(styles);

export default connect(
  {
    // states
    list: state.brands.listBanners,
    brandId: state.brands.BannerBrandId,
    stateDelete: state.brands.deleteBanners,
    // secuences
    updateSortMediaBanners: sequences.updateSortMediaBanners,

    deleteFileBanner: sequences.deleteFileBanner,
    handleClickOpenModal: sequences.handleClickOpenModalBanners,
    handleClickCloseModal: sequences.handleClickCloseModalBanners
  },
  class BannerListView extends Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.onSortEnd = this.onSortEnd.bind(this);
    }

    // eslint-disable-next-line class-methods-use-this
    onSortEnd({ oldIndex, newIndex }) {
      const { list, brandId } = this.props;
      const result = list.sort((a, b) => (a.sort > b.sort ? 1 : -1));
      const newData = arrayMove(result, oldIndex, newIndex);
      const values = {
        data: newData,
        brand_id: brandId
      };
      const { updateSortMediaBanners } = this.props;
      updateSortMediaBanners({ values });
    }

    render() {
      const {
        list,
        brandId,
        stateDelete,
        handleClickOpenModal,
        handleClickCloseModal,
        deleteFileBanner
      } = this.props;
      const SortableContainer = sortableContainer(({ children }) => {
        return <ul className={cx('content-gallery')}>{children}</ul>;
      });
      const DragHandle = sortableHandle(() => (
        <span className={cx('content-pin-move')}>
          <Icon type="pushpin" />
        </span>
      ));

      const SortableItem = sortableElement(({ value }) => (
        <li key={value.id} className={cx('list-gallery')}>
          <Card
            style={{ width: 240, marginBottom: 10, maxWidth: '100%' }}
            cover={
              <span>
                {value.type === 'IMAGE' ? (
                  <img alt={value.alt} src={value.url} className={cx('img')} />
                ) : (
                  // eslint-disable-next-line jsx-a11y/iframe-has-title
                  <iframe
                    className={cx('yt-video')}
                    src={`${value.url}?rel=0&amp;showinfo=0&modestbranding=1&rel=0`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                )}
              </span>
            }
          >
            <p className={cx('sort')}>{value.sort + 1}</p>
            <p>
              <strong>Alt:</strong> {value.type === 'IMAGE' ? value.alt : 'N/A'}
            </p>
            <Icon
              type="delete"
              className={cx('trash')}
              onClick={() => {
                handleClickOpenModal(value);
              }}
            />
            <DragHandle />
          </Card>
        </li>
      ));

      return (
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24}>
            <div className="move-content-right">
              <Tooltip title="Crear filtro" placement="left">
                <a
                  href={`/marcas/${brandId}/banners/nuevo`}
                  className="ant-btn ant-btn-primary ant-btn-icon-only"
                  rel="noopener noreferrer"
                >
                  <Icon type="plus" />
                </a>
              </Tooltip>
              <Tooltip title="Atras" placement="left">
                <a
                  style={{ marginLeft: 10 }}
                  href="/marcas"
                  className="ant-btn ant-btn-primary ant-btn-icon-only"
                  rel="noopener noreferrer"
                >
                  <Icon type="arrow-left" />
                </a>
              </Tooltip>
            </div>
            <p>
              Para cambiar el orden de las imagenes de clic en el icono de pin (
              <Icon type="pushpin" />) sin soltar y mueva la imagen hasta la
              posición deseada.
            </p>
            <Divider></Divider>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <SortableContainer
              onSortEnd={this.onSortEnd}
              axis="xy"
              useDragHandle
            >
              {list
                .sort((a, b) => (a.sort > b.sort ? 1 : -1))
                .map(file => (
                  <SortableItem
                    key={`sort-${file.sort}`}
                    index={file.sort}
                    value={file}
                  />
                ))}
            </SortableContainer>
            {list.length === 0 ? (
              <p className={cx('withoutresults')}>Sin resultados</p>
            ) : null}
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Pagination
              list="brands.listBanners"
              apiUrl="admin/banner-brand"
              extraBySearch={{ brand_id: brandId }}
            />
          </Col>
          <Modal
            closable={false}
            visible={stateDelete.open}
            onCancel={() => {
              handleClickCloseModal();
            }}
            footer={[
              <Button
                type="primary"
                onClick={() => {
                  deleteFileBanner();
                }}
                loading={stateDelete.loader}
              >
                Confirmar
              </Button>,
              <Button
                onClick={() => {
                  handleClickCloseModal();
                }}
              >
                Cancelar
              </Button>
            ]}
          >
            <h4>¿Desea eliminar el archivo?</h4>
            <p>
              Favor de verificar que el archivo seleccionado sea el que
              realmente desea eliminar.
            </p>
            <br />
            {stateDelete.file != null ? (
              <div>
                {stateDelete.file.includes('https://www.youtube.com/embed/') ===
                true ? (
                  <div>
                    <iframe
                      className={cx('yt-video2')}
                      src={`${stateDelete.file}?rel=0&amp;showinfo=0&modestbranding=1&rel=0`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                ) : (
                  <img
                    src={stateDelete.file}
                    alt="imagen a eliminar"
                    className={cx('imagePreview')}
                  />
                )}
              </div>
            ) : null}
          </Modal>
        </Row>
      );
    }
  }
);
