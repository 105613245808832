/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state } from 'cerebral';
import { Table, Tooltip, Icon } from 'antd';
import Moment from 'react-moment';
import Pagination from '@component/Elements/Pagination';

const styles = {
  img: {
    width: 'auto',
    height: '2rem',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  principal: {
    color: 'green'
  }
};

export default connect(
  {
    // states
    list: state.brands.list
  },
  class BrandListView extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
      const { list } = this.props;
      const columns = [
        {
          title: 'IMAGEN',
          dataIndex: 'url_photo',
          key: 'url_photo',
          render: (text, element, index) => (
            <img src={text} alt="Imagen" style={styles.img} />
          )
        },
        {
          title: 'NOMBRE',
          dataIndex: 'name',
          key: 'name',
          render: (text, element, index) => (
            <a href={`/marcas/${list[index].id}`}>{text}</a>
          )
        },
        {
          title: 'STATUS',
          dataIndex: 'active',
          key: 'active',
          render: text => <span>{text === 1 ? 'ACTIVO' : 'INACTIVO'}</span>
        },
        {
          title: 'FECHA CREACIÓN',
          dataIndex: 'created_at',
          key: 'created_at',
          render: text => <Moment format="DD-MM-YYYY hh:mm A">{text}</Moment>
        },
        {
          title: 'CONFIGURACION EXTRA',
          dataIndex: 'id',
          key: 'id',
          render: (text, element, index) => (
            <div>
              <Tooltip title="Ver filtros" placement="top">
                <a
                  className="ant-btn ant-btn-primary ant-btn-icon-only"
                  href={`/marcas/${text}/filtros`}
                  style={{marginRight:5}}
                >
                  <Icon type="filter" />
                </a>
              </Tooltip>
              <Tooltip title="Banners home" placement="top">
                <a
                  className="ant-btn ant-btn-primary ant-btn-icon-only"
                  href={`/marcas/${text}/banners`}
                   style={{marginRight:5}}
                >
                  <Icon type="picture" />
                </a>
              </Tooltip>
              <Tooltip title="Banner secundario" placement="top">
                <a
                  className="ant-btn ant-btn-primary ant-btn-icon-only"
                  href={`/marcas/${text}/banner-secundario`}
                   style={{marginRight:5}}
                >
                  <Icon type="file-image" />
                </a>
              </Tooltip>
            </div>
          )
        }
      ];
      return (
        <div>
          <div className="move-content-right">
            <Tooltip title="Crear marca" placement="left">
              <a
                href="/marcas/nuevo"
                className="ant-btn ant-btn-primary ant-btn-icon-only"
                rel="noopener noreferrer"
              >
                <Icon type="plus" />
              </a>
            </Tooltip>
          </div>
          <Table
            bordered
            size="small"
            dataSource={list}
            columns={columns}
            pagination={false}
            scroll={{ x: 'max-content' }}
          />
          <Pagination list="brands.list" apiUrl="admin/brands" />
        </div>
      );
    }
  }
);
