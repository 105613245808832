import React, { Component } from 'react';
import Moment from 'react-moment';
import {
  Row,
  Col,
  Tooltip,
  Button,
  Table,
  Icon,
  Modal,
} from 'antd';

const style = {
  siderbarRight: {
    backgroundColor: '#f1f2f6',
    borderRadius: 5,
    padding: '2rem 1rem',
    height: '97%'
  },
  label: {
    marginBottom: 5
  },
  subtitle: {
    margin: '1em auto 5px',
    display: 'block',
    fontSize: '15px',
  }
};

export default class TicketsSoldsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment_event: null,
      view: false,
    };

    this.setByView      = this.setByView.bind(this);
    this.setMoneyFormat = this.setMoneyFormat.bind(this);
    this.handleExcel    = this.handleExcel.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  setByView(data) {
    this.setState({
      payment_event: data,
      view: true,
    });
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  setMoneyFormat(value) {
    const options1 = { style: 'currency', currency: 'MXN' };
    const moneyFormat = new Intl.NumberFormat('es-MX', options1);
    return moneyFormat.format(value);
  }

  handleExcel(e) {
    e.preventDefault();
    if (!this.props.state_reports.export_in_process) {
      const { show, submitExcel } = this.props;
      let values = {
        event_id: show.id,
        slug: show.slug
      };
      submitExcel({values});
    }
  }

  render() {
    const {
      disabledTableTicketsSolds,
      show,
      state_reports,
      sold_refund,
      state_modal_tickets_notification,

      handleOpenEventTicketsNotificationModal,
      handleCloseEventTicketsNotificationModal,
      sendTicketsNotification,

      handleShowModalSoldTicketRefund,
      handleCloseModalSoldTicketRefund,
      payRefundTicket,
    } = this.props;

    const {
      view,
      payment_event,
    } = this.state;

    const columns = [
      {
        title: 'ACCIONES',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        render: (text, element, index) => (
          <div>
            <Tooltip title="Ver detalle de venta" placement="top">
              <Button
                type="primary"
                icon="eye"
                style={{ marginLeft: 10, display: (show.archived != 1 ? 'inline-block' : 'none') }}
                onClick={() => {
                  this.setByView(element);
                }}
              />
            </Tooltip>
          </div>
        )
      },
      {
        title: 'Cliente',
        dataIndex: 'full_name',
        key: 'ticket_sold_full_name',
        width: 300,
        render: (text, element, index) => (
          <span>{text}</span>
        )
      },
      {
        title: 'Boletos',
        dataIndex: 'details_count',
        key: 'ticket_sold_details_count',
        width: 250,
        render: (text, element, index) => (
          <span>
            {text} {(parseInt(text) > 1 ? 'boletos' : 'boleto' )} { element.details.length > 0 ? ('tipo "' + element.details[0].type + '"') : ''}
            { element.payment_status == 'pending_refund_ticket_change' ? <Icon style={{color: 'red', marginLeft: '1em'}} type="warning" title="Pendiente de rembolso" />  : null} 
            </span>
        )
      },
      {
        title: 'Asientos',
        dataIndex: 'spots_labels',
        key: 'ticket_sold_details_spots_labels',
        width: 250,
        render: (text, element, index) => (
          <span>
            { text.length > 0 ? text.join(', ') : text } 
          </span>
        )
      },
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'ticket_sold_total',
        width: 150,
        render: (text, element, index) => (
          <span>
            { element.coupon_code ? <Icon title="Cupón aplicado" style={{color: '#eb0089', marginRight: '1em'}} type="tag" />  : null} 
            { this.setMoneyFormat(text) } 
          </span>
        )
      },
      {
        title: 'Fecha de compra',
        dataIndex: 'payment_date',
        key: 'payment_date',
        width: 300,
        render: (text, element, index) => (
          <Moment format="DD-MM-YYYY hh:mm A">{element.payment_date}</Moment>
        )
      },
    ];

    return (
      <Row gutter={16} type="flex">

        <Col xs={17} sm={17} md={17}>
          <Button
            type="excel"
            style={{ display: 'inline-block' }}
            onClick={this.handleExcel}
              disabled={state_reports.export_in_process}
            >
            {false ? 'DESCARGANDO' : 'EXPORTAR A EXCEL'}
          </Button>
          <Tooltip title="Reenviar correo de boletos y asientos" placement="left">
            <Button
              style={{ width: 'auto', paddingRight: '10px', float: 'right'}}
              onClick={() => { handleOpenEventTicketsNotificationModal({'id': show.id}) } }
              className="ant-btn ant-btn-primary ant-btn-icon-only"
              rel="noopener noreferrer"
            >
              <Icon type="mail" style={{ marginLeft: 10, marginRight: 10 }} />
              Reenviar boletos y asientos
            </Button>
          </Tooltip>
        </Col>

        <Col xs={24} sm={17} md={17}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24}>
              <Table
                rowKey="solds-table"
                style={{ marginTop: 20, marginBottom: 10 }}
                columns={columns}
                dataSource={show.payments_payeds}
                bordered
                pagination={false}
                loading={disabledTableTicketsSolds}
                scroll={{ x: 'max-content' }}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={7} md={7}>
          <div style={style.siderbarRight}>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24}>
                <p style={{ textAlign: 'center' }}>
                  <h4>DETALLE DE VENTA</h4>
                </p>
              </Col>
              { view === true ? (

                <div>
                  <Col xs={24} sm={24} md={24}>
                    <strong style={style.subtitle}>DATOS DEL CLIENTE</strong>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <b style={style.label}>Nombre</b>
                    <p style={style.label}>{payment_event.full_name}</p>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <b style={style.label}>Contacto</b>
                    <p style={style.label}>Correo: {payment_event.email}</p>
                    <p style={style.label}>Teléfono: {payment_event.phone}</p>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <strong style={style.subtitle}>DATOS DEL BOLETO</strong>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <b style={style.label}>Boletos tipo {payment_event.details.length > 0 ? payment_event.details[0].type : ''}</b>
                    <p style={style.label}>Cantidad: {payment_event.quantity}</p>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <b style={style.label}>Códigos de boletos</b>
                    {payment_event.details.map(detail => (
                      <p style={style.label}>.- Boleto {payment_event.details.length > 0 ? payment_event.details[0].type : ''}: {detail.uuid} {detail.spot ? (<span><b>Asiento:</b> {detail.spot.title}</span> ) : null}</p>
                    ))}
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <b style={style.label}>Datos Odoo</b>
                    <p style={style.label}>Folio: {payment_event.folio_odoo ? payment_event.folio_odoo : 'N/D'}</p>
                    <p style={style.label}>Cliente: {payment_event.client_odoo_id ? payment_event.client_odoo_id : 'N/D'}</p>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <strong style={style.subtitle}>DETALLE DE COMPRA</strong>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    { payment_event.covid_notification == 1 ? (<p style={{color: '#eb0089', 'marginBottom': '5px'}}><Icon type="mail" /> Correo de notificacion Covid 19 enviado</p>) : null }
                    <p style={style.label}>Evento: <b>{show.title}</b></p>
                    <p style={style.label}>Método de pago: {payment_event.order_payment ? payment_event.order_payment.name : ''}</p>
                    <p style={style.label}>últimos dígitos de tarjeta: {payment_event.card_last4}</p>
                    { payment_event.coupon_code ? (<p style={style.label}><Icon title="Cupón aplicado" style={{color: '#eb0089', marginRight: '1em'}} type="tag" />Cupón aplicado: {payment_event.coupon_code}</p>) : null} 
                    { payment_event.coupon_code ? (<p style={style.label}>Descuento aplicado: { this.setMoneyFormat(payment_event.coupon_discount) }</p>) : null}
                    { ['pending_refund_ticket_change', 'refund'].indexOf(payment_event.payment_status) >= 0 ? (<p style={{color: (payment_event.pending_refund > 0 ? '#deb300' : ''), 'marginBottom': '5px'}}>Pendiente de rembolso: {this.setMoneyFormat(payment_event.pending_refund)}</p>) : null }
                    <a href={payment_event.transaction_url} style={style.label} target="_blank">Ver detalle de transacción</a>
                  </Col>
                  <Col xs={24} sm={24} md={24} style={{ marginBottom: 10 }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      { payment_event.payment_status == 'pending_refund_ticket_change' ? (
                        <Tooltip title="Marcar como rembolsado" placement="top">
                          <Button
                            icon="transaction"
                            type="primary"
                            htmlType="submit"
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                                handleShowModalSoldTicketRefund(payment_event);
                            }}
                          />
                        </Tooltip>
                      )  : null} 
                      <Tooltip title="Cancelar" placement="top">
                        <Button
                          icon="close-circle"
                          type="primary"
                          htmlType="submit"
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            this.setState({
                              payment_event: null,
                              view: false,
                            });
                          }}
                        />
                      </Tooltip>
                    </div>
                  </Col>
                </div>
              ) : (
                <Col xs={24} sm={24} md={24}>
                  <p>Es necesario seleccionar un boleto</p>
                </Col>
              )}
            </Row>
          </div>
        </Col>

        <Modal
          closable={false}
          visible={sold_refund.open}
          onCancel={() => {
            handleCloseModalSoldTicketRefund();
          }}
          footer={[
            <Button
              type="primary"
              onClick={() => {
                payRefundTicket();
              }}
              loading={sold_refund.loader} >
              Confirmar
            </Button>,
            <Button
              onClick={() => {
                handleCloseModalSoldTicketRefund();
              }}
            >
              Cancelar
            </Button>
          ]} >
          <h4>¿Actualizar la orden de compra como rembolsada?</h4>
        </Modal>

        <Modal
          closable={false}
          visible={state_modal_tickets_notification.open}
          onCancel={() => {
            handleCloseEventTicketsNotificationModal();
          }}
          footer={[
          <Button
              type="primary"
              onClick={() => {
                sendTicketsNotification();
              }}
              loading={state_modal_tickets_notification.loader} >
              Confirmar
            </Button>,
            <Button
              onClick={() => {
                handleCloseEventTicketsNotificationModal();
              }}
            >
              Cancelar
            </Button>
          ]}
          >
          <h4>¿Reenviar correo con boletos y asientos?</h4>
          <p> Esta acción enviará a todos los clientes de este evento un correo de notificación </p>
        </Modal>

      </Row>
    );
  }
}
