import { set } from 'cerebral/factories';
import { state, props } from 'cerebral';
import { WithRouter } from '@modules/actions';
import notify from '@utils/notify';
import page from 'page';

const StockNewRouter = WithRouter([
  set(state.app.currentPage, 'StockFormView')
]);
const saveStock = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values } = props;
    const data = new FormData();

    const file = values.file[0].originFileObj;
    data.append('file', file);

    return http
      .postFile('admin/upload/stock/products/excel', data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Información actualizada', 'success'),
      () => {
        page.show('/stock');
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];
export default {
  routes: {
    list: StockNewRouter
  },
  sequences: {
    saveStock
  }
};
