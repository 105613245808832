import { state } from 'cerebral';

export function getOrders({ http, path, get }) {
  return http
    .get(
      'admin/orders',
      {},
      {
        per_page: get(state.app.pagination.perPage),
        searchByShippingNumberOrder: get(
          state.orders.search.searchByShippingNumberOrder
        ),
        searchByNameOrder: get(state.orders.search.searchByNameOrder),
        sortOrder: get(state.orders.search.sortOrder),
        searchByStatusOrder: get(state.orders.search.searchByStatusOrder),
        searchByTotalOrder: get(state.orders.search.searchByTotalOrder),
        searchByInitDateStart: get(state.orders.search.searchByInitDateStart),
        searchByInitDateEnd: get(state.orders.search.searchByInitDateEnd)
      }
    )
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function getStatus({ http, path }) {
  return http
    .get('admin/orders-status', {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setOrders({ props, store }) {
  const { result } = props;
  store.set(state.orders.list, result.data);
}
export function setStatus({ props, store }) {
  const { result } = props;
  store.set(state.orders.status, result);
}

export function getOrderDetail({ props, http, path }) {
  const { uuid } = props.params;

  return http
    .get(`admin/orders/${uuid}`, {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}

export function setOrderDetail({ props, store }) {
  const { result } = props;
  store.set(state.orders.detail, result);
}
