import EventsCreateView from './EventsCreateView';
import EventsListArchivedsView from './EventsListArchivedsView';
import EventsListView from './EventsListView';
import EventsShowView from './EventsShowView';
import EventsTermsAndConditionsView from './EventsTermsAndConditionsView';

export default {
  EventsCreateView,
  EventsListArchivedsView,
  EventsListView,
  EventsShowView,
  EventsTermsAndConditionsView,
};
