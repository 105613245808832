/* eslint-disable no-loop-func */
import React from 'react';
import { Skeleton, Row, Col } from 'antd';

function SkeletonCustom(props) {
  const { avatar, columns, rows, gutter } = props;

  const numSpan = 24 / columns;
  const rowsArray = Array(rows).fill(rows);
  let countColumns = 1;
  const cards = [];

  while (countColumns <= columns) {
    const card = (
      <Col key={countColumns} span={numSpan}>
        {rowsArray.map((data, index) => (
          <Skeleton
            active
            avatar={avatar}
            // eslint-disable-next-line react/no-array-index-key
            key={`skeleton-${index}-${countColumns}`}
          />
        ))}
      </Col>
    );
    cards.push(card);
    countColumns += 1;
  }

  return <Row gutter={gutter}>{cards.map(card => card)}</Row>;
}
SkeletonCustom.defaultProps = {
  avatar: false,
  columns: 1,
  rows: 4,
  gutter: 16
};

export default SkeletonCustom;
