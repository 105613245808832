import { set } from 'cerebral/factories'; // wait
import { state, props } from 'cerebral';
import { WithRouter, setPagination } from '@modules/actions';
import notify from '@utils/notify';
import page from 'page';
import * as actions from './actions';

const AdminListRouter = WithRouter([
  set(state.app.currentPage, 'AdminListView'),
  actions.getAdmins,
  {
    success: [setPagination, actions.setAdmins],
    error: []
  }
]);

const handleClickOpenModalAdmin = [
  ({ props, store }) => {
    store.set(state.admins.delete, {
      id: props.id,
      name: props.email,
      open: true,
      loader: false
    });
  }
];
const handleClickCloseModalAdmin = [
  ({ store }) => {
    store.set(state.admins.delete, {
      id: null,
      name: null,
      open: false,
      loader: false
    });
  }
];
const deleteAdmin = [
  ({ store }) => {
    store.set(state.admins.delete.loader, true);
  },
  ({ http, path, get }) => {
    return http
      .delete(`admin/admins/${get(state.admins.delete.id)}`, {})
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Usuario eliminado', 'success'),
      ({ store }) => {
        store.set(state.admins.delete, {
          id: null,
          name: null,
          open: false,
          loader: false
        });
        page.show('/administradores');
      }
    ],
    error: [
      ({ store }) => {
        store.set(state.admins.delete, {
          id: null,
          name: null,
          open: false,
          loader: false
        });
      },
      notify(props.message.error, 'error')
    ]
  }
];
const AdminNewRouter = WithRouter([
  set(state.app.currentPage, 'AdminFormView')
]);
const saveUserAdmin = [
  ({ props, http, path, store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path, store }) => {
    const { values } = props;
    return http
      .post('admin/admins', values)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify(props.res.msg, 'success'),
      () => {
        page.show('/administradores');
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];
const AdminFormViewUpdate = WithRouter([
  set(state.admins.detail, {}),
  set(state.app.currentPage, 'AdminFormViewUpdate'),
  actions.getAdminDetail,
  {
    success: [actions.setAdminDetail],
    error: [notify(props.message.error, 'error')]
  }
]);

const updateUserAdmin = [
  ({ props, http, path, store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path, store }) => {
    const { values } = props;
    return http
      .post(`admin/admins/update`, values)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Usuario actualizado', 'success'),
      () => {
        page.show('/administradores');
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];

const SlideListView = WithRouter([
  set(state.app.currentPage, 'SlideListView'),
  ({ http, props }) => {
    return http.get('web/slides').then(res => {
      props.slides = res;
      return props;
    });
  },
  set(state.app.slides, props.slides)
]);

const SlideFormView = WithRouter([set(state.app.currentPage, 'SlideFormView')]);

/**
 * Slides
 */
const submitSlide = [
  ({ props, http, path }) => {
    const { values, type } = props;
    const data = new FormData();

    if (type === 'video') {
      data.append('media', values.mediaYoutube);
    }
    if (type === 'image') {
      data.append('media', values.media[0].originFileObj);
      data.append('thumb', values.thumb[0].originFileObj);
    }
    data.append('type', type);
    data.append('cta', values.cta);

    return http
      .postFile('web/slides', data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Slide agregado', 'success'),
      () => {
        page.show('/slides');
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];

const removeSlide = [
  ({ http, path, props }) => {
    return http.delete(`web/slides/${props.id}`).then(res => path.success());
  },
  {
    success: [
      ({ http, props }) => {
        return http.get('web/slides').then(res => {
          props.slides = res;
          return props;
        });
      },
      set(state.app.slides, props.slides),
      notify('Slide eliminado correctamenete', 'success')
    ]
  }
];

/**
 * Books
 */
const BookListView = WithRouter([
  set(state.app.currentPage, 'BookListView'),
  ({ http, props }) => {
    return http.get('web/books').then(res => {
      props.result = res;
      return props;
    });
  },
  set(state.app.books, props.result)
]);

const removeBooks = [
  ({ http, path, props }) => {
    return http
      .delete(`web/books/${props.id}`)
      .then(res => path.success())
      .catch(res => path.error());
  },
  {
    success: [
      ({ http, props }) => {
        return http.get('web/books').then(res => {
          props.books = res;
          return props;
        });
      },
      set(state.app.books, props.books),
      notify('EL libro se ha eliminado correctamenete', 'success')
    ],
    error: [({ props }) => console.log(props)]
  }
];

const submitBook = [
  ({ props, http, path }) => {
    const { values, type } = props;
    const data = new FormData();

    data.append('name', values.name);
    data.append('cover', values.cover[0].originFileObj);
    data.append('pdf', values.pdf[0].originFileObj);
    return http
      .postFile('web/books', data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('El libro se agrego correctamente.', 'success'),
      () => {
        page.show('/books');
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];

const BookFormView = WithRouter([set(state.app.currentPage, 'BookFormView')]);

/**
 * RequestDealers
 */

const RequestDealerView = WithRouter([
  set(state.app.currentPage, 'RequestDealerView'),
  actions.getDealers,
  {
    success: [setPagination, actions.setDealers],
    error: []
  }
]);

const ContactView = WithRouter([
  set(state.app.currentPage, 'ContactView'),
  actions.getContact,
  {
    success: [setPagination, actions.setContact],
    error: []
  }
]);

/**
 * Dealers
 */

const DealersListView = WithRouter([
  set(state.app.currentPage, 'DealersList'),
  actions.getDealersNormal,
  {
    success: [setPagination, actions.setDealersNormal],
    error: []
  }
]);

const DealersFormView = WithRouter([
  set(state.app.currentPage, 'DealersFormView')
]);

const DealersUpdateView = WithRouter([
  ({ http, props }) => {
    return http.get(`web/dealers/${props.params.id}`).then(res => {
      props.values = res;
      return props;
    });
  },
  set(state.app.updateDealer, props.values),
  set(state.app.currentPage, 'DealersUpdateForm')
]);

const getSingleDealer = [
  ({ http, props }) => {
    return http.get(`web/dealers/${props.id}`).then(res => {
      props.values = res;
      return props;
    });
  },
  set(state.app.updateDealer, props.values)
];

const submitDealer = [
  ({ props, http, path }) => {
    const { values } = props;

    return http
      .post('web/dealers', values)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('El distribuidor se agrego correctamente.', 'success'),
      () => {
        page.show('/dealers');
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];

const removeDealers = [
  ({ http, path, props }) => {
    return http
      .delete(`web/dealers/${props.id}`)
      .then(res => path.success())
      .catch(res => path.error());
  },
  {
    success: [
      ({ http, props }) => {
        return http.get('web/dealers').then(res => {
          props.res = res;
          return props;
        });
      },
      set(state.app.dealers, props.res),
      notify('El distribuidor se elimino correctamente', 'success')
    ],
    error: [({ props }) => console.log(props)]
  }
];

const updateDealer = [
  ({ props, http, path }) => {
    const { values, id } = props;
    return http
      .post(`web/dealers/update/${id}`, values)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('El distribuidor se actualizo correctamente.', 'success'),
      () => {
        page.show('/dealers');
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];

const searchDealer = [
  set(state.app.isSearching, true),
  ({ http, props, store }) => {
    const { value, key } = props;
    store.set(state.app.dealersSearch[key], value);
  },
  actions.getDealersNormal,
  {
    success: [
      setPagination,
      actions.setDealersNormal,
      set(state.app.isSearching, false)
    ],
    error: []
  }
];

export default {
  routes: {
    list: AdminListRouter,
    new: AdminNewRouter,
    update: AdminFormViewUpdate,
    SlideListView,
    SlideFormView,
    BookListView,
    BookFormView,
    DealersListView,
    DealersFormView,
    DealersUpdateView,
    RequestDealerView,
    ContactView
  },
  sequences: {
    saveUserAdmin,
    handleClickOpenModalAdmin,
    handleClickCloseModalAdmin,
    deleteAdmin,
    updateUserAdmin,
    submitSlide,
    removeSlide,
    removeBooks,
    submitBook,
    submitDealer,
    removeDealers,
    searchDealer,
    updateDealer,
    getSingleDealer
  }
};
