import { set } from 'cerebral/factories';
import { state, props } from 'cerebral';
import { WithRouter } from '@modules/actions';
import notify from '@utils/notify';
import page from 'page';
import * as actions from './actions';

const ProfileRouter = WithRouter([
  actions.getUserUuid,
  {
    success: [
      set(state.user.payload, props.user),
      ({ props }) => {
        localStorage.setItem('currentUser', JSON.stringify(props.user));
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  set(state.app.currentPage, 'ProfileView')
]);

const updateProfile = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  actions.updateUser,
  {
    success: [
      notify('Usuario actualizado', 'success'),
      set(state.user.payload, props.res.user),
      ({ props }) => {
        localStorage.setItem('currentUser', JSON.stringify(props.res.user));
        page.show('/perfil');
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];
const ProfileDetailRouter = WithRouter([
  set(state.app.currentPage, 'ProfileDetailView')
]);
export default {
  routes: {
    list: ProfileRouter,
    detail: ProfileDetailRouter
  },
  sequences: {
    updateProfile
  }
};
