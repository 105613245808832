export default function(target) {
  function unset(context) {
    if (!context.resolve.isTag(target, 'state', 'moduleState')) {
      throw new Error(
        'Cerebral factory.unset: You have to use the STATE or MODULESTATE tag as first argument'
      )
    }

    context.store.unset(target)
  }

  unset.displayName = `factory.unset(${String(target)})`

  return unset
}
