import { set } from 'cerebral/factories'; // wait
import { state, props } from 'cerebral';
import { WithRouter, setPagination } from '@modules/actions';
import * as actions from './actions';
import notify from '@utils/notify';
import page from 'page';

const ConfigurationsListRouter = WithRouter([

  set(state.app.currentPage, 'ConfigurationListView'),
  
  actions.getConfigurations, {
    success: [setPagination, actions.setConfigurations],
    error: []
  }

]);

const ConfigurationUpdateRouter = WithRouter([
  
  set(state.configurations.show, {}),
  set(state.app.currentPage, 'ConfigurationShowView'),

  actions.getConfiguration, {
    success: [actions.setConfiguration],
    error: [notify(props.message.error, 'error')]
  },
]);

// sequenses

const update = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values, id } = props;
    return http
      .post(`admin/configurations/${id}`, values)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Configuración actualizada', 'success'),
      () => {
        page.show('/configuraciones');
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];

export default {

  routes: {
    list: ConfigurationsListRouter,
    edit: ConfigurationUpdateRouter,
  },

  sequences: {
    update,
  }
};