/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Table, Tooltip, Icon, Button, Modal } from 'antd';
import Moment from 'react-moment';
import Pagination from '@component/Elements/Pagination';

const styles = {
  img: {
    width: 'auto',
    height: '2rem',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  principal: {
    color: 'green'
  }
};

export default connect(
  {
    // states
    list: state.events_archiveds.list,
    stateArchive: state.events_archiveds.archive,

    dearchiveEvent: sequences.dearchiveEvent,
    handleClickOpenArchivedEventModal: sequences.handleClickOpenArchivedEventModal,
    handleClickCloseArchivedEventModal: sequences.handleClickCloseArchivedEventModal,

  },
  class EventsListArchivedsView extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
      const {
        list,
        stateArchive,

        dearchiveEvent,
        handleClickOpenArchivedEventModal,
        handleClickCloseArchivedEventModal,
      } = this.props;
      const columns = [
        {
          title: 'TÍTULO',
          dataIndex: 'media_title.url',
          key: 'media_title.url',
          render: (text, element, index) => (
            <img src={text} alt="Imagen" style={styles.img} />
          )
        },
        {
          title: 'NOMBRE',
          dataIndex: 'title',
          key: 'title',
          render: (text, element, index) => (
            <a href={`/eventos/${element.slug}/${element.id}`}>{text}</a>
          )
        },
        {
          title: 'Pedidos',
          dataIndex: 'payments_count',
          key: 'payments_count',
          render: text => <span>{text}</span>
        },
        {
          title: 'FECHA DE EVENTO',
          dataIndex: 'start_at',
          key: 'start_at',
          render: text => <Moment format="DD-MM-YYYY hh:mm A">{text}</Moment>
        },
        {
          title: 'ACCIONES',
          dataIndex: 'id',
          key: 'id',
          render: (text, element, index) => (
            <Tooltip title="Sacar de archivados" placement="top">
              <Button
                icon="plus"
                onClick={() => {
                  handleClickOpenArchivedEventModal( element );
                }}
              />
            </Tooltip>
          )
        }
      ];
      return (
        <div>
          <Modal
            closable={false}
            visible={stateArchive.open}
            onCancel={() => {
              handleClickCloseArchivedEventModal();
            }}
            footer={[
              <Button
                type="primary"
                onClick={() => {
                  dearchiveEvent();
                }}
                loading={stateArchive.loader} >
                Desarchivar
              </Button>,
              <Button
                onClick={() => {
                  handleClickCloseArchivedEventModal();
                }}
              >
                Cancelar
              </Button>
            ]} >
            <h4>¿Desea regresar al listado el evento '{stateArchive.name}' ?</h4>
            <p>
              Favor de verificar que el evento seleccionado sea el que
              realmente desea regresar.
            </p>
          </Modal>
          <Table
            bordered
            size="small"
            dataSource={list}
            columns={columns}
            pagination={false}
            scroll={{ x: 'max-content' }}
          />
          <Pagination list="events_archiveds.list" apiUrl="admin/events-archiveds" />
        </div>
      );
    }
  }
);
