import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state } from 'cerebral';
import { Spin, Row, Col, Card, Avatar, Tooltip, Icon, Divider } from 'antd';
import Moment from 'react-moment';
import classNames from 'classnames/bind';
import styles from './style.css';

const cx = classNames.bind(styles);

export default connect(
  {
    // states
    isLogin: state.user.isLogin,
    detail: state.user.payload
  },
  class ProfileDetailView extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
      const { isLogin, detail } = this.props;
      return (
        <div>
          {isLogin === true ? (
            <Row gutter={32} type="flex">
              <Col xs={24} sm={5} md={5}>
                <Card
                  style={{ width: 300 ,maxWidth:'100%'}}
                  className={cx('user-profile-card')}
                >
                  <Avatar size={100} src={detail.photo}/>
                  <Divider />
                  <p className={cx('format')}>Dirección de correo electrónico:</p>
                  <p >{detail.email}</p>
                </Card>
              </Col>
              <Col  className={cx('user-profile-info')}xs={24} sm={5} md={19}>
                <p>
                  <strong>Registrado desde:</strong>{' '}
                  <Moment format="DD/MM/YYYY">{detail.created_at}</Moment>
                </p>
                <p>
                  <strong>Nombre: </strong>
                  {detail.first_name}
                </p>
                <p>
                  <strong>Apellido: </strong>
                  {detail.last_name}
                </p>
                <p>
                  <strong>Número de teléfono: </strong>
                  {detail.phone}
                </p>
                <Divider />
                  <a
                    href={`/perfil/${detail.uuid}`}
                    rel="noopener noreferrer"
                  >
                    Editar perfil <Icon type="edit" />
                  </a>
                <Divider />
              </Col>
            </Row>
          ) : (
            <Spin />
          )}
        </div>
      );
    }
  }
);
