import React, { Component } from 'react';
import productWhitoutImage from '../../../../assets/product_whitout_image.png';
import Pagination from '@component/Elements/Pagination';
import Moment from 'react-moment';
import debounce from 'lodash/debounce';
import { state, sequences } from 'cerebral';
import { formatNumberAmount } from '@utils';
import { connect } from '@cerebral/react';
import 'react-quill/dist/quill.snow.css';

import {
  Table,
  Row,
  InputNumber,
  Form,
  Col,
  Checkbox,
} from 'antd';

const IVA = 1.16;

const style = {
  imageProdcut: {
    width: '35px',
    height: '35px',
    objectFit: 'cover',
    objectPosition: 'center'
  }
};

export default connect (
  {
    // states
    syncCodes: state.syncCodes,
    loading: state.products.search.loading,
    list: state.products.list,
    extraBySearch: state.products.search,
    debounceTime: state.debounceTime,

    // sequences
    updatedSearchNameProduct: sequences.updatedSearchNameProduct,
    syncCodesProducts: sequences.syncCodesProducts,
  },

  class GeneralUpdateMultiple extends Component {
    constructor(props) {
      super(props);

      this.changePriceMoreIva = this.changePriceMoreIva.bind(this);
      this.changePrice = this.changePrice.bind(this);

      this.state = {
        price: 0,
        priceMoreIva: 0
      };
    }
  
    changePrice(value) {
      this.value = value;
      // eslint-disable-next-line no-restricted-globals
      const withIva = isNaN(parseFloat(value, 10))
        ? 0
        : parseFloat(value * IVA, 10);
      this.setState({
        // eslint-disable-next-line no-restricted-globals
        price: isNaN(parseFloat(value, 10)) ? 0 : parseFloat(value, 10),
        priceMoreIva: withIva.toFixed(2)
      });
    }
  
    changePriceMoreIva(value) {
      this.value = value;
      const { form } = this.props;
      // eslint-disable-next-line no-restricted-globals
      const withIva = isNaN(parseFloat(value, 10)) ? 0 : parseFloat(value, 10);
      this.setState({
        // eslint-disable-next-line no-restricted-globals
        price: isNaN(parseFloat(value, 10)) ? 0 : parseFloat(value / IVA, 10),
        priceMoreIva: withIva.toFixed(2)
      });
  
      form.setFieldsValue({
        // eslint-disable-next-line no-restricted-globals
        price: isNaN(parseFloat(value, 10)) ? 0 : parseFloat(value / IVA, 10)
      });
    }
  
    render() {
      const {
        form,
        list,
        loading,
        syncCodes,
        syncCodesProducts,
      } = this.props;

      const { price, priceMoreIva } = this.state;
      const { getFieldDecorator } = form;
      const FormItem = Form.Item;

      const columns = [
        {
          title: 'Edición múltiple',
          dataIndex: 'multiple-update',
          key: 'multiple-update',
          render: (text, element, index) => (
            <Checkbox value={list[index].id}>
              <span className="test"></span>
            </Checkbox>
          )
        },
        {
          title: 'NOMBRE',
          dataIndex: 'name',
          key: 'name',
          render: (text, element, index) => (
            <a href={`/productos/${list[index].uuid}`}>{text}</a>
          )
        },
        {
          title: 'REFERENCIA',
          dataIndex: 'reference',
          key: 'reference',
          render: (text, element, index) => (
            <span>
              {list[index].type === 'REGULAR' ? (
                <span>{text}</span>
              ) : (
                <span>N/A</span>
              )}
            </span>
          )
        },
        {
          title: 'PRECIO',
          dataIndex: 'price',
          key: 'price',
          render: text => (
            <span>${formatNumberAmount(text, '.', '.', ',')}</span>
          )
        },
        {
          title: 'PRECIO +  IVA',
          dataIndex: 'price_tax',
          key: 'price_tax',
          render: text => (
            <span>${formatNumberAmount(text, '.', '.', ',')}</span>
          )
        },
        {
          title: 'VISIBLE',
          dataIndex: 'show',
          key: 'show',
          render: text => <span>{text === 1 ? 'SI' : 'NO'}</span>
        },
        {
          title: 'TIPO',
          dataIndex: 'type',
          key: 'type',
          render: text => (
            <span>{text === 'REGULAR' ? 'REGULAR' : 'COMBINACIÓN'}</span>
          )
        },
      ];
  
      return (
        <Row gutter={16} type="flex">
          <Col xs={24} sm={10} md={10}>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24}>
                <Row>
                  <Col xs={12} sm={6} md={6}>
                    <FormItem label="Precio Base">
                      {getFieldDecorator('price', {
                        rules: [
                          {
                            required: true,
                            message: 'Por favor ingresa un precio base'
                          }
                        ],
                        initialValue: price
                      })(
                        <InputNumber
                          placeholder="Precio"
                          step={0.1}
                          precision={6}
                          style={{ width: '94%', marginRight: 5 }}
                          onChange={this.changePrice}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <FormItem label="Precio + IVA">
                      <InputNumber
                        placeholder="Precio"
                        step={0.1}
                        precision={6}
                        style={{ width: '94%', marginLeft: 5 }}
                        value={priceMoreIva}
                        onChange={this.changePriceMoreIva}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={14} md={14}>
            <div style={style.siderbarRight}>
              <Table
                loading={loading}
                bordered
                size="small"
                dataSource={list}
                columns={columns}
                pagination={false}
                scroll={{ x: 'max-content' }}
              />
            </div>
          </Col>
        </Row>
      );
    }
    
  }
)