import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { sequences, state } from 'cerebral';
import {
  Row,
  Col,
  Button,
  Icon,
  Form,
  Input,
  Select,
  Tabs
} from 'antd';
import Gallery from './gallery';
import mexico from '@utils/mexico-states';

const { Option } = Select;
const { TabPane } = Tabs;

const DealerConnect = connect(
  {
    initialData: state.app.updateDealer,
    submit: sequences.updateDealer,
    getSingle: sequences.getSingleDealer
  },
  class DealersUpdateForm extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isSubmit: false
      };
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
      e.preventDefault();
      this.setState({ isSubmit: true });
      const { form, submit, initialData } = this.props;
      form.validateFields((err, values) => {
        if (!err) {
          console.log('estos son los valores', values);
          submit({ values, id: initialData.id });
        } else {
          this.setState({ isSubmit: false });
        }
      });
    }

    render() {
      const { form, initialData, getSingle } = this.props;
      const { isSubmit } = this.state;
      const FormItem = Form.Item;
      const { getFieldDecorator } = form;
      return (
        <Tabs onChange={this.onChangeTab} defaultActiveKey={"general"}>
          <TabPane tab="General" key="general">
            <Form onSubmit={this.handleSubmit}>
              <h3>Actualizar Distribuidor</h3>
              {/* Name  */}
              <FormItem label="Nombre">
                {getFieldDecorator('name', {
                  initialValue: initialData.name,
                  rules: [
                    {
                      required: true,
                      message: 'Este campo es requerido'
                    }
                  ]
                })(<Input placeholder="Juan Perez / La olmeca" />)}
              </FormItem>
              {/* Email  */}
              <FormItem label="Email de contacto">
                {getFieldDecorator('email', {
                  initialValue: initialData.email,
                  rules: [
                    {
                      required: true,
                      message: 'Este campo es requerido'
                    }
                  ]
                })(<Input placeholder="email@email.com" />)}
              </FormItem>
              {/* salesman  */}
          <FormItem label="Nombre del vendedor">
            {getFieldDecorator('salesman_name', {
              initialValue: initialData.salesman_name,
              rules: [
                {
                  required: true,
                  message: 'Este campo es requerido'
                }
              ]
            })(<Input placeholder="Juan Perez" />)}
          </FormItem>
              {/* Address  */}
              <FormItem label="Dirección de contacto">
                {getFieldDecorator('address', {
                  initialValue: initialData.address,
                  rules: [
                    {
                      required: true,
                      message: 'Este campo es requerido'
                    }
                  ]
                })(<Input placeholder="Gill Castellanos Diaz" />)}
              </FormItem>
              {/* Phone  */}
              <FormItem label="Telefono de contacto">
                {getFieldDecorator('phone', {
                  initialValue: initialData.phone,
                  rules: [
                    {
                      required: true,
                      message: 'Este campo es requerido'
                    }
                  ]
                })(<Input placeholder="99312230303" maxLength={10} />)}
              </FormItem>
              {/* Phone  */}
              <FormItem label="Telefono de celular">
                {getFieldDecorator('phone2', {
                  initialValue: initialData.phone2,
                  rules: [
                    {
                      required: true,
                      message: 'Este campo es requerido'
                    }
                  ]
                })(<Input placeholder="99312230303" maxLength={10} />)}
              </FormItem>
              {/* Zip Code  */}
              <FormItem label="Código Postal">
                {getFieldDecorator('zip_code', {
                  initialValue: initialData.zip_code,
                  rules: [
                    {
                      required: true,
                      message: 'Este campo es requerido'
                    }
                  ]
                })(<Input placeholder="85720" />)}
              </FormItem>
              {/* City  */}
              <FormItem label="Ciudad">
                {getFieldDecorator('city', {
                  initialValue: initialData.city,
                  rules: [
                    {
                      required: true,
                      message: 'Este campo es requerido'
                    }
                  ]
                })(<Input placeholder="Villahermosa" />)}
              </FormItem>
              {/* Estado  */}
              <FormItem label="Estado">
                {getFieldDecorator('state', {
                  initialValue: initialData.state,
                  rules: [
                    {
                      required: true,
                      message: 'Este campo es requerido'
                    }
                  ]
                })(
                  <Select placeholder="Seleccione un estado">
                    {mexico.map(el => (
                      <Option value={el.name}>{el.name}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              {/** Gallery */}
              {/** Action */}
              <Form.Item>
                <Button
                  size="large"
                  style={{ width: '200px' }}
                  type="primary"
                  htmlType="submit"
                  loading={isSubmit}
                >
                  Actualizar
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Galeria" key="gallery">
            <Gallery gallery={initialData.images} getDealer={getSingle} dealerId={initialData.id} />
          </TabPane>
        </Tabs>
      );
    }
  }
);

export default Form.create()(DealerConnect);
