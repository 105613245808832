import { extractModuleProp } from './utils'
import StateProvider from './providers/State'
import StoreProvider from './providers/Store'

class BaseModel {
  constructor(controller) {
    this.initialState = extractModuleProp(controller.module, 'state')
    this.StateProvider = StateProvider
    this.StoreProvider = StoreProvider
    this.changedPaths = []

    controller.on('moduleAdded', this.onModuleAdded.bind(this))
    controller.on('moduleRemoved', this.onModuleRemoved.bind(this))
  }

  onModuleAdded(path, module) {
    this.set(path, module.state)
  }

  onModuleRemoved(path) {
    this.unset(path)
  }

  flush() {
    const changes = this.changedPaths.slice()

    this.changedPaths = []

    return changes
  }
}

export default BaseModel
