import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Table, Row, Col, Input } from 'antd';
import Moment from 'react-moment';
import debounce from 'lodash/debounce';
import Pagination from '@component/Elements/Pagination';
import { formatNumberAmount } from '@utils';

export default connect(
  {
    // states
    list: state.campaign.list,
    extraBySearch: state.campaign.search,
    loading: state.campaign.search.loading,
    // secuences
    updatedFilterCampaign: sequences.updatedFilterCampaign,
    debounceTime: state.debounceTime
  },
  class CampaignListView extends Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.handleSearchByCode = this.handleSearchByCode.bind(this);
      this.handleSearchDebounce = debounce(
        this.handleSearchDebounce,
        props.debounceTime
      );
    }

    handleSearchByCode(e) {
      this.e = e;
      const { value } = e.target;
      this.handleSearchDebounce('code', value);
    }

    handleSearchDebounce(key, value) {
      this.key = key;
      this.value = value;
      const { updatedFilterCampaign } = this.props;
      updatedFilterCampaign({ key, value });
    }

    sumOrders(coupon) {
      this.coupon = coupon;
      let total = 0;
      if (coupon.order_coupon.length > 0) {
        coupon.order_coupon.forEach(o => {
          total += o.order.total_paid_amount;
        });
      }
      return `$${formatNumberAmount(total, '.', '.', ',')}`;
    }

    render() {
      const { list, extraBySearch, loading } = this.props;
      const columns = [
        {
          title: 'CÓDIGO',
          dataIndex: 'code',
          key: 'code'
        },
        {
          title: 'NOMBRE USUARIO',
          dataIndex: 'coupon_users',
          key: 'coupon_users',
          render: (text, element, index) => (
            <span>{list[index].coupon_users[0].user.first_name}</span>
          )
        },
        {
          title: 'EMAIL USUARIO',
          dataIndex: 'coupon_users',
          key: 'coupon_users',
          render: (text, element, index) => (
            <span>{list[index].coupon_users[0].user.email} </span>
          )
        },
        {
          title: 'LIMITE',
          dataIndex: 'units_limit',
          key: 'units_limit',
          render: text => <span>{text === null ? 'Sin limite' : text}</span>
        },
        {
          title: 'DESCUENTO',
          dataIndex: 'value',
          key: 'value'
        },
        {
          title: 'TIPO',
          dataIndex: 'type_value',
          key: 'type_value',
          render: text => <span>{text === 'PERCENT' ? '%' : '$'}</span>
        },
        {
          title: 'INICIO',
          dataIndex: 'start_date',
          key: 'start_date',
          render: text => (
            <span>
              {text != null ? (
                <Moment format="DD-MM-YYYY">{text}</Moment>
              ) : (
                'Sin limite'
              )}{' '}
            </span>
          )
        },
        {
          title: 'FIN',
          dataIndex: 'end_date',
          key: 'end_date',
          render: text => (
            <span>
              {text != null ? (
                <Moment format="DD-MM-YYYY">{text}</Moment>
              ) : (
                'Sin limite'
              )}{' '}
            </span>
          )
        },
        {
          title: 'CANJES ACTUALES',
          dataIndex: 'redeem',
          key: 'redeem'
        },
        {
          title: 'TOTAL DE ORDENES',
          dataIndex: 'id',
          key: 'sum',
          render: (text, element, index) => (
            <span>{this.sumOrders(list[index])}</span>
          )
        }
      ];

      return (
        <div>
          <Row gutter={16} style={{ marginBottom: 20 }}>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              <Input
                placeholder="Código"
                allowClear
                onChange={this.handleSearchByCode}
                style={{ width: '100%' }}
              />
            </Col>
          </Row>
          <Table
            loading={loading}
            bordered
            size="small"
            dataSource={list}
            columns={columns}
            pagination={false}
            scroll={{ x: 'max-content' }}
          />
          <Pagination
            list="campaign.list"
            apiUrl="admin/coupons-register"
            extraBySearch={extraBySearch}
          />
        </div>
      );
    }
  }
);
