import { set } from 'cerebral/factories'; // wait
import moment from 'moment';
import { state, props } from 'cerebral';
import { WithRouter, setPagination } from '@modules/actions';
import notify from '@utils/notify';
import page from 'page';
import * as actions from './actions';

const ListRouter = WithRouter([
  set(state.dealersInitial.search, {
    loading: false,
    status: '',
    city: '',
    state: ''
  }),
  set(state.app.currentPage, 'RequestDealerViewInitial'),
  actions.getDealers,
  {
    success: [setPagination, actions.setDealers],
    error: []
  }
]);

const Detail = WithRouter([
  set(state.dealersInitial.detail, {}),
  set(state.app.currentPage, 'RequestDealerDetailInitial'),

  actions.getDetail,
  {
    success: [actions.setDetail],
    error: [notify(props.message.error, 'error')]
  }
]);

const updateDealerRequestInitial = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values } = props;
    return http
      .post('web/request_dealers/update', values)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Información actualizada', 'success'),
      () => {
        page.show('/dealers-request');
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];

const updatedFilterGeneralInitial = [
  ({ store }) => {
    store.set(state.dealersInitial.search.loading, true);
  },
  ({ props, store }) => {
    const { value, key } = props;
    store.set(state.dealersInitial.search[key], value);
  },
  actions.getDealers,
  {
    success: [
      setPagination,
      actions.setDealers,
      ({ store }) => {
        store.set(state.dealersInitial.search.loading, false);
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];

export default {
  routes: {
    list: ListRouter,
    detail: Detail
  },
  sequences: {
    updateDealerRequestInitial,
    updatedFilterGeneralInitial
  }
};
