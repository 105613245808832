import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Table, Row, Col, Input, Select, Tooltip, Icon } from 'antd';
import Pagination from '@component/Elements/Pagination';
import { formatNumberAmount } from '@utils';
import Moment from 'react-moment';
import debounce from 'lodash/debounce';

export default connect(
  {
    list: state.dealersInitial.list,
    loading: state.dealersInitial.search.loading,
    extraBySearch: state.dealersInitial.search,
    debounceTime: state.debounceTime,
    updatedFilterGeneral: sequences.updatedFilterGeneralInitial
  },
  class RequestDealerViewInitial extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: true
      };

      this.changeCity = this.changeCity.bind(this);
      this.changeState = this.changeState.bind(this);
      this.changeStatus = this.changeStatus.bind(this);
      this.handleSearchDebounce = debounce(
        this.handleSearchDebounce,
        props.debounceTime
      );
    }

    changeCity(e) {
      this.e = e;
      const { value } = e.target;
      this.handleSearchDebounce('city', value);
    }
    changeState(e) {
      this.e = e;
      const { value } = e.target;
      this.handleSearchDebounce('state', value);
    }
    handleSearchDebounce(key, value) {
      this.key = key;
      this.value = value;
      const { updatedFilterGeneral } = this.props;
      updatedFilterGeneral({ key, value });
    }

    changeStatus(value) {
      this.value = value;
      const key = 'status';
      const { updatedFilterGeneral } = this.props;
      updatedFilterGeneral({ key, value });
    }

    render() {
      const { list, extraBySearch, loading } = this.props;

      const columns = [
        {
          title: 'Nombre',
          dataIndex: 'name',
          key: 'name'
          /*  render: (text, element, index) => (
            <a href={`/dealers-request/${list[index].id}`}>
              {text}
            </a>
          ) */
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email'
        },
        {
          title: 'Mensaje',
          dataIndex: 'message',
          key: 'message',
          width: 350
        },
        {
          title: 'Telefono',
          dataIndex: 'phone',
          key: 'phone'
        },
       /*  {
          title: 'Fecha de contacto',
          dataIndex: 'contact_time',
          key: 'contact_time'
        }, */
        {
          title: 'Fecha registro',
          dataIndex: 'created_at',
          key: 'created_at',
          render: text => <Moment format="DD-MM-YYYY hh:mm A">{text}</Moment>
        },
        {
          title: 'Montos de distribución',
          dataIndex: 'range',
          key: 'range'
        },
        {
          title: 'Ciudad',
          dataIndex: 'city',
          key: 'city'
        },
        {
          title: 'Estado',
          dataIndex: 'state',
          key: 'state'
        },


        /* {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (text, element, index) => {
            if (text === 'unattended') {
              return <span style={{ color: '#919191' }}>Sin atender</span>;
            }
            if (text === 'no_answer_client') {
              return <span style={{ color: 'red' }}>No contesta cliente</span>;
            }
            if (text === 'in_process') {
              return (
                <span style={{ color: '#dda603' }}>
                  En tramite / negociación
                </span>
              );
            }
            if (text === 'sold') {
              return <span style={{ color: 'green' }}>Vendido</span>;
            }
            if (text === 'old') {
              return <span>Registro anterior</span>;
            }

            return text;
          }
        },
        {
          title: 'Monto venta',
          dataIndex: 'amount',
          key: 'amount',
          render: text => (
            <span>
              {text == null || text == ''
                ? 'n/a'
                : `$${formatNumberAmount(text, '.', '.', ',')}`}
            </span>
          )
        },
        {
          title: 'Número de pedido',
          dataIndex: 'folio',
          key: 'folio',
          render: text => (
            <span>{text == null || text == '' ? 'n/a' : `${text}`}</span>
          )
        } */
      ];

      return (
        <div>
          <Row gutter={16} style={{ marginBottom: 20 }}>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              <Input
                placeholder="Ciudad"
                allowClear
                onChange={this.changeCity}
                style={{ width: '100%' }}
              />
            </Col>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              <Input
                placeholder="Estado"
                allowClear
                onChange={this.changeState}
                style={{ width: '100%' }}
              />
            </Col>
           {/*  <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              <Select
                placeholder="Estatus"
                style={{ width: '100%' }}
                onChange={this.changeStatus}
              >
                <Select.Option value="">Ninguno</Select.Option>
                <Select.Option key="status-0" value="old">
                  Registro anterior
                </Select.Option>
                <Select.Option key="status-1" value="unattended">
                  Sin atender
                </Select.Option>
                <Select.Option key="status-2" value="no_answer_client">
                  No contesta cliente
                </Select.Option>
                <Select.Option key="status-2" value="in_process">
                  En tramite / negociación
                </Select.Option>
                <Select.Option key="status-2" value="sold">
                  Vendido
                </Select.Option>
              </Select>
            </Col> */}
            <Col xs={24} sm={6} md={6}>
              <Tooltip title="Descargar excel" placement="right">
                <a
                  href={`${
                    process.env.API_URL
                  }/api/v1/admin/export-dealerInitial?${Object.keys(
                    extraBySearch
                  )
                    .map(key => `${key}=${extraBySearch[key]}`)
                    .join('&')}`}
                  target="_blank"
                  className="ant-btn ant-btn-primary ant-btn-icon-only"
                  rel="noopener noreferrer"
                >
                  <Icon type="file-excel" />
                </a>
              </Tooltip>
            </Col>
          </Row>

          <Table
            loading={loading}
            bordered
            dataSource={list}
            columns={columns}
            pagination={false}
          />
          <Pagination
            list="dealersInitial.list"
            apiUrl="web/dealers-request-initial"
            extraBySearch={extraBySearch}
          />
        </div>
      );
    }
  }
);
