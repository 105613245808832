import FunctionTree from 'function-tree'

import Module from './Module'
import DebuggerProvider from './providers/Debugger'
import GetProvider from './providers/Get'
import ModuleProvider from './providers/Module'
import {
  cleanPath,
  ensurePath,
  forceSerializable,
  getModule,
  getProviders,
  getStateChanges,
  isDeveloping,
  isObject,
  isSerializable,
  throwError,
} from './utils'

/*
  The controller is where everything is attached. The devtools
  is attached directly. Also a top level module is created.
  The controller creates the function tree that will run all sequences,
  based on top level providers and providers defined in modules
*/
class BaseController extends FunctionTree {
  constructor(rootModule, options, functionTreeOptions) {
    super({}, functionTreeOptions)
    const {
      Model,
      devtools = null,
      stateChanges = typeof window !== 'undefined' && window.CEREBRAL_STATE,
      throwToConsole = true,
      preventInitialize = false,
      returnSequencePromise = false,
      noRethrow = false,
    } = options

    const getSequence = this.getSequence
    const getSequences = this.getSequences

    this.getSequence = () => {
      throwError(
        'You are grabbing a sequence before controller has initialized, please wait for "initialized" event'
      )
    }

    this.getSequences = () => {
      throwError(
        'You are grabbing sequences before controller has initialized, please wait for "initialized" event'
      )
    }

    this.throwToConsole = throwToConsole
    this.noRethrow = noRethrow
    this.returnSequencePromise = returnSequencePromise

    this.devtools = devtools
    this.Model = Model
    this.configure(rootModule)

    if (!preventInitialize) {
      this.emit('initialized:model')
    }

    this.contextProviders = Object.assign(
      this.contextProviders,
      getProviders(this.module),
      {
        app: this,
        controller: this, // To be deprecated
        get: GetProvider,
        state: this.model.StateProvider(this.devtools),
        store:
          this.model.StoreProvider && this.model.StoreProvider(this.devtools),
        module: ModuleProvider(this.devtools),
      },
      this.devtools
        ? {
            debugger: DebuggerProvider(this.devtools),
          }
        : {}
    )

    if (stateChanges) {
      Object.keys(stateChanges).forEach((statePath) => {
        this.model.set(ensurePath(statePath), stateChanges[statePath])
      })
    }

    if (this.devtools) {
      this.devtools.init(this)
    }

    if (
      !this.devtools &&
      isDeveloping() &&
      typeof navigator !== 'undefined' &&
      /Chrome/.test(navigator.userAgent)
    ) {
      console.warn(
        'You are not using the Cerebral devtools. It is highly recommended to use it in combination with the debugger: https://cerebraljs.com/docs/introduction/debugger.html'
      )
    }

    if (isDeveloping()) {
      this.on('functionStart', (execution, functionDetails, payload) => {
        try {
          JSON.stringify(payload)
        } catch (e) {
          throwError(
            `The function ${functionDetails.name} in sequence ${execution.name} is not given a valid payload`
          )
        }
      })
      this.on(
        'functionEnd',
        (execution, functionDetails, payload, propsToAdd) => {
          if (devtools && devtools.preventPropsReplacement) {
            Object.keys(propsToAdd || {}).forEach((key) => {
              if (key in payload) {
                throw new Error(
                  `Cerebral Devtools - You have activated the "preventPropsReplacement" option and in sequence "${execution.name}", before the action "${functionDetails.name}", the key "${key}" was replaced`
                )
              }
            })
          }
        }
      )
    }

    this.getSequence = getSequence
    this.getSequences = getSequences

    if (!preventInitialize) {
      this.emit('initialized')
    }
  }

  /*
    Configures the instance
  */
  configure(rootModule) {
    this.module =
      rootModule instanceof Module
        ? rootModule.create(this, [])
        : new Module(rootModule).create(this, [])
    this.model = new this.Model(this)
  }

  /*
    Reconfigures related to hot reloading
  */
  reconfigure(rootModule) {
    if (this.devtools) {
      const existingState = this.model.get()
      this.configure(rootModule)
      const changes = getStateChanges(
        JSON.parse(this.devtools.initialModelString),
        existingState,
        this.model.get()
      )
      changes.forEach((change) => {
        this.model.set(change.path, change.value)
      })
      this.devtools.sendReInit()
      this.flush()
    }
  }

  /*
    Conveniance method for grabbing the model
  */
  getModel() {
    return this.model
  }

  /*
    Method called by view to grab state
  */
  getState(path) {
    const value = this.model.get(ensurePath(cleanPath(path)))

    if (typeof path === 'string' && path.substr(path.length - 2, 2) === '.*') {
      return value ? Object.keys(value) : []
    }

    return value
  }

  /*
    Uses function tree to run the array and optional
    payload passed in. The payload will be checkd
  */
  runSequence(name, sequence, payload = {}) {
    if (this.devtools && (!isObject(payload) || !isSerializable(payload))) {
      console.warn(
        `You passed an invalid payload to sequence "${name}". Only serializable payloads can be passed to a sequence. The payload has been ignored. This is the object:`,
        payload
      )
      payload = {}
    }

    if (this.devtools) {
      payload = Object.keys(payload).reduce((currentPayload, key) => {
        if (!isSerializable(payload[key], this.devtools.allowedTypes)) {
          console.warn(
            `You passed an invalid payload to sequence "${name}", on key "${key}". Only serializable values like Object, Array, String, Number and Boolean can be passed in. Also these special value types:`,
            this.devtools.allowedTypes
          )

          return currentPayload
        }

        currentPayload[key] = forceSerializable(payload[key])

        return currentPayload
      }, {})
    }

    const callback = (error) => {
      if (error) {
        const sequencePath = ensurePath(error.execution.name)
        const catchingResult = sequencePath.reduce(
          (details, key, index) => {
            if (details.currentModule.catch) {
              details.catchingModule = details.currentModule
            }

            details.currentModule = details.currentModule.modules[key]

            return details
          },
          {
            currentModule: this.module,
            catchingModule: null,
          }
        )

        if (catchingResult.catchingModule) {
          for (const [errorType, errorSequence] of catchingResult.catchingModule
            .catch) {
            if (error instanceof errorType) {
              this.runSequence('catch', errorSequence, error.payload)

              // Throw the error to console even if handling it
              if (this.throwToConsole) {
                setTimeout(() => {
                  console.log(
                    `Cerebral is handling error "${error.name}: ${error.message}" thrown by sequence "${error.execution.name}". Check debugger for more information.`
                  )
                })
              }

              return
            }
          }
        }

        // If we already catch errors in controller.on('error'), we might not
        // want errors to be thrown again.
        if (!this.noRethrow) {
          if (error.execution.isAsync) {
            setTimeout(() => {
              throw error
            })
          } else {
            throw error
          }
        }
      }
    }

    if (this.returnSequencePromise) {
      return this.run(name, sequence, payload).catch(callback)
    } else {
      this.run(name, sequence, payload, callback)
    }
  }

  /*
    Returns a function which binds the name/path of sequence,
    and the array. This allows view layer to just call it with
    an optional payload and it will run
  */
  getSequence(path) {
    const pathArray = ensurePath(path)
    const sequenceKey = pathArray.pop()
    const module = pathArray.reduce((currentModule, key) => {
      return currentModule ? currentModule.modules[key] : undefined
    }, this.module)
    const sequence = module && module.sequences[sequenceKey]

    if (!sequence) {
      return
    }

    return sequence && sequence.run
  }

  getSequences(modulePath) {
    const pathArray = ensurePath(modulePath)
    const module = pathArray.reduce((currentModule, key) => {
      return currentModule ? currentModule.modules[key] : undefined
    }, this.module)

    const sequences = module && module.sequences

    if (!sequences) {
      return undefined
    }

    const callableSequences = {}
    for (const name in sequences) {
      callableSequences[name] = sequences[name].run
    }

    return callableSequences
  }

  addModule(path, module) {
    const pathArray = ensurePath(path)
    const moduleKey = pathArray.pop()
    const parentModule = getModule(pathArray, this.module)
    const newModule =
      module instanceof Module
        ? module.create(this, ensurePath(path))
        : new Module(module).create(this, ensurePath(path))
    parentModule.modules[moduleKey] = newModule

    if (newModule.providers) {
      Object.assign(this.contextProviders, newModule.providers)
    }

    this.emit('moduleAdded', path.split('.'), newModule)

    this.flush()
  }

  removeModule(path) {
    if (!path) {
      console.warn('Controller.removeModule requires a Module Path')
      return null
    }

    const pathArray = ensurePath(path)
    const moduleKey = pathArray.pop()
    const parentModule = getModule(pathArray, this.module)

    const module = parentModule.modules[moduleKey]

    if (module.providers) {
      Object.keys(module.providers).forEach((provider) => {
        delete this.contextProviders[provider]
      })
    }

    delete parentModule.modules[moduleKey]

    this.emit('moduleRemoved', ensurePath(path), module)

    this.flush()
  }
}

export default BaseController
