import React, { Component } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Tooltip,
  Button,
  Table,
  Modal,
  Badge,
  InputNumber,
  Select,
} from 'antd';

const style = {
  siderbarRight: {
    backgroundColor: '#f1f2f6',
    borderRadius: 5,
    padding: '2rem 1rem',
    height: '97%'
  },
  label: {
    marginBottom: 5
  },
  labelFirst: {
    marginBottom: 5
  },
  unsigned: {
    color:  '#EB0089',
    marginBottom: 5
  },
  subtitle: {
    margin: '0 auto 1em',
    display: 'block',
    fontSize: '15px',
  }
};

export default class SpotsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      create_type_method: 1,

      number_rows: 1,
      row_label: '',

      asign_rows_ticket: null,
      asign_rows_apply: '0',

      asign_column_ticket: null
    };

    this.onChangeCreateTypeMethod = this.onChangeCreateTypeMethod.bind(this)
    this.onChangespotsQuantity  = this.onChangespotsQuantity.bind(this)

    this.onChangespotsLabel     = this.onChangespotsLabel.bind(this)
    this.onChangespotsLabelSelect     = this.onChangespotsLabelSelect.bind(this)
    

    this.onChangespotsAsignTicket = this.onChangespotsAsignTicket.bind(this)
    this.onChangespotsAsignApply  = this.onChangespotsAsignApply.bind(this)

    this.onChangespotsAsignTicketColumn = this.onChangespotsAsignTicketColumn.bind(this)

    this.getTypes               = this.getTypes.bind(this);
  }

  onChangeCreateTypeMethod(value) {
    this.setState({
      create_type_method: value,
      row_label: ''
    });
  }

  onChangespotsQuantity(value) {
    this.setState({
      number_rows: value
    });
  }

  onChangespotsAsignTicket(value) {
    this.setState({
      asign_rows_ticket: value
    });
  }

  onChangespotsAsignTicketColumn(value) {
    this.setState({
      asign_column_ticket: value
    });
  }

  onChangespotsAsignApply(value) {
    this.setState({
      asign_rows_apply: value
    });
  }

  onChangespotsLabel(element) {
    this.setState({
      row_label: element.currentTarget.value
    });
  }

  onChangespotsLabelSelect(value) {
    this.setState({
      row_label: value
    });
  }

  getTypes( row ) {
    const { show } = this.props
    const events_types_ids = Object.values(show.spots_matriz[row]).map( event_spot => event_spot.event_ticket_id ).filter( function( item, index, inputArray ) {
      return inputArray.indexOf(item) == index;
    });

    const types = Object.values(show.tickets).filter( event_ticket => events_types_ids.indexOf(event_ticket.id) > -1 ).map( ticket => ticket.type );
    return types.length > 0 ? types.join(', ') : 'Sin asignar';
  }

  render() {
    const {
      form,
      show,

      event_spot,
      event_spot_row,
      event_spot_column,
      event_spot_delete_column,
      event_spot_delete_row,

      handleShowModalCreateSpotRows,
      handleCloseModalCreateSpotRows,
      submitModalSpotRows,

      handleShowModalAsignSpotRows,
      handleCloseModalAsignSpotRows,
      submitModalAsignSpot,

      handleShowModalAsignSpotColumn,
      handleCloseModalAsignSpotColumn,
      submitModalAsignSpotColumn,

      handleShowModalDeleteSpotColumn,
      handleCloseModalDeleteSpotColumn,
      submitModalDeleteSpotColumn,
      
      handleShowModalDeleteSpotRow,
      handleCloseModalDeleteSpotRow,
      submitModalDeleteSpotRow,

    } = this.props;

    const {
      create_type_method,
      
      number_rows,
      row_label,

      asign_rows_ticket,
      asign_rows_apply,

      asign_column_ticket,
    } = this.state;

    const { getFieldDecorator } = form;
    const FormItem = Form.Item;

    const columns = [
      {
        title: 'FILA',
        dataIndex: 'label',
        key: 'event_spots_label',
        render: (text, element) => (
          <div>
            <p>{element}</p>
          </div>
        )
      },
      {
        title: 'DISPONIBILIDAD',
        dataIndex: 'label',
        key: 'event_spots_avaliable',
        render: (text, element) => (
          <div>
            <p>{ Object.values(show.spots_matriz[element]).filter( column => column.enabled == 0).length < Object.values(show.spots_matriz[element]).length  ? (
              <span>
                <Badge status="success" />
                {Object.values(show.spots_matriz[element]).length - Object.values(show.spots_matriz[element]).filter( column => column.enabled == 0).length} Disponibles
                <br></br>
                { Object.values(show.spots).filter( event_spot => { return event_spot.label == element && !event_spot.event_ticket_id } ).length > 0 ?
                (
                  <span>
                    <Badge status="warning" />
                      { Object.values(show.spots).filter( event_spot => { return event_spot.label == element && !event_spot.event_ticket_id } ).length + ' Sin tipo de boleto' }
                  </span>
                )
                : '' }
              </span>
            ) : (<span>
              <Badge status="default" />
              0 Disponibles
            </span>)}</p>
          </div>
        )
      },
      {
        title: 'TIPOS DE BOLETOS',
        dataIndex: 'label',
        key: 'event_spots_type_ticket',
        render: (text, element) => (
          <div>
            { this.getTypes( element ) }
          </div>
        )
      },
      {
        title: 'ASIENTOS',
        dataIndex: 'label',
        key: 'event_spots_number',
        render: (text, element) => (
          <div>
            <p>{ Object.values(show.spots_matriz[element]).length }</p>
          </div>
        )
      },
      {
        title: 'RESERVADOS',
        dataIndex: 'label',
        key: 'event_spots_number_blocks',
        render: (text, element) => (
          <div>
            { Object.values(show.spots_matriz[element]).filter( column => column.enabled == 0).length }
          </div>
        )
      },
        {
          title: 'ACCIONES',
          dataIndex: 'id',
          key: 'id',
          width: 150,
          render: (text, element) => (
            <div>
              <Tooltip title="Asignar tipo de boleto para esta fila" placement="top">
                <Button
                  type="primary"
                  icon="file-protect"
                  style={{ marginLeft: 10, display: (show.archived != 1 ? 'inline-block' : 'none') }}
                  onClick={() => {
                    handleShowModalAsignSpotRows({ 'label': element });
                  }}
                />
              </Tooltip>
              {
                Object.values(show.spots_matriz[element]).length > Object.values(show.spots_matriz[element]).filter( column => column.enabled == 0).length ?
                (<Tooltip title="Eliminar asientos disponibles" placement="top">
                <Button
                  type="primary"
                  icon="delete"
                  style={{ marginLeft: 10, display: (show.archived != 1 ? 'inline-block' : 'none') }}
                  onClick={() => {
                    handleShowModalDeleteSpotRow({ 'label': element });
                  }}
                />
              </Tooltip>)
              : null }
              
            </div>
          )
        },
    ];

    const subcolumns = [
      {
        title: 'Número de fila',
        dataIndex: 'id',
        sorter: (a, b) => (a.id - b.id),
        sortOrder: 'acends',
        key: 'event_column_spots_number',
        render: (text, element) => (
          <span>
            {element.number}
          </span>
        ),
      },
      {
        title: 'Disponibilidad',
        dataIndex: 'enabled',
        key: 'event_column_spots_enabled',
        render: (text) => (
          <span>
            <Badge status={text == 1 ? "success" : 'default'} />
            {text == 1 ? 'Disponible' : 'Bloqueado'}
          </span>
        ),
      },
      {
        title: 'Tipo de boleto',
        dataIndex: 'event_ticket_id',
        key: 'event_column_spots_event_ticket_id',
        render: (text) => (
          <span>
            { Object.values(show.tickets).filter( event_spot => event_spot.id == text ).length > 0 ?
              Object.values(show.tickets).filter( event_spot => event_spot.id == text )[0].type :
              'Sin asignar'
            }
          </span>
        ),
      },
      {
        title: 'Nombre',
        dataIndex: 'event_ticket_id',
        key: 'event_column_spots_event_detail_name',
        render: (text, element) => (
          <span>
            { element.payment_detail && element.payment_detail.payment ?
              element.payment_detail.payment.full_name :
              '-'
            }
          </span>
        ),
      },
      {
        title: 'Boleto',
        dataIndex: 'event_ticket_id',
        key: 'event_column_spots_event_detail_ticket',
        render: (text, element) => (
          <span>
            { element.payment_detail ?
              element.payment_detail.uuid :
              '-'
            }
          </span>
        ),
      },
      {
        title: 'Acciones',
        dataIndex: 'id',
        key: 'event_column_spot_id',
        width: 150,
        render: (text, element) => (
          <div>
            <Tooltip title="Cambiar tipo de boleto para este lugar" placement="top">
              <Button
                type="ghost"
                icon="undo"
                style={{ marginLeft: 10}}
                onClick={() => {
                  handleShowModalAsignSpotColumn({ 'label': element.label, 'number': element.number });
                }}
              />
            </Tooltip>
            {
              element.enabled == 1 ? (
                <Tooltip title="Bloquear lugar" placement="top">
                  <Button
                    type="ghost"
                    icon="block"
                    style={{ marginLeft: 10}}
                    onClick={() => {
                      handleShowModalDeleteSpotColumn({ 'label': element.label, 'number': element.number });
                    }}
                  />
                </Tooltip>
              ) : 
              null }
          </div>
        )
      },
    ];

    const expandedRowRender = record => <Table
      rowKey="subtable"
      pagination={false}
      columns={subcolumns}
      dataSource={Object.values(show.spots_matriz[record])}
    />;

    return (
      <Row gutter={16} type="flex">

        <Col xs={24} sm={17} md={17}>
          <Row gutter={16}>
            <Col xs={24} sm={8} md={12}>
              <Tooltip title="Agregar asientos" placement="left">
                <Button
                  icon="plus"
                  type="primary"
                  htmlType="submit"
                  loading={event_spot.loading}
                  onClick={() => {
                    handleShowModalCreateSpotRows();
                  }}
                />
              </Tooltip>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={24} md={24}>
              <Table
                rowKey="spots-table"
                style={{ marginTop: 20, marginBottom: 10 }}
                columns={columns}
                dataSource={ Object.keys(show.spots_matriz) }
                bordered
                pagination={false}
                expandedRowRender={expandedRowRender}
                expandRowByClick={true}
                scroll={{ x: 'max-content' }}
              />
            </Col>
          </Row>
        </Col>
        
        <Col xs={24} sm={7} md={7}>
          <div style={style.siderbarRight}>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24}>
                <strong style={style.subtitle}>DETALLES GENERALES</strong>
              </Col>
              { Object.values(show.tickets).map( ticket => (
                  <Col xs={24} sm={12} md={12} style={{paddingBottom:'10px'}} key={'ticket-asign-' + ticket.id}>
                    <b style={style.labelFirst}>Boleto tipo {ticket.type}</b>
                    <p style={style.label}>- Lugares asignados: {ticket.spot_count}</p>
                    <p style={style.label}>- Lugares bloqueados: {ticket.spot_disabled_count}</p>
                    <p style={style.label}>- Lugares disponibles: {ticket.spot_enabled_count}</p>
                  </Col>
                )
              )}
              <Col xs={24} sm={24} md={24}>
                <b style={style.unsigned}>Total lugares asignados a boletos</b>
                <p style={style.label}>- Lugares asignados: { Object.values(show.tickets).reduce( (partial_sum, ticket) => partial_sum + ticket.spot_count, 0) }</p>
                <p style={style.label}>- Lugares bloqueados: { Object.values(show.tickets).reduce( (partial_sum, ticket) => partial_sum + ticket.spot_disabled_count, 0) }</p>
                <p style={style.label}>- Lugares disponibles: { Object.values(show.tickets).reduce( (partial_sum, ticket) => partial_sum + ticket.spot_enabled_count, 0) }</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Modal
          closable={false}
          visible={event_spot.open}
          onCancel={() => {
            handleCloseModalCreateSpotRows();
          }}
          footer={[
          <Button
              type="primary"
              onClick={() => {
                submitModalSpotRows({
                  'row_label': row_label,
                  'number_rows': number_rows,
                });
              }}
              loading={event_spot.loader} >
              Confirmar
            </Button>,
            <Button
              onClick={() => {
                handleCloseModalCreateSpotRows();
              }}
            >
              Cancelar
            </Button>
          ]}
          >
          <h4>Crear asientos</h4>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24}>
              <FormItem label="Tipo de creación">
                <Select placeholder="Por favor seleccione una opción" value={create_type_method} style={{ width: '100%' }} onChange={this.onChangeCreateTypeMethod} >
                  <Select.Option key='show-ticket-unsigned' value={0}>
                    Agregar asientos en fila existente
                  </Select.Option>
                  <Select.Option key='show-ticket-unsigned' value={1}>
                    Crear nueva fila
                  </Select.Option>
                </Select>
              </FormItem>
            </Col>

            { create_type_method == 0 ? (
              <Col xs={24} sm={24} md={24}>
                <FormItem label="Seleccionar fila">
                  <Select placeholder="Por favor seleccione una opción" style={{ width: '100%' }} onChange={this.onChangespotsLabelSelect}>
                    { Object.keys(show.spots_matriz).map(rows => 
                      (
                        <Select.Option key='show-ticket-unsigned' value={rows}>
                          Fila {rows}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </FormItem>
              </Col>
            ) : null}
            
            <Col xs={24} sm={24} md={24}>
              <FormItem label="Número de asientos">
                <InputNumber
                  placeholder="Ejemplo: 10"
                  step={0.1}
                  precision={0}
                  value={number_rows}
                  onChange={this.onChangespotsQuantity}
                  style={{ width: '100%', marginBottom: 10 }}
                />
              </FormItem>
            </Col>
          </Row>
        </Modal>

        <Modal
          closable={false}
          visible={event_spot_row.open}
          onCancel={() => {
            handleCloseModalAsignSpotRows();
          }}
          footer={[
          <Button
              type="primary"
              onClick={() => {
                submitModalAsignSpot({
                  'event_ticket_id': asign_rows_ticket,
                  'apply_all': asign_rows_apply,
                });
              }}
              loading={event_spot_row.loader} >
              Confirmar
            </Button>,
            <Button
              onClick={() => {
                handleCloseModalAsignSpotRows();
              }}
            >
              Cancelar
            </Button>
          ]}
          >
          <h4>Asignar fila a un tipo de boleto</h4>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>

              <FormItem label="Tipo de boleto">
                <Select placeholder="Por favor seleccione una opción" style={{ width: '100%' }} onChange={this.onChangespotsAsignTicket} >
                  <Select.Option key='show-ticket-unsigned' value={0}>
                    Sin asignar
                  </Select.Option>
                  { Object.values(show.tickets).map( ticket => (
                      <Select.Option key={'show-ticket-type-' + ticket.id} value={ticket.id}>
                        {ticket.type}
                      </Select.Option>
                    )
                  )}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} sm={12} md={12}>
              <FormItem label="Número de asientos">
                <Select placeholder="Por favor seleccione una opción" style={{ width: '100%' }} value={asign_rows_apply} onChange={this.onChangespotsAsignApply}>
                  <Select.Option key='apply_to_enableds' value='0'>
                    Aplicar solo a disponibles
                  </Select.Option>
                </Select>
              </FormItem>
            </Col>
            { asign_rows_apply == 1 ? (
              <Col xs={24} sm={24} md={24}>
                <span style={{color: '#c40079'}}> Esta acción cambiará el tipo de boleto para asientos ya vendidos.</span>
              </Col>)
            : null}
            
          </Row>
        </Modal>

        <Modal
          closable={false}
          visible={event_spot_column.open}
          onCancel={() => {
            handleCloseModalAsignSpotColumn();
          }}
          footer={[
          <Button
              type="primary"
              onClick={() => {
                submitModalAsignSpotColumn({
                  'event_ticket_id': asign_column_ticket,
                });
              }}
              loading={event_spot_column.loader} >
              Confirmar
            </Button>,
            <Button
              onClick={() => {
                handleCloseModalAsignSpotColumn();
              }}
            >
              Cancelar
            </Button>
          ]}
          >
          <h4>Asignar asiento a un tipo de boleto</h4>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24}>

              <FormItem label="Nombre de fila">
                <Select placeholder="Por favor seleccione una opción" style={{ width: '100%' }} onChange={this.onChangespotsAsignTicketColumn} >
                  <Select.Option key='show-ticket-unsigned' value={0}>
                    Sin asignar
                  </Select.Option>
                  { Object.values(show.tickets).map( ticket => (
                      <Select.Option key={'show-ticket-row-' + ticket.id} value={ticket.id}>
                        {ticket.type}
                      </Select.Option>
                    )
                  )}
                </Select>
              </FormItem>
            </Col>
            
          </Row>
        </Modal>

        <Modal
          closable={false}
          visible={event_spot_delete_row.open}
          onCancel={() => {
            handleCloseModalDeleteSpotRow();
          }}
          footer={[
          <Button
              type="primary"
              onClick={() => {
                submitModalDeleteSpotRow();
              }}
              loading={event_spot_delete_row.loader} >
              Confirmar
            </Button>,
            <Button
              onClick={() => {
                handleCloseModalDeleteSpotRow();
              }}
            >
              Cancelar
            </Button>
          ]}
          >
          <h4>Eliminar asientos disponibles de esta fila</h4>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24}>
              <p style={{marginBottom: '5px'}}>Se eliminarán todos los lugares que no han sido bloqueados u ocupados.</p>
            </Col>
          </Row>
        </Modal>

        <Modal
          closable={false}
          visible={event_spot_delete_column.open}
          onCancel={() => {
            handleCloseModalDeleteSpotColumn();
          }}
          footer={[
          <Button
              type="primary"
              onClick={() => {
                submitModalDeleteSpotColumn();
              }}
              loading={event_spot_delete_column.loader} >
              Confirmar
            </Button>,
            <Button
              onClick={() => {
                handleCloseModalDeleteSpotColumn();
              }}
            >
              Cancelar
            </Button>
          ]}
          >
          <h4>Bloquear lugar</h4>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24}>
              <p>Puedes bloquear este lugar para que no sea asignado automáticamente en la compra de un boleto</p>
            </Col>
          </Row>
        </Modal>
      </Row>
    );
  }
}
