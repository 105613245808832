import { state } from 'cerebral';

export function getTags({http, path,get}) {
  return http
    .get('admin/tags', {}, {per_page: get(state.app.pagination.perPage)})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}

export function getTagsUsers({http, path,get}) {
  return http
    .get('admin/tags-users', {}, {per_page: get(state.app.pagination.perPage)})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}

export function setTags({props, store}) {
  const { result } = props;
  store.set(state.tags.list, result.data)
}

export function setTagsUsers({props, store}) {
  const { result } = props;
  store.set(state.tags_users.list, result.data)
}

export function getTagDetail({ props, http, path }) {
  const { id } = props.params;
  return http
    .get(`admin/tags/${id}`, {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function getTagUsersDetail({ props, http, path }) {
  const { id } = props.params;
  return http
    .get(`admin/tags-users/${id}`, {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setTagDetail({ props, store }) {
  const { result } = props;
  store.set(state.tags.detail, result);
}
export function setTagUsersDetail({ props, store }) {
  const { result } = props;
  store.set(state.tags.detail, result);
  store.set(state.tags_users.detail, result);
}
