import { state } from 'cerebral';

export function getDealers({ http, path, get }) {
  return http
    .get(
      'web/dealers-request-initial',
      {},
      {
        per_page: get(state.app.pagination.perPage),
        status: get(state.dealersInitial.search.status),
        city: get(state.dealersInitial.search.city),
        state: get(state.dealersInitial.search.state)
      }
    )
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}

export function setDealers({ props, store }) {
  const { result } = props;
  store.set(state.dealersInitial.list, result.data);
}

export function getDetail({ props, http, path }) {
  const { id } = props.params;
  return http
    .get(`web/request_dealers/${id}`, {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setDetail({ props, store }) {
  const { result } = props;
  store.set(state.dealersInitial.detail, result);
}
