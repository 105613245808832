import { Tag } from '../tags'
import { isComputedValue } from '../utils'

const HELP_URL = 'http://cerebraljs.com/docs/api/factories.html#when'

function whenFactory(...args) {
  const whenFunc = args.length > 1 ? args[args.length - 1] : null
  const valueTemplates = args.length > 1 ? args.slice(0, -1) : args
  function when({ path, props, resolve }) {
    if (valueTemplates.length > 0 && !(valueTemplates[0] instanceof Tag)) {
      throw new Error(
        `Cerebral factory.when: You have to use the STATE, MODULESTATE or PROPS tag as values, see: ${HELP_URL}`
      )
    }
    if (!path || !path.true || !path.false) {
      throw new Error(
        'Cerebral factory.when: true/false paths need to be provided, see: http://cerebraljs.com/docs/api/factories.html#when'
      )
    }
    const values = valueTemplates.map((tag) => {
      const value = resolve.value(tag)
      if (isComputedValue(value)) {
        return value.getValue(props)
      } else {
        return value
      }
    })
    const isTrue = Boolean(whenFunc ? whenFunc(...values) : values[0])

    return isTrue ? path.true() : path.false()
  }

  when.displayName = `factory.when(${args
    .filter((arg) => {
      return typeof arg !== 'function'
    })
    .map((arg) => {
      return String(arg)
    })
    .join(',')})`

  return when
}

export default whenFactory
