import { notification } from 'antd';
import audio from '../assets/general1.mp3';

export default function notifyFactory(message, type = 'info') {
  function notifyAction({ resolve, get }) {
    const isPropsTag = resolve.isTag(message, 'props');
    const propsText = get(message);
    let text = message;
    if (isPropsTag) {
      text = propsText;
    }
    notification[type]({
      message: 'Notificación',
      description: text
    });
    if (process.env.SOUND_NOTIFICATION === 'true') {
      // eslint-disable-next-line no-undef
      const audioFile = new Audio(audio);
      audioFile.play();
    }
  }

  return notifyAction;
}
