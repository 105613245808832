import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Pagination as PaginationCustom } from 'antd';

export default connect(
  {
    // State
    pagination: state.app.pagination,
    // Sequences
    setPagination: sequences.submitPagination
  },
  function Pagination(props) {
    const { pagination, apiUrl, list, setPagination,extraBySearch } = props;
    return (
      <div style={{ marginTop: 20, marginBottom: 20, textAlign: 'right' }}>
        <PaginationCustom
          rowsPerPage={pagination.perPage}
          pageSize={parseInt(pagination.perPage,10)}
          pageSizeOptions={['5', '10', '20', '30', '40']}
          showSizeChanger
          onShowSizeChange={(current, pageSize) => {
             setPagination({apiUrl, list, p:current,perPage:pageSize,extraBySearch})
          }}
          onChange={(page, pageSize) => {
             setPagination({apiUrl, list, p:page,perPage:pageSize,extraBySearch})
          }}
          defaultCurrent={pagination.currentPage}
          total={pagination.total}
          showTotal={() => {
            return `Total ${pagination.total} registros`;
          }}
        />
      </div>
    );
  }
);
