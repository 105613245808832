import { state } from 'cerebral';

export function getWeb({ http, path, get }) {
  var tags = get(state.web.search.searchTagsIds)
  if ( tags && tags.length > 0 ) {
    tags = tags.join(',');
  }
  return http
    .get(
      'admin/normals',
      {},
      {
        per_page: get(state.app.pagination.perPage),
        searchByNameClient: get(state.web.search.searchByNameClient),
        searchByEmailClient: get(state.web.search.searchByEmailClient),
        searchTagsIds: tags,
      }
    )
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}

export function setWeb({ props, store }) {
  const { result } = props;
  store.set(state.web.list, result.data);
}
export function getWebDetail({ props, http, path, get }) {
  const { uuid } = props.params;
  return http
    .get(`admin/normals/${uuid}`, {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setWebDetail({ props, store }) {
  const { result } = props;
  store.set(state.web.detail, result);
}

export function getTagsSimple({ http, path }) {
  return http
    .get('admin/tags-simple/USERS/')
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setTagsSimple({ props, store }) {
  const { result } = props;
  store.set(state.web.tags, result);
}
