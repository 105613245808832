import { set } from 'cerebral/factories'; // wait
import moment from 'moment';
import { state, props } from 'cerebral';
import { WithRouter, setPagination } from '@modules/actions';
import notify from '@utils/notify';
import page from 'page';
import * as actions from './actions';

const CouponsListRouter = WithRouter([
  actions.getCoupons,
  {
    success: [setPagination, actions.setCoupons],
    error: [notify(props.message.error, 'error')]
  },
  set(state.app.currentPage, 'CouponsListView')
]);
const CampaignListRouter = WithRouter([
  actions.getCampaign,
  {
    success: [setPagination, actions.setCampaign],
    error: [notify(props.message.error, 'error')]
  },
  set(state.app.currentPage, 'CampaignListView')
]);

const CuoponsNewRouter = WithRouter([
  actions.getCategoriesSimple,
  {
    success: [actions.setCategoriesSimple],
    error: [notify(props.message.error, 'error')]
  },
  actions.getTagsSimple,
  {
    success: [setPagination, actions.setTagsSimple],
    error: [notify(props.message.error, 'error')]
  },
  actions.getUserTagsSimple,
  {
    success: [setPagination, actions.setUserTagsSimple],
    error: [notify(props.message.error, 'error')]
  },
  actions.getEventsSimple,
  {
    success: [setPagination, actions.setEventsSimple],
    error: [notify(props.message.error, 'error')]
  },
  actions.getCouponModalitySimple,
  {
    success: [setPagination, actions.setCouponModalitySimple],
    error: [notify(props.message.error, 'error')]
  },
  set(state.app.currentPage, 'CouponFormView')
]);

const CuoponsFormViewUpdate = WithRouter([
  set(state.coupons.detail, {}),
  actions.getCategoriesSimple,
  {
    success: [actions.setCategoriesSimple],
    error: [notify(props.message.error, 'error')]
  },
  actions.getTagsSimple,
  {
    success: [setPagination, actions.setTagsSimple],
    error: [notify(props.message.error, 'error')]
  },
  actions.getUserTagsSimple,
  {
    success: [setPagination, actions.setUserTagsSimple],
    error: [notify(props.message.error, 'error')]
  },
  actions.getCouponModalitySimple,
  {
    success: [setPagination, actions.setCouponModalitySimple],
    error: [notify(props.message.error, 'error')]
  },
  actions.getEventsSimple,
  {
    success: [setPagination, actions.setEventsSimple],
    error: [notify(props.message.error, 'error')]
  },
  actions.getCouponDetail,
  {
    success: [actions.setCouponDetail],
    error: [notify(props.message.error, 'error')]
  },
  set(state.app.currentPage, 'CouponUpdateFormView')
]);

const deleteCoupon = [
  ({ http, path, props }) => {
    return http
      .delete(`admin/coupons/${props.id}`, {})
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Cupon eliminado', 'success'),
      actions.getCoupons,
      {
        success: [setPagination, actions.setCoupons],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];

const updatedFilterCoupon = [
  ({ store }) => {
    store.set(state.coupons.search.loading, true);
  },
  ({ props, store }) => {
    const { value, key } = props;
    store.set(state.coupons.search[key], value);
  },
  actions.getCoupons,
  {
    success: [
      setPagination,
      actions.setCoupons,
      ({ store }) => {
        store.set(state.coupons.search.loading, false);
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];

const updatedFilterCampaign = [
  ({ store }) => {
    store.set(state.campaign.search.loading, true);
  },
  ({ props, store }) => {
    const { value, key } = props;
    store.set(state.campaign.search[key], value);
  },
  actions.getCampaign,
  {
    success: [
      setPagination,
      actions.setCampaign,
      ({ store }) => {
        store.set(state.campaign.search.loading, false);
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];

const updatedFilterCouponDatesStart = [
  ({ store }) => {
    store.set(state.coupons.search.loading, true);
  },
  ({ props, store }) => {
    store.set(
      state.coupons.search.searchByStartDate1,
      props.searchByStartDate1
    );
    store.set(
      state.coupons.search.searchByStartDate2,
      props.searchByStartDate2
    );
  },
  actions.getCoupons,
  {
    success: [
      setPagination,
      actions.setCoupons,
      ({ store }) => {
        store.set(state.coupons.search.loading, false);
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];

const updatedFilterCouponDatesEnd = [
  ({ store }) => {
    store.set(state.coupons.search.loading, true);
  },
  ({ props, store }) => {
    store.set(state.coupons.search.searchByEndDate1, props.searchByEndDate1);
    store.set(state.coupons.search.searchByEndDate2, props.searchByEndDate2);
  },
  actions.getCoupons,
  {
    success: [
      setPagination,
      actions.setCoupons,
      ({ store }) => {
        store.set(state.coupons.search.loading, false);
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];

const saveCoupon = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values } = props;
    const data = {};
    if (
      typeof values.code !== 'undefined' &&
      values.code != null &&
      values.code != ''
    ) {
      data.code = values.code;
    }
    data.applies_to_discounted_products = values.applies_to_discounted_products;
    data.apply_all_cart_products = values.apply_all_cart_products;
    data.description = values.description;
    data.name = values.name;
    data.type_value = values.type_value;
    data.unique_redeem_per_user = values.unique_redeem_per_user;
    data.value = values.value;

    if (typeof values.start_date !== 'undefined' && values.start_date != null) {
      data.start_date = `${moment(values.start_date).format(
        'YYYY-MM-DD'
      )} 00:00:01`;
    }
    if (typeof values.end_date !== 'undefined' && values.end_date != null) {
      data.end_date = `${moment(values.end_date).format(
        'YYYY-MM-DD'
      )} 00:00:01`;
    }
    if (typeof values.units_limit !== 'undefined') {
      data.units_limit = values.units_limit;
    }
    if (typeof values.min_purchase !== 'undefined') {
      data.min_purchase = values.min_purchase;
    }
    if (typeof values.max_purchase !== 'undefined') {
      data.max_purchase = values.max_purchase;
    }
    if (typeof values.modality !== 'undefined') {
      data.modality = values.modality;
      if (values.modality === 'COUPON_NxN') {
        data.modality_nxn_products_quantity = values.modality_nxn_products_quantity;
        data.modality_nxn_products_paid = values.modality_nxn_products_paid;
      }
    }
    
    data.type_coupon = values.type_coupon;
    data.is_global_user = values.is_global_user;
    if (values.is_global_user === 0) {
      if (typeof values.users === 'undefined') {
        data.users = [];
      } else {
        const us = [];
        values.users.forEach(element => {
          us.push(element.key);
        });
        data.users = us;
      }
      if (typeof values.user_tags !== 'undefined') {
        data.user_tags = values.user_tags;
      }
    }
    if (values.type_coupon === 'ADVANCED') {
      if (values.modality !== 'COUPON_EVENT') {
        if (typeof values.categories !== 'undefined') {
          data.categories = values.categories;
        }
        if (typeof values.tags !== 'undefined') {
          data.tags = values.tags;
        }
        if (typeof values.products !== 'undefined') {
          const p = [];
          values.products.forEach(element => {
            p.push(element.key);
          });
          data.products = p;
        }
      } else {
        if (typeof values.events !== 'undefined') {
          data.events = values.events;
        }
      }
    }
    return http
      .post(`admin/coupons`, data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Cupón creado', 'success'),
      () => {
        page.show('/cupones');
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];

const searchCupon = [
  ({ props, http, path }) => {
    const { code } = props;
    return http
      .post(`admin/coupons/exist/code`, { code })
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [],
    error: [
      ({ store, props }) => {
        store.set(state.coupons.error, props.message.error);
      }
    ]
  }
];

const clearErrorCupon = [
  ({ store }) => {
    store.set(state.coupons.error, null);
  }
];

const updateCoupon = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values } = props;
    const data = {};
    data.applies_to_discounted_products = values.applies_to_discounted_products;
    data.apply_all_cart_products = values.apply_all_cart_products;
    data.coupon_id = values.coupon_id;
    data.description = values.description;
    data.name = values.name;
    data.type_value = values.type_value;
    data.unique_redeem_per_user = values.unique_redeem_per_user;
    data.value = values.value;
    
    if (typeof values.modality !== 'undefined') {
      data.modality = values.modality;
      if (values.modality === 'COUPON_NxN') {
        data.modality_nxn_products_quantity = values.modality_nxn_products_quantity;
        data.modality_nxn_products_paid = values.modality_nxn_products_paid;
      }
    }
    if (
      typeof values.start_date !== 'undefined' &&
      values.start_date !== null
    ) {
      data.start_date = `${moment(values.start_date).format(
        'YYYY-MM-DD'
      )} 00:00:01`;
    }
    if (typeof values.end_date !== 'undefined' && values.end_date !== null) {
      data.end_date = `${moment(values.end_date).format(
        'YYYY-MM-DD'
      )} 00:00:01`;
    }
    if (
      typeof values.units_limit !== 'undefined' &&
      values.units_limit !== null
    ) {
      data.units_limit = values.units_limit;
    }
    if (
      typeof values.min_purchase !== 'undefined' &&
      values.min_purchase !== null
    ) {
      data.min_purchase = values.min_purchase;
    }
    if (
      typeof values.max_purchase !== 'undefined' &&
      values.max_purchase !== null
    ) {
      data.max_purchase = values.max_purchase;
    }
    data.type_coupon = values.type_coupon;
    data.is_global_user = values.is_global_user;
    if (values.is_global_user === 0) {
      if (typeof values.users === 'undefined') {
        data.users = [];
      } else {
        const us = [];
        values.users.forEach(element => {
          us.push(element.key);
        });
        data.users = us;
      }
      if (typeof values.user_tags !== 'undefined') {
        data.user_tags = values.user_tags;
      }
    }
    if (values.type_coupon === 'ADVANCED') {
      if (values.modality !== 'COUPON_EVENT') {
        if (typeof values.categories !== 'undefined') {
          data.categories = values.categories;
        }
        if (typeof values.tags !== 'undefined') {
          data.tags = values.tags;
        }
        if (typeof values.products !== 'undefined') {
          const p = [];
          values.products.forEach(element => {
            p.push(element.key);
          });
          data.products = p;
        }
      } else {
        if (typeof values.events !== 'undefined') {
          data.events = values.events;
        }
      }
    }
    return http
      .post(`admin/coupons/update`, data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Cupón actualizado', 'success'),
      () => {
        page.show('/cupones');
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];

export default {
  routes: {
    list: CouponsListRouter,
    list_campaign: CampaignListRouter,
    new: CuoponsNewRouter,
    update: CuoponsFormViewUpdate
  },
  sequences: {
    deleteCoupon,
    updatedFilterCoupon,
    updatedFilterCouponDatesStart,
    updatedFilterCouponDatesEnd,
    saveCoupon,
    updateCoupon,
    updatedFilterCampaign,
    searchCupon,
    clearErrorCupon
  }
};
