import { state } from 'cerebral';

export function getOptions({http, path,get}) {
  return http
    .get('admin/options', {}, {per_page: get(state.app.pagination.perPage)})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}


export function setOptions({props, store}) {
  const { result } = props;
  store.set(state.options.list, result.data)
}

export function getOptionDetail({ props, http, path }) {
  const { id } = props.params;
  return http
    .get(`admin/options/${id}`, {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setOptionDetail({ props, store }) {
  const { result } = props;
  store.set(state.options.detail, result);
}
