import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';

import GeneralCreateComponent from './Tabs/GeneralCreateComponent';

import {
  Button,
  Form,
  Icon,
  Tabs,
  Tooltip,
} from 'antd';

const { TabPane } = Tabs;

export default Form.create()(
  connect(
    {
      // states
      loadingLogin: state.user.loginButtonDisable,
      openTab: state.events.openTab,

      // sequences
      submit: sequences.createEvent,
      updateTab: sequences.updateTab,

    },
    class EventsCreateView extends Component {
      constructor(props) {
        super(props);
        this.state = {};

        this.onChangeTab = this.onChangeTab.bind(this);
        this.handleSubmitAll = this.handleSubmitAll.bind(this);

      }

      onChangeTab(key) {
        const { updateTab } = this.props;
        updateTab({ tab: key });
      }

      handleSubmitAll(e) {
        e.preventDefault();
        const {
          form,
          submit,
        } = this.props;
        form.validateFields((err, values) => {
          if (!err) {
              submit({values});
          }
        });
      }

      render() {
        const { 
          form,
          loadingLogin,
          openTab,
        } = this.props;
        
        return (
          <div>
            <div className="move-content-right">
              {openTab === 'general' ? (
                <Tooltip title="Guardar cambios" placement="left">
                  <Button
                    icon="save"
                    type="primary"
                    htmlType="submit"
                    loading={loadingLogin}
                    onClick={this.handleSubmitAll}
                  />
                </Tooltip>
              ) : (
                <Button disabled icon="save" type="primary" htmlType="submit" />
              )}
              <Tooltip title="Cancelar" placement="left">
                <a
                  style={{ marginLeft: 10 }}
                  href="/eventos"
                  className="ant-btn ant-btn-primary ant-btn-icon-only"
                  rel="noopener noreferrer"
                >
                  <Icon type="arrow-left" />
                </a>
              </Tooltip>
            </div>
            <Tabs defaultActiveKey={openTab} onChange={this.onChangeTab}>

              <TabPane tab="General" key="general">
                <Form layout="horizontal">
                  <GeneralCreateComponent form={form} />
                </Form>
              </TabPane>
              
            </Tabs>
          </div>
        );
      }
    }
  )
);
