import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { Form, Input, Button, Row, Col, Tooltip, Select } from 'antd';
import { createValidation } from '@utils';

export default Form.create()(
  connect(
    {},
    class FormStatus extends Component {
      constructor(props) {
        super(props);
        this.state = {
          loading: false,
          currentStatus: props.select
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
      }

      handleChangeStatus(value) {
        this.value = value;
        this.setState({
          currentStatus: value
        });
      }

      handleSubmit(e) {
        e.preventDefault();
        const { form, orderUuid, updatedStatusOrder } = this.props;
        form.validateFields((err, data) => {
          if (!err) {
            const values = Object.assign(data, {
              order_uuid: orderUuid
            });

            updatedStatusOrder({ values });
          }
        });
      }

      render() {
        const {
          status,
          select,
          loadingLogin,
          form,
          deliveryNumber,
          trackingNumber,
          refNumber,
          paymentMethod,
        } = this.props;
        const { currentStatus } = this.state;
        const { getFieldDecorator } = form;
        const FormItem = Form.Item;
        const { TextArea } = Input;
        
        return (
          <div>
            <Form onSubmit={this.handleSubmit} layout="horizontal">
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24}>
                  <FormItem className="move-content-right">
                    <Tooltip title="Guardar estatus de orden" placement="left">
                      <Button
                        icon="save"
                        type="primary"
                        htmlType="submit"
                        loading={loadingLogin}
                      />
                    </Tooltip>
                  </FormItem>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <Row>
                    <Col xs={24} sm={24} md={24}>
                      <FormItem label="Estatus">
                        {getFieldDecorator('order_status_id', {
                          rules: [
                            {
                              required: true,
                              message: 'Por favor seleccione algun estatus'
                            }
                          ],
                          initialValue: select
                        })(
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Por favor seleccione algun estatus"
                            onChange={this.handleChangeStatus}
                          >
                            {status.map(value => (
                              <Select.Option
                                key={`status-${value.id}-cambio`}
                                value={value.id}
                              >
                                {value.name}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <FormItem label="Comentario (opcional)">
                        {getFieldDecorator('comment', {
                          rules: [
                            createValidation(
                              false,
                              'Por favor ingresa un comentario (opcional)',
                              ''
                            )
                          ],
                          initialValue: ''
                        })(
                          <TextArea
                            rows={4}
                            placeholder="Por favor ingresa un comentario (opcional)"
                          />
                        )}
                      </FormItem>
                    </Col>
                    {currentStatus === 4 ? (
                      <Col xs={24} sm={24} md={24}>
                        <FormItem label="Número de rastreo">
                          {getFieldDecorator('delivery_number', {
                            rules: [
                              {
                                required: currentStatus === 4,
                                message:
                                  'Por favor ingresa un número de rastreo',
                                type: ''
                              }
                            ],
                            initialValue: deliveryNumber
                          })(
                            <Input placeholder="Por favor ingresa un número de rastreo" disabled={!!trackingNumber} />
                          )}
                        </FormItem>
                      </Col>
                    ) : null}
                    {currentStatus === 2 ? (
                     <Col xs={4} sm={4} md={4}>
                        <FormItem label="Método de pago">
                          {getFieldDecorator('payment_method_reference', {
                            rules: [
                              {
                                required: currentStatus === 2,
                                message:
                                  'Por favor ingresa un número de referencia',
                                type: ''
                              }
                            ],
                            initialValue: paymentMethod ? paymentMethod.name : ''
                          })(
                            <Select
                              defaultValue=""
                              placeholder="Tipo"
                              style={{ width: '100%' }}
                            >
                              <Select.Option key="payment-1" value="Paypal">
                                Paypal
                              </Select.Option>
                              <Select.Option key="payment-2" value="Spei">
                                Spei
                              </Select.Option>
                              <Select.Option key="payment-3" value="Oxxo">
                                Oxxo
                              </Select.Option>
                            </Select>
                          )}
                        </FormItem>
                      </Col>
                    ) : null}
                    {currentStatus === 2 ? ( <Col xs={19} sm={2} md={2}></Col> ) : null }
                    {currentStatus === 2 ? (
                      <Col xs={19} sm={18} md={18}>
                      <FormItem label="Número de referencia">
                        {getFieldDecorator('reference_number', {
                          rules: [
                            {
                              required: currentStatus === 2,
                              message:
                                'Por favor ingresa un número de referencia',
                              type: ''
                            }
                          ],
                          initialValue: refNumber
                        })(
                          <Input placeholder="Por favor ingresa un número de referencia" disabled={!!trackingNumber} />
                        )}
                      </FormItem>
                    </Col>
                    ) : null}
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        );
      }
    }
  )
);
