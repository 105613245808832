import React, { Component } from 'react';
import {
  Col,
  Form,
  Row,
  Select,
} from 'antd';

import 'react-quill/dist/quill.snow.css';
import { createValidation } from '@utils';
import ReactQuill from 'react-quill';

export default class GeneralComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {

    const {
      form,
      show,
    } = this.props;
    const { getFieldDecorator } = form;
    const FormItem = Form.Item;
    const modules = {
      toolbar: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' }
        ],
        ['link', 'image', 'video'],
        ['clean']
      ],
      clipboard: {
        matchVisual: false
      }
    };
    const formats = [
      'header',
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'video'
    ];
    return (
      <Row gutter={16} type="flex">
        <Col xs={24} sm={17} md={17}>
          <Row gutter={16}>

            <Col xs={24} sm={8} md={12}>
              <FormItem label="Estatus">
                {getFieldDecorator('active', {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor seleccione una opción'
                    }
                  ],
                  initialValue: show.active
                })(
                  <Select
                    placeholder="Por favor seleccione una opción"
                    style={{ width: '100%' }}
                  >
                    <Select.Option selected={show.active == 1} key="show-1" value={1}>
                      MOSTRAR
                    </Select.Option>
                    <Select.Option selected={show.active == 0} key="show-2" value={0}>
                      OCULTAR
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16} type="flex">
            <Col xs={24} sm={24} md={24}>
              <FormItem label="Descripción">
                {getFieldDecorator('value', {
                  rules: [
                    createValidation(
                      false,
                      'Por favor ingresa la descripción',
                      ''
                    )
                  ],
                  initialValue: show.value
                })(
                  <ReactQuill
                    placeholder="..."
                    modules={modules}
                    formats={formats}
                  />
                )}
              </FormItem>
            </Col>

          </Row>

        </Col>

      </Row>
    );
  }
}
