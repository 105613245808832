import { state } from 'cerebral';

export function getConfigurations({http, path,get}) {
  return http
    .get('admin/configurations', {}, {per_page: get(state.app.pagination.perPage)})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}

export function setConfigurations({props, store}) {
  const { result } = props;
  store.set(state.configurations.list, result.data)
}

export function getConfiguration({ props, http, path }) {
  const { id } = props.params;
  return http
    .get(`admin/configurations/${id}`, {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}

export function setConfiguration({ props, store }) {
  const { result } = props;
  store.set(state.configurations.show, result);
}