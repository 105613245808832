import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Table, Button, Modal, Tooltip, Icon } from 'antd';
import Moment from 'react-moment';
import Pagination from '@component/Elements/Pagination';

export default connect(
  {
    // states
    stateDelete: state.options.delete,
    list: state.options.list,
    // secuences
    deleteOption: sequences.deleteOption,
    handleClickOpenModal: sequences.handleClickOpenModalOption,
    handleClickCloseModal: sequences.handleClickCloseModalOption
  },
  function OptionsListView(props) {
    const {
      list,
      stateDelete,
      deleteOption,
      handleClickCloseModal,
      handleClickOpenModal
    } = props;
    const columns = [
      {
        title: 'OPCIÓN',
        dataIndex: 'name',
        key: 'name',
        render: (text, element, index) => (
          <a href={`/opciones/${list[index].id}`}>{text}</a>
        )
      },
      {
        title: 'FECHA CREACIÓN',
        dataIndex: 'created_at',
        key: 'created_at',
        render: text => <Moment format="DD-MM-YYYY hh:mm A">{text}</Moment>
      },
      {
        title: 'ACCIONES',
        dataIndex: 'id',
        key: 'id',
        render: (text, element, index) => (
          <div>
            {/* <Tooltip title="Eliminar opcion" placement="left">
              <Button
                type="primary"
                icon="delete"
                onClick={() => {
                  handleClickOpenModal(list[index]);
                }}
              />
            </Tooltip> */}
            <Tooltip title="Ver atributos" placement="top">
              <a
                style={{ marginLeft: 10 }}
                href={`/opciones/${list[index].id}/atributos`}
                className="ant-btn ant-btn-primary ant-btn-icon-only"
                rel="noopener noreferrer"
              >
                <Icon type="eye" />
              </a>
            </Tooltip>
          </div>
        )
      }
    ];
    return (
      <div>
       {/*  <Modal
          closable={false}
          visible={stateDelete.open}
          onCancel={() => {
            handleClickCloseModal();
          }}
          footer={[
            <Button
              type="primary"
              onClick={() => {
                deleteOption();
              }}
              loading={stateDelete.loader}
            >
              Confirmar
            </Button>,
            <Button
              onClick={() => {
                handleClickCloseModal();
              }}
            >
              Cancelar
            </Button>
          ]}
        >
          <h4>¿Desea eliminar la opción '{stateDelete.name}' ?</h4>
          <p>
            Favor de verificar que la opción seleccionada sea la que realmente
            desea eliminar.
          </p>
        </Modal> */}
       {/*  <div className="move-content-right">
          <Tooltip title="Crear opción" placement="left">
            <a
              href="/opciones/nuevo"
              className="ant-btn ant-btn-primary ant-btn-icon-only"
              rel="noopener noreferrer"
            >
              <Icon type="plus" />
            </a>
          </Tooltip>
        </div> */}
        <Table
          bordered
          size="small"
          dataSource={list}
          columns={columns}
          pagination={false}
          scroll={{ x: 'max-content' }}
        />
        <Pagination list="options.list" apiUrl="admin/options" />
      </div>
    );
  }
);
