import { state, props } from 'cerebral';
import { notification } from 'antd';
import notify from '@utils/notify';
import audio from '../assets/notification.mp3';

export const toggleLefSidebar = [
  ({ store }) => {
    const menu = localStorage.getItem('collapsed');
    if (menu != null) {
      // eslint-disable-next-line eqeqeq
      if (menu == 'true') {
        localStorage.setItem('collapsed', false);
        store.set(state.collapsed, false);
      } else {
        localStorage.setItem('collapsed', true);
        store.set(state.collapsed, true);
      }
    } else {
      localStorage.setItem('collapsed', true);
      store.set(state.collapsed, true);
    }
  }
];

export const openOrCloseLefSidebarStatus = [
  ({ store }) => {
    const menu = localStorage.getItem('collapsed');
    if (menu != null) {
      // eslint-disable-next-line eqeqeq
      if (menu == 'true') {
        localStorage.setItem('collapsed', true);
        store.set(state.collapsed, true);
      } else {
        localStorage.setItem('collapsed', false);
        store.set(state.collapsed, false);
      }
    } else {
      localStorage.setItem('collapsed', false);
      store.set(state.collapsed, false);
    }
  }
];

export const getNotificationByOrders = [
  ({ http, path, get }) => {
    return http
      .get(`admin/notifications`, {})
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      ({ store, props, get }) => {
        const newDataNotification = props.res;
        const defaultLog = localStorage.getItem('notifications');
        if (defaultLog === null) {
          localStorage.setItem(
            'notifications',
            JSON.stringify(newDataNotification)
          );
        } else {
          const local = localStorage.getItem('notifications');
          if (local !== JSON.stringify(newDataNotification)) {
            notification.warning({
              message: 'Aviso de compras',
              description: 'Tienes notificaciones nuevas que revisar.'
            });
            if (process.env.SOUND_NOTIFICATION === 'true') {
              // eslint-disable-next-line no-undef
              const audioFile = new Audio(audio);
              setTimeout(() => {
                audioFile.play();
              }, 500);
            }
            localStorage.setItem(
              'notifications',
              JSON.stringify(newDataNotification)
            );
          }
        }
        store.set(state.notifications, newDataNotification);
      }
    ],
    error: []
  }
];
export const scrollTop = [
  () => {
    window.scrollTo(0, 0);
  }
];
export const init = [];

export const openModalRecoverPassword = [
  ({ store }) => {
    store.set(state.recoverPassword.open, true);
  }
];
export const closeModalRecoverPassword = [
  ({ store }) => {
    store.set(state.recoverPassword.open, false);
    store.set(state.recoverPassword.email, '');
  }
];
export const setEmailRecoverPassword = [
  ({ store, props }) => {
    store.set(state.recoverPassword.email, props.email);
  }
];
export const sendEmailRecoverPassword = [
  ({ store, props }) => {
    store.set(state.recoverPassword.loader, true);
  },
  ({ http, path, props }) => {
    return http
      .post(`recover-password`, { email: props.email })
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify(props.res.msg, 'success'),
      ({ store }) => {
        store.set(state.recoverPassword.open, false);
        store.set(state.recoverPassword.email, '');
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.recoverPassword.loader, false);
  }
];
