import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Upload,
  Icon,
  Tooltip
} from 'antd';
import normFile from '@utils/normFile';
import { createValidation } from '@utils';

export default Form.create()(
  connect(
    {
      // states
      postId: state.posts_gallery.post_id,
       loadingLogin: state.user.loginButtonDisable,
      // secuences
       submit: sequences.saveGalleryPosts
    },
    class PostGalleryFormView extends Component {
      constructor(props) {
        super(props);
        this.state = {
          type: 'CODE_YOUTUBE'
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
      }

      handleSubmit(e) {
        e.preventDefault();
        const { form, submit, postId } = this.props;
        form.validateFields((err, values) => {
          if (!err) {
            values.blog_id = postId;
              submit({ values });
          }
        });
      }

      handleChange(value) {
        this.value = value;
        this.setState({ type: value });
      }

      render() {
        const { form, loadingLogin, postId } = this.props;
        const { type } = this.state;
        const { getFieldDecorator } = form;
        const FormItem = Form.Item;

        return (
          <div>
            <Form onSubmit={this.handleSubmit} layout="horizontal">
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24}>
                  <FormItem className="move-content-right">
                    <Tooltip title="Guardar registro" placement="left">
                      <Button
                        icon="save"
                        type="primary"
                        htmlType="submit"
                        loading={loadingLogin}
                      />
                    </Tooltip>
                    <Tooltip title="Cancelar" placement="left">
                      <a
                        style={{ marginLeft: 10 }}
                        href={`/posts/${postId}/galeria`}
                        className="ant-btn ant-btn-primary ant-btn-icon-only"
                        rel="noopener noreferrer"
                      >
                        <Icon type="arrow-left" />
                      </a>
                    </Tooltip>
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={12}>
                  <FormItem label="Tipo">
                    {getFieldDecorator('type', {
                      rules: [
                        {
                          required: true,
                          message: 'Por favor seleccione un tipo'
                        }
                      ],
                      initialValue: type
                    })(
                      <Select
                        placeholder="Tipo"
                        style={{ width: '100%' }}
                        onChange={this.handleChange}
                      >
                        <Select.Option
                          key="type-CODE_YOUTUBE"
                          value="CODE_YOUTUBE"
                        >
                          CODIGO YOUTUBE
                        </Select.Option>
                        <Select.Option key="tipo-IMG" value="IMG">
                          IMAGEN
                        </Select.Option>
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
              {type === 'CODE_YOUTUBE' ? (
                <Row gutter={16}>
                  <Col xs={24} sm={8} md={12}>
                    <FormItem label="Código youtube">
                      {getFieldDecorator('file', {
                        rules: [
                          createValidation(
                            true,
                            'Por favor ingresa un codigo',
                            ''
                          )
                        ]
                      })(<Input placeholder="Código youtube" />)}
                    </FormItem>
                  </Col>
                </Row>
              ) : (
                <Row gutter={16}>
                  <Col xs={24} sm={8} md={12}>
                    <FormItem label="Imagen normal">
                      {getFieldDecorator('file', {
                        valuePropName: 'fileList',
                        getValueFromEvent: normFile,
                        rules: [
                          {
                            required: true,
                            message: 'Por favor seleccione una imagen'
                          }
                        ]
                      })(
                        <Upload
                          name="file"
                          beforeUpload={() => false}
                          accept="image/jpeg, image/png"
                        >
                          <Button>
                            <Icon type="upload" /> Subir imagen
                          </Button>
                        </Upload>
                      )}
                    </FormItem>
                  </Col>
                </Row>
              )}
              <Row gutter={16}>
                <Col xs={24} sm={8} md={12}>
                  <FormItem label="Thumb  (850 x 550)">
                    {getFieldDecorator('thumb', {
                      valuePropName: 'fileList',
                      getValueFromEvent: normFile,
                      rules: [
                        {
                          required: true,
                          message: 'Por favor seleccione una imagen'
                        }
                      ]
                    })(
                      <Upload
                        name="thumb"
                        beforeUpload={() => false}
                        accept="image/jpeg, image/png"
                      >
                        <Button>
                          <Icon type="upload" /> Subir imagen
                        </Button>
                      </Upload>
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>
        );
      }
    }
  )
);
