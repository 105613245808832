import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Upload,
  Icon,
  Tooltip
} from 'antd';
import normFile from '@utils/normFile';
import { createValidation } from '@utils';

export default Form.create()(
  connect(
    {
      // states
      loadingLogin: state.user.loginButtonDisable,
      detail: state.brands.detail,
      // secuences
      submit: sequences.updateBrand
    },
    class BrandFormViewUpdate extends Component {
      constructor(props) {
        super(props);
        this.state = {};
        this.handleSubmit = this.handleSubmit.bind(this);
      }

      handleSubmit(e) {
        e.preventDefault();
        const { form, submit, detail } = this.props;
        form.validateFields((err, values) => {
          if (!err) {
            submit({ id: detail.id, values });
          }
        });
      }

      render() {
        const { form, loadingLogin, detail } = this.props;
        const { getFieldDecorator } = form;
        const FormItem = Form.Item;

        return (
          <div>
            {Object.keys(detail).length === 0 ? (
              ''
            ) : (
              <Form onSubmit={this.handleSubmit} layout="horizontal">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24}>
                    <FormItem className="move-content-right">
                      <Tooltip title="Guardar marca" placement="left">
                        <Button
                          icon="save"
                          type="primary"
                          htmlType="submit"
                          loading={loadingLogin}
                        />
                      </Tooltip>
                      <Tooltip title="Cancelar" placement="left">
                        <a
                          style={{ marginLeft: 10 }}
                          href="/marcas"
                          className="ant-btn ant-btn-primary ant-btn-icon-only"
                          rel="noopener noreferrer"
                        >
                          <Icon type="arrow-left" />
                        </a>
                      </Tooltip>
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={8} md={12}>
                    <FormItem label="Nombre">
                      {getFieldDecorator('name', {
                        rules: [
                          createValidation(
                            true,
                            'Por favor ingresa un nombre',
                            ''
                          )
                        ],
                        initialValue: detail.name
                      })(<Input placeholder="Nombre" />)}
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={8} md={12}>
                    <FormItem label="Status">
                      {getFieldDecorator('active', {
                        rules: [
                          createValidation(
                            true,
                            'Por favor seleccione una opción',
                            ''
                          )
                        ],
                        initialValue: detail.active.toString()
                      })(
                        <Select placeholder="Status" style={{ width: '100%' }}>
                          <Select.Option key="status-1" value="1">
                            ACTIVO
                          </Select.Option>
                          <Select.Option key="status-2" value="0">
                            INACTIVO
                          </Select.Option>
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <Row>
                      <Col xs={24} sm={5} md={5}>
                        <p
                          style={{
                            color: 'rgba(0, 0, 0, 0.85)',
                            fontSize: 14,
                            marginTop: 10
                          }}
                        >
                          Imagen actual
                        </p>
                        <img
                          src={detail.url_photo}
                          alt={detail.alt}
                          style={{
                            width: '5rem',
                            height: '5rem',
                            objectFit: 'cover',
                            objectPosition: 'center'
                          }}
                        />
                      </Col>
                      <Col xs={24} sm={19} md={19}>
                        <FormItem label="Nueva imagen">
                          {getFieldDecorator('url_photo', {
                            valuePropName: 'fileList',
                            getValueFromEvent: normFile,
                            rules: [
                              {
                                required: false,
                                message: 'Por favor seleccione una imagen'
                              }
                            ]
                          })(
                            <Upload
                              name="url_photo"
                              beforeUpload={() => false}
                              accept="image/jpeg, image/png"
                            >
                              <Button>
                                <Icon type="upload" /> Subir imagen
                              </Button>
                            </Upload>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            )}
          </div>
        );
      }
    }
  )
);
