import { set } from 'cerebral/factories'; // wait
import { state, props } from 'cerebral';
import { WithRouter, setPagination } from '@modules/actions';
import notify from '@utils/notify';
import page from 'page';
import * as actions from './actions';

const TagListRouter = WithRouter([
  set(state.app.currentPage, 'TagListView'),
  actions.getTags,
  {
    success: [setPagination, actions.setTags],
    error: []
  }
]);
const TagUserListRouter = WithRouter([
  set(state.app.currentPage, 'TagUserListView'),
  actions.getTagsUsers,
  {
    success: [setPagination, actions.setTagsUsers],
    error: []
  }
]);

const TagNewRouter = WithRouter([set(state.app.currentPage, 'TagFormView')]);
const TagUserNewRouter = WithRouter([set(state.app.currentPage, 'TagUsersFormView')]);
const TagFormViewUpdate = WithRouter([
  set(state.tags.detail, {}),
  set(state.app.currentPage, 'TagFormViewUpdate'),
  actions.getTagDetail,
  {
    success: [actions.setTagDetail],
    error: [notify(props.message.error, 'error')]
  }
]);

const TagFormUserViewUpdate = WithRouter([
  set(state.tags.detail, {}),
  set(state.app.currentPage, 'TagFormViewUpdate'),
  actions.getTagUsersDetail,
  {
    success: [actions.setTagUsersDetail],
    error: [notify(props.message.error, 'error')]
  }
]);

const saveTag = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values } = props;
    const axios_url = values.type == 'USERS' ? 'admin/tags-users' : 'admin/tags';
    return http
      .post( axios_url , values)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Tag creado', 'success'),
      ({props}) => {
        const { values } = props;
        const redirect_url = values.type == 'USERS' ? '/tags-usuarios' : '/tags';
        page.show(redirect_url);
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];
const updateTag = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values, id } = props;
    const axios_url = props.type == 'USERS' ? `admin/tags-users/${id}/update` : `admin/tags/${id}/update`;
    return http
      .patch(axios_url, values)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Tag actualizado', 'success'),
      ({props}) => {
        const redirect_url = props.type == 'USERS' ? '/tags-usuarios' : '/tags';
        page.show(redirect_url);
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];
const deleteTag = [
  ({ store }) => {
    store.set(state.tags.delete.loader, true);
  },
  ({ http, path, get, props }) => {
    const { values } = props;
    const axios_url = props.type == 'USERS' ? `admin/tags-users/${get(state.tags_users.delete.id)}` : `admin/tags/${get(state.tags.delete.id)}`;
    return http
      .delete(axios_url, {})
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      ({ store, props }) => {
        if (props.type != 'USERS') {
          store.set(state.tags.delete, {
            id: null,
            name: null,
            type: null,
            open: false,
            loader: false
          });
        }
        store.set(state.tags_users.delete, {
          id: null,
          name: null,
          type: null,
          open: false,
          loader: false
        });
      },
      notify('Tag eliminado', 'success'),
      ({props}) => {
        const redirect_url = props.type == 'USERS' ? '/tags-usuarios' : '/tags';
        page.show(redirect_url);
      }
    ],
    error: [
      ({ store, props }) => {
        if (props.type != 'USERS') {
          store.set(state.tags.delete, {
            id: null,
            name: null,
            type: null,
            open: false,
            loader: false
          });
        }
        store.set(state.tags_users.delete, {
          id: null,
          name: null,
          type: null,
          open: false,
          loader: false
        });
      },
      notify(props.message.error, 'error')
    ]
  }
];

const handleClickOpenModalTag = [
  ({ props, store }) => {
    store.set(state.tags.delete, {
      id: props.id,
      name: props.name,
      type: props.type,
      open: true,
      loader: false
    });
  }
];
const handleClickCloseModalTag = [
  ({ store }) => {
    store.set(state.tags.delete, {
      id: null,
      name: null,
      type: null,
      open: false,
      loader: false
    });
  }
];

const handleClickOpenModalTagUser = [
  ({ props, store }) => {
    store.set(state.tags_users.delete, {
      id: props.id,
      name: props.name,
      type: props.type,
      open: true,
      loader: false
    });
  }
];
const handleClickCloseModalTagUser = [
  ({ store }) => {
    store.set(state.tags_users.delete, {
      id: null,
      name: null,
      type: null,
      open: false,
      loader: false
    });
  }
];

export default {
  routes: {
    list: TagListRouter,
    new: TagNewRouter,
    update: TagFormViewUpdate,
    
    user_list: TagUserListRouter,
    user_list_new: TagUserNewRouter,
    user_list_update: TagFormUserViewUpdate,
  },
  sequences: {
    saveTag,
    deleteTag,
    handleClickOpenModalTag,
    handleClickCloseModalTag,
    handleClickOpenModalTagUser,
    handleClickCloseModalTagUser,
    updateTag
  }
};
