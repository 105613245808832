import { createTemplateTag, extractValueWithPath } from 'function-tree'
import { getRootPath, DEPRECATE } from '../utils'
export {
  createTemplateTag,
  extractValueWithPath,
  resolveObject,
  ResolveValue,
  Tag,
} from 'function-tree'

export const string = createTemplateTag('string', (path) => {
  return path
})

export const path = createTemplateTag('path', (path) => {
  return path
})

export const signal = createTemplateTag('signal', (path, context) => {
  DEPRECATE('tags.signal', 'use the "sequences" tag instead')
  return context.controller.getSequence(path)
})

export const signals = createTemplateTag('signals', (path, context) => {
  DEPRECATE('tags.signals', 'use the "sequences" tag instead')
  return context.controller.getSequences(path)
})

export const sequences = createTemplateTag('sequences', (path, context) => {
  return (
    context.controller.getSequence(path) ||
    context.controller.getSequences(path)
  )
})

export const state = createTemplateTag('state', (path, context) => {
  return context.controller.getState(path)
})

export const module = createTemplateTag('module', (path, context) => {
  DEPRECATE('tags.module', 'use the "moduleState" tag instead')
  return context.controller.getState(getRootPath(path, context))
})

export const moduleState = createTemplateTag('moduleState', (path, context) => {
  return context.controller.getState(getRootPath(path, context))
})

export const moduleSequences = createTemplateTag(
  'moduleSequences',
  (path, context) => {
    return (
      context.controller.getSequence(getRootPath(path, context)) ||
      context.controller.getSequences(getRootPath(path, context))
    )
  }
)

export const props = createTemplateTag('props', (path, context) => {
  return extractValueWithPath(context.props, path)
})
