import * as global from './global';
import * as routes from './routers';
import admin from './admin';
import AdminsModules from '@modules/admin';
import AppModules from '@modules/app';
import AttributesModules from '@modules/attributes';
import BrandsModules from '@modules/brands';
import CategoriesModules from '@modules/categories';
import ConfigurationsModule from '@modules/configurations';
import CouponsModules from '@modules/coupons';
import Dashboard from '@modules/dashboard';
import DealersInitial from '@modules/dealersInitial';
import DealersMayoreo from '@modules/dealersMayoreo';
import DealersTelemarketing from '@modules/dealersTelemarketing';
import EventsModules from '@modules/events';
import OptionsModules from '@modules/options';
import OrdersModules from '@modules/orders';
import page from 'page';
import PostsModules from '@modules/posts';
import ProductsModules from '@modules/products';
import Profile from '@modules/profile';
import providers from '@providers';
import qs from 'query-string';
import ReportsModules from '@modules/reports';
import state from './state';
import Stock from '@modules/stock';
import TagsModules from '@modules/tags';
import UserModules from '@modules/user';
import UsersWebModules from '@modules/userWeb';

/**
 * Main module with page.js
 */
export default ({ app }) => {
  app.on('initialized', () => page.start());
  function route(url, sequence) {
    page(url, ({ path, params, querystring }) => {
      const query = qs.parse(querystring);
      app.runSequence(path, sequence, { params, query, path });
      window.scrollTo(0, 0);
    });
    return ({ props }) => {
      const urlWithReplacedParams = Object.keys(props.params || {}).reduce(
        (currentUrl, param) =>
          currentUrl.replace(`:${param}`, props.params[param]),
        url
      );
      const query = props.query ? `?${qs.stringify(props.query)}` : '';
      page.show(urlWithReplacedParams + query);
    };
  }
  return {
    // States
    state,
    // Sequences
    sequences: {
      /**
       * Routers
       */
      // Dashboard
      HomeRoute: route('/', Dashboard.routes.list),
      // Profile
      ProfileViewRoute: route('/perfil', Profile.routes.detail),
      ProfileDetailRoute: route('/perfil/:uuid', Profile.routes.list),
      // User Admins
      AdminListRoute: route('/administradores', AdminsModules.routes.list),
      AdminNewtRoute: route('/administradores/nuevo', AdminsModules.routes.new),
      AdminUpdateRoute: route(
        '/administradores/:uuid',
        AdminsModules.routes.update
      ),
      // Users Web
      WebListRoute: route('/clientes', UsersWebModules.routes.list),
      WebDetailRoute: route('/clientes/:uuid', UsersWebModules.routes.detail),
      // Brands Routers
      BrandListRoute: route('/marcas', BrandsModules.routes.list),
      BrandNewtRoute: route('/marcas/nuevo', BrandsModules.routes.new),
      BrandUpdateRoute: route('/marcas/:id', BrandsModules.routes.update),
      BrandListFilterRoute: route(
        '/marcas/:brand_id/filtros',
        BrandsModules.routes.listFilters
      ),
      BrandFilterNewRoute: route(
        '/marcas/:brand_id/filtros/nuevo',
        BrandsModules.routes.filtersNew
      ),
      BrandFilterUpdateRoute: route(
        '/marcas/:brand_id/filtros/:filter_id/editar',
        BrandsModules.routes.filtersUpdte
      ),
      BrandFilterDetailRoute: route(
        '/marcas/:brand_id/filtros/:filter_id',
        BrandsModules.routes.filtersDetail
      ),

      BrandListBannersRoute: route(
        '/marcas/:brand_id/banners',
        BrandsModules.routes.listBanners
      ),
      BrandBannerNewRoute: route(
        '/marcas/:brand_id/banners/nuevo',
        BrandsModules.routes.BannersNew
      ),
      BrandListBannersSecondaryRoute: route(
        '/marcas/:brand_id/banner-secundario',
        BrandsModules.routes.listBannerSecondary
      ),
      BrandBannerSecondaryNewRoute: route(
        '/marcas/:brand_id/banner-secundario/nuevo',
        BrandsModules.routes.BannerSecondaryNew
      ),

      // Categories Routers
      CategoryListRoute: route('/categorias', CategoriesModules.routes.list),
      CategoryNewtRoute: route(
        '/categorias/nuevo',
        CategoriesModules.routes.new
      ),
      CategoryUpdateRoute: route(
        '/categorias/:id',
        CategoriesModules.routes.update
      ),

      EventsListRoute: route('/eventos', EventsModules.routes.list),
      EventsListArchivedsRoute: route('/eventos-archivados', EventsModules.routes.archiveds),
      EventsTermsConditionsRoute: route('/eventos-terminos-y-condiciones', EventsModules.routes.terms_conditions),
      EventsCreateRoute: route('/eventos/nuevo', EventsModules.routes.create),
      EventsShowRoute: route('/eventos/:slug/:id', EventsModules.routes.show),

      ConfigurationListRoute: route('/configuraciones', ConfigurationsModule.routes.list),
      ConfigurationShowRoute: route('/configuraciones/:id', ConfigurationsModule.routes.edit),

      // POSTS
      PostListRoute: route('/posts', PostsModules.routes.list),
      PostNewRoute: route('/posts/nuevo', PostsModules.routes.new),
      PostUpdateRoute: route('/posts/:id', PostsModules.routes.update),
      PostGalleryRoute: route(
        '/posts/:id/galeria',
        PostsModules.routes.gallery_list
      ),
      PostGalleryNewRoute: route(
        '/posts/:id/galeria/nuevo',
        PostsModules.routes.gallery_list_new
      ),

      // Tags Routers
      TagListRoute: route('/tags', TagsModules.routes.list),
      TagUserListRoute: route('/tags-usuarios', TagsModules.routes.user_list),
      TagNewtRoute: route('/tags/nuevo', TagsModules.routes.new),
      TagNewUserRoute: route('/tags-usuarios/nuevo', TagsModules.routes.user_list_new),
      TagUpdatetRoute: route('/tags/:id', TagsModules.routes.update),
      TagUpdateUserRoute: route('/tags-usuarios/:id', TagsModules.routes.user_list_update),

      // Cuopons Routers
      CouponsListRoute: route('/cupones', CouponsModules.routes.list),
      CampaignListRoute: route(
        '/campaign',
        CouponsModules.routes.list_campaign
      ),
      CouponsNewtRoute: route('/cupones/nuevo', CouponsModules.routes.new),
      CouponsUpdatetRoute: route('/cupones/:id', CouponsModules.routes.update),
      // Options Routers
      OptionListRoute: route('/opciones', OptionsModules.routes.list),
      OptionNewtRoute: route('/opciones/nuevo', OptionsModules.routes.new),
      OptionUpdatetRoute: route('/opciones/:id', OptionsModules.routes.update),
      // Atributes Opcions
      AttributeListRoute: route(
        '/opciones/:option_id/atributos',
        AttributesModules.routes.list
      ),
      AttributeNewtRoute: route(
        '/opciones/:option_id/atributos/nuevo',
        AttributesModules.routes.new
      ),
      AttributeUpdatetRoute: route(
        '/opciones/:option_id/atributos/:attribute_id',
        AttributesModules.routes.update
      ),
      // Products Routers
      ProductListRoute: route('/productos', ProductsModules.routes.list),
      ProductNewRoute: route('/productos/nuevo', ProductsModules.routes.new),
      ProductNewRoute: route('/productos/edicion-multiple', ProductsModules.routes.updateMultiple),
      ProductUpdatedRoute: route(
        '/productos/:uuid',
        ProductsModules.routes.update
      ),
      ProductCombinationGalleryRoute: route(
        '/productos/:uuid/:product_combination_id',
        ProductsModules.routes.combinationGallery
      ),
      // Orders Routers
      OrderListRoute: route('/ordenes', OrdersModules.routes.list),
      OrderDetailtRoute: route('/ordenes/:uuid', OrdersModules.routes.detail),
      // Slides Routers
      SlideListOrder: route('/slides', admin.routes.SlideListView),
      SlideFormOrder: route('/slides/new', admin.routes.SlideFormView),
      // Books
      BookList: route('/books', admin.routes.BookListView),
      BookForm: route('/books/new', admin.routes.BookFormView),
      // Dealers
      DealersList: route('/dealers', admin.routes.DealersListView),
      DealerNew: route('/dealers/new', admin.routes.DealersFormView),
      DealerUpdate: route(
        '/dealers/update/:id',
        admin.routes.DealersUpdateView
      ),
      // RequestDealer
      DealerRequest: route('/dealers-request', DealersInitial.routes.list),
      DealerRequest: route('/dealers-request/:id', DealersInitial.routes.detail),
      
      DealerRequest: route('/dealers-request-telemarketing', DealersTelemarketing.routes.list),
      DealerRequest: route('/dealers-request-telemarketing/:id', DealersTelemarketing.routes.detail),

      DealerRequest: route('/dealers-request-mayoreo', DealersMayoreo.routes.list),
      DealerRequest: route('/dealers-request-mayoreo/:id', DealersMayoreo.routes.detail),
      //REPORTS
      ReportsModules: route('/reports', ReportsModules.routes.module),

      Contact: route('/contact', admin.routes.ContactView),
      //UPLOAD STOCK
      Contact: route('/stock', Stock.routes.list),
      // 404
      NotFound: route('*', routes.Template404),
      /**
       * Normal Sequences
       */
      ...AdminsModules.sequences,
      ...AppModules,
      ...AttributesModules.sequences,
      ...BrandsModules.sequences,
      ...CategoriesModules.sequences,
      ...ConfigurationsModule.sequences,
      ...CouponsModules.sequences,
      ...Dashboard.sequences,
      ...DealersInitial.sequences,
      ...DealersMayoreo.sequences,
      ...DealersTelemarketing.sequences,
      ...EventsModules.sequences,
      ...global,
      ...OptionsModules.sequences,
      ...OrdersModules.sequences,
      ...PostsModules.sequences,
      ...ProductsModules.sequences,
      ...Profile.sequences,
      ...ReportsModules.sequences,
      ...Stock.sequences,
      ...TagsModules.sequences,
      ...UserModules.sequences,
      ...UsersWebModules.sequences,
    },
    // Providers
    providers
  };
};
