import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import {
  Table,
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Tooltip,
  Icon
} from 'antd';
import Moment from 'react-moment';
import Pagination from '@component/Elements/Pagination';
import debounce from 'lodash/debounce';
import { formatNumberAmount } from '@utils';

export default connect(
  {
    // states
    extraBySearch: state.orders.search,
    list: state.orders.list,
    statusList: state.orders.status,
    loading: state.orders.search.loading,
    debounceTime: state.debounceTime,
    // sequences
    updatedFilterGeneral: sequences.updatedFilterGeneral
  },
  class OrderListView extends Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.handleSearchByShippingNumberOrder = this.handleSearchByShippingNumberOrder.bind(
        this
      );
      this.handleSearchByByNameOrder = this.handleSearchByByNameOrder.bind(
        this
      );
      this.handleSearchByStatusOrder = this.handleSearchByStatusOrder.bind(
        this
      );
      this.handleSearchBySortOrder = this.handleSearchBySortOrder.bind(this);
      this.handleSearchByTotalOrder = this.handleSearchByTotalOrder.bind(this);
      this.handleSearchByInitDateStart = this.handleSearchByInitDateStart.bind(
        this
      );
      this.handleSearchByInitDateEnd = this.handleSearchByInitDateEnd.bind(
        this
      );

      this.handleSearchDebounce = debounce(
        this.handleSearchDebounce,
        props.debounceTime
      );
    }

    handleSearchByShippingNumberOrder(e) {
      this.e = e;
      const { value } = e.target;
      this.handleSearchDebounce('searchByShippingNumberOrder', value);
    }

    handleSearchByByNameOrder(e) {
      this.e = e;
      const { value } = e.target;
      this.handleSearchDebounce('searchByNameOrder', value);
    }

    handleSearchByTotalOrder(e) {
      this.e = e;
      const { value } = e.target;
      this.handleSearchDebounce('searchByTotalOrder', value);
    }

    handleSearchByStatusOrder(value) {
      this.value = value;
      const key = 'searchByStatusOrder';
      const { updatedFilterGeneral } = this.props;
      updatedFilterGeneral({ key, value });
    }

    handleSearchBySortOrder(value) {
      this.value = value;
      const key = 'sortOrder';

      const { updatedFilterGeneral } = this.props;
      updatedFilterGeneral({ key, value });
    }

    handleSearchDebounce(key, value) {
      this.key = key;
      this.value = value;
      const { updatedFilterGeneral } = this.props;
      updatedFilterGeneral({ key, value });
    }

    handleSearchByInitDateStart(date, dateString) {
      this.date = date;
      this.dateString = dateString;

      const key = 'searchByInitDateStart';
      const { updatedFilterGeneral } = this.props;
      updatedFilterGeneral({ key, value: dateString });
    }

    handleSearchByInitDateEnd(date, dateString) {
      this.date = date;
      this.dateString = dateString;

      const key = 'searchByInitDateEnd';
      const { updatedFilterGeneral } = this.props;
      updatedFilterGeneral({ key, value: dateString });
    }

    render() {
      const { list, loading, extraBySearch, statusList } = this.props;
      const columns = [
        {
          title: 'ORDEN ID',
          dataIndex: 'shipping_number',
          key: 'shipping_number',
          render: (text, element, index) => (
            <a href={`/ordenes/${list[index].uuid}`}>{text}</a>
          )
        },
        {
          title: 'CLIENTE',
          dataIndex: 'name',
          key: 'name',
          render: (text, element, index) => (
            <span>
              {list[index].first_name} {list[index].last_name}
            </span>
          )
        },
        {
          title: 'ESTATUS',
          dataIndex: 'order_status.name',
          key: 'order_status_name',
          render: (text, element, index) => {
            if (text === 'Pendiente de pago') {
              return <span style={{ color: 'red' }}>{text}</span>;
            }
            if (text === 'Pago exitoso, por enviar pedido.') {
              return <span style={{ color: '#baba0c' }}>{text}</span>;
            }
            if (text === 'Pendiente de envio') {
              return <span style={{ color: '#baba0c' }}>{text}</span>;
            }
            if (text === 'En camino') {
              return <span style={{ color: 'green' }}>{text}</span>;
            }
            if (text === 'Pendiente de devolución') {
              return <span style={{ color: 'orange' }}>{text}</span>;
            }
            if (text === 'Devolución en camino') {
              return <span style={{ color: 'green' }}>{text}</span>;
            }
            if (text === 'Recibido') {
              return <span style={{ color: 'green' }}>{text}</span>;
            }
            return text;
          }
        },
        {
          title: 'TIPO',
          dataIndex: 'order_payment_method.name',
          key: 'order_payment_method.name',
          width: 100
        },
        {
          title: 'TOTAL',
          dataIndex: 'total_paid_amount',
          key: 'total_paid_amount',
          render: text => (
            <span>${formatNumberAmount(text, '.', '.', ',')}</span>
          )
        },
        {
          title: 'FECHA CREACIÓN',
          dataIndex: 'created_at',
          key: 'created_at',
          render: text => <Moment format="DD-MM-YYYY hh:mm A">{text}</Moment>
        },
        {
          title: 'FOLIO ODOO',
          dataIndex: 'folio_odoo',
          key: 'folio_odoo',
          render: text => (
            <span>{text === null || text === '' ? 'No disponible' : text}</span>
          )
        }
      ];
      return (
        <div>
          <Row gutter={16} style={{ marginBottom: 20 }}>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              <Input
                placeholder="Orden ID"
                allowClear
                onChange={this.handleSearchByShippingNumberOrder}
                style={{ width: '100%' }}
              />
            </Col>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              <Input
                placeholder="Cliente"
                allowClear
                onChange={this.handleSearchByByNameOrder}
                style={{ width: '100%' }}
              />
            </Col>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              <Select
                placeholder="Estatus"
                style={{ width: '100%' }}
                onChange={this.handleSearchByStatusOrder}
              >
                <Select.Option value="">Ninguno</Select.Option>
                {statusList.map(value => (
                  <Select.Option
                    key={`status-order-${value.id}`}
                    value={value.id}
                  >
                    {value.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              <Input
                placeholder="Total"
                allowClear
                onChange={this.handleSearchByTotalOrder}
                style={{ width: '100%' }}
              />
            </Col>
            <br />
            <br />
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              <DatePicker
                placeholder="Fecha inicio"
                style={{ width: '100%' }}
                onChange={this.handleSearchByInitDateStart}
              />
            </Col>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              <DatePicker
                placeholder="Fecha termino"
                style={{ width: '100%' }}
                onChange={this.handleSearchByInitDateEnd}
              />
            </Col>
            <Col xs={24} sm={6} md={6}>
              <Select
                defaultValue="DESC"
                placeholder="Orden"
                style={{ width: '100%' }}
                onChange={this.handleSearchBySortOrder}
              >
                <Select.Option key="sort-1" value="ASC">
                  ASC
                </Select.Option>
                <Select.Option key="sort-2" value="DESC">
                  DESC
                </Select.Option>
              </Select>
            </Col>
            <Col xs={24} sm={6} md={6}>
              <Tooltip title="Descargar excel" placement="right">
                <a
                  href={`${
                    process.env.API_URL
                  }/api/v1/admin/export-orders?${Object.keys(extraBySearch)
                    .map(key => `${key}=${extraBySearch[key]}`)
                    .join('&')}`}
                  target="_blank"
                  className="ant-btn ant-btn-primary ant-btn-icon-only"
                  rel="noopener noreferrer"
                >
                  <Icon type="file-excel" />
                </a>
              </Tooltip>
            </Col>
          </Row>
          <Table
            loading={loading}
            bordered
            size="small"
            dataSource={list}
            columns={columns}
            pagination={false}
            scroll={{ x: 'max-content' }}
          />

          <Pagination
            list="orders.list"
            apiUrl="admin/orders"
            extraBySearch={extraBySearch}
          />
        </div>
      );
    }
  }
);
