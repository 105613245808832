import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Card, Row, Col, Icon, Divider, Alert, Select } from 'antd';
import 'moment/locale/es';
import moment from 'moment';
import classNames from 'classnames/bind';
import styles from './home.css';

const cx = classNames.bind(styles);
const style = {
  select: {
    fontSize: 9,
    width: 110
  },
  selectYear: {
    fontSize: 9,
    width: 110,
    marginRight: '1em'
  },
  select2: {
    fontSize: 9
  },
  selectContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
    /* justify-content: 'space-between', alignItems: 'center' */
  }
};
const { Option } = Select;

export default connect(
  {
    // state
    dashboard: state.dashboard,
    brandList: state.brandList,
    dashboardBrandSalesSelected: state.dashboardBrandSalesSelected,
    sales_month_date: state.dashboardDates.sales_month_date,
    coupons_redeemed_for_the_month_date: state.dashboardDates.coupons_redeemed_for_the_month_date,
    users_date: state.dashboardDates.users_date,

    // sequences
    handleChangeSalesMonthDateDashboard: sequences.handleChangeSalesMonthDateDashboard,
    handleChangeSalesYearDateDashboard: sequences.handleChangeSalesYearDateDashboard,
    handleChangeCouponsRedeemedForTheMonthDateDashboard: sequences.handleChangeCouponsRedeemedForTheMonthDateDashboard,
    handleChangeUsersDateDashboard: sequences.handleChangeUsersDateDashboard,
    onChangeDaySalesBrandIdDashboard: sequences.onChangeDaySalesBrandIdDashboard,
    onChangeMonthSalesBrandIdDashboard: sequences.onChangeMonthSalesBrandIdDashboard,
    onChangeTotalSalesBrandIdDashboard: sequences.onChangeTotalSalesBrandIdDashboard,
    loadAsyncDashboardData: sequences.loadAsyncDashboardData,
  },
  class Home extends Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.handleChangeSalesMonthDate = this.handleChangeSalesMonthDate.bind(this);
      this.handleChangeSalesYearDate = this.handleChangeSalesYearDate.bind(this);
      this.handleChangeCouponsRedeemedForTheMonthDate = this.handleChangeCouponsRedeemedForTheMonthDate.bind(this);
      this.handleChangeUsersDate = this.handleChangeUsersDate.bind(this);

      this.onLoad = this.onLoad.bind(this);
      this.onChangeDaySalesBrandId = this.onChangeDaySalesBrandId.bind(this);
      this.onChangeMonthSalesBrandId = this.onChangeMonthSalesBrandId.bind(this);
      this.onChangeTotalSalesBrandId = this.onChangeTotalSalesBrandId.bind(this);
    }

    onLoad() {
      const { loadAsyncDashboardData } = this.props;
      loadAsyncDashboardData();
    }

    handleChangeSalesMonthDate(value) {
      const { handleChangeSalesMonthDateDashboard } = this.props;
      handleChangeSalesMonthDateDashboard({ date: value });
    }

    handleChangeSalesYearDate(value) {
      const { handleChangeSalesYearDateDashboard } = this.props;
      handleChangeSalesYearDateDashboard({ date: value });
    }

    onChangeDaySalesBrandId(value) { 
      const { onChangeDaySalesBrandIdDashboard } = this.props;
      onChangeDaySalesBrandIdDashboard({ brand_id: value });
    }

    onChangeMonthSalesBrandId(value) {
      const { onChangeMonthSalesBrandIdDashboard } = this.props;
      onChangeMonthSalesBrandIdDashboard({ brand_id: value });
    }

    onChangeTotalSalesBrandId(value) {
      const { onChangeTotalSalesBrandIdDashboard } = this.props;
      onChangeTotalSalesBrandIdDashboard({ brand_id: value });
    }

    handleChangeCouponsRedeemedForTheMonthDate(value) {
      const {
        handleChangeCouponsRedeemedForTheMonthDateDashboard
      } = this.props;
      handleChangeCouponsRedeemedForTheMonthDateDashboard({ date: value });
    }

    handleChangeUsersDate(value) {
      const { handleChangeUsersDateDashboard } = this.props;
      handleChangeUsersDateDashboard({ date: value });
    }

    // eslint-disable-next-line class-methods-use-this
    renderOptions() {
      const list = [];
      list.push({
        name: moment().format('MMMM YY'),
        value: moment().format('YYYY-MM-DD')
      });
      // eslint-disable-next-line no-plusplus
      for (let index = 1; index < 12; index++) {
        list.push({
          name: moment()
            .subtract(index, 'month')
            .format('MMMM YY'),
          value: moment()
            .subtract(index, 'month')
            .format('YYYY-MM-DD')
        });
      }

      return list;
    }

    getCount(param, brand_id = null) {
      if ( param == 'daily' ) {
        if ( brand_id ) {
          switch (brand_id) {
            case 1: return this.props.dashboard.sales_of_the_day_total.calm_la_girls;
            case 2: return this.props.dashboard.sales_of_the_day_total.calm_hayan;
            case 3: return this.props.dashboard.sales_of_the_day_total.calm_la_colors;
            case 4: return this.props.dashboard.sales_of_the_day_total.calm_jlash;
            case 6: return this.props.dashboard.sales_of_the_day_total.calm_jbabe;
          }
          return 0;
        }
        var total = parseFloat(this.props.dashboard.sales_of_the_day_total.calm_la_girls) + 
          parseFloat(this.props.dashboard.sales_of_the_day_total.calm_hayan) +
          parseFloat(this.props.dashboard.sales_of_the_day_total.calm_la_colors) +
          parseFloat(this.props.dashboard.sales_of_the_day_total.calm_jbabe) +
          parseFloat(this.props.dashboard.sales_of_the_day_total.calm_jlash);

        return isNaN(total) ? 'Calculando... ' : total;
      }
      if ( param == 'monthly' ) {
        if ( brand_id ) {
          switch (brand_id) {
            case 1: return this.props.dashboard.sales_of_the_month_total.calm_la_girls;
            case 2: return this.props.dashboard.sales_of_the_month_total.calm_hayan;
            case 3: return this.props.dashboard.sales_of_the_month_total.calm_la_colors;
            case 4: return this.props.dashboard.sales_of_the_month_total.calm_jlash;
            case 6: return this.props.dashboard.sales_of_the_month_total.calm_jbabe;
          }
          return 0;
        }

        var total = parseFloat(this.props.dashboard.sales_of_the_month_total.calm_la_girls) + 
          parseFloat(this.props.dashboard.sales_of_the_month_total.calm_hayan) +
          parseFloat(this.props.dashboard.sales_of_the_month_total.calm_la_colors) +
          parseFloat(this.props.dashboard.sales_of_the_month_total.calm_jbabe) +
          parseFloat(this.props.dashboard.sales_of_the_month_total.calm_jlash);
          
        return isNaN(this.props.dashboard.sales_of_the_month_total.all) ? 'Calculando... ' : this.props.dashboard.sales_of_the_month_total.all;
//        return isNaN(total) ? 'Calculando... ' : 123123131;
      }
      if ( param == 'total' ) {
        if ( brand_id ) {
          switch (brand_id) {
            case 1: return this.props.dashboard.total_sales_total.calm_la_girls;
            case 2: return this.props.dashboard.total_sales_total.calm_hayan;
            case 3: return this.props.dashboard.total_sales_total.calm_la_colors;
            case 4: return this.props.dashboard.total_sales_total.calm_jlash;
            case 6: return this.props.dashboard.total_sales_total.calm_jbabe;
          }
          return 0;
        }

        var total = parseFloat(this.props.dashboard.total_sales_total.calm_la_girls) + 
          parseFloat(this.props.dashboard.total_sales_total.calm_hayan) +
          parseFloat(this.props.dashboard.total_sales_total.calm_la_colors) +
          parseFloat(this.props.dashboard.total_sales_total.calm_jbabe) +
          parseFloat(this.props.dashboard.total_sales_total.calm_jlash);
          
        return isNaN(total) ? 'Calculando... ' : total;
      }
      return 0;
    }

    getAmount(param, brand_id = null) {
      const options1 = { style: 'currency', currency: 'MXN' };
      const moneyFormat = new Intl.NumberFormat('es-MX', options1);

      if ( param == 'daily' ) {
        if ( brand_id ) {
          switch (brand_id) {
            case 1: return moneyFormat.format(this.props.dashboard.sales_of_the_day_amount.calm_la_girls);
            case 2: return moneyFormat.format(this.props.dashboard.sales_of_the_day_amount.calm_hayan);
            case 3: return moneyFormat.format(this.props.dashboard.sales_of_the_day_amount.calm_la_colors);
            case 4: return moneyFormat.format(this.props.dashboard.sales_of_the_day_amount.calm_jlash);
            case 6: return moneyFormat.format(this.props.dashboard.sales_of_the_day_amount.calm_jbabe);
          }
          return moneyFormat.format(0);
        } 

        var total = parseFloat(this.props.dashboard.sales_of_the_day_amount.calm_la_girls) + 
          parseFloat(this.props.dashboard.sales_of_the_day_amount.calm_hayan) +
          parseFloat(this.props.dashboard.sales_of_the_day_amount.calm_la_colors) +
          parseFloat(this.props.dashboard.sales_of_the_day_amount.calm_jbabe) +
          parseFloat(this.props.dashboard.sales_of_the_day_amount.calm_jlash);
        
        return isNaN(total) ? 'Calculando... ' : moneyFormat.format(total);

      }

      if ( param == 'monthly' ) {
        if ( brand_id ) {
          switch (brand_id) {
            case 1: return 'Calculando...' == this.props.dashboard.sales_of_the_month_amount.calm_la_girls ? this.props.dashboard.sales_of_the_month_amount.calm_la_girls : moneyFormat.format(this.props.dashboard.sales_of_the_month_amount.calm_la_girls);
            case 2: return 'Calculando...' == this.props.dashboard.sales_of_the_month_amount.calm_hayan ? this.props.dashboard.sales_of_the_month_amount.calm_hayan : moneyFormat.format(this.props.dashboard.sales_of_the_month_amount.calm_hayan);
            case 3: return 'Calculando...' == this.props.dashboard.sales_of_the_month_amount.calm_la_colors ? this.props.dashboard.sales_of_the_month_amount.calm_la_colors : moneyFormat.format(this.props.dashboard.sales_of_the_month_amount.calm_la_colors);
            case 4: return 'Calculando...' == this.props.dashboard.sales_of_the_month_amount.calm_jlash ? this.props.dashboard.sales_of_the_month_amount.calm_jlash : moneyFormat.format(this.props.dashboard.sales_of_the_month_amount.calm_jlash);
            case 6: return 'Calculando...' == this.props.dashboard.sales_of_the_month_amount.calm_jbabe ? this.props.dashboard.sales_of_the_month_amount.calm_jbabe : moneyFormat.format(this.props.dashboard.sales_of_the_month_amount.calm_jbabe);
          }
          return moneyFormat.format(0);
        } else {

          return isNaN(this.props.dashboard.sales_of_the_month_amount.all) ? 'Calculando... ' : moneyFormat.format(this.props.dashboard.sales_of_the_month_amount.all);
        }
      }
      if ( param == 'total' ) {
        if ( brand_id ) {
          switch (brand_id) {
            case 1: return 'Calculando...' == this.props.dashboard.total_sales_amount.calm_la_girls ? this.props.dashboard.total_sales_amount.calm_la_girls : moneyFormat.format(this.props.dashboard.total_sales_amount.calm_la_girls);
            case 2: return 'Calculando...' == this.props.dashboard.total_sales_amount.calm_hayan ? this.props.dashboard.total_sales_amount.calm_hayan : moneyFormat.format(this.props.dashboard.total_sales_amount.calm_hayan);
            case 3: return 'Calculando...' == this.props.dashboard.total_sales_amount.calm_la_colors ? this.props.dashboard.total_sales_amount.calm_la_colors : moneyFormat.format(this.props.dashboard.total_sales_amount.calm_la_colors);
            case 4: return 'Calculando...' == this.props.dashboard.total_sales_amount.calm_jlash ? this.props.dashboard.total_sales_amount.calm_jlash : moneyFormat.format(this.props.dashboard.total_sales_amount.calm_jlash);
            case 6: return 'Calculando...' == this.props.dashboard.total_sales_amount.calm_jbabe ? this.props.dashboard.total_sales_amount.calm_jbabe : moneyFormat.format(this.props.dashboard.total_sales_amount.calm_jbabe);
          }
          return moneyFormat.format(0);
        }else {
          var total = parseFloat(this.props.dashboard.total_sales_amount.calm_la_girls) + 
            parseFloat(this.props.dashboard.total_sales_amount.calm_hayan) +
            parseFloat(this.props.dashboard.total_sales_amount.calm_la_colors) +
            parseFloat(this.props.dashboard.total_sales_amount.calm_jbabe) +
            parseFloat(this.props.dashboard.total_sales_amount.calm_jlash);
            
          return isNaN(total) ? 'Calculando... ' : moneyFormat.format(total);
        }
      }
      return moneyFormat.format(0);
    }

    render() {

      if (!this.props.dashboard.loaded) {
        this.onLoad();
      }
      
      const {
        brandList,
        dashboard,
        dashboardBrandSalesSelected,
        sales_month_date,
        coupons_redeemed_for_the_month_date,
        users_date
      } = this.props;

      return (
        <Row gutter={16} type="flex">
          <Col xs={24} sm={8} md={8} className={cx('mb')}>
            <Card className={cx('card-bg-1', 'card')}>
              <div style={style.selectContainer}>
                <div>
                  <h1>VENTAS DEL DIA</h1>
                </div>

                <div>
                  <Select
                    defaultValue={dashboardBrandSalesSelected.dayBrandId}
                    style={style.select}
                    onChange={this.onChangeDaySalesBrandId}>
                    {brandList.map(item => (
                      <Option
                        style={style.select2}
                        key={`day-brand-${item.name}`}
                        value={item.id}>
                        {item.name.toUpperCase()}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className={cx('text')}>
                <span className={cx('icon')}>
                  <Icon type="dollar" />
                </span>
                <span>{this.getAmount('daily', dashboard.brand_dayly_id)}</span>
              </div>
              <div className={cx('text')}>
                <span className={cx('icon')}>
                  <Icon type="number" />
                </span>
                <span>{this.getCount('daily', dashboard.brand_dayly_id)}</span>
              </div>
              <svg className={cx('waves')} width="530" height="50">
                <g>
                  <path
                    d="M0,25Q38.27777777777778,19.291666666666664,44.166666666666664,19.374999999999996C52.99999999999999,19.499999999999996,79.5,25.0625,88.33333333333333,26.25S123.66666666666667,30.875,132.5,31.25S167.83333333333331,30.625,176.66666666666666,30S211.99999999999997,24.25,220.83333333333331,25S256.1666666666667,35.625,265,37.5S300.3333333333333,43.75,309.16666666666663,43.75S344.5,38.4375,353.3333333333333,37.5S388.6666666666667,35.3125,397.5,34.375S432.8333333333333,27.8125,441.66666666666663,28.125S477,37.8125,485.8333333333333,37.5Q491.72222222222223,37.291666666666664,530,25L530,50Q491.72222222222223,50,485.8333333333333,50C477,50,450.49999999999994,50,441.66666666666663,50S406.3333333333333,50,397.5,50S362.16666666666663,50,353.3333333333333,50S317.99999999999994,50,309.16666666666663,50S273.8333333333333,50,265,50S229.66666666666666,50,220.83333333333331,50S185.5,50,176.66666666666666,50S141.33333333333334,50,132.5,50S97.16666666666666,50,88.33333333333333,50S52.99999999999999,50,44.166666666666664,50Q38.27777777777778,50,0,50Z"
                    fill="rgba(255,255,255,0.5)"
                  />
                </g>
              </svg>
            </Card>
          </Col>
          <Col xs={24} sm={8} md={8} className={cx('mb')}>
            <Card className={cx('card-bg-2', 'card')}>

              <div style={style.selectContainer}>
                <div>
                  <h1>VENTAS DEL MES</h1>
                  <Select
                    defaultValue={sales_month_date}
                    style={style.select}
                    onChange={this.handleChangeSalesMonthDate}
                  >
                    {this.renderOptions().map(item => (
                      <Option
                        style={style.select2}
                        key={`sales_month_date-${item.name}`}
                        value={item.value}
                      >
                        {item.name.toUpperCase()}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div>
                  <Select
                    defaultValue={dashboardBrandSalesSelected.monthBrandId}
                    style={style.select}
                    onChange={this.onChangeMonthSalesBrandId}>
                    {brandList.map(item => (
                      <Option
                        style={style.select2}
                        key={`month-brand-${item.name}`}
                        value={item.id}>
                        {item.name.toUpperCase()}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className={cx('text')}>
                <span className={cx('icon')}>
                  <Icon type="dollar" />
                </span>
                <span>{this.getAmount('monthly', dashboard.brand_monthly_id)}</span>
              </div>
              <div className={cx('text')}>
                <span className={cx('icon')}>
                  <Icon type="number" />
                </span>
                <span>{this.getCount('monthly', dashboard.brand_monthly_id)}</span>
              </div>
              <svg className={cx('waves')} width="530" height="50">
                <g>
                  <path
                    d="M0,25Q38.27777777777778,19.291666666666664,44.166666666666664,19.374999999999996C52.99999999999999,19.499999999999996,79.5,25.0625,88.33333333333333,26.25S123.66666666666667,30.875,132.5,31.25S167.83333333333331,30.625,176.66666666666666,30S211.99999999999997,24.25,220.83333333333331,25S256.1666666666667,35.625,265,37.5S300.3333333333333,43.75,309.16666666666663,43.75S344.5,38.4375,353.3333333333333,37.5S388.6666666666667,35.3125,397.5,34.375S432.8333333333333,27.8125,441.66666666666663,28.125S477,37.8125,485.8333333333333,37.5Q491.72222222222223,37.291666666666664,530,25L530,50Q491.72222222222223,50,485.8333333333333,50C477,50,450.49999999999994,50,441.66666666666663,50S406.3333333333333,50,397.5,50S362.16666666666663,50,353.3333333333333,50S317.99999999999994,50,309.16666666666663,50S273.8333333333333,50,265,50S229.66666666666666,50,220.83333333333331,50S185.5,50,176.66666666666666,50S141.33333333333334,50,132.5,50S97.16666666666666,50,88.33333333333333,50S52.99999999999999,50,44.166666666666664,50Q38.27777777777778,50,0,50Z"
                    fill="rgba(255,255,255,0.5)"
                  />
                </g>
              </svg>
            </Card>
          </Col>
          <Col xs={24} sm={8} md={8} className={cx('mb')}>
            <Card className={cx('card-bg-3', 'card')}>
            <div style={style.selectContainer}>
              <div>
                <h1>VENTAS TOTALES</h1>
                <Select
                    defaultValue={ new Date().getFullYear() }
                    style={style.select}
                    onChange={this.handleChangeSalesYearDate}
                  >
                    {dashboard.sales_years.map(item => (
                      <Option
                        style={style.select2}
                        key={`sales_month_date-${item}`}
                        value={item}
                      >
                        {item}
                      </Option>
                    ))}
                  </Select>
              </div>

              <div>
                <Select
                  defaultValue={dashboardBrandSalesSelected.totalBrandId}
                  style={style.select}
                  onChange={this.onChangeTotalSalesBrandId}>
                  {brandList.map(item => (
                    <Option
                      style={style.select2}
                      key={`total-brand-${item.name}`}
                      value={item.id}>
                      {item.name.toUpperCase()}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>

              <div className={cx('text')}>
                <span className={cx('icon')}>
                  <Icon type="dollar" />
                </span>
                <span>{this.getAmount('total', dashboard.brand_id)}</span>
              </div>
              <div className={cx('text')}>
                <span className={cx('icon')}>
                  <Icon type="number" />
                </span>
                <span>{this.getCount('total', dashboard.brand_id)}</span>
              </div>
              <svg className={cx('waves')} width="530" height="50">
                <g>
                  <path
                    d="M0,25Q38.27777777777778,19.291666666666664,44.166666666666664,19.374999999999996C52.99999999999999,19.499999999999996,79.5,25.0625,88.33333333333333,26.25S123.66666666666667,30.875,132.5,31.25S167.83333333333331,30.625,176.66666666666666,30S211.99999999999997,24.25,220.83333333333331,25S256.1666666666667,35.625,265,37.5S300.3333333333333,43.75,309.16666666666663,43.75S344.5,38.4375,353.3333333333333,37.5S388.6666666666667,35.3125,397.5,34.375S432.8333333333333,27.8125,441.66666666666663,28.125S477,37.8125,485.8333333333333,37.5Q491.72222222222223,37.291666666666664,530,25L530,50Q491.72222222222223,50,485.8333333333333,50C477,50,450.49999999999994,50,441.66666666666663,50S406.3333333333333,50,397.5,50S362.16666666666663,50,353.3333333333333,50S317.99999999999994,50,309.16666666666663,50S273.8333333333333,50,265,50S229.66666666666666,50,220.83333333333331,50S185.5,50,176.66666666666666,50S141.33333333333334,50,132.5,50S97.16666666666666,50,88.33333333333333,50S52.99999999999999,50,44.166666666666664,50Q38.27777777777778,50,0,50Z"
                    fill="rgba(255,255,255,0.5)"
                  />
                </g>
              </svg>
            </Card>
          </Col>
          <Col xs={24} sm={8} md={8} className={cx('mb')}>
            <Card className={cx('card-bg-4', 'card')}>
              <div>
                <h1>ORDENES PENDIENTES DE PAGO</h1>
              </div>
              <div className={cx('text')}>
                <span className={cx('icon')}>
                  <Icon type="dollar" />
                </span>
                <span>{dashboard.pending_payment_orders_amount}</span>
              </div>
              <div className={cx('text')}>
                <span className={cx('icon')}>
                  <Icon type="number" />
                </span>
                <span>{dashboard.pending_payment_orders_total}</span>
              </div>
              <svg className={cx('waves')} width="530" height="50">
                <g>
                  <path
                    d="M0,25Q38.27777777777778,19.291666666666664,44.166666666666664,19.374999999999996C52.99999999999999,19.499999999999996,79.5,25.0625,88.33333333333333,26.25S123.66666666666667,30.875,132.5,31.25S167.83333333333331,30.625,176.66666666666666,30S211.99999999999997,24.25,220.83333333333331,25S256.1666666666667,35.625,265,37.5S300.3333333333333,43.75,309.16666666666663,43.75S344.5,38.4375,353.3333333333333,37.5S388.6666666666667,35.3125,397.5,34.375S432.8333333333333,27.8125,441.66666666666663,28.125S477,37.8125,485.8333333333333,37.5Q491.72222222222223,37.291666666666664,530,25L530,50Q491.72222222222223,50,485.8333333333333,50C477,50,450.49999999999994,50,441.66666666666663,50S406.3333333333333,50,397.5,50S362.16666666666663,50,353.3333333333333,50S317.99999999999994,50,309.16666666666663,50S273.8333333333333,50,265,50S229.66666666666666,50,220.83333333333331,50S185.5,50,176.66666666666666,50S141.33333333333334,50,132.5,50S97.16666666666666,50,88.33333333333333,50S52.99999999999999,50,44.166666666666664,50Q38.27777777777778,50,0,50Z"
                    fill="rgba(255,255,255,0.5)"
                  />
                </g>
              </svg>
            </Card>
          </Col>
          <Col xs={24} sm={8} md={8} className={cx('mb')}>
            <Card className={cx('card-bg-1', 'card')}>
              <div>
                <h1>ORDENES VENCIDAS</h1>
              </div>
              <div className={cx('text')}>
                <span className={cx('icon')}>
                  <Icon type="dollar" />
                </span>
                <span>{dashboard.expiry_orders_amount}</span>
              </div>
              <div className={cx('text')}>
                <span className={cx('icon')}>
                  <Icon type="number" />
                </span>
                <span>{dashboard.expiry_orders_total}</span>
              </div>
              <svg className={cx('waves')} width="530" height="50">
                <g>
                  <path
                    d="M0,25Q38.27777777777778,19.291666666666664,44.166666666666664,19.374999999999996C52.99999999999999,19.499999999999996,79.5,25.0625,88.33333333333333,26.25S123.66666666666667,30.875,132.5,31.25S167.83333333333331,30.625,176.66666666666666,30S211.99999999999997,24.25,220.83333333333331,25S256.1666666666667,35.625,265,37.5S300.3333333333333,43.75,309.16666666666663,43.75S344.5,38.4375,353.3333333333333,37.5S388.6666666666667,35.3125,397.5,34.375S432.8333333333333,27.8125,441.66666666666663,28.125S477,37.8125,485.8333333333333,37.5Q491.72222222222223,37.291666666666664,530,25L530,50Q491.72222222222223,50,485.8333333333333,50C477,50,450.49999999999994,50,441.66666666666663,50S406.3333333333333,50,397.5,50S362.16666666666663,50,353.3333333333333,50S317.99999999999994,50,309.16666666666663,50S273.8333333333333,50,265,50S229.66666666666666,50,220.83333333333331,50S185.5,50,176.66666666666666,50S141.33333333333334,50,132.5,50S97.16666666666666,50,88.33333333333333,50S52.99999999999999,50,44.166666666666664,50Q38.27777777777778,50,0,50Z"
                    fill="rgba(255,255,255,0.5)"
                  />
                </g>
              </svg>
            </Card>
          </Col>
          <Col xs={24} sm={8} md={8} className={cx('mb')}>
            <Card className={cx('card-bg-2', 'card')}>
              <div>
                <h1>CUPONES CANJEADOS DEL MES</h1>
                <Select
                  defaultValue={coupons_redeemed_for_the_month_date}
                  style={style.select}
                  onChange={this.handleChangeCouponsRedeemedForTheMonthDate}
                >
                  {this.renderOptions().map(item => (
                    <Option
                      style={style.select2}
                      key={`coupons_redeemed_for_the_month_date-${item.name}`}
                      value={item.value}
                    >
                      {item.name.toUpperCase()}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className={cx('text')}>
                <span className={cx('icon')}>
                  <Icon type="dollar" />
                </span>
                <span>{dashboard.coupons_redeemed_for_the_month_amount}</span>
              </div>
              <div className={cx('text')}>
                <span className={cx('icon')}>
                  <Icon type="number" />
                </span>
                <span>{dashboard.coupons_redeemed_for_the_month_total}</span>
              </div>
              <svg className={cx('waves')} width="530" height="50">
                <g>
                  <path
                    d="M0,25Q38.27777777777778,19.291666666666664,44.166666666666664,19.374999999999996C52.99999999999999,19.499999999999996,79.5,25.0625,88.33333333333333,26.25S123.66666666666667,30.875,132.5,31.25S167.83333333333331,30.625,176.66666666666666,30S211.99999999999997,24.25,220.83333333333331,25S256.1666666666667,35.625,265,37.5S300.3333333333333,43.75,309.16666666666663,43.75S344.5,38.4375,353.3333333333333,37.5S388.6666666666667,35.3125,397.5,34.375S432.8333333333333,27.8125,441.66666666666663,28.125S477,37.8125,485.8333333333333,37.5Q491.72222222222223,37.291666666666664,530,25L530,50Q491.72222222222223,50,485.8333333333333,50C477,50,450.49999999999994,50,441.66666666666663,50S406.3333333333333,50,397.5,50S362.16666666666663,50,353.3333333333333,50S317.99999999999994,50,309.16666666666663,50S273.8333333333333,50,265,50S229.66666666666666,50,220.83333333333331,50S185.5,50,176.66666666666666,50S141.33333333333334,50,132.5,50S97.16666666666666,50,88.33333333333333,50S52.99999999999999,50,44.166666666666664,50Q38.27777777777778,50,0,50Z"
                    fill="rgba(255,255,255,0.5)"
                  />
                </g>
              </svg>
            </Card>
          </Col>
          <Col xs={24} sm={8} md={8} className={cx('mb')}>
            <Card className={cx('card-bg-3', 'card')}>
              <div>
                <h1>CUPONES TOTALES</h1>
              </div>
              <div className={cx('text')}>
                <span className={cx('icon')}>
                  <Icon type="dollar" />
                </span>
                <span>{dashboard.total_coupons_redeemed_amount}</span>
              </div>
              <div className={cx('text')}>
                <span className={cx('icon')}>
                  <Icon type="number" />
                </span>
                <span>{dashboard.total_coupons_redeemed_total}</span>
              </div>
              <svg className={cx('waves')} width="530" height="50">
                <g>
                  <path
                    d="M0,25Q38.27777777777778,19.291666666666664,44.166666666666664,19.374999999999996C52.99999999999999,19.499999999999996,79.5,25.0625,88.33333333333333,26.25S123.66666666666667,30.875,132.5,31.25S167.83333333333331,30.625,176.66666666666666,30S211.99999999999997,24.25,220.83333333333331,25S256.1666666666667,35.625,265,37.5S300.3333333333333,43.75,309.16666666666663,43.75S344.5,38.4375,353.3333333333333,37.5S388.6666666666667,35.3125,397.5,34.375S432.8333333333333,27.8125,441.66666666666663,28.125S477,37.8125,485.8333333333333,37.5Q491.72222222222223,37.291666666666664,530,25L530,50Q491.72222222222223,50,485.8333333333333,50C477,50,450.49999999999994,50,441.66666666666663,50S406.3333333333333,50,397.5,50S362.16666666666663,50,353.3333333333333,50S317.99999999999994,50,309.16666666666663,50S273.8333333333333,50,265,50S229.66666666666666,50,220.83333333333331,50S185.5,50,176.66666666666666,50S141.33333333333334,50,132.5,50S97.16666666666666,50,88.33333333333333,50S52.99999999999999,50,44.166666666666664,50Q38.27777777777778,50,0,50Z"
                    fill="rgba(255,255,255,0.5)"
                  />
                </g>
              </svg>
            </Card>
          </Col>
          <Col xs={24} sm={8} md={8} className={cx('mb')}>
            <Card className={cx('card-bg-4', 'card')}>
              <div>
                <h1>USUARIOS REGISTRADOS DEL MES</h1>
                <Select
                  defaultValue={users_date}
                  style={style.select}
                  onChange={this.handleChangeUsersDate}
                >
                  {this.renderOptions().map(item => (
                    <Option
                      style={style.select2}
                      key={`users_date-${item.name}`}
                      value={item.value}
                    >
                      {item.name.toUpperCase()}
                    </Option>
                  ))}
                </Select>
              </div>

              <div className={cx('text')}>
                <span className={cx('icon')}>
                  <Icon type="number" />
                </span>
                <span>{dashboard.registered_users_per_month_amount}</span>
              </div>
              <svg className={cx('waves')} width="530" height="50">
                <g>
                  <path
                    d="M0,25Q38.27777777777778,19.291666666666664,44.166666666666664,19.374999999999996C52.99999999999999,19.499999999999996,79.5,25.0625,88.33333333333333,26.25S123.66666666666667,30.875,132.5,31.25S167.83333333333331,30.625,176.66666666666666,30S211.99999999999997,24.25,220.83333333333331,25S256.1666666666667,35.625,265,37.5S300.3333333333333,43.75,309.16666666666663,43.75S344.5,38.4375,353.3333333333333,37.5S388.6666666666667,35.3125,397.5,34.375S432.8333333333333,27.8125,441.66666666666663,28.125S477,37.8125,485.8333333333333,37.5Q491.72222222222223,37.291666666666664,530,25L530,50Q491.72222222222223,50,485.8333333333333,50C477,50,450.49999999999994,50,441.66666666666663,50S406.3333333333333,50,397.5,50S362.16666666666663,50,353.3333333333333,50S317.99999999999994,50,309.16666666666663,50S273.8333333333333,50,265,50S229.66666666666666,50,220.83333333333331,50S185.5,50,176.66666666666666,50S141.33333333333334,50,132.5,50S97.16666666666666,50,88.33333333333333,50S52.99999999999999,50,44.166666666666664,50Q38.27777777777778,50,0,50Z"
                    fill="rgba(255,255,255,0.5)"
                  />
                </g>
              </svg>
            </Card>
          </Col>
          <Col xs={24} sm={8} md={8} className={cx('mb')}>
            <Card className={cx('card-bg-1', 'card')}>
              <div>
                <h1>REGISTROS TOTALES</h1>
              </div>
              <div className={cx('text')}>
                <span className={cx('icon')}>
                  <Icon type="number" />
                </span>
                <span>{dashboard.users}</span>
              </div>
              <svg className={cx('waves')} width="530" height="50">
                <g>
                  <path
                    d="M0,25Q38.27777777777778,19.291666666666664,44.166666666666664,19.374999999999996C52.99999999999999,19.499999999999996,79.5,25.0625,88.33333333333333,26.25S123.66666666666667,30.875,132.5,31.25S167.83333333333331,30.625,176.66666666666666,30S211.99999999999997,24.25,220.83333333333331,25S256.1666666666667,35.625,265,37.5S300.3333333333333,43.75,309.16666666666663,43.75S344.5,38.4375,353.3333333333333,37.5S388.6666666666667,35.3125,397.5,34.375S432.8333333333333,27.8125,441.66666666666663,28.125S477,37.8125,485.8333333333333,37.5Q491.72222222222223,37.291666666666664,530,25L530,50Q491.72222222222223,50,485.8333333333333,50C477,50,450.49999999999994,50,441.66666666666663,50S406.3333333333333,50,397.5,50S362.16666666666663,50,353.3333333333333,50S317.99999999999994,50,309.16666666666663,50S273.8333333333333,50,265,50S229.66666666666666,50,220.83333333333331,50S185.5,50,176.66666666666666,50S141.33333333333334,50,132.5,50S97.16666666666666,50,88.33333333333333,50S52.99999999999999,50,44.166666666666664,50Q38.27777777777778,50,0,50Z"
                    fill="rgba(255,255,255,0.5)"
                  />
                </g>
              </svg>
            </Card>
          </Col>
          <Col xs={24} sm={8} md={8} className={cx('mb')}>
            <Card className={cx('card-bg-1', 'card')}>
              <div>
                <h1> Sincronización de inventario</h1>
              </div>
              {dashboard.stock_syncs == null && (
                <div className={cx('text')}>
                  <span className={cx('icon')}>
                    <Icon type="warning" />
                  </span>
                  {dashboard.stock_syncs == null && (
                    <span>Sin registro encontrado</span>
                  )}
                </div>
              )}
              {dashboard.stock_syncs != null && (
                <div>
                  <div className={cx('text')}>
                    <span className={cx('icon')}>
                      <Icon type="file-text" />
                    </span>
                    <span>{dashboard.stock_syncs.name_file}</span>
                  </div>
                  <div className={cx('text')}>
                    <span className={cx('icon')}>
                      <Icon type="calendar" />
                    </span>
                    <span>{dashboard.stock_syncs.created_at}</span>
                  </div>
                </div>
              )}
              <svg className={cx('waves')} width="530" height="50">
                <g>
                  <path
                    d="M0,25Q38.27777777777778,19.291666666666664,44.166666666666664,19.374999999999996C52.99999999999999,19.499999999999996,79.5,25.0625,88.33333333333333,26.25S123.66666666666667,30.875,132.5,31.25S167.83333333333331,30.625,176.66666666666666,30S211.99999999999997,24.25,220.83333333333331,25S256.1666666666667,35.625,265,37.5S300.3333333333333,43.75,309.16666666666663,43.75S344.5,38.4375,353.3333333333333,37.5S388.6666666666667,35.3125,397.5,34.375S432.8333333333333,27.8125,441.66666666666663,28.125S477,37.8125,485.8333333333333,37.5Q491.72222222222223,37.291666666666664,530,25L530,50Q491.72222222222223,50,485.8333333333333,50C477,50,450.49999999999994,50,441.66666666666663,50S406.3333333333333,50,397.5,50S362.16666666666663,50,353.3333333333333,50S317.99999999999994,50,309.16666666666663,50S273.8333333333333,50,265,50S229.66666666666666,50,220.83333333333331,50S185.5,50,176.66666666666666,50S141.33333333333334,50,132.5,50S97.16666666666666,50,88.33333333333333,50S52.99999999999999,50,44.166666666666664,50Q38.27777777777778,50,0,50Z"
                    fill="rgba(255,255,255,0.5)"
                  />
                </g>
              </svg>
            </Card>
          </Col>

          {dashboard.products_alert_stock_regular.length > 0 ||
          dashboard.products_alert_stock_combinations.length > 0 ? (
            <Col xs={24} sm={24} md={24}>
              <Divider></Divider>
              <strong style={{ marginBottom: 20, display: 'block' }}>
                AVISOS:
              </strong>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24}>
                  {dashboard.products_alert_stock_regular.map(value => (
                    <Alert
                      showIcon
                      style={{ marginBottom: 10 }}
                      key={`regular-${value.id}`}
                      message={`El producto "${value.name}" con código "${
                        value.reference == null ? 'n/a' : value.reference
                      }" tiene "${value.quantity}" de stock.`}
                      type="warning"
                    />
                  ))}
                  {dashboard.products_alert_stock_combinations.map(value => (
                    <Alert
                      showIcon
                      style={{ marginBottom: 10 }}
                      key={`combination-${value.id}`}
                      message={`El producto "${value.product.name} (${
                        value.option_attribute.name
                      })" con código "${
                        value.reference == null ? 'n/a' : value.reference
                      }" tiene "${value.quantity}" de stock.`}
                      type="warning"
                    />
                  ))}
                </Col>
              </Row>
            </Col>
          ) : null}
        </Row>
      );
    }
  }
);
