import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Tabs, Form, Button, Tooltip, Icon } from 'antd';
import GeneralUpdateMultiple from './forms/GeneralUpdateMultiple';

const { TabPane } = Tabs;
export default Form.create()(
  connect(
    {
      // states
      loadingLogin: state.user.loginButtonDisable,
      openTab: state.products.openTab,
      tagsAll: state.products.tags,
      brandsAll: state.products.brands,
      categoriesAll: state.products.categories,
      submit: sequences.saveProduct,
      updateTab: sequences.updateTab,
      saveNotificationError: sequences.saveNotificationError
    },
    class ProductFormViewNew extends Component {
      constructor(props) {
        super(props);
        this.state = {
          isKit: '0',
          listKits: []
        };
        this.handleSubmitAll = this.handleSubmitAll.bind(this);
        this.onChangeTab = this.onChangeTab.bind(this);
        this.onChangeKit = this.onChangeKit.bind(this);
        this.addProductInKit = this.addProductInKit.bind(this);
        this.removeProductInKit = this.removeProductInKit.bind(this);
      }

      onChangeTab(key) {
        const { updateTab } = this.props;
        updateTab({ tab: key });
      }

      onChangeKit(value) {
        this.value = value;
        this.setState({ isKit: value });
      }

      addProductInKit(value) {
        this.value = value;
        const { listKits } = this.state;
        const newList = listKits.concat([value]);
        this.setState({ listKits: newList });
      }

      removeProductInKit(value) {
        this.value = value;
        const { listKits } = this.state;
        const newList = listKits.filter(item => item !== value);
        this.setState({ listKits: newList });
      }

      handleSubmitAll(e) {
        e.preventDefault();
        const { form, submit } = this.props;
        const { listKits } = this.state;
        form.validateFields((err, values) => {
          if (!err) {
            values.kits_items = listKits;
            submit({ values });
          }
        });
      }

      render() {
        const {
          categoriesAll,
          tagsAll,
          brandsAll,
          form,
          loadingLogin,
          openTab,
          saveNotificationError,
          submit
        } = this.props;
        const { isKit, listKits } = this.state;
        return (
          <div>
            <div className="move-content-right">
              <Tooltip title="Guardar productos" placement="left">
                <Button
                  icon="save"
                  type="primary"
                  htmlType="submit"
                  loading={loadingLogin}
                  onClick={this.handleSubmitAll}
                />
              </Tooltip>
              <Tooltip title="Cancelar" placement="left">
                <a
                  style={{ marginLeft: 10 }}
                  href="/productos"
                  className="ant-btn ant-btn-primary ant-btn-icon-only"
                  rel="noopener noreferrer"
                >
                  <Icon type="arrow-left" />
                </a>
              </Tooltip>
            </div>
            <Tabs onChange={this.onChangeTab} defaultActiveKey={openTab}>
              <TabPane tab="General" key="general">
                <Form layout="horizontal">
                  <GeneralUpdateMultiple
                    submit={submit}
                    form={form}
                    categoriesAll={categoriesAll}
                    tagsAll={tagsAll}
                    brandsAll={brandsAll}
                    saveNotificationError={saveNotificationError}
                    isKit={isKit}
                    listKits={listKits}
                    onChangeKit={this.onChangeKit}
                  />
                </Form>
              </TabPane>
            </Tabs>
          </div>
        );
      }
    }
  )
);
