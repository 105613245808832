import { set } from 'cerebral/factories'; // wait
import { state, props } from 'cerebral';
import moment from 'moment';
import { WithRouter, setPagination } from '@modules/actions';
import notify from '@utils/notify';
import page from 'page';
import * as actions from './actions';

// START RENDER VIEWS
const ProductListRouter = WithRouter([
  set(state.products.search, {
    loading: false,
    searchNameProduct: '',
    searchCategoriesIds: [],
    searchReferenceProduct: '',
    sortProduct: 'ASC',
    brand_id: null
  }),
  actions.getBrandsBySelect,
  {
    success: [actions.setBrandsBySelect],
    error: [notify(props.message.error, 'error')]
  },
  actions.getProducts,
  {
    success: [setPagination, actions.setProducts],
    error: [notify(props.message.error, 'error')]
  },
  set(state.app.currentPage, 'ProductListView'),
  actions.getCategoriesSimple,{
    success: [actions.setCategoriesSimple],
    error: [notify(props.message.error, 'error')]
  },
  actions.getBrandsSimple,{
    success: [actions.setBrandsSimple],
    error: [notify(props.message.error, 'error')]
  },
]);
const ProductNewRouter = WithRouter([
  set(state.app.currentPage, 'ProductFormViewNew'),
  actions.getBrandsSimple,
  {
    success: [actions.setBrandsSimple],
    error: [notify(props.message.error, 'error')]
  },
  actions.getOptionsAttributeSimple,
  {
    success: [actions.setOptionsAttributeSimple],
    error: [notify(props.message.error, 'error')]
  },
  actions.getCategoriesSimple,
  {
    success: [actions.setCategoriesSimple],
    error: [notify(props.message.error, 'error')]
  },
  actions.getTagsSimple,
  {
    success: [setPagination, actions.setTagsSimple],
    error: [notify(props.message.error, 'error')]
  }
]);
const ProductUpdateMultipleRouter = WithRouter([
  set(state.app.currentPage, 'ProductFormViewUpdateMultiple'),
  actions.getBrandsSimple,
  {
    success: [actions.setBrandsSimple],
    error: [notify(props.message.error, 'error')]
  },
  actions.getOptionsAttributeSimple,
  {
    success: [actions.setOptionsAttributeSimple],
    error: [notify(props.message.error, 'error')]
  },
  actions.getCategoriesSimple,
  {
    success: [actions.setCategoriesSimple],
    error: [notify(props.message.error, 'error')]
  },
  actions.getTagsSimple,
  {
    success: [setPagination, actions.setTagsSimple],
    error: [notify(props.message.error, 'error')]
  }
]);
const ProductFormViewUpdate = WithRouter([
  actions.setDefaultStateInProduct,
  actions.getProductDetail,
  {
    success: [
      actions.setProductDetail,
      actions.getBrandsSimple,
      {
        success: [actions.setBrandsSimple],
        error: [notify(props.message.error, 'error')]
      },
      actions.getOptionsAttributeSimple,
      {
        success: [actions.setOptionsAttributeSimple],
        error: [notify(props.message.error, 'error')]
      },
      actions.getCategoriesSimple,
      {
        success: [actions.setCategoriesSimple],
        error: [notify(props.message.error, 'error')]
      },
      actions.getTagsSimple,
      {
        success: [setPagination, actions.setTagsSimple],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  set(state.app.currentPage, 'ProductFormViewUpdate')
]);
const CombinationGalleryView = WithRouter([
  set(state.app.currentPage, 'CombinationGalleryView'),
  actions.getCombinationGallery,
  {
    success: [actions.setCombinationGallery],
    error: [notify(props.message.error, 'error')]
  }
]);
// END RENDER VIEWS
// START SEARCHES
const updatedSearchNameProduct = [
  ({ store }) => {
    store.set(state.products.search.loading, true);
  },
  ({ props, store }) => {
    const { value } = props;
    store.set(state.products.search.searchNameProduct, value);
  },
  actions.getProducts,
  {
    success: [
      setPagination,
      actions.setProducts,
      ({ store }) => {
        store.set(state.products.search.loading, false);
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];
const updatedSearchCategoriesIds = [
  ({ store }) => {
    store.set(state.products.search.loading, true);
  },
  ({ props, store }) => {
    const { value } = props;
    store.set(state.products.search.searchCategoriesIds, value.value);
  },
  actions.getProducts,
  {
    success: [
      setPagination,
      actions.setProducts,
      ({ store }) => {
        store.set(state.products.search.loading, false);
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];
const updatedSearchReferenceProduct = [
  ({ store }) => {
    store.set(state.products.search.loading, true);
  },
  ({ props, store }) => {
    const { value } = props;
    store.set(state.products.search.searchReferenceProduct, value);
  },
  actions.getProducts,
  {
    success: [
      setPagination,
      actions.setProducts,
      ({ store }) => {
        store.set(state.products.search.loading, false);
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];
const updatedSearchSortProduct = [
  ({ store }) => {
    store.set(state.products.search.loading, true);
  },
  ({ props, store }) => {
    const { value } = props;
    store.set(state.products.search.sortProduct, value);
  },
  actions.getProducts,
  {
    success: [
      setPagination,
      actions.setProducts,
      ({ store }) => {
        store.set(state.products.search.loading, false);
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];
const updatedSearchBrand = [
  ({ store }) => {
    store.set(state.products.search.loading, true);
  },
  ({ props, store }) => {
    const { value } = props;
    store.set(
      state.products.search.brand_id,
      typeof value === 'undefined' ? null : value
    );
  },
  actions.getProducts,
  {
    success: [
      setPagination,
      actions.setProducts,
      ({ store }) => {
        store.set(state.products.search.loading, false);
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];
//  END SEARCHES
// EVENTS
const saveProduct = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values } = props;
    const data = new FormData();
    if (typeof values.image_default_url !== 'undefined') {
      data.append(
        'image_default_url',
        values.image_default_url[0].originFileObj
      );
    }
    const { categories, tags, brands } = values;
    data.append('is_kit', values.is_kit);

    data.append('kits_items', JSON.stringify(values.kits_items));

    data.append('name', values.name);
    data.append('description', values.description);
    data.append('type', values.type);

    if (values.weight) {
      data.append('weight', values.weight);
    }
    if (typeof values.video_youtube !== 'undefined') {
      data.append('video_youtube', values.video_youtube);
    }
    if (typeof values.price !== 'undefined') {
      data.append('price', values.price);
    }
    if (typeof values.ingredients !== 'undefined') {
      data.append('ingredients', values.ingredients);
    }
    if (typeof values.instructions !== 'undefined') {
      data.append('instructions', values.instructions);
    }
    if (typeof values.short_description !== 'undefined') {
      data.append('short_description', values.short_description);
    }
    if (values.type === 'REGULAR') {
      data.append('reference', values.reference);
      data.append('quantity', values.quantity);
    }
    if (typeof values.categories !== 'undefined') {
      const categoriesArray = categories.toString().split(',');
      categoriesArray.forEach(element => {
        if (element !== '') {
          data.append('categories[]', element);
        }
      });
    }
    if (typeof values.tags !== 'undefined') {
      const tagsArray = tags.toString().split(',');
      tagsArray.forEach(element => {
        if (element !== '') {
          data.append('tags[]', element);
        }
      });
    }
    if (typeof values.brands !== 'undefined') {
      const brandsArray = brands.toString().split(',');
      brandsArray.forEach(element => {
        if (element !== '') {
          data.append('brands[]', element);
        }
      });
    }
    if (
      values.sort != 'null' &&
      typeof values.sort !== 'undefined' &&
      values.sort != null
    ) {
      data.append('sort', values.sort);
    }
    return http
      .postFile(`admin/products`, data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Producto guardado', 'success'),
      ({ props }) => {
        page.show(`/productos/${props.res.uuid}`);
      }
    ],
    error: [notify(props.message.error, 'error')]
  },

  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];
const updateProduct = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values, productId } = props;
    const data = new FormData();
    if (typeof values.image_default_url !== 'undefined') {
      data.append(
        'image_default_url',
        values.image_default_url[0].originFileObj
      );
    }
    data.append('product_id', productId);
    data.append('name', values.name);
    data.append('is_kit', values.is_kit);

    data.append('kits_items', JSON.stringify(values.kits_items));

    data.append('price', values.price);
    data.append('show', values.show);

    data.append('type', values.type);
    if (values.type === 'REGULAR') {
      data.append('reference', values.reference);
      data.append('quantity', values.quantity);
    }
    data.append(
      'video_youtube',
      values.video_youtube == 'null' ? '' : values.video_youtube
    );

    if (values.weight) {
      data.append('weight', values.weight);
    }

    const { categories, tags, brands } = values;
    const categoriesArray = categories.toString().split(',');
    const tagsArray = tags.toString().split(',');
    const brandsArray = brands.toString().split(',');
    categoriesArray.forEach(element => {
      if (element !== '') {
        data.append('categories[]', element);
      }
    });
    tagsArray.forEach(element => {
      if (element !== '') {
        data.append('tags[]', element);
      }
    });
    brandsArray.forEach(element => {
      if (element !== '') {
        data.append('brands[]', element);
      }
    });
    if (values.description !== null) {
      data.append('description', values.description);
    }
    if (values.ingredients !== null) {
      data.append('ingredients', values.ingredients);
    }
    if (values.instructions !== null) {
      data.append('instructions', values.instructions);
    }
    if (values.short_description !== null) {
      data.append('short_description', values.short_description);
    }
    if (
      values.sort != 'null' &&
      typeof values.sort !== 'undefined' &&
      values.sort != null
    ) {
      data.append('sort', values.sort);
    }
    return http
      .postFile(`admin/products/update`, data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Producto actualizado', 'success'),
      ({ props }) => {
        page.show(`/productos/${props.res.uuid}`);
      }
      /*  actions.setDefaultStateInProduct,
      actions.getProductDetail,
      {
        success: [
          actions.setProductDetail,
          actions.getBrandsSimple,
          {
            success: [actions.setBrandsSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getOptionsAttributeSimple,
          {
            success: [actions.setOptionsAttributeSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getCategoriesSimple,
          {
            success: [actions.setCategoriesSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getTagsSimple,
          {
            success: [setPagination, actions.setTagsSimple],
            error: [notify(props.message.error, 'error')]
          }
        ],
        error: [notify(props.message.error, 'error')]
      } */
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];
const createKit = [
  ({ store }) => {
    store.set(state.kit.loading, true);
  },
  ({ props, http, path }) => {
    const { value } = props;
    return http
      .post(`admin/product-kit`, value)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Producto guardado en kit', 'success'),
      ({ props }) => {
        page.show(`/productos/${props.res.uuid}`);
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.products.openTab, 'kits');
    store.set(state.kit.loading, false);
  }
];
const deleteProductInKit = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { id } = props;

    return http
      .delete(`admin/product-kit/${id}`)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Producto actualizado', 'success'),
      ({ props }) => {
        page.show(`/productos/${props.res.uuid}`);
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.products.openTab, 'kits');
    store.set(state.user.loginButtonDisable, false);
  }
];
const uploadFileProductNormal = [
  set(state.product_files.loading, true),
  actions.uploadFilesNormal,
  {
    success: [
      notify('Archivos subidos', 'success'),
      actions.getProductDetail,
      {
        success: [
          actions.setProductDetail,
          actions.getBrandsSimple,
          {
            success: [actions.setBrandsSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getOptionsAttributeSimple,
          {
            success: [actions.setOptionsAttributeSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getCategoriesSimple,
          {
            success: [actions.setCategoriesSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getTagsSimple,
          {
            success: [setPagination, actions.setTagsSimple],
            error: [notify(props.message.error, 'error')]
          },
          ({ get, http, path, props }) => {
            const data = get(state.products.detail.product_image);
            const uuid = get(state.products.detail.uuid);
            const result = data.sort((a, b) => (a.sort > b.sort ? 1 : -1));
            return http
              .post(`admin/products/media/sort`, { data: result, uuid })
              .then(res => path.success({ res }))
              .catch(error =>
                error.json().then(message => path.error({ message }))
              );
          },
          {
            success: [
              ({ props }) => {
                page.show(`/productos/${props.res.uuid}/`);
              }
            ],
            error: [notify(props.message.error, 'error')]
          }
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message, 'error')]
  },
  set(state.product_files.loading, false)
];
const updateTypeProduct = [
  ({ get, store }) => {
    const currentState = get(state.products.combinationsDisabled);
    store.set(state.products.combinationsDisabled, currentState !== true);
  }
];
const sendMessageByView = [[notify(props.msg, 'warning')]];
const updateOptionsAttributesInSelect = [
  ({ props, get, store }) => {
    store.set(
      state.products.combinations.optionsAttributesInSelect,
      props.values
    );
  }
];
const modeUpdateCombination = [
  ({ props, get, store }) => {
    store.set(state.products.combinations.editMode, props.value);
  }
];
const updateTypeProductCustom = [
  ({ store, props }) => {
    store.set(state.products.combinationsDisabled, props.status);
  }
];
const saveNotificationError = [
  notify('Es necesario guardar el producto para esta opción', 'warning')
];
// ACTIONS

const handleClickOpenModalGalleryNormalProducts = [
  ({ props, store }) => {
    store.set(state.product_files.product_id, props.product_id);
    store.set(state.product_files.delete, {
      id: props.id,
      file: props.image.url_image,
      open: true,
      loader: false
    });
  }
];
const handleClickCloseModalGalleryNormalProducts = [
  ({ store }) => {
    store.set(state.product_files.product_id, null);
    store.set(state.product_files.delete, {
      id: null,
      file: null,
      open: false,
      loader: false
    });
  }
];
const updateStateCombinationSelect = [
  ({ props, store }) => {
    store.set(state.products.combinations.selectGallery, props.value);
  }
];
const updateSortMedia = [
  ({ props, http, path }) => {
    const { values, uuid } = props;
    return http
      .post(`admin/products/media/sort`, { data: values.data, uuid })
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Orden actualizado', 'success'),
      actions.setDefaultStateInProduct,
      actions.getProductDetail,
      {
        success: [
          actions.setProductDetail,
          actions.getBrandsSimple,
          {
            success: [actions.setBrandsSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getOptionsAttributeSimple,
          {
            success: [actions.setOptionsAttributeSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getCategoriesSimple,
          {
            success: [actions.setCategoriesSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getTagsSimple,
          {
            success: [setPagination, actions.setTagsSimple],
            error: [notify(props.message.error, 'error')]
          }
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];
const deleteGalleryNormalFileProduct = [
  ({ store }) => {
    store.set(state.product_files.delete.loader, true);
  },
  ({ http, path, get }) => {
    const productId = get(state.product_files.product_id);
    const imageId = get(state.product_files.delete.id);
    return http
      .delete(`admin/products/${productId}/media/${imageId}`, {})
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Archivo eliminado', 'success'),
      ({ store, get, http, path }) => {
        store.set(state.product_files.product_id, null);
        store.set(state.product_files.delete, {
          id: null,
          file: null,
          open: false,
          loader: false
        });
      },
      actions.getProductDetail,
      {
        success: [
          actions.setProductDetail,
          actions.getBrandsSimple,
          {
            success: [actions.setBrandsSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getOptionsAttributeSimple,
          {
            success: [actions.setOptionsAttributeSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getCategoriesSimple,
          {
            success: [actions.setCategoriesSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getTagsSimple,
          {
            success: [setPagination, actions.setTagsSimple],
            error: [notify(props.message.error, 'error')]
          },
          ({ get, http, path, props }) => {
            const data = get(state.products.detail.product_image);
            const uuid = get(state.products.detail.uuid);
            const result = data.sort((a, b) => (a.sort > b.sort ? 1 : -1));
            return http
              .post(`admin/products/media/sort`, { data: result, uuid })
              .then(res => path.success({ res }))
              .catch(error =>
                error.json().then(message => path.error({ message }))
              );
          },
          {
            success: [
              ({ props }) => {
                page.show(`/productos/${props.res.uuid}/`);
              }
            ],
            error: []
          }
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [
      notify(props.message.error, 'error'),
      ({ store }) => {
        store.set(state.product_files.product_id, null);
        store.set(state.product_files.delete, {
          id: null,
          file: null,
          open: false,
          loader: false
        });
      }
    ]
  }
];
const generateCombinations = [
  ({ store, props }) => {
    store.set(state.products.combinations.loading, true);
  },
  ({ http, path, get, props }) => {
    const { combinations, productId } = props;
    return http
      .post(`admin/products-combinations`, {
        product_id: productId,
        attributes: combinations
      })
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Combinaciones creadas', 'success'),
      actions.setDefaultStateInProduct,
      actions.getProductDetail,
      {
        success: [
          actions.setProductDetail,
          actions.getBrandsSimple,
          {
            success: [actions.setBrandsSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getOptionsAttributeSimple,
          {
            success: [actions.setOptionsAttributeSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getCategoriesSimple,
          {
            success: [actions.setCategoriesSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getTagsSimple,
          {
            success: [setPagination, actions.setTagsSimple],
            error: [notify(props.message.error, 'error')]
          }
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store, props }) => {
    store.set(state.products.combinations.loading, false);
  }
];
const deleteCombination = [
  ({ store }) => {
    store.set(state.products.combinations.delete.loader, true);
  },
  ({ props, http, path }) => {
    const { data } = props;
    return http
      .delete(`admin/products-combinations/${data.id}/delete`)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Combinación eliminada', 'success'),
      actions.setDefaultStateInProduct,
      actions.getProductDetail,
      {
        success: [
          actions.setProductDetail,
          actions.getBrandsSimple,
          {
            success: [actions.setBrandsSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getOptionsAttributeSimple,
          {
            success: [actions.setOptionsAttributeSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getCategoriesSimple,
          {
            success: [actions.setCategoriesSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getTagsSimple,
          {
            success: [setPagination, actions.setTagsSimple],
            error: [notify(props.message.error, 'error')]
          }
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.products.combinations.delete.loader, false);
  }
];

const updateCombination = [
  ({ store }) => {
    store.set(state.products.combinations.update.loader, true);
  },
  ({ props, http, path }) => {
    const {
      percentOrPrice,
      productCombinationId,
      quantity,
      reference,
      typePercent
    } = props;
    return http
      .patch(`admin/products-combinations`, {
        product_combination_id: productCombinationId,
        reference,
        quantity,
        type_percent: typePercent,
        percent: percentOrPrice
      })
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Combinación actualizada', 'success'),
      actions.setDefaultStateInProduct,
      actions.getProductDetail,
      {
        success: [
          actions.setProductDetail,
          actions.getBrandsSimple,
          {
            success: [actions.setBrandsSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getOptionsAttributeSimple,
          {
            success: [actions.setOptionsAttributeSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getCategoriesSimple,
          {
            success: [actions.setCategoriesSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getTagsSimple,
          {
            success: [setPagination, actions.setTagsSimple],
            error: [notify(props.message.error, 'error')]
          }
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.products.combinations.update.loader, false);
  }
];
const updateGalleryCombination = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values, id } = props;
    return http
      .post(`admin/products-combinations/gallery`, {
        product_combination_id: id,
        select: values
      })
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Galeria actualizada', 'success'),
      ({ props, http, path, get, store }) => {
        store.set(
          state.products.combinations.selectGalleryComplete,
          props.res.selected_complete
        );
        const selectComplete = get(
          state.products.combinations.selectGalleryComplete
        );
        const productCombination = get(
          state.products.combinations.productCombination
        );
        const result = selectComplete.sort((a, b) =>
          a.sort > b.sort ? 1 : -1
        );
        const values = {
          data: result,
          product_combination_id: productCombination.id
        };
        return http
          .post(`admin/products-combinations-sort`, values)
          .then(res => path.success({ res }))
          .catch(error =>
            error.json().then(message => path.error({ message }))
          );
      },
      {
        success: [
          ({ props }) => {
            page.show(
              `/productos/${props.res.product_combination.product.uuid}/${props.res.product_combination.id}`
            );
          }
        ],
        error: []
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];
// ACTIONS
const updateTab = [
  ({ props, store }) => {
    store.set(state.products.openTab, props.tab);
  }
];

const updateSortMediaCombination = [
  ({ props, http, path }) => {
    const { values } = props;
    return http
      .post(`admin/products-combinations-sort`, values)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Orden actualizado', 'success'),
      ({ props }) => {
        page.show(
          `/productos/${props.res.product_combination.product.uuid}/${props.res.product_combination.id}`
        );
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];

const handleClickOpenModalProduct = [
  ({ props, store }) => {
    store.set(state.products.delete, {
      id: props.uuid,
      name: props.name,
      open: true,
      loader: false
    });
  }
];
const handleClickCloseModalProduct = [
  ({ store }) => {
    store.set(state.products.delete, {
      id: null,
      name: null,
      open: false,
      loader: false
    });
  }
];

const handleClickOpenModalProductAttributes = [
  ({ props, store }) => {
    store.set(state.products.update_multiple_products_modal_button, true);
  }
];
const handleClickCloseModalProductAttributes = [
  ({ store }) => {
    store.set(state.products.update_multiple_products_modal_button, false);
  }
];
const handleClickCloseModalSaveMultipleConfirm = [
  ({ store }) => {
    store.set(state.products.save_multiples, {
      open: false,
      loader: false
    });
    store.set(state.products.is_multiple_edition, {
      open: true,
      loader: false
    });
  }
];
const handleClickOpenModalSaveMultipleConfirm = [
  ({ store }) => {
    store.set(state.products.save_multiples, {
      open: true,
      loader: false
    });
    store.set(state.products.is_multiple_edition, {
      open: false,
      loader: false
    });
  }
];



const handleClickOpenModalMultipleUpdate = [
  ({ props, store }) => {
    store.set(state.products.update_multiple_products_modal_button, false);
    store.set(state.products.update_multiple_products_modal_button_fields, true);
  }
];
const handleClickCloseModalMultipleUpdate = [
  ({ store, get }) => {
    let update_multiple_list = get(state.products.update_multiple_list);
    store.set(state.products.update_multiple_products_modal_button_fields, false);

    store.set(state.products.edit_rows_table_columns, []);
    store.set(state.products.edit_rows_table_read, []);
    store.set(state.products.edit_rows_table_read_preview, []);
      update_multiple_list.map( function(row) { 
        row['new_name'] = '';
        row['new_price'] = '';
        row['new_description'] = '';
        row['new_instructions'] = '';
        row['new_show'] = '';
        row['new_type'] = '';
        row['new_instructions'] = '';
        row['new_brands'] = [];
        row['new_categories'] = [];
      });
    store.set(state.products.update_multiple_list, update_multiple_list )
  }
];

const saveMultipleProductsUpdate = [
  ({ http, path, get, store }) => {

    const { values } = props;
    const data = new FormData();
    const current_values = get(state.products.edit_rows_table_read_preview);
    const current_products_ids = get(state.products.update_multiple_list).map( (row) => { return row.id; });

    data.append('product_ids', current_products_ids);

    if ( current_values.description ) {
      data.append('description', current_values.description);
    }
    if ( current_values.instructions ) {
      data.append('instructions', current_values.instructions);
    }
    if ( current_values.name ) {
      data.append('name', current_values.name);
    }
    if ( current_values.price ) {
      data.append('price', current_values.price);
    }
    if ( current_values.show ) {
      data.append('show', current_values.show);
    }
    if ( current_values.type ) {
      data.append('type', current_values.type);
    }
    if ( current_values.brands ) {
      data.append('brands_ids', current_values.brands);
    }
    if ( current_values.categories ) {
      data.append('categories_ids', current_values.categories);
    }

    store.set(state.products.save_multiples, {
      open: false,
      loader: false
    });

    return http
      .postFile(`admin/products/update/multiple`, data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
    },
    {
      success: [
        notify('Productos actualizados', 'success'),
        ({ store, get, http, path }) => {

          store.set(state.products.update_multiple_products_modal_button_fields, false)
          store.set(state.products.edit_rows_table_columns, [])
          store.set(state.products.edit_rows_table_read, [])
          store.set(state.products.edit_rows_table_read_preview, [])
          store.set(state.products.update_multiple_list, [])
          store.set(state.products.update_multiple_fields, [])
          page.show(`/productos/`);
        }
      ]
     }
];

const deleteProduct = [
  ({ store }) => {
    store.set(state.products.delete.loader, true);
  },
  ({ http, path, get }) => {
    return http
      .delete(`admin/products/${get(state.products.delete.id)}/delete`, {})
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Producto eliminado', 'success'),
      ({ store }) => {
        store.set(state.products.delete, {
          id: null,
          name: null,
          open: false,
          loader: false
        });
      },
      () => {
        page.show('/productos');
      }
    ],
    error: [
      ({ store }) => {
        store.set(state.products.delete, {
          id: null,
          name: null,
          open: false,
          loader: false
        });
      },
      notify(props.message.error, 'error')
    ]
  }
];
const deleteDiscount = [
  ({ store }) => {
    store.set(state.discounts.delete.loader, true);
  },
  ({ props, http, path }) => {
    return http
      .delete(`admin/discounts/${props.id}`)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Descuento eliminado exitosamente', 'success'),
      actions.setDefaultStateInProduct,
      actions.getProductDetail,
      {
        success: [
          actions.setProductDetail,
          actions.getBrandsSimple,
          {
            success: [actions.setBrandsSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getOptionsAttributeSimple,
          {
            success: [actions.setOptionsAttributeSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getCategoriesSimple,
          {
            success: [actions.setCategoriesSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getTagsSimple,
          {
            success: [setPagination, actions.setTagsSimple],
            error: [notify(props.message.error, 'error')]
          }
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.discounts.delete.loader, false);
  }
];
const saveDiscount = [
  ({ store }) => {
    store.set(state.discounts.delete.loader, true);
  },
  ({ props, http, path }) => {
    const { values } = props;
    const data = {};
    data.description = values.description;
    data.type_value = values.type_value;
    data.value = values.value;
    data.combination = values.combination;
    data.product_uuid = values.product_uuid;
    if (typeof values.start_date !== 'undefined' && values.start_date != null) {
      data.start_date = `${moment(values.start_date).format('YYYY-MM-DD')}`;
    }
    if (typeof values.end_date !== 'undefined' && values.end_date != null) {
      data.end_date = `${moment(values.end_date).format('YYYY-MM-DD')}`;
    }
    return http
      .post(`admin/discounts`, data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Descuento creado', 'success'),
      actions.setDefaultStateInProduct,
      actions.getProductDetail,
      {
        success: [
          actions.setProductDetail,
          actions.getBrandsSimple,
          {
            success: [actions.setBrandsSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getOptionsAttributeSimple,
          {
            success: [actions.setOptionsAttributeSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getCategoriesSimple,
          {
            success: [actions.setCategoriesSimple],
            error: [notify(props.message.error, 'error')]
          },
          actions.getTagsSimple,
          {
            success: [setPagination, actions.setTagsSimple],
            error: [notify(props.message.error, 'error')]
          }
        ],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.discounts.delete.loader, false);
  }
];
const syncCodesProducts = [
  ({ store }) => {
    store.set(state.syncCodes, true);
  },
  ({ props, http, path }) => {
    return http
      .post('admin/sync-codes-odoo')
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [notify('Sincronización finalizada', 'success')],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.syncCodes, false);
  }
];
const updateMultipleProductIds = [
  ({ props, store, get }) => {
    let update_multiple_list = get(state.products.update_multiple_list);

    if (props.status && update_multiple_list.filter(function(item){ return item.id === props.value.id }).length == 0 ) {
      store.concat(state.products.update_multiple_list, [ props.value ]);
    } else {
      const index = update_multiple_list.map(function(item){ return item.id === props.value.id }).indexOf(true);
      store.splice(state.products.update_multiple_list, index, 1)
    }
  }
];
const updateMultipleTableProduct = [
  ({ props, store }) => {
    store.unshift(state.products.edit_rows_table_columns, props );
  }
];
const updateMultipleProductFields = [
  ({ props, store, get }) => {
    if (props.status) {
      store.concat(state.products.update_multiple_fields, [ props.value ]);
    } else {
      const index = get(state.products.update_multiple_fields).indexOf( props.value );
      store.splice(state.products.update_multiple_fields, index, 1)
    }
  }
];
const updateReadNewValue = [
  ({ props, store, get }) => {
    const current_values = get(state.products.edit_rows_table_read_preview);
    let update_multiple_list = get(state.products.update_multiple_list);

    if ( props.newValues.column != 'price' ) {
      current_values[props.newValues.column] = props.newValues.value
    } else if ( !isNaN(props.newValues.value) ) {
      current_values[props.newValues.column] = props.newValues.value
    }

    store.set(state.products.edit_rows_table_read_preview, current_values )

    if ( props.newValues.column == 'name' ) {
      update_multiple_list.map( function(row) { 
        row['new_' + props.newValues.column] = props.newValues.value;
      });
    }
    if ( props.newValues.column == 'price' ) {
      update_multiple_list.map( function(row) { 
        row['new_' + props.newValues.column] = props.newValues.value;
      });
    }
    if ( props.newValues.column == 'description' ) {
      update_multiple_list.map( function(row) { 
        row['new_' + props.newValues.column] = props.newValues.value;
      });
    }
    if ( props.newValues.column == 'instructions' ) {
      update_multiple_list.map( function(row) { 
        row['new_' + props.newValues.column] = props.newValues.value;
      });
    }
    if ( props.newValues.column == 'show' ) {
      update_multiple_list.map( function(row) { 
        row['new_' + props.newValues.column] = props.newValues.label;
      });
    }
    if ( props.newValues.column == 'type' ) {
      update_multiple_list.map( function(row) { 
        row['new_' + props.newValues.column] = props.newValues.label;
      });
    }
    store.set(state.products.update_multiple_list, update_multiple_list )
  }
];

const selectMultipleValue = [
  ({ props, store, get }) => {
    let update_multiple_list = get(state.products.update_multiple_list);
    const current_values = get(state.products.edit_rows_table_read_preview);
    
    if ( props.newValues.column == 'categories' ) {
      current_values['categories'] = props.newValues.value

      update_multiple_list.map( function(row) { 
        row['new_' + props.newValues.column] = props.newValues.labels;
      });
    }
    
    if ( props.newValues.column == 'brands' ) {
      current_values['brands'] = props.newValues.value

      update_multiple_list.map( function(row) {
        row['new_' + props.newValues.column] = props.newValues.labels;
      });
    }
    store.set(state.products.update_multiple_list, update_multiple_list )
    store.set(state.products.edit_rows_table_read_preview, current_values )
  }
];

const uncheckAll = [
  ({ store }) => {
    store.set(state.products.update_multiple_list, []);
  }
];


export default {
  routes: {
    list: ProductListRouter,
    new: ProductNewRouter,
    update: ProductFormViewUpdate,
    updateMultiple: ProductUpdateMultipleRouter,
    combinationGallery: CombinationGalleryView,
  },
  sequences: {
    uploadFileProductNormal,
    updateTypeProductCustom,
    updateTypeProduct,
    updateTab,
    updateStateCombinationSelect,
    updateSortMediaCombination,
    updateSortMedia,
    updateReadNewValue,
    updateProduct,
    updateOptionsAttributesInSelect,
    updateMultipleTableProduct,
    updateMultipleProductIds,
    uncheckAll,
    updateMultipleProductFields,
    updateGalleryCombination,
    updatedSearchSortProduct,
    updatedSearchReferenceProduct,
    updatedSearchNameProduct,
    updatedSearchCategoriesIds,
    updatedSearchBrand,
    updateCombination,
    syncCodesProducts,
    sendMessageByView,
    saveProduct,
    saveNotificationError,
    selectMultipleValue,
    saveMultipleProductsUpdate,
    saveDiscount,
    modeUpdateCombination,
    handleClickCloseModalGalleryNormalProducts,
    handleClickCloseModalMultipleUpdate,
    handleClickCloseModalProduct,
    handleClickCloseModalProductAttributes,
    handleClickCloseModalSaveMultipleConfirm,
    handleClickOpenModalGalleryNormalProducts,
    handleClickOpenModalMultipleUpdate,
    handleClickOpenModalProduct,
    handleClickOpenModalProductAttributes,
    handleClickOpenModalSaveMultipleConfirm,
    generateCombinations,
    deleteProductInKit,
    deleteProduct,
    deleteGalleryNormalFileProduct,
    deleteDiscount,
    deleteCombination,
    createKit,
  }
};
