import queryString from 'query-string';
import { API_URL, NAME_TOKEN } from '@utils/constants';

export const getToken = {
  get(key) {
    return localStorage.getItem(key);
  },
  set(key, value) {
    localStorage.setItem(key, value);
  }
};

export const http = (() => {
  const apiUrl = `${API_URL}/api/v1`;
  function withDefaultHeaders(config, token) {
    return {
      ...config,
      headers: {
        ...(config.headers || {}),
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };
  }
  function withMultiPartHeaders(config, token) {
    return {
      ...config,
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  }
  
  function withExcelHeaders(config, token) {
    return {
      ...config,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };
  }

  function evaluateResponse(response) {
    if (response.status >= 200 && response.status < 300) {
      return response.json();
    }
    if (response.status === 401) {
      localStorage.removeItem(process.env.NAME_TOKEN);
      localStorage.removeItem(process.env.OBJ_USER);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
    return Promise.reject(response);
  }

  return {
    request(config = {}) {
      const token = this.context.getToken.get(NAME_TOKEN);
      return fetch(
        `${apiUrl}/${config.url}`,
        withDefaultHeaders(config, token)
      ).then(evaluateResponse);
    },

    get(url, config = {}, params = {}) {
      const token = this.context.getToken.get(NAME_TOKEN);
      config.url = url;
      return fetch(
        `${apiUrl}/${url}?${queryString.stringify(params)}`,
        withDefaultHeaders(config, token)
      ).then(evaluateResponse);
    },

    post(url, data, config = {}) {
      const token = this.context.getToken.get(NAME_TOKEN);
      config.url = url;
      config.method = 'POST';
      config.body = JSON.stringify(data);

      return fetch(`${apiUrl}/${url}`, withDefaultHeaders(config, token)).then(
        evaluateResponse
      );
    },

    delete(url, data, config = {}) {
      const token = this.context.getToken.get(NAME_TOKEN);
      config.url = url;
      config.method = 'DELETE';
      config.body = JSON.stringify(data);

      return fetch(`${apiUrl}/${url}`, withDefaultHeaders(config, token)).then(
        evaluateResponse
      );
    },
    patch(url, data, config = {}) {
      const token = this.context.getToken.get(NAME_TOKEN);
      config.url = url;
      config.method = 'PATCH';
      config.body = JSON.stringify(data);

      return fetch(`${apiUrl}/${url}`, withDefaultHeaders(config, token)).then(
        evaluateResponse
      );
    },
    postFile(url, data, config = {}) {
      const token = this.context.getToken.get(NAME_TOKEN);
      config.url = url;
      config.method = 'POST';
      config.body = data;

      return fetch(
        `${apiUrl}/${url}`,
        withMultiPartHeaders(config, token)
      ).then(evaluateResponse);
    },
    postExcel(url, data, config = {}) {
      const token = this.context.getToken.get(NAME_TOKEN);
      config.url = url;
      config.method = 'POST';
      config.body = data;

      return fetch( `${apiUrl}/${url}`,withExcelHeaders(config, token) );
    }
  };
})();
