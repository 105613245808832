import { set } from 'cerebral/factories'; // wait
import { state, props } from 'cerebral';
import { WithRouter } from '@modules/actions';
import * as actions from './actions';
import notify from '@utils/notify';
import axios from 'axios';
import { API_URL, NAME_TOKEN } from '@utils/constants';


const ReportModuleRouter = WithRouter([
    set(state.app.currentPage, 'ReportModuleView'),
    actions.getReportContent, {
        success: [ actions.setReportContent ],
        error: []
    },
    actions.getBrandsBySelect,{
        success: [actions.setBrandsSimple],
        error: [notify(props.message.error, 'error')]
    },
    actions.getCategoriesSimple,{
      success: [actions.setCategoriesSimple],
      error: [notify(props.message.error, 'error')]
    },
]);

const GetReport = [
  ({ store }) => { store.set(state.reports.report_in_process, true); },
  ({ props, http, path, store }) => {
    const { values } = props;

    const data = new FormData();
    
    if ( values.report ) {
      data.append('report', values.report);
    }
    if ( values.visibility ) {
      data.append('visible', values.visibility);
    }
    if ( values.start_date ) {
      data.append('start_date', values.start_date);
    }
    if ( values.end_date ) {
      data.append('end_date', values.end_date);
    }
    if ( values.brands && values.brands.length > 0  ) {
      const brandsArray = values.brands.toString().split(',');
      brandsArray.forEach(element => {
        if (element !== '') {
          data.append('brands[]', element);
        }
      });
    }
    if ( values.clients && values.clients.length > 0  ) {
      const clientsArray = values.clients.map( response => response.key );
      clientsArray.forEach(element => {
          data.append('clients[]', element);
      });
    }
    if ( values.categories && values.categories.length > 0  ) {
      const categoriesArray = values.categories.toString().split(',');
      categoriesArray.forEach(element => {
        if (element !== '') {
          data.append('categories[]', element);
        }
      });
    }
    
    return http
      .postFile(`admin/reports/show`, data)
      .then( function(res) {
        if ( values.report == 'STOCK' || values.report == 'OUT_STOCK' ) {
          store.set(state.reports.list[values.report],  []);
          res.data.map( function(product) {
            if ( product.type == 'REGULAR') {
              store.concat(state.reports.list[values.report], product);
              return ;
            } else {
              product.product_combination.map( function(combination) {
                store.concat(state.reports.list[values.report], {
                  'uuid': product.uuid,
                  'id': product.id,
                  'parent_id': combination.id,
                  'product_id': combination.product_id,
                  'first_product_brand': product.first_product_brand,
                  'name': product.name + ' ' + combination.option_attribute.name,
                  'type': product.type,
                  'quantity': combination.quantity,
                  'price_tax': combination.price_tax,
                });
                return ;
              })
            }
          });
        } else {
          store.set(state.reports.list[values.report],  res.data);
        }

        store.set(state.reports.report_in_process, false);
        store.set(state.reports.total_results[values.report],  state.reports.list[values.report].length);
        path.success({ res })
      })
      .catch( function(error) {
        store.set(state.reports.report_in_process, false);
        error.json().then(message => path.error({ message }));
      });
  },
  {
    success: [
      notify('Reporte generado', 'success'),
    ],
    error: [notify(props.message.error, 'error')]
  },
];

const GetExcel = [
  ({ store }) => { store.set(state.reports.export_in_process, true); },
  ({ props, http, path, store }) => {
    const { values } = props;
    const data = new FormData();

    data.append('type', 'Excel');
    if ( values.report ) {
      data.append('report', values.report);
    }
    if ( values.visibility ) {
      data.append('visible', values.visibility);
    }
    if ( values.start_date ) {
      data.append('start_date', values.start_date);
    }
    if ( values.end_date ) {
      
      data.append('end_date', values.end_date);
    }
    if ( values.brands && values.brands.length > 0  ) {
      const brandsArray = values.brands.toString().split(',');
      brandsArray.forEach(element => {
        if (element !== '') {
          data.append('brands[]', element);
        }
      });
    }
    if ( values.clients && values.clients.length > 0  ) {
      const clientsArray = values.clients.map( response => response.key );
      clientsArray.forEach(element => {
          data.append('clients[]', element);
      });
    }
    if ( values.categories && values.categories.length > 0  ) {
      const categoriesArray = values.categories.toString().split(',');
      categoriesArray.forEach(element => {
        if (element !== '') {
          data.append('categories[]', element);
        }
      });
    }

    const token = localStorage.getItem(NAME_TOKEN);
    axios({
      url: `${API_URL}/api/v1` + `/admin/reports/show`,
      method: 'POST',
      data: data,
      responseType: 'blob', // important
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/vnd.ms-excel; charset=UTF-8'
      }
    }).then((response) => {
      var date = new Date();
      var friendly_date = date.getFullYear() + '_' + date.getMonth() + '_' + date.getDate();
      var outputFilename = `${friendly_date}.xlsx`;
      if (values.report == 'STOCK' ) {
        var outputFilename = `reporte_stock_${friendly_date}.xlsx`;
      }
      if (values.report == 'OUT_STOCK' ) {
        var outputFilename = `reporte_productos_sin_stock_${friendly_date}.xlsx`;
      }
      if (values.report == 'SALES' ) {
        var outputFilename = `ventas_consolidado_${friendly_date}.xlsx`;
      }
      if (values.report == 'SALES_DETAILS' ) {
        var outputFilename = `ventas_detalles_${friendly_date}.xlsx`;
      }
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      store.set(state.reports.export_in_process, false);
      path.success({ res })
    }).catch( function() {
      store.set(state.reports.export_in_process, false);
    })
  },
  {
    success: [
      notify('Reporte generado', 'success'),
    ],
    error: [notify(props.message.error, 'error')]
  },
];

export default {
    routes: {
        module: ReportModuleRouter,
    },
    sequences: {
      GetExcel,
      GetReport,
    }
  };
  