import PostListView from './PostListView';
import PostsFormView from './PostsFormView';
import PostsFormUpdateView from './PostsFormUpdateView';
import PostGalleryListView from './Gallery/PostGalleryListView';
import PostGalleryFormView from './Gallery/PostGalleryFormView';

export default {
  PostListView,
  PostsFormView,
  PostsFormUpdateView,
  PostGalleryListView,
  PostGalleryFormView
};
