import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { sequences, state } from 'cerebral';
import { Form, Input, Button, Row, Col, Tooltip, Icon, Select } from 'antd';
import NumericInput from '@component/NumericInput';
import { createValidation } from '@utils';

export default Form.create()(
  connect(
    {
      // states
      loadingLogin: state.user.loginButtonDisable,
      // secuences
      submit: sequences.saveUserAdmin
    },
    class AdminFormView extends Component {
      constructor(props) {
        super(props);
        this.state = {
          phone: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
      }

      onChange(value) {
        this.setState({ phone: value });
      }

      handleSubmit(e) {
        e.preventDefault();
        const { form, submit } = this.props;
        form.validateFields((err, values) => {
          if (!err) {
            submit({ values });
          }
        });
      }

      render() {
        const { phone } = this.state;
        const { form, loadingLogin } = this.props;
        const { getFieldDecorator } = form;
        const FormItem = Form.Item;

        return (
          <div>
            <Form onSubmit={this.handleSubmit} layout="horizontal">
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24}>
                  <FormItem className="move-content-right">
                    <Tooltip title="Guardar administrador" placement="left">
                      <Button
                        icon="save"
                        type="primary"
                        htmlType="submit"
                        loading={loadingLogin}
                      />
                    </Tooltip>
                    <Tooltip title="Cancelar" placement="left">
                      <a
                        style={{ marginLeft: 10 }}
                        href="/administradores"
                        className="ant-btn ant-btn-primary ant-btn-icon-only"
                        rel="noopener noreferrer"
                      >
                        <Icon type="arrow-left" />
                      </a>
                    </Tooltip>
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={12}>
                  <FormItem label="Email">
                    {getFieldDecorator('email', {
                      rules: [
                        createValidation(
                          true,
                          'Por favor ingresa un email',
                          'email'
                        )
                      ]
                    })(<Input placeholder="Email" />)}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={12}>
                  <FormItem label="Nombre">
                    {getFieldDecorator('first_name', {
                      rules: [
                        createValidation(
                          true,
                          'Por favor ingresa un nombre',
                          ''
                        )
                      ]
                    })(<Input placeholder="Nombre" />)}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={12}>
                  <FormItem label="Apellido">
                    {getFieldDecorator('last_name', {
                      rules: [
                        createValidation(
                          true,
                          'Por favor ingresa un apellido',
                          ''
                        )
                      ]
                    })(<Input placeholder="Apellido" />)}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={12}>
                  <FormItem label="Teléfono">
                    {getFieldDecorator('phone', {
                      rules: [
                        createValidation(
                          true,
                          'Por favor ingresa tu teléfono',
                          ''
                        )
                      ],
                      initialValue: phone
                    })(
                      <NumericInput
                        maxLength={10}
                        placeholder="Teléfono"
                        value={phone}
                        onChange={this.onChange}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={12}>
                  <FormItem label="Rol">
                    {getFieldDecorator('rol_admin', {
                      rules: [
                        createValidation(
                          true,
                          'Por favor seleccione un rol',
                          ''
                        )
                      ],
                      initialValue: 'ADMIN'
                    })(
                      <Select placeholder="Status" style={{ width: '100%' }}>
                        <Select.Option key="status-1" value="ADMIN">
                          ADMIN
                        </Select.Option>
                        <Select.Option key="status-2" value="DEALER_1">
                          MINORISTA
                        </Select.Option>
                        <Select.Option key="status-3" value="DEALER_2">
                          TELEMARKETING
                        </Select.Option>
                        <Select.Option key="status-3" value="DEALER_3">
                          MAYOREO
                        </Select.Option>
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>
        );
      }
    }
  )
);
