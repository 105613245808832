import { connect } from '@cerebral/react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Spin,
  Table,
} from 'antd';
import {
  sequences,
  state,
} from 'cerebral';
import debounce from 'lodash/debounce';
import productWhitoutImage from '../../../assets/product_whitout_image.png';
import React, {
  Component
} from 'react';

import moment from 'moment';
import Moment from 'react-moment';
import 'moment/locale/es';
moment.locale('es');

const style = {
  ReportConfigurationCol: {
    borderRight: '1px solid #d3d6d9',
    paddingTop: '8px',
  },
  ParentFormItem: {
    marginBottom: '0px',
    borderBottom: '1px solid #d3d6d9',
    paddingBottom: '10px',
  },
  ParentFormItemCheckbox: {
    marginBottom: '0px',
    borderBottom: '1px solid #d3d6d9',
  },
  FormItem: {
    marginBottom: '0px',
  },
  miniFileCategory: {
    width: '1rem',
    height: '1rem',
    objectFit: 'cover',
    objectPosition: 'center'
  }
}

export default Form.create() (
  connect(
    {
      // states
      state_reports: state.reports,

      // sequences
      submit: sequences.GetReport,
      submitExcel: sequences.GetExcel,

    },
    class ReportModuleView extends Component {
      constructor(props) {
        super(props);
        this.state = {
          dataClients: [],
          fetchingClients: false,
          products_not_visibles: false,
          is_range_dates: true,
          valueClients: [],
          report_type: 'SALES',
        };

        this.lastFetchId = 0;
        this.lastFetchIdClients = 0;

        this.fetchCombinationsClients = debounce(
          this.fetchCombinationsClients.bind(this),
          500
        );
        this.getBrands              = this.getBrands.bind(this);
        this.handleChangeClients    = this.handleChangeClients.bind(this);
        this.handleSubmit           = this.handleSubmit.bind(this);
        this.handleExcel            = this.handleExcel.bind(this);
        this.toggleRangeDates       = this.toggleRangeDates.bind(this);
        this.toggleVisibilityStock       = this.toggleVisibilityStock.bind(this);
        this.setReportType          = this.setReportType.bind(this);
      }

      toggleRangeDates(event) {
        this.setState({ is_range_dates: event.target.checked });
      }

      toggleVisibilityStock(event) {
        this.setState({ products_not_visibles: event.target.checked });
      }

      handleSubmit(e) {
        e.preventDefault();
        if (!this.props.state_reports.report_in_process) {
          const { form, submit } = this.props;
          form.validateFields((err, values) => {
            if (!err) {
              submit({ values });
            }
          });
        }
      }

      handleExcel(e) {
        e.preventDefault();
        if (!this.props.state_reports.export_in_process) {
          const { form, submitExcel } = this.props;
          form.validateFields((err, values) => {
            if (!err) {
              
              submitExcel({ values });
            }
          });
        }
      }

      handleChangeClients(value) {
        this.value = value;
        this.setState({
          valueClients: value,
          dataClients: [],
          fetchingClients: false
        });
      }

      setReportType(event) {
        this.setState({
          report_type: event,
        });
      }

      fetchCombinationsClients(value) {
        this.value = value;
        if (value !== '') {
          this.lastFetchIdClients += 1;
          const fetchIdClients = this.lastFetchIdClients;
          this.setState({ dataClients: [], fetchingClients: true });
          fetch( `${process.env.API_URL}/api/v1/admin/reports/search-client?searchByNameClient=${value}`,
            { headers: {
                authorization: `bearer ${localStorage.getItem(
                  process.env.NAME_TOKEN
                )}`
              }
            }
          )
          .then(response => response.json())
          .then(body => {
              if (fetchIdClients !== this.lastFetchIdClients) {
                return;
              }
              this.setState({ dataClients: body, fetchingClients: false });
            });
        }
      }

      getBrands(categoryBrand) {
        const bullet = categoryBrand.length > 0 ? ' * ' : '';
        let brands = '(';
        brands += bullet;
        categoryBrand.forEach(element => {
          brands += `${element.brand.name.toUpperCase()}`;
          brands += bullet;
        });
        brands += ')';
        return brands;
      }

      render() {

        const {
          form,
          state_reports,
        } = this.props;

        const {
          dataClients,
          fetchingClients,
          is_range_dates,
          products_not_visibles,
          valueClients,
          report_type,
        } = this.state;

        const { getFieldDecorator } = form;
        const FormItem = Form.Item;

        const columns = {
          'SALES': [
            {
              title: 'ID DE ORDEN',
              dataIndex: 'shipping_number',
              key: 'shipping_number',
              render(text, element, index) {
                return <a target='_blank' href={`/ordenes/${element.uuid}`}>{text}</a>;
              },
              sortDirections: ['descend', 'ascend'],
              sorter: (a, b) => parseInt(a.shipping_number) - parseInt(b.shipping_number),
            },
            {
              title: 'FOLIO ODOO',
              dataIndex: 'folio_odoo',
              key: 'folio_odoo',
              sortDirections: ['descend', 'ascend'],
              sorter(a, b) {
                a = (a.folio_odoo + '').split('').map(char => {
                    return char.charCodeAt(0).toString(2);
                }).join(' ');
                b = (b.folio_odoo + '').split('').map(char => {
                  return char.charCodeAt(0).toString(2);
              }).join(' ');

                return parseInt(a) - parseInt(b);
              }
            },
            {
              title: 'FECHA DE ORDEN',
              dataIndex: 'created_at',
              key: 'created_at',
              render: text => <Moment format="DD-MM-YYYY hh:mm A">{text}</Moment>,
              sortDirections: ['descend', 'ascend'],
              sorter: (a, b) => a.created_at > b.created_at,
            },
            {
              title: 'CLIENTE',
              dataIndex: 'client_name',
              key: 'client_name',
              render: (text, element, index) => (
                <span>{element.first_name + ' ' + element.last_name}</span>
              ),
              sortDirections: ['descend', 'ascend'],
              sorter(a, b) {
                a = (a.first_name + ' ' + a.last_name).toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").split('').map(char => {
                  return char.charCodeAt(0).toString(2);
                }).join(' ');
                b = (b.first_name + ' ' + b.last_name).toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").split('').map(char => {
                  return char.charCodeAt(0).toString(2);
                }).join(' ');
              
                return parseInt(a) - parseInt(b);
              }
            },
            {
              title: 'TIPO',
              dataIndex: 'order_payment_method.name',
              key: 'order_payment_method.name',
              sortDirections: ['descend', 'ascend'],
              sorter(a, b) {
                a = (a.order_payment_method ? a.order_payment_method.name : '').toUpperCase().split('').map(char => {
                  return char.charCodeAt(0).toString(2);
                }).join(' ');
                b = (b.order_payment_method ? b.order_payment_method.name : '').toUpperCase().split('').map(char => {
                  return char.charCodeAt(0).toString(2);
                }).join(' ');
              
                return parseInt(a) - parseInt(b);
              }
            },
            {
              title: 'TOTAL',
              dataIndex: 'total_paid_amount',
              key: 'total_paid_amount',
              render(text, element, index) {
                const options1 = { style: 'currency', currency: 'MXN' };
                const moneyFormat = new Intl.NumberFormat('es-MX', options1);
                return isNaN(text) ? '' : moneyFormat.format(text);
              },
              sortDirections: ['descend', 'ascend'],
              sorter: (a, b) => a.total_paid_amount - b.total_paid_amount,
            },
          ],
          'SALES_DETAILS': [
            {
              title: 'ID DE ORDEN',
              dataIndex: 'order_relation.shipping_number',
              key: 'order_relation.shipping_number',
              render(text, element, index) {
                return <a target='_blank' href={`/ordenes/${element.order_relation.uuid}`}>{text}</a>;
              },
              sorter: (a, b) => parseInt(a.order_relation.shipping_number) - parseInt(b.order_relation.shipping_number),
              sortDirections: ['descend', 'ascend'],
            },
            {
              title: 'REFERENCIA DE PRODUCTO',
              dataIndex: 'product_reference',
              key: 'product_reference',
              sorter(a, b) {
                a = (a.product_reference + '').toUpperCase().split('').map(char => {
                    return char.charCodeAt(0).toString(2);
                }).join(' ');
                b = (b.product_reference + '').toUpperCase().split('').map(char => {
                  return char.charCodeAt(0).toString(2);
              }).join(' ');

                return parseInt(a) - parseInt(b);
              },
              sortDirections: ['descend', 'ascend'],
            },
            {
              title: 'REFERENCIA ODOO',
              dataIndex: 'product.odoo_reference',
              key: 'product.odoo_reference',
              sorter(a, b) {
                a = (a.product.odoo_reference + '').toUpperCase().split('').map(char => {
                    return char.charCodeAt(0).toString(2);
                }).join(' ');
                b = (b.product.odoo_reference + '').toUpperCase().split('').map(char => {
                  return char.charCodeAt(0).toString(2);
              }).join(' ');

                return parseInt(a) - parseInt(b);
              },
              sortDirections: ['descend', 'ascend'],
            },
            {
              title: 'FECHA DE ORDEN',
              dataIndex: 'order_relation.created_at',
              key: 'order_relation.created_at',
              render: text => <Moment format="DD-MM-YYYY hh:mm A">{text}</Moment>,
              sorter(a, b) {
                return a.order_relation.id - b.order_relation.id;
              },
              sortDirections: ['descend', 'ascend'],
            },
            {
              title: 'NOMBRE DE PRODUCTO',
              dataIndex: 'product_name',
              key: 'product_name',
              sorter(a, b) {
                a = (a.product_name + '').toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").split('').map(char => {
                    return char.charCodeAt(0).toString(2);
                }).join(' ');
                b = (b.product_name + '').toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").split('').map(char => {
                  return char.charCodeAt(0).toString(2);
              }).join(' ');

                return parseInt(a) - parseInt(b);
              },
              sortDirections: ['descend', 'ascend'],
            },
            {
              title: 'CANTIDAD',
              dataIndex: 'product_quantity',
              key: 'product_quantity',
              sorter(a, b) {
                a = (a.product_quantity + '').split('').map(char => {
                    return char.charCodeAt(0).toString(2);
                }).join(' ');
                b = (b.product_quantity + '').split('').map(char => {
                  return char.charCodeAt(0).toString(2);
              }).join(' ');
                return parseInt(a) - parseInt(b);
              },
              sortDirections: ['descend', 'ascend'],
            },
            {
              title: 'PRECIO TOTAL DE PRODUCTO',
              dataIndex: 'product_total_paid',
              key: 'product_total_paid',
              sorter(a, b) {
                a = (a.product_total_paid + '').split('').map(char => {
                    return char.charCodeAt(0).toString(2);
                }).join(' ');
                b = (b.product_total_paid + '').split('').map(char => {
                  return char.charCodeAt(0).toString(2);
              }).join(' ');
                return parseInt(a) - parseInt(b);
              },
              sortDirections: ['descend', 'ascend'],
            },
          ],
          'STOCK': [
            {
              title: 'ID DE PRODUCTO',
              dataIndex: 'id',
              key: 'id',
              render(text, element, index) {
                return <a target='_blank' href={`/productos/${element.uuid}`}>{text}</a>;
              },
              sorter: (a, b) => parseInt(a.id) - parseInt(b.id),
              sortDirections: ['descend', 'ascend'],
            },
            {
              title: 'ID DE PRODUCTO PADRE',
              dataIndex: 'parent_id',
              key: 'parent_id',
              sorter: (a, b) => parseInt(a.parent_id) - parseInt(b.parent_id),
              sortDirections: ['descend', 'ascend'],
            },
            {
              title: 'MARCA',
              dataIndex: 'first_product_brand.brand.name',
              key: 'first_product_brand.brand.name',
              sorter(a, b) {
                console.log(a.first_product_brand.brand.name);
                a = (a.first_product_brand.brand.name).toUpperCase().split('').map(char => {
                  return char.charCodeAt(0).toString(2);
                }).join(' ');
                
                b = (b.first_product_brand.brand.name).toUpperCase().split('').map(char => {
                  return char.charCodeAt(0).toString(2);
                }).join(' ');
                return parseInt(a.split(' ').join('')) - parseInt(b.split(' ').join(''));
              },
              sortDirections: ['descend', 'ascend'],
            },
            {
              title: 'PRODUCTO',
              dataIndex: 'name',
              key: 'name',
              sorter(a, b) {
                a = (a.name).toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").split('').map(char => {
                  return char.charCodeAt(0).toString(2);
                }).join(' ');
                b = (b.name).toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").split('').map(char => {
                  return char.charCodeAt(0).toString(2);
                }).join(' ');
              
                return parseInt(a) - parseInt(b);
              },
              sortDirections: ['descend', 'ascend'],
            },
            {
              title: 'TIPO',
              dataIndex: 'type',
              key: 'type',
              render(text, element, index) {
                return <span >{text == 'REGULAR' ? text : 'COMBINADO'}</span>;
              },
              sorter(a, b) {
                a = (a.type == 'REGULAR' ? 'REGULAR' : 'COMBINADO').toUpperCase().split('').map(char => {
                  return char.charCodeAt(0).toString(2);
                }).join(' ');
                b = (b.type == 'REGULAR' ? 'REGULAR' : 'COMBINADO').toUpperCase().split('').map(char => {
                  return char.charCodeAt(0).toString(2);
                }).join(' ');
                return parseInt(a) - parseInt(b);
              },
              sortDirections: ['descend', 'ascend'],
            },
            {
              title: 'STOCK',
              dataIndex: 'quantity',
              key: 'quantity',
              sorter: (a, b) => parseInt(a.quantity) - parseInt(b.quantity),
              sortDirections: ['descend', 'ascend'],
            },
            {
              title: 'PRECIO',
              dataIndex: 'price_tax',
              key: 'price_tax',
              render(text, element, index) {
                const options1 = { style: 'currency', currency: 'MXN' };
                const moneyFormat = new Intl.NumberFormat('es-MX', options1);
                return isNaN(text) ? '' : moneyFormat.format(text);
              },
              sorter: (a, b) => parseInt(a.price_tax) - parseInt(b.price_tax),
              sortDirections: ['descend', 'ascend'],
            },
          ],
          'OUT_STOCK': [
            {
              title: 'ID DE PRODUCTO',
              dataIndex: 'id',
              key: 'id',
              render(text, element, index) {
                return <a target='_blank' href={`/productos/${element.uuid}`}>{text}</a>;
              },
              sorter: (a, b) => parseInt(a.id) - parseInt(b.id),
              sortDirections: ['descend', 'ascend'],
            },
            {
              title: 'ID DE PRODUCTO PADRE',
              dataIndex: 'parent_id',
              key: 'parent_id',
              sorter: (a, b) => parseInt(a.parent_id) - parseInt(b.parent_id),
              sortDirections: ['descend', 'ascend'],
            },
            {
              title: 'MARCA',
              dataIndex: 'first_product_brand.brand.name',
              key: 'first_product_brand.brand.name',
              sorter(a, b) {
                console.log(a.first_product_brand.brand.name);
                a = (a.first_product_brand.brand.name).toUpperCase().split('').map(char => {
                  return char.charCodeAt(0).toString(2);
                }).join(' ');
                
                b = (b.first_product_brand.brand.name).toUpperCase().split('').map(char => {
                  return char.charCodeAt(0).toString(2);
                }).join(' ');
                return parseInt(a.split(' ').join('')) - parseInt(b.split(' ').join(''));
              },
              sortDirections: ['descend', 'ascend'],
            },
            {
              title: 'PRODUCTO',
              dataIndex: 'name',
              key: 'name',
              sorter(a, b) {
                a = (a.name).toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").split('').map(char => {
                  return char.charCodeAt(0).toString(2);
                }).join(' ');
                b = (b.name).toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").split('').map(char => {
                  return char.charCodeAt(0).toString(2);
                }).join(' ');
              
                return parseInt(a) - parseInt(b);
              },
              sortDirections: ['descend', 'ascend'],
            },
            {
              title: 'TIPO',
              dataIndex: 'type',
              key: 'type',
              render(text, element, index) {
                return <span >{text == 'REGULAR' ? text : 'COMBINADO'}</span>;
              },
              sorter(a, b) {
                a = (a.type == 'REGULAR' ? 'REGULAR' : 'COMBINADO').toUpperCase().split('').map(char => {
                  return char.charCodeAt(0).toString(2);
                }).join(' ');
                b = (b.type == 'REGULAR' ? 'REGULAR' : 'COMBINADO').toUpperCase().split('').map(char => {
                  return char.charCodeAt(0).toString(2);
                }).join(' ');
                return parseInt(a) - parseInt(b);
              },
              sortDirections: ['descend', 'ascend'],
            },
            {
              title: 'STOCK',
              dataIndex: 'quantity',
              key: 'quantity',
              sorter: (a, b) => parseInt(a.quantity) - parseInt(b.quantity),
              sortDirections: ['descend', 'ascend'],
            },
            {
              title: 'PRECIO',
              dataIndex: 'price_tax',
              key: 'price_tax',
              render(text, element, index) {
                const options1 = { style: 'currency', currency: 'MXN' };
                const moneyFormat = new Intl.NumberFormat('es-MX', options1);
                return isNaN(text) ? '' : moneyFormat.format(text);
              },
              sorter: (a, b) => parseInt(a.price_tax) - parseInt(b.price_tax),
              sortDirections: ['descend', 'ascend'],
            },
          ],
        };

        return (
          <div style={style.MainRow}>
            <Row gutter={16}>
              <Col xs={4} sm={4} md={4} style={style.ReportConfigurationCol} >

              <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} style={style.ParentFormItem} >
                    <FormItem label="REPORTE" style={style.FormItem}>
                      {getFieldDecorator('report', {
                        initialValue: report_type
                      })(
                        <Select
                          placeholder="Por favor seleccione una opción"
                          style={{width: '100%'}}
                          name='show'
                          onChange={this.setReportType}>
                          { state_reports.reports_types.map(value => (
                            <Select.Option key="report-" value={value.value}> <span data='show'>{value.name}</span> </Select.Option>
                          ) ) }
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                </Row>

                { ['STOCK', 'OUT_STOCK'].includes(report_type) ? (
                  <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} >
                    <FormItem label="" style={style.FormItem}>
                      {getFieldDecorator('visibility', {
                        initialValue: products_not_visibles
                      })(
                        <Checkbox value="true" onChange={this.toggleVisibilityStock} checked={products_not_visibles}>
                          <span>MOSTRAR NO VISIBLES</span>
                        </Checkbox>
                      )}
                    </FormItem>
                  </Col>
                </Row>) : null}

                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} >
                    <FormItem label="MARCAS" style={style.FormItem} >
                      {getFieldDecorator('brands', { })(
                        <Select
                          mode="multiple"
                          style={{ width: '100%' }}
                          placeholder="Seleccionar marcas"
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {state_reports.brands.map(value => (
                            <Select.Option
                              key={`marcas-${value.id}`}
                              value={value.id}
                            >
                              {value.name}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} >
                    <FormItem label="CATEGORIAS" style={style.FormItem} >
                      {getFieldDecorator('categories', { })(
                        <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Seleccionar categorías"
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        { state_reports.categories.map(value => (
                          <Select.Option
                            key={`categories-${value.id}`}
                            value={value.id}>
                              {(value.parent_complete !== null ? `${value.parent_complete.name}->` : '') + value.name + (value.parent_complete === null ? this.getBrands(value.category_brand) : '')}
                          </Select.Option>
                        ))}
                      </Select>
                      )}
                    </FormItem>
                  </Col>
                </Row>

                { ['SALES', 'SALES_DETAILS'].includes(report_type) ? (
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} >
                      <FormItem label="CLIENTES">
                        {getFieldDecorator('clients', { })(
                          <Select
                            mode="multiple"
                            labelInValue
                            placeholder="Buscar por nombre"
                            notFoundContent={
                              fetchingClients ? <Spin size="small" /> : null
                            }
                            filterOption={false}
                            style={{ width: '100%' }}
                            allowClear
                            onSearch={this.fetchCombinationsClients}
                            onChange={this.handleChangeClients}
                            value={valueClients}
                          >
                            {dataClients.map(value => (
                              <Select.Option
                                key={`client-${value.id}`}
                                value={value.id}  >
                                {value.first_name} {value.last_name}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                  </Row> ) : null }

                  { ['SALES', 'SALES_DETAILS'].includes(report_type) ? (
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={24} >
                        <FormItem label="" style={style.FormItem}>
                          {getFieldDecorator('dates_at', {
                            initialValue: is_range_dates
                          })(
                            <Checkbox value="true" onChange={this.toggleRangeDates} checked={is_range_dates}>
                              <span>ESTABLECER FECHAS</span>
                            </Checkbox>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  ) : null }
                
                { is_range_dates && ['SALES', 'SALES_DETAILS'].includes(report_type) ? (
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} >
                      <FormItem label="FECHA INICIO" style={style.FormItem}>
                        {getFieldDecorator('start_date', {
                            initialValue: moment().subtract(1, 'months')
                        } ) (
                          <DatePicker
                            placeholder="Fecha de inicio"
                            style={{ width: '100%' }}
                          />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                ) : null }

                { is_range_dates && ['SALES', 'SALES_DETAILS'].includes(report_type) ? (
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={24}>
                      <FormItem label="FECHA FIN" style={style.FormItem}>
                        {getFieldDecorator('end_date', { } ) (
                          <DatePicker
                            placeholder="Fecha de inicio"
                            style={{ width: '100%' }}
                          />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                ) : null }

                <Row gutter={16} style={{ marginTop: '3em' }}>
                  <Col xs={24} sm={24} md={24}>
                    <Button
                      type="primary"
                      style={{ margin: '0 auto', display: 'block' }}
                      onClick={this.handleSubmit}
                      disabled={state_reports.report_in_process}>
                      {state_reports.report_in_process ? 'Consultando..' : 'Consultar'}
                    </Button>
                  </Col>
                </Row>

              </Col>

              <Col xs={20} sm={20} md={20}>
                <Row gutter={16}>
                  { state_reports.total_results[report_type] > 0 ? (
                    <Col xs={12} sm={12} md={12} style={{
                      float: 'left',
                      fontsize: '14px',
                      marginTop: '10px',
                      marginBottom: '1em' }}>
                      <span>{state_reports.total_results[report_type]} resultados encontrados. Descargar excel para ver detalles.</span>
                    </Col>
                  ) : (<Col xs={12} sm={12} md={12}></Col>) }
                  
                  <Col xs={12} sm={12} md={12} style={{ marginBottom: '1em' }}>
                    <Button
                      type="excel"
                      style={{ float: 'right', display: 'block' }}
                      onClick={this.handleExcel}
                      disabled={state_reports.export_in_process}
                      >
                      {state_reports.export_in_process ? 'DESCARGANDO' : 'EXPORTAR A EXCEL'}
                    </Button>
                  </Col>
                </Row>
                <Table
                  bordered
                  size="small"
                  dataSource={state_reports.list[report_type]}
                  columns={columns[report_type]}
                  pagination={false}
                  scroll={{ x: 'max-content' }}
                />
              </Col>
            </Row>
          </div>
        );
      }
    }
  )
);
