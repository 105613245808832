import { when, set } from 'cerebral/factories'; // wait
import notify from '@utils/notify';
import { state, props } from 'cerebral';
import { LOGIN_ERROR, ME_ERROR } from '@utils/constants';
import notifyAnt from '@utils/notify';
import page from 'page';
import * as actions from './actions';
import * as actionsDashboard from '../dashboard/actions';

export const bootstrap = [
  when(state.user.isLogin),
  {
    true: [],
    false: [
      actions.getLocalUser,
      {
        success: [
          actions.getUser,
          {
            success: [
              actions.setUser,
              set(state.user.isLogin, true),
              // wait(1000),
              set(state.app.showSplash, false)
            ],
            error: [
              set(state.app.showSplash, false),
              set(state.user.isLogin, false),
              notifyAnt(ME_ERROR, 'error'),
              actions.clearUser
            ]
          }
        ],
        error: [
          set(state.app.showSplash, false),
          set(state.user.isLogin, false),
          actions.clearUser
        ]
      }
    ]
  }
];

export const signIn = [
  ({ props, http, path, store }) => {
    store.set(state.user.loginButtonDisable, true);
    const { values } = props;
    return http
      .post('login', values)
      .then(res => path.success({ jwt: res.access_token, user: res.user }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      actions.validateRolUser,
      {
        success: [
          actions.getLocalUser,
          {
            success: [
              () => {
                page.show('/');
              },
              actions.setUser,
              actionsDashboard.getDashboardData,
              {
                success: [actionsDashboard.setDashboardData],
                error: []
              },
              set(state.user.isLogin, true)
            ],
            error: [notifyAnt(ME_ERROR, 'error')]
          }
        ],
        error: [notifyAnt(LOGIN_ERROR, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];

export const logout = [
  () => localStorage.clear(),
  set(state.user.isLogin, false),
  set(state.user.payload, {})
];

export default {
  bootstrap,
  signIn,
  logout
};
