import { set } from 'cerebral/factories';
import { state, props } from 'cerebral';
import { WithRouter } from '@modules/actions';
import * as actions from './actions';

import 'moment/locale/es';
import moment from 'moment';

const DashboardListRouter = WithRouter([
  set(state.app.currentPage, 'Home'),
  set(state.dashboardDates, {
    sales_month_date: moment().format('YYYY-MM-DD'),
    coupons_redeemed_for_the_month_date: moment().format('YYYY-MM-DD'),
    users_date: moment().format('YYYY-MM-DD')
  }),
  actions.getBrands,
  {
    success: [actions.setBrandList],
    error: []
  }
]);

const loadAsyncDashboardData = [
  ({ props, store }) => {

  },
  actions.getDashboardData,
  {
    success: [actions.setDashboardData],
    error: []
  },
];

const handleChangeSalesMonthDateDashboard = [
  ({ props, store }) => {
    store.set(state.dashboardDates.sales_month_date, props.date);
    store.set(state.dashboard.sales_of_the_month_amount, {calm_la_girls: 'Calculando...', calm_hayan: 'Calculando...', calm_la_colors: 'Calculando...', calm_jlash: 'Calculando...', calm_jbabe: 'Calculando...',} );
    store.set(state.dashboard.sales_of_the_month_total, {calm_la_girls: 'Calculando...', calm_hayan: 'Calculando...', calm_la_colors: 'Calculando...', calm_jlash: 'Calculando...', calm_jbabe: 'Calculando...',} );
  },
  actions.getDashboardDataMonth,
  {
    success: [actions.setDashboardMonthData],
    error: []
  }
];

const handleChangeSalesYearDateDashboard = [
  ({ props, store }) => {
    store.set(state.dashboardDates.sales_year_date, props.date);
    store.set(state.dashboard.total_sales_amount, {calm_la_girls: 'Calculando...', calm_hayan: 'Calculando...', calm_la_colors: 'Calculando...', calm_jlash: 'Calculando...', calm_jbabe: 'Calculando...',} );
    store.set(state.dashboard.total_sales_total, {calm_la_girls: 'Calculando...', calm_hayan: 'Calculando...', calm_la_colors: 'Calculando...', calm_jlash: 'Calculando...', calm_jbabe: 'Calculando...',} );
  },
  actions.getDashboardDataYear,
  {
    success: [actions.setDashboardYearData],
    error: []
  }
];
const handleChangeCouponsRedeemedForTheMonthDateDashboard = [
  ({ props, store }) => {
    store.set(
      state.dashboardDates.coupons_redeemed_for_the_month_date,
      props.date
    );
  },
  actions.getDashboardData,
  {
    success: [actions.setDashboardData],
    error: []
  }
];
const handleChangeUsersDateDashboard = [
  ({ props, store }) => {
    store.set(state.dashboardDates.users_date, props.date);
  },
  actions.getDashboardData,
  {
    success: [actions.setDashboardData],
    error: []
  }
];

const onChangeDaySalesBrandIdDashboard = [
  ({ props, get, store }) => {
    store.set(state.dashboard.brand_dayly_id, props.brand_id);
  }
];

const onChangeMonthSalesBrandIdDashboard = [
  ({ props, get, store }) => {
    store.set(state.dashboard.brand_monthly_id, props.brand_id);
  }
];

const onChangeTotalSalesBrandIdDashboard = [
  ({ props, get, store }) => {
    store.set(state.dashboard.brand_id, props.brand_id);
  }
];

export default {
  routes: {
    list: DashboardListRouter
  },
  sequences: {
    handleChangeSalesMonthDateDashboard,
    handleChangeSalesYearDateDashboard,
    handleChangeCouponsRedeemedForTheMonthDateDashboard,
    handleChangeUsersDateDashboard,
    onChangeDaySalesBrandIdDashboard,
    onChangeMonthSalesBrandIdDashboard,
    onChangeTotalSalesBrandIdDashboard,
    loadAsyncDashboardData,
  }
};
