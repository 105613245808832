export default function(target, value) {
  function push(context) {
    if (!context.resolve.isTag(target, 'state', 'moduleState')) {
      throw new Error(
        'Cerebral factory.push: You have to use the STATE TAG as first argument'
      )
    }

    context.store.push(target, value)
  }

  push.displayName = `factory.push(${String(target)})`

  return push
}
