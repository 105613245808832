import React, { Component } from 'react';
import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Upload,
  Button,
  Icon,
  Radio,
} from 'antd';

import 'react-quill/dist/quill.snow.css';
import { createValidation } from '@utils';
import normFile from '@utils/normFile';
import productWhitoutImage from '../../../../assets/product_whitout_image.png';
import ReactQuill from 'react-quill';

const style = {
  imageProductDefault: {
    maxWidth: '100%',
    width: '15rem',
    display: 'block',
    margin: 'auto',
    marginTop: '1rem',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  siderbarRight: {
    backgroundColor: '#f1f2f6',
    borderRadius: 5,
    padding: '2rem 1rem',
    height: '97%'
  }
};

export default class GeneralCreateComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {

    const {
      form,
    } = this.props;
    
    const { getFieldDecorator } = form;
    const FormItem = Form.Item;
    const modules = {
      toolbar: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' }
        ],
        ['link', 'image', 'video'],
        ['clean']
      ],
      clipboard: {
        matchVisual: false
      }
    };
    const formats = [
      'header',
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'video'
    ];
    return (
      <Row gutter={16} type="flex">
        <Col xs={24} sm={17} md={17}>

          <Row gutter={16}>
            <Col xs={24} sm={8} md={12}>
              <FormItem label="Ruta de navegación">
                {getFieldDecorator('slug', {
                  rules: [
                    createValidation(true, 'Por favor ingresa un slug pare el evento', '')
                  ]
                })(<Input placeholder="ruta de navegación" />)}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
          
            <Col xs={24} sm={8} md={12}>
              <FormItem label="Título">
                {getFieldDecorator('title', {
                  rules: [
                    createValidation(true, 'Por favor ingresa un título de evento', '')
                  ]
                })(<Input placeholder="Título" />)}
              </FormItem>
            </Col>

            <Col xs={24} sm={8} md={12}>
              <FormItem label="Estatus">
                {getFieldDecorator('status', {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor seleccione una opción'
                    }
                  ],
                  initialValue: 'PUBLISH'
                })(
                  <Select
                    placeholder="Por favor seleccione una opción"
                    style={{ width: '100%' }}
                  >
                    <Select.Option key="show-1" value='ERASE'>
                    Borrador
                    </Select.Option>
                    <Select.Option key="show-2" value='COMING_SOON'>
                    Próximamente
                    </Select.Option>
                    <Select.Option key="show-2" value='PUBLISH'>
                    Publicado
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          
          <Row gutter={16}>

            <Col xs={24} sm={8} md={12}>
              <FormItem label="Encabezado para mapa de ubicación">
                {getFieldDecorator('location_title', {
                  rules: [
                    createValidation(true, 'Ingresa un encabezado para el mapa de ubicación', '')
                  ]
                })(<Input placeholder="Encabezado" />)}
              </FormItem>
            </Col>

            <Col xs={24} sm={8} md={12}>
              <FormItem label="Fecha">
                {getFieldDecorator('start_at', {
                  rules: [{
                    required: true,
                    message: 'Por favor ingresa una Fecha'
                  }]
                })(<DatePicker
                  placeholder="Fecha"
                  style={{ width: '100%' }}> </DatePicker>)}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={16} type="flex">

            <Col xs={24} sm={24} md={24}>
              <FormItem label="Descripción">
                {getFieldDecorator('description', {
                  rules: [
                    createValidation(
                      false,
                      'Por favor ingresa la descripción',
                      ''
                    )
                  ],
                  initialValue: ''
                })(
                  <ReactQuill
                    placeholder="..."
                    modules={modules}
                    formats={formats}
                  />
                )}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={16}>

            <Col xs={24} sm={4} md={12}>
              <FormItem label="Título de sección de Video">
                {getFieldDecorator('video_title', {
                  rules: [
                    createValidation(true, 'Por favor ingresa un slug pare el evento', '')
                  ]
                })(<Input placeholder="Título de sección de Video" />)}
              </FormItem>
            </Col>
            
            <Col xs={24} sm={4} md={12}>
              <FormItem label="Código de video Youtube">
                {getFieldDecorator('video_code', {
                  rules: [
                    createValidation(true, 'Por favor ingresa un slug pare el evento', '')
                  ]
                })(<Input placeholder="Ej. KnuJY-XdLWs" />)}
              </FormItem>
            </Col>

            <Col xs={24} sm={8} md={12}>
              <FormItem label="Iframe google maps">
                {getFieldDecorator('event_iframe_gmaps', {
                  rules: [
                    createValidation(false, '', '')
                  ],
                  initialValue: ''
                })(<Input placeholder="Ej. <iframe src='google.com'></iframe>"/>)}
              </FormItem>
            </Col>

            <Col xs={24} sm={8} md={12}>
                <FormItem label="# Referencia">
                  {getFieldDecorator('reference', {
                    rules: [
                      createValidation(
                        false,
                        'Por favor ingresa una referencia',
                        ''
                      )
                    ],
                    initialValue: ''
                  })(<Input placeholder="Referencia" />)}
                </FormItem>
            </Col>

          </Row>

          <Row gutter={16}>

            <Col xs={24} sm={8} md={12}>
              <FormItem label="Nombre de salón de eventos">
                {getFieldDecorator('event_hall', {
                  rules: [
                    createValidation(true, 'Por favor ingresa el nombre del salón de eventos', '')
                  ],
                  initialValue: ''
                })(<Input placeholder="título de sección de de video"/>)}
              </FormItem>
            </Col>

            <Col xs={24} sm={8} md={12}>
              <FormItem label="Dirección de salón de eventos">
                {getFieldDecorator('event_address', {
                  rules: [
                    createValidation(false, 'Por favor ingresa un slug pare el evento', '')
                  ],
                  initialValue: ''
                })(<Input placeholder="Calle, número, CP. ciudad"/>)}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={16}>

            <Col xs={24} sm={8} md={12}>
              <FormItem label="Enlace externo">
                {getFieldDecorator('event_url', {
                  rules: [
                    createValidation(false, 'Por favor ingresa un enlace externo', '')
                  ],
                  initialValue: ''
                })(<Input placeholder="Ej. https://www.cosmeticosalamoda.com"/>)}
              </FormItem>
            </Col>

            <Col xs={24} sm={8} md={12}>
              <FormItem label="Texto en enlace">
                {getFieldDecorator('event_url_title', {
                  rules: [
                    createValidation(false, 'Por favor ingresa un texto para el enlace', '')
                  ],
                  initialValue: ''
                })(<Input placeholder="Ej. Ver más"/>)}
              </FormItem>
            </Col>

          </Row>
        </Col>

        <Col xs={24} sm={7} md={7}>

          <div style={style.siderbarRight}>
            
            <strong style={{margin: '0 auto 2em', display: 'table', alignContent: 'center',fontSize: '18px'}}>MEDIA</strong>

            <Row gutter={16}>
              <Col xs={24} sm={24} md={24}>
                <p style={style.pLabel}> Imagen Título (Altura: 115 x Ancho: 716):
                  <img
                    src={productWhitoutImage}
                    alt="sin imagen"
                    style={style.imageProductDefault}
                  />
                </p>
                <FormItem>
                  {getFieldDecorator('media_title_url', {
                    valuePropName: 'fileList',
                    getValueFromEvent: normFile,
                    rules: [
                      {
                        required: false,
                        message: 'Por favor seleccione una imagen'
                      }
                    ]
                  })(
                    <Upload
                      name="media_title_url"
                      beforeUpload={() => false}
                      accept="image/jpeg, image/png"
                      style={{ width: '100%' }}
                    >
                      <Button style={{ width: '100%' }}>
                        <Icon type="upload" /> Subir imagen
                      </Button>
                    </Upload>
                  )}
                </FormItem>
              </Col>
            </Row>
            <hr/>

            <Row gutter={16}>
              <Col xs={24} sm={24} md={24}>
                <p style={style.pLabel}> Imagen Descripción (Altura: 978 x Ancho: 673):
                  <img
                    src={productWhitoutImage}
                    alt="sin imagen"
                    style={style.imageProductDefault}
                  />
                </p>
                <FormItem>
                  {getFieldDecorator('media_description_url', {
                    valuePropName: 'fileList',
                    getValueFromEvent: normFile,
                    rules: [
                      {
                        required: false,
                        message: 'Por favor seleccione una imagen'
                      }
                    ]
                  })(
                    <Upload
                      name="media_description_url"
                      beforeUpload={() => false}
                      accept="image/jpeg, image/png"
                      style={{ width: '100%' }}
                    >
                      <Button style={{ width: '100%' }}>
                        <Icon type="upload" /> Subir imagen
                      </Button>
                    </Upload>
                  )}
                </FormItem>
              </Col>
            </Row>
            <hr/>

            <Row gutter={16}>
              <Col xs={24} sm={24} md={24}>
                <p style={style.pLabel}> Imagen Mapa Imagen Mapa (Altura: 650 x Ancho: 1280):
                  <img
                    src={productWhitoutImage}
                    alt="sin imagen"
                    style={style.imageProductDefault}
                  />
                </p>
                <FormItem>
                  {getFieldDecorator('media_location_url', {
                    valuePropName: 'fileList',
                    getValueFromEvent: normFile,
                    rules: [
                      {
                        required: false,
                        message: 'Por favor seleccione una imagen'
                      }
                    ]
                  })(
                    <Upload
                      name="media_location_url"
                      beforeUpload={() => false}
                      accept="image/jpeg, image/png"
                      style={{ width: '100%' }}
                    >
                      <Button style={{ width: '100%' }}>
                        <Icon type="upload" /> Subir imagen
                      </Button>
                    </Upload>
                  )}
                </FormItem>
              </Col>
            </Row>
            <hr/>

            <Row gutter={16}>
              <Col xs={24} sm={24} md={24}>
                <p style={style.pLabel}> Imagen Mapa Mobile (Altura: 460 x Ancho: 300):
                  <img
                    src={productWhitoutImage}
                    alt="sin imagen"
                    style={style.imageProductDefault}
                  />
                </p>
                <FormItem>
                  {getFieldDecorator('media_location_mobile_url', {
                    valuePropName: 'fileList',
                    getValueFromEvent: normFile,
                    rules: [
                      {
                        required: false,
                        message: 'Por favor seleccione una imagen'
                      }
                    ]
                  })(
                    <Upload
                      name="media_location_mobile_url"
                      beforeUpload={() => false}
                      accept="image/jpeg, image/png"
                      style={{ width: '100%' }}
                    >
                      <Button style={{ width: '100%'}}> <Icon type="upload"/> Subir imagen </Button>
                    </Upload>
                  )}
                </FormItem>
              </Col>
            </Row>
            <hr/>

            <Row gutter={16}>
              <Col xs={24} sm={24} md={24}>
                <p style={style.pLabel}> Imagen fondo para video (Altura: 720 x Ancho: 1920):
                  <img
                    src={productWhitoutImage}
                    alt="sin imagen"
                    style={style.imageProductDefault}
                  />
                </p>
                <FormItem>
                  {getFieldDecorator('media_bg_url', {
                    valuePropName: 'fileList',
                    getValueFromEvent: normFile,
                    rules: [
                      {
                        required: false,
                        message: 'Por favor seleccione una imagen'
                      }
                    ]
                  })(
                    <Upload
                      name="media_bg_url"
                      beforeUpload={() => false}
                      accept="image/jpeg, image/png"
                      style={{ width: '100%' }}
                    >
                      <Button style={{ width: '100%' }}>
                        <Icon type="upload" /> Subir imagen
                      </Button>
                    </Upload>
                  )}
                </FormItem>
              </Col>
            </Row>
          
          </div>
        
        </Col>

      </Row>
    );
  }
}
