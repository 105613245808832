import { state } from 'cerebral';

export function getAttributes({ http, path, get, props, store }) {
  let id_ = null;
  if (typeof props.params !== 'undefined') {
    id_ = props.params.option_id;
  }else{
    id_ = props.option_id;
  }
  store.set(state.attributes.father, id_);
  return http
    .get(
      `admin/options-attribute/${id_}/option`,
      {},
      {
        per_page: get(state.app.pagination.perPage),
        searchName: get(state.attributes.search.searchName)
      }
    )
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setAttributes({ props, store }) {
  const { result } = props;
  store.set(state.attributes.list, result.data);
}

export function getAttributeDetail({ props, http, path, store }) {
  // eslint-disable-next-line camelcase
  const { attribute_id, option_id } = props.params;
  store.set(state.attributes.father, option_id);
  return (
    http
      // eslint-disable-next-line camelcase
      .get(`admin/options-attribute/${attribute_id}`, {})
      .then(res => path.success({ result: res }))
      .catch(error => error.json().then(message => path.error({ message })))
  );
}
export function setAttributeDetail({ props, store }) {
  const { result } = props;
  store.set(state.attributes.detail, result);
}
