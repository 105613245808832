import { state } from 'cerebral';

// getters
export function getReportContent({http, path, get})
{
    return http
        .get('admin/reports/')
        .then(result => path.success({result: result}) )
        .catch(error => error.json().then( message => path.error({message}) ) )
}

export function getBrandsBySelect({ http, path }) {
    return http
        .get('brands-simple/', {})
        .then(res => path.success({ result: res }))
        .catch(error => error.json().then(message => path.error({ message })));
}

export function getCategoriesSimple({ http, path }) {
    return http
      .get('admin/categories-simple-product', {})
      .then(res => path.success({ result: res }))
      .catch(error => error.json().then(message => path.error({ message })));
}

export function getClients({ http, path }) {
    return http
      .get('admin/reports/clients', {})
      .then(res => path.success({ result: res }))
      .catch(error => error.json().then(message => path.error({ message })));
}
  
//setters
export function setBrandsSimple({ props, store }) {
    const { result } = props;
    store.set(state.reports.brands, result);
}

export function setCategoriesSimple({ props, store }) {
    const { result } = props;
    store.set(state.reports.categories, result);
}

export function setClients({ props, store }) {
    const { result } = props;
    store.set(state.coupons.clients, result);
}

export function setReportContent({props, store})
{
    const {result} = props
    store.set(state.reports.reports_types, result.types)
}