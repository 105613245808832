import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import {
  Divider,
  Icon,
  Form,
  Row,
  Col,
  Input,
  Select,
  InputNumber,
  Tooltip,
  Button,
  Table
} from 'antd';
import { createValidation } from '@utils';
import NumericInput from '@component/NumericInput';

export default Form.create()(
  connect(
    {
      detail: state.dealersMayoreo.detail,
      loadingLogin: state.user.loginButtonDisable,
      submit: sequences.updateDealerRequestMayoreo
    },
    class RequestDealerDetailTelemarketing extends Component {
      constructor(props) {
        super(props);
        this.state = {
          folio: props.detail.folio
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
      }

      handleSubmit(e) {
        e.preventDefault();
        const { form, submit, detail } = this.props;
        const { folio } = this.state;
        form.validateFields((err, values) => {
          if (!err) {
            values.folio = folio;
            values.id = detail.id;
            submit({ values });
          }
        });
      }

      onChange(value) {
        this.setState({ folio: value });
      }

      render() {
        const { detail, form, loadingLogin } = this.props;
        const { folio } = this.state;
        const { getFieldDecorator } = form;
        const FormItem = Form.Item;
        const { TextArea } = Input;

        return (
          <div>
            <Divider></Divider>
            <p>
              <Icon type="idcard" /> <strong>INFORMACIÓN GENERAL </strong>
            </p>
            <p>
              <strong>Nombre:</strong> {detail.name} {detail.last_name}
            </p>
            <p>
              <strong>Email:</strong> {detail.email}
            </p>
            <p>
              <strong>Telefono:</strong> {detail.phone}
            </p>
            <p>
            <strong>Fecha de registro:</strong> {detail.created_at}
            </p>
            <p>
              <strong>Montos de distribución:</strong> {detail.range}
            </p>
            <p>
              <strong>Ciudad:</strong> {detail.city}
            </p>
            <p>
              <strong>Estado:</strong> {detail.state}
            </p>
            <p>
              <strong>Mensaje:</strong> {detail.message}
            </p>
            <Divider></Divider>
            <p>
              <Icon type="tag" /> <strong>LOGS </strong>
            </p>
            <Table
              bordered
              dataSource={detail.logs_request_dealers}
              columns={[
                {
                  title: 'USUARIO',
                  dataIndex: 'user',
                  key: 'user',
                  render: (text, element, index) => (
                    <span>
                      {detail.logs_request_dealers[index].user.first_name}{' '}
                      {detail.logs_request_dealers[index].user.last_name}{' '}
                      {' / '}
                      {detail.logs_request_dealers[index].user.email}
                    </span>
                  )
                },
                {
                  title: 'CAMBIO',
                  dataIndex: 'change',
                  key: 'name',
                  render: (text, element, index) => (
                    <p>
                      {text
                        .split('*')
                        .filter(e => String(e).trim())
                        .map(log => (
                          <span>
                            {log}
                            <br />
                          </span>
                        ))}
                    </p>
                  )
                }
              ]}
              pagination={false}
            />
            <Divider></Divider>
            <Form onSubmit={this.handleSubmit} layout="horizontal">
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24}>
                  <FormItem className="move-content-right">
                    <Tooltip title="Guardar" placement="left">
                      <Button
                        icon="save"
                        type="primary"
                        htmlType="submit"
                        loading={loadingLogin}
                      />
                    </Tooltip>
                    <Tooltip title="Cancelar" placement="left">
                      <a
                        style={{ marginLeft: 10 }}
                        href="/dealers-request-mayoreo"
                        className="ant-btn ant-btn-primary ant-btn-icon-only"
                        rel="noopener noreferrer"
                      >
                        <Icon type="arrow-left" />
                      </a>
                    </Tooltip>
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={12}>
                  <FormItem label="Número de pedido">
                    {getFieldDecorator('folio', {
                      rules: [
                        createValidation(
                          false,
                          'Por favor ingresa un número de pedido',
                          ''
                        )
                      ],
                      initialValue: detail.folio == null ? '' : detail.folio
                    })(
                      <NumericInput
                        maxLength={30}
                        placeholder="Número de pedido"
                        value={folio}
                        onChange={this.onChange}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={12}>
                  <FormItem label="Status">
                    {getFieldDecorator('status', {
                      rules: [
                        createValidation(
                          false,
                          'Por favor seleccione una opción',
                          ''
                        )
                      ],
                      initialValue: detail.status == null ? '' : detail.status
                    })(
                      <Select placeholder="Status" style={{ width: '100%' }}>
                        <Select.Option key="status-0" value="old">
                          Registro anterior
                        </Select.Option>
                        <Select.Option key="status-1" value="unattended">
                          Sin atender
                        </Select.Option>
                        <Select.Option key="status-2" value="no_answer_client">
                          No contesta cliente
                        </Select.Option>
                        <Select.Option key="status-2" value="in_process">
                          En tramite / negociación
                        </Select.Option>
                        <Select.Option key="status-2" value="sold">
                          Vendido
                        </Select.Option>
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8} md={12}>
                  <FormItem label="Monto venta">
                    {getFieldDecorator('amount', {
                      rules: [
                        {
                          required: false,
                          message: 'Por favor ingresa un monto de venta'
                        }
                      ],
                      initialValue: detail.amount == null ? '' : detail.amount
                    })(
                      <InputNumber
                        placeholder="Monto venta"
                        step={0.1}
                        precision={2}
                        style={{ width: '100%' }}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <FormItem label="Comentarios">
                    {getFieldDecorator('comments', {
                      rules: [
                        createValidation(
                          false,
                          'Por favor ingresa un comentario',
                          ''
                        )
                      ],
                      initialValue:
                        detail.comments == null ? '' : detail.comments
                    })(<TextArea placeholder="Comentarios" rows={6} />)}
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>
        );
      }
    }
  )
);
