import React from 'react';
import { connect } from '@cerebral/react';
import { state } from 'cerebral';
import classnames from 'classnames/bind';
import { Breadcrumb as BreadcrumbAntd } from 'antd';
import capitalize from 'lodash/capitalize';
import style from './style.css';

const cx = classnames.bind(style);

export default connect(
  {
    path: state.app.currentPath
  },
  function Breadcrumb(props) {
    const routes = props.path.split('/').filter(url => url !== '');
    return (
      <BreadcrumbAntd className={cx('content-bredcrumb')}>
        <BreadcrumbAntd.Item>
          <a href="/">Inicio</a>
        </BreadcrumbAntd.Item>
        {routes.map((route, i) => {
          const url = `/${routes.slice(0, i + 1).join('/')}`;
          return (
            <BreadcrumbAntd.Item key={url}>
              {i === routes.length ? (
                capitalize()
              ) : (
                <a href={url}>{capitalize(route)}</a>
              )}
            </BreadcrumbAntd.Item>
          );
        })}
      </BreadcrumbAntd>
    );
  }
);
