import { state } from 'cerebral';

export function getProducts({ http, path, get }) {
    return http
    .get(
      'admin/products',
      {},
      {
        per_page: get(state.app.pagination.perPage),
        searchNameProduct: get(state.products.search.searchNameProduct),
        searchCategoriesIds: get(state.products.search.searchCategoriesIds).join(','),
        searchReferenceProduct: get(
          state.products.search.searchReferenceProduct
        ),
        sortProduct: get(state.products.search.sortProduct),
        brand_id: get(state.products.search.brand_id)
      }
    )
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setProducts({ props, store }) {
  const { result } = props;
  store.set(state.products.list, result.data);
}
export function getCategoriesSimple({ http, path }) {
  return http
    .get('admin/categories-simple-product', {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setCategoriesSimple({ props, store }) {
  const { result } = props;
  store.set(state.products.categories, result);
}
export function getCombinationGallery({ http, path, props }) {
  const { params } = props;
  return http
    .get(
      `admin/products-combinations/${params.uuid}/${params.product_combination_id}/gallery`,
      {}
    )
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setCombinationGallery({ props, store }) {
  const { result } = props;
  store.set(state.products.combinations.gallery, result.all);
  store.set(state.products.combinations.selectGallery, result.select);
  store.set(state.products.combinations.selectGalleryComplete, result.selected_complete);
  store.set(state.products.combinations.productCombination, result.productCombination);
}
export function getBrandsSimple({ http, path }) {
  return http
    .get('brands-simple/', {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setBrandsSimple({ props, store }) {
  const { result } = props;
  store.set(state.products.brands, result);
}
export function getBrandsBySelect({ http, path }) {
  return http
    .get('brands-simple/', {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setBrandsBySelect({ props, store }) {
  const { result } = props;
  store.set(state.products.search.brands, result);
}
export function getOptionsAttributeSimple({ http, path }) {
  return http
    .get('admin/options-attribute-simple-list', {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setOptionsAttributeSimple({ props, store }) {
  const { result } = props;
  store.set(state.products.optionsAttributes, result);
}
export function getTagsSimple({ http, path }) {
  return http
    .get('admin/tags-simple', {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setTagsSimple({ props, store }) {
  const { result } = props;
  store.set(state.products.tags, result);
}
export function setDefaultStateInProduct({ store }) {
  store.set(state.products.detail, {});
  store.set(state.products.combinations.optionsAttributesInSelect, []);
  store.set(state.products.combinations.editMode, false);
}
export function getProductDetail({ props, http, path, store }) {
  let uuid_ = null;
  if (typeof props.params !== 'undefined') {
    uuid_ = props.params.uuid;
  } else if (typeof props.res !== 'undefined') {
    uuid_ = props.res.uuid;
  } else {
    uuid_ = props.uuid;
  }
  return http
    .get(`admin/products/${uuid_}`, {})
    .then(res => path.success({ result: res }))
    .catch(error => error.json().then(message => path.error({ message })));
}
export function setProductDetail({ props, store }) {
  const { result } = props;
  store.set(state.products.detail, result);
}
export function uploadFilesNormal({ http, path, props }) {
  const { productId, files } = props;
  return Promise.all(
    files.map(file => {
      const data = new FormData();
      data.append('url_image', file);
      data.append('product_id', productId);
      return http.postFile(`admin/products/media/image`, data).catch(error => {
        return Promise.reject(error);
      });
    })
  )
    .then(res => path.success())
    .catch(error =>
      path.error({ message: 'Algunos archivos no se pudieron guardar', error })
    );
}