import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import Moment from 'react-moment';
import { Table, Button, Modal, Tooltip, Icon } from 'antd';
import Pagination from '@component/Elements/Pagination';

export default connect(
  {
    // states
    userSession: state.user.payload,
    stateDelete: state.admins.delete,
    list: state.admins.list,
    // secuences
    deleteAdmin: sequences.deleteAdmin,
    handleClickOpenModal: sequences.handleClickOpenModalAdmin,
    handleClickCloseModal: sequences.handleClickCloseModalAdmin
  },
  function AdminListView(props) {
    const {
      list,
      stateDelete,
      userSession,
      deleteAdmin,
      handleClickCloseModal,
      handleClickOpenModal
    } = props;

    const columns = [
      {
        title: 'CORREO ELECTRÓNICO',
        dataIndex: 'email',
        key: 'email',
        render: (text, element, index) => (
          <a href={`/administradores/${list[index].uuid}`}>{text}</a>
        )
      },
      {
        title: 'NOMBRE',
        dataIndex: 'first_name',
        key: 'first_name'
      },
      {
        title: 'APELLIDO',
        dataIndex: 'last_name',
        key: 'last_name'
      },
      {
        title: 'TELÉFONO',
        dataIndex: 'phone',
        key: 'phone'
      },
      {
        title: 'VERIFICACIÓN',
        dataIndex: 'verified',
        key: 'verified',
        render: text => (text === 1 ? 'Verificado' : 'Pendiente')
      },
      {
        title: 'FECHA REGISTRO',
        dataIndex: 'created_at',
        key: 'created_at',
        render: text => <Moment format="DD-MM-YYYY hh:mm A">{text}</Moment>
      },
      {
        title: 'ACCIONES',
        dataIndex: 'id',
        key: 'id',
        render: (text, element, index) => (
          <Tooltip title="Eliminar administrador" placement="left">
            <Button
              type="primary"
              icon="delete"
              disabled={userSession.id === text}
              onClick={() => {
                handleClickOpenModal(list[index]);
              }}
            />
          </Tooltip>
        )
      }
    ];
    return (
      <div>
        <Modal
          closable={false}
          visible={stateDelete.open}
          onCancel={() => {
            handleClickCloseModal();
          }}
          footer={[
            <Button
              type="primary"
              onClick={() => {
                deleteAdmin();
              }}
              loading={stateDelete.loader}
            >
              Confirmar
            </Button>,
            <Button
              onClick={() => {
                handleClickCloseModal();
              }}
            >
              Cancelar
            </Button>
          ]}
        >
          <h4>¿Desea eliminar al usuario '{stateDelete.name}' ?</h4>
          <p>
            Favor de verificar que el usuario seleccionado sea el que realmente
            desea eliminar.
          </p>
        </Modal>
        <div className="move-content-right">
          <Tooltip title="Crear administrador" placement="left">
            <a
              href="/administradores/nuevo"
              className="ant-btn ant-btn-primary ant-btn-icon-only"
              rel="noopener noreferrer"
            >
              <Icon type="plus" />
            </a>
          </Tooltip>
        </div>
        <Table
          bordered
          size="small"
          dataSource={list}
          columns={columns}
          pagination={false}
          scroll={{ x: 'max-content' }}
        />
        <Pagination list="admins.list" apiUrl="admin/admins" />
      </div>
    );
  }
);
