import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import {
  Button,
  Table,
  Tooltip,
  Modal,
  Row,
  Col,
  InputNumber,
  Select,
  Spin
} from 'antd';
import classNames from 'classnames/bind';
import { formatNumberAmount } from '@utils';
import debounce from 'lodash/debounce';
import styles from './style.css';

const { Option } = Select;
const cx = classNames.bind(styles);
const { confirm } = Modal;
const IVA = 1.16;
const style = {
  label: {
    marginBottom: 5
  }
};
export default connect(
  {
    // states
    debounceTime: state.debounceTime,
    // sequences
    sendMessageByView: sequences.sendMessageByView
  },
  class Kits extends Component {
    constructor(props) {
      super(props);
      this.state = {
        quantity: 0,
        price: 0,
        priceMoreIva: 0,
        selectValue: [],
        fetching: false,
        data: []
      };
      this.getPrice = this.getPrice.bind(this);
      this.showConfirm = this.showConfirm.bind(this);
      this.changePrice = this.changePrice.bind(this);
      this.changePriceMoreIva = this.changePriceMoreIva.bind(this);
      // search producto
      this.lastFetchId = 0;
      this.fetchCombinations = debounce(this.fetchCombinations.bind(this), 500);
      this.handleChangeCodes = this.handleChangeCodes.bind(this);
    }

    // eslint-disable-next-line react/sort-comp
    changePriceMoreIva(value) {
      this.value = value;
      // eslint-disable-next-line no-restricted-globals
      const withIva = isNaN(parseFloat(value, 10)) ? 0 : parseFloat(value, 10);
      this.setState({
        priceMoreIva: withIva.toFixed(2),
        // eslint-disable-next-line no-restricted-globals
        price: isNaN(parseFloat(value, 10)) ? 0 : parseFloat(value / IVA, 10)
      });
    }

    changePrice(value) {
      this.value = value;
      // eslint-disable-next-line no-restricted-globals
      const withIva = isNaN(parseFloat(value, 10))
        ? 0
        : parseFloat(value * IVA, 10);
      this.setState({
        // eslint-disable-next-line no-restricted-globals
        price: isNaN(parseFloat(value, 10)) ? 0 : parseFloat(value, 10),
        priceMoreIva: withIva.toFixed(2)
      });
    }

    showConfirm(product) {
      const { removeProductInKit } = this.props;
      confirm({
        title: `¿Esta seguro que desear eliminar el código "${product.code}"?`,
        content: '',
        onOk() {
          removeProductInKit(product);
        },
        onCancel() {}
      });
    }

    handleChangeCodes(value) {
      this.value = value;
      this.setState({
        selectValue: [value]
      });
    }

    fetchCombinations(value) {
      this.value = value;
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.setState({ data: [], fetching: true });
      fetch(
        `${process.env.API_URL}/api/v1/admin/search-products-kit?search=${value}`,
        {
          headers: {
            authorization: `bearer ${localStorage.getItem(
              process.env.NAME_TOKEN
            )}`
          }
        }
      )
        .then(response => response.json())
        .then(body => {
          if (fetchId !== this.lastFetchId) {
            return;
          }
          this.setState({ data: body, fetching: false });
        });
    }

    getPrice() {
      const { listKits } = this.props;
      let total = 0;
      listKits.forEach(element => {
        const priceTax = (16 * element.price) / 100 + element.price;
        total += priceTax * element.quantity;
      });
      return total;
    }

    addItem() {
      const { addProductInKit, sendMessageByView } = this.props;
      const { quantity, price, selectValue } = this.state;

      // product_id_kit

      if (quantity <= 0) {
        sendMessageByView({
          msg: 'Cantidad debe ser mayor a 0'
        });
        return;
      }
      if (price < 1) {
        sendMessageByView({
          msg: 'Precio debe ser mayor o igual a 1'
        });
        return;
      }
      addProductInKit({
        quantity,
        price,
        name: selectValue[0].label[2],
        code: selectValue[0].label[0],
        product_id_kit: selectValue[0].label[4],
        product_combination_id: selectValue[0].label[6]
      });
      this.setState({
        quantity: 0,
        price: 0,
        priceMoreIva: 0,
        selectValue: [],
        fetching: false,
        data: []
      });
    }

    render() {
      const { listKits, sendMessageByView } = this.props;
      const {
        quantity,
        priceMoreIva,
        price,
        selectValue,
        fetching,
        data
      } = this.state;
      const columns = [
        {
          title: 'CÓDIGO',
          dataIndex: 'code',
          key: 'code'
        },
        {
          title: 'Producto',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: 'Cantidad',
          dataIndex: 'quantity',
          key: 'quantity'
        },
        {
          title: 'PRECIO',
          dataIndex: 'price',
          key: 'price',
          render: (text, element, index) => (
            <span>${formatNumberAmount(text, '.', '.', ',', 4)}</span>
          )
        },
        {
          title: 'PRECIO + IVA',
          dataIndex: 'price',
          key: 'price',
          render: (text, element, index) => (
            <span>
              ${formatNumberAmount((16 * text) / 100 + text, '.', '.', ',', 6)}
            </span>
          )
        },
        {
          title: 'Total',
          dataIndex: 'price',
          key: 'price',
          render: (text, element, index) => (
            <span>
              $
              {formatNumberAmount(
                ((16 * text) / 100 + text) * listKits[index].quantity,
                '.',
                '.',
                ',',
                4
              )}
            </span>
          )
        },
        {
          title: 'ACCIONES',
          dataIndex: 'id',
          key: 'id',
          render: (text, element, index) => (
            <div>
              <Tooltip title="Eliminar" placement="top">
                <Button
                  type="primary"
                  icon="delete"
                  onClick={() => {
                    this.showConfirm(listKits[index]);
                  }}
                />
              </Tooltip>
            </div>
          )
        }
      ];
      return (
        <div>
          <Row className={cx('content')}>
            <Col xs={24} sm={5} md={5}>
              <p style={style.label}>
                <span className={cx('red')}>*</span> Código
              </p>
              {/*   */}
              <Select
                showSearch
                labelInValue
                value={selectValue}
                placeholder="Busque por el código de producto"
                notFoundContent={fetching ? <Spin size="small" /> : null}
                filterOption={false}
                className={cx('item-form')}
                // allowClear
                onSearch={this.fetchCombinations}
                onChange={this.handleChangeCodes}
              >
                {data.map(d => (
                  <Option
                    key={`${d.code}-${d.name}-${d.product_id}-${d.product_combination_id}`}
                    value={d.code}
                  >
                    {d.code} / {d.name} / {d.product_id} /
                    {d.product_combination_id}
                  </Option>
                ))}
              </Select>
              {selectValue.length > 0 && (
                <Button
                  size="small"
                  onClick={() => {
                    this.setState({
                      selectValue: []
                    });
                  }}
                  type="danger"
                  icon="close"
                  style={{ marginTop: 10 }}
                />
              )}
            </Col>
            <Col xs={24} sm={4} md={4}>
              <p style={style.label}>
                <span className={cx('red')}>*</span> Cantidad
              </p>
              <InputNumber
                className={cx('item-form')}
                placeholder="Cantidad"
                step={1}
                precision={0}
                value={quantity}
                onChange={e => {
                  this.setState({
                    // eslint-disable-next-line no-restricted-globals
                    quantity: isNaN(parseFloat(e, 10)) ? 0 : parseFloat(e, 10)
                  });
                }}
              />
            </Col>
            <Col xs={24} sm={4} md={4}>
              <p style={style.label}>
                <span className={cx('red')}>*</span> Precio
              </p>
              <InputNumber
                placeholder="Precio"
                step={0.1}
                precision={6}
                value={price}
                className={cx('item-form')}
                onChange={this.changePrice}
              />
            </Col>
            <Col xs={24} sm={4} md={4}>
              <p style={style.label}>
                <span className={cx('red')}>*</span> Precio + IVA
              </p>
              <InputNumber
                placeholder="Precio"
                step={0.1}
                precision={6}
                className={cx('item-form')}
                value={priceMoreIva}
                onChange={this.changePriceMoreIva}
              />
            </Col>
            <Col xs={24} sm={4} md={4}>
              <Button
                className={cx('btn-create')}
                type="primary"
                htmlType="submit"
                // loading={loading}
                onClick={() => {
                  if (selectValue.length > 0) {
                    this.addItem();
                  } else {
                    sendMessageByView({
                      msg: 'Es necesario seleccionar un código de producto'
                    });
                  }
                }}
              >
                Añadir kit
              </Button>
            </Col>
          </Row>
          <Table
            style={{ marginTop: 20, marginBottom: 10 }}
            columns={columns}
            dataSource={listKits}
            bordered
            pagination={false}
            // loading={loading}
            scroll={{ x: 'max-content' }}
          />
          <p className={cx('total')}>
            <strong> Precio final:</strong> $
            {formatNumberAmount(this.getPrice(), '.', '.', ',', 6)}
          </p>
        </div>
      );
    }
  }
);
