import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import {
  Table,
  Button,
  Modal,
  Tooltip,
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Icon
} from 'antd';
import Moment from 'react-moment';
import debounce from 'lodash/debounce';
import Pagination from '@component/Elements/Pagination';
import moment from 'moment';
import { formatNumberAmount } from '@utils';

const { RangePicker } = DatePicker;

export default connect(
  {
    // states
    list: state.coupons.list,
    extraBySearch: state.coupons.search,
    loading: state.coupons.search.loading,
    // secuences
    deleteCoupon: sequences.deleteCoupon,
    updatedFilterCoupon: sequences.updatedFilterCoupon,
    updatedFilterCouponDatesStart: sequences.updatedFilterCouponDatesStart,
    updatedFilterCouponDatesEnd: sequences.updatedFilterCouponDatesEnd,
    debounceTime: state.debounceTime
  },
  class CouponsListView extends Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.deleteItem = this.deleteItem.bind(this);
      this.handleSearchByCode = this.handleSearchByCode.bind(this);
      this.handleSearchByName = this.handleSearchByName.bind(this);
      this.handleSearchBySort = this.handleSearchBySort.bind(this);
      this.handleSearchDebounce = debounce(
        this.handleSearchDebounce,
        props.debounceTime
      );
      this.handleSearchByStartDate = this.handleSearchByStartDate.bind(this);
      this.handleSearchByEndDate = this.handleSearchByEndDate.bind(this);
    }

    handleSearchByStartDate(value) {
      this.value = value;
      const { updatedFilterCouponDatesStart } = this.props;

      if (value.length === 2) {
        updatedFilterCouponDatesStart({
          searchByStartDate1: moment(value[0]).format('YYYY-MM-DD H:mm'),
          searchByStartDate2: moment(value[1]).format('YYYY-MM-DD H:mm')
        });
      } else {
        updatedFilterCouponDatesStart({
          searchByStartDate1: '',
          searchByStartDate2: ''
        });
      }
    }

    handleSearchByEndDate(value) {
      this.value = value;

      const { updatedFilterCouponDatesEnd } = this.props;

      if (value.length === 2) {
        updatedFilterCouponDatesEnd({
          searchByEndDate1: moment(value[0]).format('YYYY-MM-DD H:mm'),
          searchByEndDate2: moment(value[1]).format('YYYY-MM-DD H:mm')
        });
      } else {
        updatedFilterCouponDatesEnd({
          searchByEndDate1: '',
          searchByEndDate2: ''
        });
      }
    }

    deleteItem(id, title) {
      this.id = id;
      const { deleteCoupon } = this.props;
      Modal.confirm({
        title: 'Confirmación',
        content: `Esta seguro que desea eliminar el cupon "${title}"`,
        okText: 'Aceptar',
        cancelText: 'Cancelar',
        onOk: () => deleteCoupon({ id })
      });
    }

    handleSearchByCode(e) {
      this.e = e;
      const { value } = e.target;
      this.handleSearchDebounce('code', value);
    }

    handleSearchByName(e) {
      this.e = e;
      const { value } = e.target;
      this.handleSearchDebounce('searchByName', value);
    }

    handleSearchBySort(value) {
      this.value = value;
      const key = 'sort';

      const { updatedFilterCoupon } = this.props;
      updatedFilterCoupon({ key, value });
    }

    handleSearchDebounce(key, value) {
      this.key = key;
      this.value = value;
      const { updatedFilterCoupon } = this.props;
      updatedFilterCoupon({ key, value });
    }

    sumOrders(coupon) {
      this.coupon = coupon;
      let total = 0;
      if (coupon.order_coupon.length > 0) {
        coupon.order_coupon.forEach(o => {
          total += o.order.total_paid_amount;
        });
      }
      return `$${formatNumberAmount(total, '.', '.', ',')}`;
    }

    render() {
      const { list, extraBySearch, loading } = this.props;
      const columns = [
        {
          title: 'CÓDIGO',
          dataIndex: 'code',
          key: 'code',
          render: (text, element, index) => (
            <a href={`/cupones/${list[index].id}`}>{text}</a>
          )
        },
        {
          title: 'NOMBRE',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: 'MODALIDAD',
          dataIndex: 'modality_front',
          key: 'modality_front',
          render: text => <span>{text === null ? 'Sin limite' : text}</span>
        },
        {
          title: 'LIMITE',
          dataIndex: 'units_limit',
          key: 'units_limit',
          render: text => <span>{text === null ? 'Sin limite' : text}</span>
        },
        {
          title: 'DESCUENTO',
          dataIndex: 'value',
          key: 'value'
        },
        {
          title: 'TIPO',
          dataIndex: 'type_value',
          key: 'type_value',
          render: text => <span>{text === 'PERCENT' ? '%' : '$'}</span>
        },
        {
          title: 'INICIO',
          dataIndex: 'start_date',
          key: 'start_date',
          render: text => (
            <span>
              {text != null ? (
                <Moment format="DD-MM-YYYY">{text}</Moment>
              ) : (
                'Sin limite'
              )}{' '}
            </span>
          )
        },
        {
          title: 'FIN',
          dataIndex: 'end_date',
          key: 'end_date',
          render: text => (
            <span>
              {text != null ? (
                <Moment format="DD-MM-YYYY">{text}</Moment>
              ) : (
                'Sin limite'
              )}{' '}
            </span>
          )
        },
        {
          title: 'ACCIONES',
          dataIndex: 'id',
          key: 'id',
          render: (text, element, index) => (
            <Tooltip title="Eliminar cupon" placement="top">
              <Button
                type="primary"
                icon="delete"
                onClick={() => {
                  this.deleteItem(list[index].id, list[index].code);
                }}
              />
            </Tooltip>
          )
        },
        {
          title: 'CANJES ACTUALES',
          dataIndex: 'redeem',
          key: 'redeem'
        },
        {
          title: 'TOTAL DE ORDENES',
          dataIndex: 'id',
          key: 'sum',
          render: (text, element, index) => (
            <span>{this.sumOrders(list[index])}</span>
          )
        }
      ];

      return (
        <div>
          <div className="move-content-right">
            <Tooltip title="Crear cupon" placement="left">
              <a
                href="/cupones/nuevo"
                className="ant-btn ant-btn-primary ant-btn-icon-only"
                rel="noopener noreferrer"
              >
                <Icon type="plus" />
              </a>
            </Tooltip>
          </div>
          <Row gutter={16} style={{ marginBottom: 20 }}>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              <Input
                placeholder="Código"
                allowClear
                onChange={this.handleSearchByCode}
                style={{ width: '100%' }}
              />
            </Col>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              <Input
                placeholder="Nombre"
                allowClear
                onChange={this.handleSearchByName}
                style={{ width: '100%' }}
              />
            </Col>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              <Select
                defaultValue="DESC"
                placeholder="Orden"
                style={{ width: '100%' }}
                onChange={this.handleSearchBySort}
              >
                <Select.Option key="sort-1" value="ASC">
                  ASC
                </Select.Option>
                <Select.Option key="sort-2" value="DESC">
                  DESC
                </Select.Option>
              </Select>
            </Col>
            <br></br>
            <br></br>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              <RangePicker
                showTime={false}
                format="YYYY-MM-DD"
                placeholder={['Inicio rango', 'Inicio rango']}
                onChange={this.handleSearchByStartDate}
                style={{ width: '100%' }}
              />
            </Col>
            <Col xs={24} sm={6} md={6}>
              <RangePicker
                showTime={false}
                format="YYYY-MM-DD "
                placeholder={['Fin rango', 'Fin rango']}
                onChange={this.handleSearchByEndDate}
                style={{ width: '100%' }}
              />
            </Col>
          </Row>
          <Table
            loading={loading}
            bordered
            size="small"
            dataSource={list}
            columns={columns}
            pagination={false}
            scroll={{ x: 'max-content' }}
          />
          <Pagination
            list="coupons.list"
            apiUrl="admin/coupons"
            extraBySearch={extraBySearch}
          />
        </div>
      );
    }
  }
);
