import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { sequences, state } from 'cerebral';
import { 
  Button,
  Card,
  Col,
  Form,
  Icon,
  Row,
  Select,
  Table,
  Tooltip,
} from 'antd';

import Moment from 'react-moment';
import classNames from 'classnames/bind';
import { formatNumberAmount } from '@utils';
import styles from './styles.css';

const cx = classNames.bind(styles);
const { Option } = Select;

export default Form.create()(

  connect(
    {
      // states
      detail: state.web.detail,
      tags: state.web.tags,
      loadingLogin: state.user.loginButtonDisable,

      submit: sequences.updateUser
    },
    class WebDetailView extends Component {

      constructor(props) {
        super(props);
        this.state = {};
        this.tagsDefault = this.tagsDefault.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }

      tagsDefault() {
        const { detail } = this.props;
        const data = [];
        detail.tags.forEach(element => {
          data.push(element.id.toString());
        });
        return data;
      }

      handleSubmit(e) {
        e.preventDefault();
        const { form, submit, detail } = this.props;
        const { } = this.state;
        form.validateFields((err, values) => {
          if (!err) {
            values.rol_id = detail.rol_id
            values.uuid = detail.uuid
            submit({ values });
          }
        });
      }

      render() {
        const { 
          detail,
          form,
          loadingLogin,
          tags,
        } = this.props; 
        const FormItem = Form.Item;
        const { getFieldDecorator } = form;

        const columns = [
          {
            title: 'ORDEN ID',
            dataIndex: 'shipping_number',
            key: 'shipping_number',
            render: (text, element, index) => (
              <a href={`/ordenes/${detail.order[index].uuid}`}>{text}</a>
            )
          },
          {
            title: 'CLIENTE',
            dataIndex: 'name',
            key: 'name',
            render: (text, element, index) => (
              <span>
                {detail.order[index].first_name} {detail.order[index].last_name}
              </span>
            )
          },
          {
            title: 'ESTATUS',
            dataIndex: 'order_status.name',
            key: 'order_status_name'
          },
          {
            title: 'TOTAL',
            dataIndex: 'total_paid_amount',
            key: 'total_paid_amount',
            render: text => <span>${formatNumberAmount(text, '.', '.', ',')}</span>
          },
          {
            title: 'FECHA CREACIÓN',
            dataIndex: 'created_at',
            key: 'created_at',
            render: text => <Moment format="DD-MM-YYYY hh:mm A">{text}</Moment>
          }
        ];
        const columnsAddress = [
          {
            title: 'ALIAS',
            dataIndex: 'alias',
            key: 'alias',
            render: text => <strong>{text.toUpperCase()}</strong>
          },
          {
            title: 'DIRECCIÓN',
            dataIndex: 'id',
            key: 'id',
            render: (text, element, index) => (
              <span>
                {detail.order_address[index].address1}{' '}
                {detail.order_address[index].city}{' '}
                {detail.order_address[index].state}{' '}
                {detail.order_address[index].country}. Referencia:{' '}
                {detail.order_address[index].address2 === null
                  ? 'N/A'
                  : detail.order_address[index].address2}
              </span>
            )
          },
          {
            title: 'CÓDIGO POSTAL',
            dataIndex: 'post_code',
            key: 'post_code'
          },
          {
            title: 'TELÉFONO',
            dataIndex: 'phone',
            key: 'phone',
            render: (text, element, index) => (
              <span>
                Celular: {detail.order_address[index].phone_mobile}
                {detail.order_address[index].phone === null ? null : (
                  <span>
                    <br />
                    Casa:{detail.order_address[index].phone}
                  </span>
                )}
              </span>
            )
          },
          {
            title: 'PERSONA QUE RECIBIRA',
            dataIndex: 'first_name',
            key: 'first_name',
            render: (text, element, index) => (
              <span>
                {detail.order_address[index].first_name}{' '}
                {detail.order_address[index].last_name}
              </span>
            )
          },
          {
            title: 'FECHA CREACIÓN',
            dataIndex: 'created_at',
            key: 'created_at',
            render: text => <Moment format="DD-MM-YYYY hh:mm A">{text}</Moment>
          }
        ];
        const columnsCards = [
          {
            title: 'DEFAULT',
            dataIndex: 'default',
            key: 'default',
            render: text => <strong>{text === true ? 'Si' : 'No'}</strong>
          },
          {
            title: 'NOMBRE DUEÑO DE TARJETA',
            dataIndex: 'name',
            key: 'name'
          },
          {
            title: 'MARCA',
            dataIndex: 'brand',
            key: 'brand'
          },
          {
            title: 'TARJETA',
            dataIndex: 'last4',
            key: 'last4',
            render: (text, element, index) => <span>XXXX-XXXX-XXXX-{text}</span>
          },
          {
            title: 'MES EXPIRACIÓN',
            dataIndex: 'exp_month',
            key: 'exp_month',
            render: (text, element, index) => (
              <span>
                {text === '1' || text === '01' ? 'ENERO' : null}
                {text === '2' || text === '02' ? 'FEBRERO' : null}
                {text === '3' || text === '03' ? 'MARZO' : null}
                {text === '4' || text === '04' ? 'ABRIL' : null}
                {text === '5' || text === '05' ? 'MAYO' : null}
                {text === '6' || text === '06' ? 'JUNIO' : null}
                {text === '7' || text === '07' ? 'JULIO' : null}
                {text === '8' || text === '08' ? 'AGOSTO' : null}
                {text === '9' || text === '09' ? 'SEPTIEMBRE' : null}
                {text === '10' ? 'OCTUBRE' : null}
                {text === '11' ? 'NOVIEMBRE' : null}
                {text === '12' ? 'DICIEMBRE' : null}
              </span>
            )
          },
          {
            title: 'AÑO EXPIRACIÓN',
            dataIndex: 'exp_year',
            key: 'exp_year'
          },
          {
            title: 'FECHA CREACIÓN',
            dataIndex: 'created_at',
            key: 'created_at',
            render: text => (
              <Moment unix format="DD-MM-YYYY hh:mm A">
                {text}
              </Moment>
            )
          }
        ];

        return (
          <div>
            <Form onSubmit={this.handleSubmit} layout="horizontal">

              <div className="move-content-right">
                <Tooltip title="Guardar cambios" placement="left">
                  <Button
                    icon="save"
                    type="primary"
                    htmlType="submit"
                    loading={loadingLogin}
                  />
                </Tooltip>
                <Tooltip title="Atras" placement="left">
                  <a
                    style={{ marginLeft: 10 }}
                    href="/clientes"
                    className="ant-btn ant-btn-primary ant-btn-icon-only"
                    rel="noopener noreferrer"
                  >
                    <Icon type="arrow-left" />
                  </a>
                </Tooltip>
              </div>

              <Row gutter={16} className={cx('mb-20')} type="flex">
                <Col xs={24} sm={8} md={8}>
                  <Card title="Datos generales" className={cx('h-100')}>
                    <Row
                      gutter={16}
                      className={cx('mb-10')}
                      type="flex"
                      align="middle"
                    >
                      <Col>
                        <Tooltip title="Nombre completo">
                          <Button type="primary" icon="smile" />
                        </Tooltip>
                      </Col>
                      <Col>
                        {detail.first_name} {detail.last_name}
                      </Col>
                    </Row>
                    <Row
                      gutter={16}
                      className={cx('mb-10')}
                      type="flex"
                      align="middle"
                    >
                      <Col>
                        <Tooltip title="Correo electrónico">
                          <Button type="primary" icon="mail" />
                        </Tooltip>
                      </Col>
                      <Col> {detail.email}</Col>
                    </Row>
                    <Row
                      gutter={16}
                      className={cx('mb-10')}
                      type="flex"
                      align="middle"
                    >
                      <Col>
                        <Tooltip title="Teléfono">
                          <Button type="primary" icon="phone" />
                        </Tooltip>
                      </Col>
                      <Col>
                        {' '}
                        {detail.phone === null ? 'Sin registro' : detail.phone}
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs={24} sm={8} md={8}>
                  <Card title="Cuenta" className={cx('h-100')}>
                    <Row
                      gutter={16}
                      className={cx('mb-10')}
                      type="flex"
                      align="middle"
                    >
                      <Col>
                        <Tooltip title="Fecha registro">
                          <Button type="primary" icon="calendar" />
                        </Tooltip>
                      </Col>
                      <Col>
                        <Moment format="DD/MM/YYYY hh:mm A">
                          {detail.created_at}
                        </Moment>
                      </Col>
                    </Row>
                    <Row
                      gutter={16}
                      className={cx('mb-10')}
                      type="flex"
                      align="middle"
                    >
                      <Col>
                        <Tooltip title="Verificación">
                          <Button type="primary" icon="thunderbolt" />
                        </Tooltip>
                      </Col>
                      <Col>{detail.verified === 1 ? 'Verificado' : 'Pendiente'}</Col>
                    </Row>
                    <Row
                      gutter={16}
                      className={cx('mb-10')}
                      type="flex"
                      align="middle"
                    >
                      <Col>
                        <Tooltip title="Provedor">
                          <Button type="primary" icon="info-circle" />
                        </Tooltip>
                      </Col>
                      <Col>
                        {detail.provider === null
                          ? 'Cuenta normal'
                          : detail.provider.toUpperCase()}
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs={24} sm={8} md={8}>
                  <Card title="Etiquetas de clientes" className={cx('h-100')}>
                    <Row
                      gutter={16}
                      className={cx('mb-10')}
                      style={{ width: '100%', display: 'block' }}
                      type="flex"
                      align="middle">
                      <FormItem label="Tags">
                        {getFieldDecorator('tags', {
                          rules: [
                            {
                              required: false,
                              message: 'Por favor seleccione algunos tags'
                            }
                          ],
                          initialValue: this.tagsDefault()
                        })(
                          <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Por favor seleccione algunos tags"
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {tags.map(value => (
                              <Select.Option
                                key={`tags-${value.id}`}
                                value={value.id.toString()}
                              >
                                {value.name}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </FormItem>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Row gutter={16} style={{ marginBottom: 20 }}>
                <Col span={24}>
                  <Card
                    title={`Ordenes (${detail.order.length})`}
                    className={cx('h-100')}
                  >
                    <Table
                      columns={columns}
                      dataSource={detail.order}
                      bordered
                      pagination={false}
                      scroll={{ x: 'max-content' }}
                    />
                  </Card>
                </Col>
              </Row>

              <Row gutter={16} style={{ marginBottom: 20 }}>
                <Col span={24}>
                  <Card title="Direcciones de entrega" className={cx('h-100')}>
                    <Table
                      columns={columnsAddress}
                      dataSource={detail.order_address}
                      bordered
                      pagination={false}
                      scroll={{ x: 'max-content' }}
                    />
                  </Card>
                </Col>
              </Row>

              <Row gutter={16} style={{ marginBottom: 20 }}>
                <Col span={24}>
                  <Card title="Tarjetas" className={cx('h-100')}>
                    <Table
                      columns={columnsCards}
                      dataSource={detail.cards}
                      bordered
                      pagination={false}
                      scroll={{ x: 'max-content' }}
                    />
                  </Card>
                </Col>
              </Row>
              
            </Form>
          </div>
        );

      }
    }
  )
);
