import { set } from 'cerebral/factories'; // wait
import { state, props } from 'cerebral';
import { WithRouter, setPagination } from '@modules/actions';
import * as actions from './actions';
import notify from '@utils/notify';
import page from 'page';

const WebListRouter = WithRouter([
  set(state.web.search, {
    loading: false,
    searchByNameClient: '',
    searchByEmailClient: ''
  }),
  set(state.app.currentPage, 'WebListView'),
  actions.getWeb,
  {
    success: [setPagination, actions.setWeb],
    error: []
  },
  actions.getTagsSimple,
  {
    success: [setPagination, actions.setTagsSimple],
    error: [notify(props.message.error, 'error')]
  },
]);
const WebDetailRouter = WithRouter([
  set(state.web.detail, {}),

  actions.getWebDetail,
  {
    success: [actions.setWebDetail],
    error: [notify(props.message.error, 'error')]
  },
  set(state.app.currentPage, 'WebDetailView'),
  actions.getTagsSimple,
  {
    success: [setPagination, actions.setTagsSimple],
    error: [notify(props.message.error, 'error')]
  },
]);
const updatedFilterGeneralClient = [
  ({ store }) => {
    store.set(state.web.search.loading, true);
  },
  ({ props, store }) => {
    const { value, key } = props;
    store.set(state.web.search[key], value);
  },
  actions.getWeb,
  {
    success: [
      setPagination,
      actions.setWeb,
      ({ store }) => {
        store.set(state.web.search.loading, false);
      }
    ],
    error: []
  }
];

const updateUser = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values } = props;
    
    return http
      .post("admin/normals/" + values.uuid + "/update", values)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Usuario actualizado', 'success'),
      () => {
        page.show('/clientes');
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
]

const updatedSearchTagsIds = [
  ({ store }) => {
    store.set(state.web.search.loading, true);
  },
  ({ props, store }) => {
    const { value } = props;
    store.set(state.web.search.searchTagsIds, value.value);
  },
  actions.getWeb,
  {
    success: [
      setPagination,
      actions.setWeb,
      ({ store }) => {
        store.set(state.web.search.loading, false);
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
];

export default {
  routes: {
    list: WebListRouter,
    detail: WebDetailRouter
  },
  sequences: {
    updatedFilterGeneralClient,
    updateUser,
    updatedSearchTagsIds,
  }
};
