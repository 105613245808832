import React from 'react';
import { Spin, Icon } from 'antd';
import { LOADING_TEXT } from '@utils/constants';
import style from './style.css';

export default function Splash() {
  const antIcon = <Icon type="loading" style={{ fontSize: 40 }} />;

  return (
    <div className={style.loader}>
      <div className={style.content}>
        <Spin indicator={antIcon} />
        <div className={style.wrapper}>
          <h4>{LOADING_TEXT}</h4>
        </div>
      </div>
    </div>
  );
}
