import { set } from 'cerebral/factories'; // wait
import { state, props } from 'cerebral';
import { WithRouter, setPagination } from '@modules/actions';
import notify from '@utils/notify';
import page from 'page';
import * as actions from './actions';

const CategoryListRouter = WithRouter([
  set(state.app.currentPage, 'CategoryListView'),
  set(state.categories.search, {
    loading: false,
    categoryNameSearch: ''
  }),
  actions.getCategoryFatherAll,
  {
    success: [actions.setCategoryFatherAll],
    error: [notify(props.message.error, 'error')]
  },
  actions.getCategories,
  {
    success: [setPagination, actions.setCategories],
    error: [notify(props.message.error, 'error')]
  }
]);
const CategoryNewRouter = WithRouter([
  set(state.app.currentPage, 'CategoryFormView'),
  actions.getBrandsSimple,
  {
    success: [actions.setBrandsSimple],
    error: [notify(props.message.error, 'error')]
  },
  actions.getCategoryFatherOnlyActive,
  {
    success: [actions.setCategoryFatherOnlyActive],
    error: [notify(props.message.error, 'error')]
  }
]);
const CategoryFormViewUpdate = WithRouter([
  set(state.categories.detail, {}),
  set(state.app.currentPage, 'CategoryFormViewUpdate'),
  actions.getCategoryFatherOnlyActive,
  {
    success: [
      actions.setCategoryFatherOnlyActive,
      actions.getBrandsSimple,
      {
        success: [actions.setBrandsSimple],
        error: [notify(props.message.error, 'error')]
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  actions.getCategoriesDetail,
  {
    success: [actions.setCategoriesDetail],
    error: [notify(props.message.error, 'error')]
  }
]);

const handleClickOpenModalCategories = [
  ({ props, store }) => {
    store.set(state.categories.delete, {
      id: props.id,
      name: props.name,
      open: true,
      loader: false
    });
  }
];
const handleClickCloseModalCategories = [
  ({ store }) => {
    store.set(state.categories.delete, {
      id: null,
      name: null,
      open: false,
      loader: false
    });
  }
];
const deleteCategory = [
  ({ store }) => {
    store.set(state.categories.delete.loader, true);
  },
  ({ http, path, get }) => {
    return http
      .delete(`admin/categories/${get(state.categories.delete.id)}`, {})
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Categoria eliminada', 'success'),
      ({ store }) => {
        store.set(state.categories.delete, {
          id: null,
          name: null,
          open: false,
          loader: false
        });
        page.show('/categorias');
      }
    ],
    error: [
      ({ store }) => {
        store.set(state.categories.delete, {
          id: null,
          name: null,
          open: false,
          loader: false
        });
      },
      notify(props.message.error, 'error')
    ]
  }
];
const saveCategory = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values } = props;
    const { brands } = values;

    const data = new FormData();
    if (typeof values.banner_url !== 'undefined') {
      const file = values.banner_url[0].originFileObj;
      data.append('banner_url', file);
    }
    if (typeof values.banner_big_url !== 'undefined') {
      const file = values.banner_big_url[0].originFileObj;
      data.append('banner_big_url', file);
    }
    if (typeof values.banner_small_url !== 'undefined') {
      const file = values.banner_small_url[0].originFileObj;
      data.append('banner_small_url', file);
    }
    data.append('name', values.name);
    data.append('status', values.status);
    if (values.parent !== 'null') {
      data.append('parent', values.parent);
    }
    if (typeof values.alt !== 'undefined') {
      data.append('alt', values.alt);
    }
    if (values.sort !== null) {
      data.append('sort', values.sort);
    }
    if (typeof values.brands !== 'undefined') {
      const brandsArray = brands.toString().split(',');
      brandsArray.forEach(element => {
        if (element !== '') {
          data.append('brands[]', element);
        }
      });
    }
    return http
      .postFile('admin/categories', data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Categoria creada', 'success'),
      () => {
        page.show('/categorias');
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];
const updateCategory = [
  ({ store }) => {
    store.set(state.user.loginButtonDisable, true);
  },
  ({ props, http, path }) => {
    const { values, id } = props;
    const { brands } = values;
    const data = new FormData();

    data.append('category_id', id);
    data.append('name', values.name);
    data.append('status', values.status);
    data.append('parent', values.parent);
    if (typeof values.alt !== 'undefined' && values.alt !== null) {
      data.append('alt', values.alt);
    }
    if (typeof values.banner_url !== 'undefined') {
      const file = values.banner_url[0].originFileObj;
      data.append('banner_url', file);
    }
    if (typeof values.banner_big_url !== 'undefined') {
      const file = values.banner_big_url[0].originFileObj;
      data.append('banner_big_url', file);
    }
    if (typeof values.banner_small_url !== 'undefined') {
      const file = values.banner_small_url[0].originFileObj;
      data.append('banner_small_url', file);
    }
    if (values.sort !== null) {
      data.append('sort', values.sort);
    }
    if (typeof values.brands !== 'undefined') {
      const brandsArray = brands.toString().split(',');
      brandsArray.forEach(element => {
        if (element !== '') {
          data.append('brands[]', element);
        }
      });
    }
    return http
      .postFile('admin/categories/update', data)
      .then(res => path.success({ res }))
      .catch(error => error.json().then(message => path.error({ message })));
  },
  {
    success: [
      notify('Categoria actualizada', 'success'),
      () => {
        page.show('/categorias');
      }
    ],
    error: [notify(props.message.error, 'error')]
  },
  ({ store }) => {
    store.set(state.user.loginButtonDisable, false);
  }
];

const updatedFilterGeneralCategory = [
  ({ store }) => {
    store.set(state.categories.search.loading, true);
  },
  ({ props, store }) => {
    const { value, key } = props;
    store.set(state.categories.search[key], value);
  },
  actions.getCategories,
  {
    success: [
      setPagination,
      actions.setCategories,
      ({ store }) => {
        store.set(state.categories.search.loading, false);
      }
    ],
    error: [notify(props.message.error, 'error')]
  }
];
export default {
  routes: {
    list: CategoryListRouter,
    new: CategoryNewRouter,
    update: CategoryFormViewUpdate
  },
  sequences: {
    saveCategory,
    handleClickOpenModalCategories,
    handleClickCloseModalCategories,
    deleteCategory,
    updateCategory,
    updatedFilterGeneralCategory
  }
};
