import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Upload,
  Icon,
  Tooltip,
  Divider
} from 'antd';
import normFile from '@utils/normFile';
import NumericInput from '@component/NumericInput';
import { createValidation } from '@utils';
import whitoutImage from '../../../assets/product_whitout_image.png';

const style = {
  miniFileCategory: {
    width: '1rem',
    height: '1rem',
    objectFit: 'cover',
    objectPosition: 'center'
  }
};
export default Form.create()(
  connect(
    {
      // states
      loadingLogin: state.user.loginButtonDisable,
      categories: state.categories.categories,
      detail: state.categories.detail,
      brandsAll: state.products.brands,
      // secuences
      submit: sequences.updateCategory
    },
    class CategoryFormViewUpdate extends Component {
      constructor(props) {
        super(props);
        this.state = {
          sort: props.detail.sort,
          father: props.detail.parent
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleChangeParent = this.handleChangeParent.bind(this);
        this.brandsDefault = this.brandsDefault.bind(this);
      }

      // eslint-disable-next-line react/sort-comp
      handleChangeParent(value) {
        this.value = value;
        this.setState({ father: value });
      }

      brandsDefault() {
        const { detail } = this.props;
        const data = [];
        detail.category_brand.forEach(element => {
          data.push(element.brand_id);
        });
        return data;
      }

      onChange(value) {
        this.setState({ sort: value });
      }

      handleSubmit(e) {
        e.preventDefault();
        const { form, submit, detail } = this.props;
        form.validateFields((err, values) => {
          if (!err) {
            submit({ id: detail.id, values });
          }
        });
      }

      getBrands(categoryBrand) {
        this.categoryBrand = categoryBrand;
        const bullet = categoryBrand.length > 0 ? ' * ' : '';
        let brands = '(';
        brands += bullet;
        categoryBrand.forEach(element => {
          brands += `${element.brand.name.toUpperCase()}`;
          brands += bullet;
        });
        brands += ')';
        return brands;
      }

      render() {
        const {
          form,
          loadingLogin,
          categories,
          detail,
          brandsAll
        } = this.props;
        const { sort, father } = this.state;
        const { getFieldDecorator } = form;
        const FormItem = Form.Item;
        return (
          <div>
            {Object.keys(detail).length === 0 ? (
              ''
            ) : (
              <Form onSubmit={this.handleSubmit} layout="horizontal">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24}>
                    <FormItem className="move-content-right">
                      <Tooltip title="Guardar categoria" placement="left">
                        <Button
                          icon="save"
                          type="primary"
                          htmlType="submit"
                          loading={loadingLogin}
                        />
                      </Tooltip>
                      <Tooltip title="Cancelar" placement="left">
                        <a
                          style={{ marginLeft: 10 }}
                          href="/categorias"
                          className="ant-btn ant-btn-primary ant-btn-icon-only"
                          rel="noopener noreferrer"
                        >
                          <Icon type="arrow-left" />
                        </a>
                      </Tooltip>
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={8} md={12}>
                    <FormItem label="Nombre">
                      {getFieldDecorator('name', {
                        rules: [
                          createValidation(
                            true,
                            'Por favor ingresa un nombre',
                            ''
                          )
                        ],
                        initialValue: detail.name
                      })(<Input placeholder="Nombre" />)}
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={8} md={12}>
                    <FormItem label="Orden">
                      {getFieldDecorator('sort', {
                        rules: [
                          {
                            required: false,
                            message: 'Por favor ingresa número de orden'
                          }
                        ],
                        initialValue: sort
                      })(
                        <NumericInput
                          maxLength={3}
                          placeholder="Orden"
                          value={sort}
                          onChange={this.onChange}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={8} md={12}>
                    <FormItem label="Status">
                      {getFieldDecorator('status', {
                        rules: [
                          createValidation(
                            true,
                            'Por favor seleccione una opción',
                            ''
                          )
                        ],
                        initialValue: detail.status
                      })(
                        <Select placeholder="Status" style={{ width: '100%' }}>
                          <Select.Option key="status-1" value="ACTIVE">
                            ACTIVO
                          </Select.Option>
                          <Select.Option key="status-2" value="INACTIVE">
                            INACTIVO
                          </Select.Option>
                        </Select>
                      )}
                    </FormItem>
                  </Col>

                  <Col xs={24} sm={8} md={12}>
                    <FormItem label="Padre">
                      {getFieldDecorator('parent', {
                        rules: [{ required: false, message: 'Es opcional' }],
                        initialValue:
                          detail.parent === null ? 'null' : detail.parent
                      })(
                        <Select
                          onChange={this.handleChangeParent}
                          placeholder="Padre"
                          style={{ width: '100%' }}
                        >
                          <Select.Option value="null">Ninguno</Select.Option>
                          {categories.map(value => (
                            <Select.Option
                              key={`categories-${value.id}`}
                              value={value.id}
                            >
                              {value.name}{' '}
                              {this.getBrands(value.category_brand)}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={8} md={12}>
                    <FormItem label="Descripcion corta para imagen">
                      {getFieldDecorator('alt', {
                        rules: [
                          createValidation(
                            false,
                            'Por favor ingresa una descripción corta para imagen',
                            ''
                          )
                        ],
                        initialValue: detail.alt
                      })(<Input placeholder="Descripcion corta para imagen" />)}
                    </FormItem>
                  </Col>
                  {father === null || father === 'null' ? (
                    <Col xs={24} sm={8} md={12}>
                      <FormItem label="Marcas">
                        {getFieldDecorator('brands', {
                          rules: [
                            {
                              required: true,
                              message: 'Por favor seleccione algunas marcas'
                            }
                          ],
                          initialValue: this.brandsDefault()
                        })(
                          <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Por favor seleccione algunas marcas"
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children[2].props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {brandsAll.map(value => (
                              <Select.Option
                                key={`marcas-${value.id}`}
                                value={value.id}
                              >
                                <img
                                  src={value.url_photo}
                                  alt={value.name}
                                  style={style.miniFileCategory}
                                />{' '}
                                <span>{value.name}</span>
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                  ) : null}

                  <Col xs={24} sm={24} md={24}>
                    <Row>
                      <Col xs={24} sm={5} md={5}>
                        <p
                          style={{
                            color: 'rgba(0, 0, 0, 0.85)',
                            fontSize: 14,
                            marginTop: 10
                          }}
                        >
                          Imagen actual cover
                        </p>
                        <img
                          src={
                            detail.banner_url !== null
                              ? detail.banner_url
                              : whitoutImage
                          }
                          alt={detail.alt}
                          style={{
                            width: '5rem',
                            height: '5rem',
                            objectFit: 'cover',
                            objectPosition: 'center'
                          }}
                        />
                      </Col>
                      <Col xs={24} sm={19} md={19}>
                        <FormItem label="Nueva imagen cover (260x260)">
                          {getFieldDecorator('banner_url', {
                            valuePropName: 'fileList',
                            getValueFromEvent: normFile,
                            rules: [
                              {
                                required: false,
                                message: 'Por favor seleccione una imagen'
                              }
                            ]
                          })(
                            <Upload
                              name="banner_url"
                              beforeUpload={() => false}
                              accept="image/jpeg, image/png"
                            >
                              <Button>
                                <Icon type="upload" /> Subir imagen
                              </Button>
                            </Upload>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <Row>
                      <Divider></Divider>
                      <Col xs={24} sm={5} md={5}>
                        <p
                          style={{
                            color: 'rgba(0, 0, 0, 0.85)',
                            fontSize: 14,
                            marginTop: 10
                          }}
                        >
                          Imagen actual banner
                        </p>
                        <img
                          src={
                            detail.banner_big_url !== null
                              ? detail.banner_big_url
                              : whitoutImage
                          }
                          alt={detail.alt}
                          style={{
                            width: '5rem',
                            height: '5rem',
                            objectFit: 'cover',
                            objectPosition: 'center'
                          }}
                        />
                      </Col>
                      <Col xs={24} sm={19} md={19}>
                        <FormItem label="Nueva imagen banner (1500x600)">
                          {getFieldDecorator('banner_big_url', {
                            valuePropName: 'fileList',
                            getValueFromEvent: normFile,
                            rules: [
                              {
                                required: false,
                                message: 'Por favor seleccione una imagen'
                              }
                            ]
                          })(
                            <Upload
                              name="banner_big_url"
                              beforeUpload={() => false}
                              accept="image/jpeg, image/png"
                            >
                              <Button>
                                <Icon type="upload" /> Subir imagen
                              </Button>
                            </Upload>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={5} md={5}>
                        <p
                          style={{
                            color: 'rgba(0, 0, 0, 0.85)',
                            fontSize: 14,
                            marginTop: 10
                          }}
                        >
                          Imagen actual móvil
                        </p>
                        <img
                          src={
                            detail.banner_small_url !== null
                              ? detail.banner_small_url
                              : whitoutImage
                          }
                          alt={detail.alt}
                          style={{
                            width: '5rem',
                            height: '5rem',
                            objectFit: 'cover',
                            objectPosition: 'center'
                          }}
                        />
                      </Col>
                      <Col xs={24} sm={19} md={19}>
                        <FormItem label="Imagen móvil">
                          {getFieldDecorator('banner_small_url', {
                            valuePropName: 'fileList',
                            getValueFromEvent: normFile,
                            rules: [
                              {
                                required: false,
                                message: 'Por favor seleccione una imagen'
                              }
                            ]
                          })(
                            <Upload
                              name="banner_small_url"
                              beforeUpload={() => false}
                              accept="image/jpeg, image/png"
                            >
                              <Button>
                                <Icon type="upload" /> Subir imagen
                              </Button>
                            </Upload>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            )}
          </div>
        );
      }
    }
  )
);
