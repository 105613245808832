import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { Input } from 'antd';

export default connect(
  {},
  class NumericInput extends Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.onChange = this.onChange.bind(this);
      this.onBlur = this.onBlur.bind(this);
    }

    onChange(e) {
      const { value } = e.target;
      const { onChange } = this.props;
      const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
      if (
        (!Number.isNaN(value) && reg.test(value)) ||
        value === '' ||
        value === '-'
      ) {
        onChange(value);
      }
    }

    onBlur() {
      const { value, onBlur, onChange } = this.props;
      if (value.charAt(value.length - 1) === '.' || value === '-') {
        onChange(value.slice(0, -1));
      }
      if (onBlur) {
        onBlur();
      }
    }

    render() {
      const { value, maxLength, placeholder } = this.props;
      return (
        <Input
          value={value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          placeholder={placeholder}
          maxLength={maxLength}
        />
      );
    }
  }
);
