import React from 'react';
import { connect } from '@cerebral/react';
import { state } from 'cerebral';
import { BackTop , Layout } from 'antd';
import classnames from 'classnames/bind';
import LeftNav from '@component/LeftNav';
import Header from '@component/Header';
import Breadcrumb from '@component/Breadcrumb';
import Pages from '@component/Pages';
import Login from '@component/Login';
import Splash from '@component/Splash';
import Skeleton from '@component/Skeleton';

import Helmet from 'react-helmet';
import styles from './style.css';

const { Content, Footer } = Layout;

const cx = classnames.bind(styles);

export default connect(
  {
    // State
    user: state.user,
    app: state.app,
    collapsed: state.collapsed
  },
  function App(props) {
    const { user, app } = props;
    const Page = Pages[app.currentPage];
    const titleGeneral = process.env.NAME_PROJECT;
    if (!user.isLogin && !app.showSplash) {
      return <Login />;
    }
    return (
      <Layout>
        <BackTop />
        <Helmet>
          <title>{`${app.titlePage} | ${titleGeneral}`}</title>
        </Helmet>
        {app.showSplash ? <Splash /> : null}
        <LeftNav />
        <Layout
          className={`${!props.collapsed ? cx('layout') : cx('layout-close')}`}
        >
          <Header />
          <Breadcrumb routes={[]} />
          <Content className={cx('content-primary')}>
            {app.isLoadingPage === false ? <Page /> : <Skeleton />}
          </Content>
          <Footer className={cx('footer')}>
            {process.env.NAME_PROJECT} © Todos los derechos reservados{' '}
            {new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
    );
  }
);
