/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Table, Tooltip, Icon, Button, Modal } from 'antd';
import Moment from 'react-moment';
import Pagination from '@component/Elements/Pagination';

const styles = {
  img: {
    width: 'auto',
    height: '2rem',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  principal: {
    color: 'green'
  }
};

export default connect(
  {
    // states
    list: state.events.list,
    stateDelete: state.events.delete,

    deleteEvent: sequences.deleteEvent,
    archiveEvent: sequences.archiveEvent,
    handleClickOpenDeleteEventModal: sequences.handleClickOpenDeleteEventModal,
    handleClickCloseDeleteEventModal: sequences.handleClickCloseDeleteEventModal,

  },
  class EventsListView extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
      const {
        list,
        stateDelete,

        deleteEvent,
        archiveEvent,
        handleClickOpenDeleteEventModal,
        handleClickCloseDeleteEventModal,
      } = this.props;
      const columns = [
        {
          title: 'TÍTULO',
          dataIndex: 'media_title.url',
          key: 'media_title.url',
          render: (text, element, index) => (
            <img src={text} alt="Imagen" style={styles.img} />
          )
        },
        {
          title: 'NOMBRE',
          dataIndex: 'title',
          key: 'title',
          render: (text, element, index) => (
            <a href={`/eventos/${element.slug}/${element.id}`}>{text}</a>
          )
        },
        {
          title: 'STATUS',
          dataIndex: 'status_label',
          key: 'status_label',
          render: text => <span>{text}</span>
        },
        {
          title: 'FECHA DE EVENTO',
          dataIndex: 'start_at',
          key: 'start_at',
          render: text => <Moment format="DD-MM-YYYY hh:mm A">{text}</Moment>
        },
        {
          title: 'ACCIONES',
          dataIndex: 'id',
          key: 'id',
          render: (text, element, index) => (
            <Tooltip title="Eliminar evento" placement="top">
              <Button
                icon="delete"
                onClick={() => {
                  handleClickOpenDeleteEventModal( element );
                }}
              />
            </Tooltip>
          )
        }
      ];
      return (
        <div>
          <Modal
            closable={false}
            visible={stateDelete.open}
            onCancel={() => {
              handleClickCloseDeleteEventModal();
            }}
            footer={[
              stateDelete.orders_count > 0 ? (
                <Button
                type="primary"
                onClick={() => {
                  archiveEvent();
                }}
                loading={stateDelete.loader} >
                Archivar
              </Button>
              ) : (<Button
                type="primary"
                onClick={() => {
                  deleteEvent();
                }}
                loading={stateDelete.loader} >
                Confirmar
              </Button>),
              <Button
                onClick={() => {
                  handleClickCloseDeleteEventModal();
                }}
              >
                Cancelar
              </Button>
            ]} >
            <h4>¿Desea eliminar el evento '{stateDelete.name}' ?</h4>
            <p>
              Favor de verificar que el evento seleccionado sea el que
              realmente desea eliminar.{' '}
              { stateDelete.orders_count > 0 ? (
                <span style={{ color: 'orange' }}>
                  Solo se pueden eliminar eventos que no tengan ventas realizadas. En su lugar será archivado para que ya no se encuentre disponible sin borrar su historico de ventas.
                </span>
              ) : null }
            </p>
          </Modal>

          <div className="move-content-right">
            <Tooltip title="Crear evento" placement="left">
              <a
                href="/eventos/nuevo"
                className="ant-btn ant-btn-primary ant-btn-icon-only"
                rel="noopener noreferrer"
              >
                <Icon type="plus" />
              </a>
            </Tooltip>
          </div>
          <Table
            bordered
            size="small"
            dataSource={list}
            columns={columns}
            pagination={false}
            scroll={{ x: 'max-content' }}
          />
          <Pagination list="events.list" apiUrl="admin/events" />
        </div>
      );
    }
  }
);
