import { state } from 'cerebral';

// getters
export function getEvents({http, path, get})
{
    return http
        .get('admin/events/',
        {},
        {
          per_page: get(state.app.pagination.perPage),
        })
        .then(result => path.success({result: result}) )
        .catch(error => error.json().then( message => path.error({message}) ) )
}

export function getEventsArchiveds({http, path, get})
{
    return http
        .get('admin/events-archiveds/',
        {},
        {
          per_page: get(state.app.pagination.perPage),
        })
        .then(result => path.success({result: result}) )
        .catch(error => error.json().then( message => path.error({message}) ) )
}

export function getEvent({http, path, props, get})
{
    var event_id = '';
    try {
        const { id } = props.params;
        event_id = id
    } catch (error) {
        const id = get(state.events.show.id);
        event_id = id
    }
    return http
        .get('admin/events/event/' + event_id )
        .then(result => path.success({result: result}) )
        .catch(error => error.json().then( message => path.error({message}) ) )
}

export function getEventTermsConditions({http, path, props})
{
    return http
        .get('admin/events/event-terms-conditions/')
        .then(result => path.success({ result: result}) )
        .catch(error => error.json().then( message => path.error({message}) ) )
}

//setters
export function setEvents({props, store})
{
    const {result} = props
    store.set(state.events.list, result.data)
}

export function setEventsArchiveds({props, store})
{
    const {result} = props
    store.set(state.events_archiveds.list, result.data)
}

export function setEvent({props, store})
{
    const {result} = props
    store.set(state.events.show, result)
}

export function setEventTermsConditions({props, store})
{
    const {result} = props
    store.set(state.events.terms.show, result)
}

// upload
export function uploadSlider({ http, path, props }) {
    const { event_id, files } = props;
    return Promise.all(
        files.map(file => {
            const data = new FormData();
            data.append('url_image', file);
            return http.postFile(`admin/events/event/save-media/` + event_id, data).catch(error => {
                return Promise.reject(error);
            });
        })
    ).then(res => path.success() ).catch(error =>
        path.error({ message: 'Algunos archivos no se pudieron guardar', error })
    );
}

export function uploadSliderLocation({ http, path, props }) {
    const { event_id, files } = props;
    return Promise.all(
        files.map(file => {
            const data = new FormData();
            data.append('url_image', file);
            return http.postFile(`admin/events/event/save-media-location/` + event_id, data).catch(error => {
                return Promise.reject(error);
            });
        })
    ).then(res => path.success() ).catch(error =>
        path.error({ message: 'Algunos archivos no se pudieron guardar', error })
    );
}