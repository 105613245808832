import { connect } from '@cerebral/react';
import { state } from 'cerebral';
import { Table } from 'antd';

import Moment from 'react-moment';
import Pagination from '@component/Elements/Pagination';
import React from 'react';

export default connect(
  {
    // states
    state_configuration: state.configurations,
    list: state.configurations.list,
  },
  function ConfigurationListView(props) {
    const {
        state_configuration,
        list,
    } = props;

    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name',
        render: (text, element, index) => (
          <a href={`/configuraciones/${element.id}`}>{text}</a>
        )
      },
      {
        title: 'Descripción',
        dataIndex: 'description',
        key: 'description'
      },
      {
        title: 'Valor',
        dataIndex: 'value',
        key: 'value'
      },
      {
        title: 'Actualizado por',
        dataIndex: 'updated_by.first_name',
        key: 'updated_by.first_name',
        render: (text, element, index) => (
          <a href={`/administradores/${element.updated_by.uuid}`}>
            <span>
              {text}
              <br/>
              {element.updated_by.email}
            </span>
          </a>
        )
      },
      {
        title: 'Ultima actualización',
        dataIndex: 'updated_at',
        key: 'updated_at',
        render: text => <Moment format="DD-MM-YYYY hh:mm A">{text}</Moment>
      }
    ];

    return (
      <div>
        <Table
          bordered
          rowKey="id"
          size="small"
          dataSource={list}
          columns={columns}
          pagination={false}
          scroll={{ x: 'max-content' }}
        />
        <Pagination list="list" apiUrl="admin/configurations" />
      </div>
    );
  }
);