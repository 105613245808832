/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { sequences, state } from 'cerebral';
import { connect } from '@cerebral/react';
import classNames from 'classnames/bind';
import Logo from '@assets/logo.png';
import { Form, Icon, Input, Button, Card, Modal } from 'antd';
import styles from './style.css';

const cx = classNames.bind(styles);
const createValidation = (isRequired, message, type) => ({
  required: isRequired,
  message,
  type
});
export default Form.create()(
  connect(
    {
      // states
      loadingLogin: state.user.loginButtonDisable,
      recoverPassword: state.recoverPassword,
      // sequences
      submit: sequences.signIn,
      openModalRecoverPassword: sequences.openModalRecoverPassword,
      closeModalRecoverPassword: sequences.closeModalRecoverPassword,
      setEmailRecoverPassword: sequences.setEmailRecoverPassword,
      sendEmailRecoverPassword: sequences.sendEmailRecoverPassword
    },
    class Login extends Component {
      constructor(props) {
        super(props);
        this.state = {};
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.sendEmailRecover = this.sendEmailRecover.bind(this);
      }

      handleSubmit(e) {
        e.preventDefault();
        const { form, submit } = this.props;
        form.validateFields((err, values) => {
          if (!err) {
            submit({ values });
          }
        });
      }

      changeEmail(e) {
        const { value } = e.target;
        const { setEmailRecoverPassword } = this.props;
        setEmailRecoverPassword({ email: value });
      }

      sendEmailRecover() {
        const { recoverPassword } = this.props;
        const { sendEmailRecoverPassword } = this.props;
        sendEmailRecoverPassword({ email: recoverPassword.email });
      }

      render() {
        const {
          form,
          loadingLogin,
          recoverPassword,
          openModalRecoverPassword,
          closeModalRecoverPassword
        } = this.props;
        const { getFieldDecorator } = form;
        const FormItem = Form.Item;
        return (
          <div className={cx('container-form')}>
            <Card bordered={false} className={cx('card-form')}>
              <img src={Logo} alt="logo" className={cx('logo')} />

              <h1 className={cx('title')}>Ingresar</h1>
              <Form onSubmit={this.handleSubmit}>
                <FormItem>
                  {getFieldDecorator('email', {
                    rules: [
                      createValidation(
                        true,
                        'Por favor ingresa tu email',
                        'email'
                      )
                    ]
                  })(
                    <Input prefix={<Icon type="user" />} placeholder="Email" />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [
                      createValidation(true, 'Por favor ingresa tu password')
                    ]
                  })(
                    <Input
                      prefix={<Icon type="lock" />}
                      type="password"
                      placeholder="Password"
                    />
                  )}
                </FormItem>
                <FormItem className="container-actions">
                  <Button
                    style={{ width: '100%' }}
                    type="primary"
                    htmlType="submit"
                    size="large"
                    loading={loadingLogin}
                  >
                    Ingresar
                  </Button>
                </FormItem>
              </Form>
              <p
                className={cx('recover-action')}
                onClick={() => {
                  openModalRecoverPassword();
                }}
              >
                ¿SE TE OLVIDÓ LA CONTRASEÑA?
              </p>
              <Modal
                title="Recuperar contraseña"
                visible={recoverPassword.open}
                onCancel={closeModalRecoverPassword}
                footer={[
                  <Button key="back" onClick={closeModalRecoverPassword}>
                    Cancelar
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    loading={recoverPassword.loader}
                    onClick={this.sendEmailRecover}
                  >
                    Enviar
                  </Button>
                ]}
              >
                <p>
                  ¿Olvidaste tu contraseña? Por favor ingresa tu correo,
                  recibiras un email con un link para actualizar la contraseña.
                </p>
                <Input
                  placeholder="Correo electrónico"
                  value={recoverPassword.email}
                  onChange={this.changeEmail}
                />
              </Modal>
            </Card>
          </div>
        );
      }
    }
  )
);
