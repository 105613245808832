import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Row, Col, Button, Icon, Table, Modal, Input } from 'antd';
import Pagination from '@component/Elements/Pagination';
import debounce from 'lodash/debounce';

export default connect(
  {
    // States
    extraBySearch: state.app.dealersSearch,
    dealers: state.app.dealers.data,
    isSearching: state.app.isSearching,
    // Sequences
    remove: sequences.removeDealers,
    searchDealer: sequences.searchDealer,
    debounceTime: state.debounceTime
  },
  class DealersList extends Component {
    constructor(props) {
      super(props);
      this.state = {
        modalForm: false
      };
      this.handleSearch = this.handleSearch.bind(this);

      this.handleSearchDebounce = debounce(
        this.handleSearchDebounce,
        props.debounceTime
      );
    }

    handleSearch(e) {
      this.e = e;
      const { value } = e.target;
      this.handleSearchDebounce('query_search', value);
    }

    handleSearchDebounce(key, value) {
      this.key = key;
      this.value = value;
      const { searchDealer } = this.props;
      searchDealer({ key, value });
    }

    deleteItem(id) {
      const { remove } = this.props;
      Modal.confirm({
        title: 'Confirm',
        content: 'Estas seguro de eliminar el distribuidor',
        okText: 'Aceptar',
        cancelText: 'Cancelar',
        onOk: () => remove({ id })
      });
    }

    render() {
      const { dealers, isSearching, extraBySearch } = this.props;
      const columns = [
        {
          title: 'id',
          dataIndex: 'id'
        },
        {
          title: 'Nombre',
          dataIndex: 'name'
        },
        {
          title: 'email',
          dataIndex: 'email'
        },
        {
          title: 'Dirección',
          dataIndex: 'address'
        },
        {
          title: 'Acciones',
          render: (value, row) => (
            <div>
              <Button type="link" onClick={() => this.deleteItem(row.id)}>
                <Icon type="delete" />
              </Button>

              <a
                className="ant-btn ant-btn-primary ant-btn-link"
                href={`/dealers/update/${row.id}`}
                style={{ marginRight: 5 }}
              >
                <Icon type="edit" />
              </a>
            </div>
          )
        }
      ];
      return (
        <div>
          <Row
            gutter={16}
            style={{ paddingBottom: 20 }}
            type="flex"
            justify="space-between"
          >
            <Col span={6}>
              <h3>Distribuidores</h3>
              <Input
                placeholder="Buscar..."
                onChange={this.handleSearch}
                allowClear
              />
            </Col>
            <Col span={4}>
              <a
                className="ant-btn ant-btn-primary"
                href="/dealers/new"
                style={{ marginRight: 5 }}
              >
                <Icon type="plus" />
                Agregar nuevo
              </a>
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={dealers}
            pagination={false}
            loading={isSearching}
          />
          <Pagination
            list="app.dealers.data"
            apiUrl="web/dealers"
            extraBySearch={extraBySearch}
          />
        </div>
      );
    }
  }
);
