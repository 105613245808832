import React, { Component } from 'react';
import {
  Row,
  Col,
  Tooltip,
  Button,
  Table,
  Modal,
  Input,
  InputNumber,
} from 'antd';

const { confirm } = Modal;

const style = {
  siderbarRight: {
    backgroundColor: '#f1f2f6',
    borderRadius: 5,
    padding: '2rem 1rem',
    height: '97%'
  },
  label: {
    marginBottom: 5
  }
};

export default class TicketsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: '',
      price: '',
      quantity: '',
      spots_rows: '',
      spots_sites_per_row: '',
      spots_sites_last_row: '',
      spots_sites: '',
      spots_rows_last_row: '',
      ticket_id: '',
      edit: false,
      create: false,
    };

    this.showConfirm = this.showConfirm.bind(this);
    this.setByUpdate = this.setByUpdate.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.onChangePrice = this.onChangePrice.bind(this);
    this.onChangeQuantity = this.onChangeQuantity.bind(this);

    this.onChangeSpotRow = this.onChangeSpotRow.bind(this);
    this.onChangeSpotSites = this.onChangeSpotSites.bind(this);
    
    this.createTickets = this.createTickets.bind(this);
    this.beforeCreateTicket = this.beforeCreateTicket.bind(this);
    this.setMoneyFormat = this.setMoneyFormat.bind(this);
  }

  setMoneyFormat(value) {
    const options1 = { style: 'currency', currency: 'MXN' };
    const moneyFormat = new Intl.NumberFormat('es-MX', options1);
    return moneyFormat.format(value);
  }

  // eslint-disable-next-line class-methods-use-this
  setByUpdate(data) {
    this.setState({
      type: data.type,
      price: data.price,
      quantity: data.quantity,
      spots_rows: data.spots_rows,
      spots_sites_per_row: data.spots_sites_per_row,
      spots_sites_last_row: data.spots_sites_last_row,
      ticket_id: data.id,
      edit: true,
      create: false,
    });
    const { modeUpdateTickets } = this.props;
    modeUpdateTickets({ value: true });
  }

  createTickets() {
    this.setState({
      create: true,
      edit: false,
      type: '',
      price: '',
      quantity: '',
      ticket_id: '',
      spots_rows: '',
      spots_sites: '',
      spots_rows_last_row: '',
    });
    const { modeCreateTickets } = this.props;
    modeCreateTickets({ value: true });
  }

    beforeCreateTicket(type, price, quantity, spots_sites ) {
    const { createTickets, modeCreateTickets } = this.props;
    createTickets({
      type,
      price,
      quantity,
      spots_sites,
    })
    this.setState({
      create: false,
      edit: false,
      type: '',
      price: '',
      quantity: '',
      spots_rows: '',
      spots_sites: '',
      spots_rows_last_row: '',
      ticket_id: '',
    });
    modeCreateTickets({ value: true });
  }

  showConfirm(data) {
    this.data = data;
    const {deleteTickets} = this.props;
    confirm({
      title: `¿Esta seguro que desear eliminar el boleto "${data.type}"?`,
      content: '',
      onOk() {
      const event_id = data.event_id
        const ticket_id = data.id
        deleteTickets({ event_id, ticket_id });
      },
      onCancel() {}
    });
  }

  onChangeType(element) {
    this.setState({
      type: element.target.value
    });
  }

  onChangePrice(value) {
    this.setState({
      price: value
    });
  }

  onChangeQuantity(value) {
    const {spots_sites, create} = this.state;
    this.setState({
      quantity: value
    });
    if ( typeof spots_sites && spots_sites != Infinity && create ) {
      this.onChangeSpotRow( Math.ceil(value / spots_sites) );
      this.setState({
        spots_rows_last_row: spots_sites - ((Math.ceil(value / spots_sites) * spots_sites) - value),
      });
    }
  }

  onChangeSpotRow(value) {
    this.setState({
      spots_rows: value
    });
  }

  onChangeSpotSites(value) {
    if ( typeof value && value != Infinity ) {
      const {quantity} = this.state;
      this.onChangeSpotRow( Math.ceil(quantity / value) );
      this.setState({
        spots_sites: value,
        spots_rows_last_row: value - ((Math.ceil(quantity / value) * value) - quantity),
      });
    }
  }

  render() {
    const {
      disabledTableTickets,
      updateTickets,
      loaderTickets,
      TicketsStates,
      show,
    } = this.props;

    const {
      edit,
      create,
      type,
      price,
      quantity,
      ticket_id,
      spots_rows,
      spots_sites,
      spots_rows_last_row,
      spots_sites_last_row,
      spots_sites_per_row,
    } = this.state;

    const columns = [
      {
        title: 'Acciones',
        dataIndex: 'id',
        key: 'id',
        width: 150,
        render: (text, element, index) => (
          <div>
            <Tooltip title="Eliminar boleto" placement="top">
              <Button
                type="primary"
                icon="delete"
                style={{ display: (show.archived != 1 ? 'inline-block' : 'none') }}
                onClick={() => {
                  this.showConfirm(element);
                }}
              />
            </Tooltip>
            <Tooltip title="Editar boleto" placement="top">
              <Button
                type="primary"
                icon="edit"
                style={{ marginLeft: 10, display: (show.archived != 1 ? 'inline-block' : 'none') }}
                onClick={() => {
                  this.setByUpdate(element);
                }}
              />
            </Tooltip>
          </div>
        )
      },
      {
        title: 'Tipo de boleto',
        dataIndex: 'type',
        key: 'ticket_type',
        width: 200,
      },
      {
        title: 'Precio',
        dataIndex: 'price',
        key: 'ticket_price',
        width: 200,
        render: (text) => (
          <span>{ this.setMoneyFormat(text) }</span>
        )
      },
      {
        title: 'Disponibles',
        dataIndex: 'quantity_pending',
        key: 'quantity_pending',
        width: 200,
        render: (text, element) => (
          <span>{ text + '/' + element.quantity }</span>
        )
      },
      {
        title: 'Asientos',
        dataIndex: 'quantity_spots',
        key: 'quantity_spots',
        width: 200,
        render: (text, element) => (
          <span>
            <p style={style.label}>{element.spots_sites_per_row == element.spots_sites_last_row ? element.spots_rows : element.spots_rows - 1} filas con {element.spots_sites_per_row} asientos</p>
            { element.spots_sites_per_row != element.spots_sites_last_row ? (
                <p style={style.label}>1 fila con {element.spots_sites_last_row} asientos</p>
              ) : null
            }
          </span>
        )
      },
    ];

    return (
      <Row gutter={16} type="flex">
        <Col xs={24} sm={17} md={17}>
          <Row gutter={16}>
            <Col xs={24} sm={8} md={12}>
              <Tooltip title="Generar nuevo tipo de boleto" placement="left">
                <Button
                  icon="plus"
                  type="primary"
                  htmlType="submit"
                  style={{ display: (show.archived != 1 ? 'block' : 'none') }}
                  loading={TicketsStates.loading}
                  onClick={() => {
                    this.createTickets();
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24}>
              <Table
                rowKey="tickets-types-table"
                style={{ marginTop: 20, marginBottom: 10 }}
                columns={columns}
                dataSource={show.tickets}
                bordered
                pagination={false}
                loading={disabledTableTickets}
                scroll={{ x: 'max-content' }}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={7} md={7}>
          <div style={style.siderbarRight}>
            <Row gutter={16}>
            { create !== true  ? (
              <Col xs={24} sm={24} md={24}>
                <p style={{ textAlign: 'center' }}>
                  <strong>EDICIÓN DE BOLETOS:</strong>
                </p>
              </Col>
            ) : (
              <Col xs={24} sm={24} md={24}>
                <p style={{ textAlign: 'center' }}>
                  <strong>CREACIÓN DE BOLETOS:</strong>
                </p>
              </Col>
            ) }

              { edit === true || create === true ? (
                <div>

                  <Col xs={24} sm={24} md={24}>
                    <p style={style.label}>Tipo de boleto:</p>
                    <Input
                      placeholder="Tipo de boleto"
                      style={{ width: '100%', marginBottom: 10 }}
                      value={type}
                      onChange={this.onChangeType}
                    />
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <p style={style.label}>Precio:</p>
                    <InputNumber
                      placeholder="Precio"
                      step={0.1}
                      precision={6}
                      style={{ width: '100%', marginBottom: 10 }}
                      value={price}
                      onChange={this.onChangePrice}
                    />
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <p style={style.label}>Cantidad de boletos total:</p>
                    <InputNumber
                      placeholder="Cantidad de boletos total"
                      step={0.1}
                      precision={0}
                      style={{ width: '100%', marginBottom: 10 }}
                      value={quantity}
                      onChange={this.onChangeQuantity}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <b style={{display: 'block', margin: '15px 0'}}>Asientos</b>
                  </Col>
                  { create === true ?
                    (
                      <div>
                        <Col xs={24} sm={24} md={24}>
                          <p style={style.label}>Asientos por fila:</p>
                          <InputNumber
                            placeholder="Asientos por fila"
                            step={0.1}
                            precision={0}
                            style={{ width: '100%', marginBottom: 10 }}
                            value={spots_sites}
                            onChange={this.onChangeSpotSites}
                          />
                        </Col>
                        { typeof spots_rows && spots_rows > 0 && spots_rows != Infinity ? (
                          <Col xs={24} sm={24} md={24}>
                            { spots_rows_last_row != spots_sites ? 
                              (
                                <div>
                                  { spots_rows - 1 > 0 ? 
                                    (
                                      <p style={style.label}>{spots_rows-1} filas de {spots_sites} asientos</p>
                                    ) : null
                                  }
                                </div>
                              ): (<p style={style.label}>{spots_rows} filas de {spots_sites} asientos</p>)}
                            { spots_rows_last_row != spots_sites ? (<p style={style.label}>Una fila {spots_rows-1 > 0 ? 'extra' : ''} de {spots_rows_last_row} asientos</p>) : null }
                          </Col>
                        ) : null }
                      </div>
                    ) : (
                      <Col xs={24} sm={24} md={24}>
                        <p style={{color: '#deb300', marginBottom: 5}}>Para agregar más asientos, se deberán de crear desde la pestaña "Asientos"</p>
                        <p style={style.label}>Filas: {spots_rows}</p>
                        <p style={style.label}>Asientos por fila: {spots_sites_per_row}</p>
                        {
                          spots_sites_last_row && spots_sites_per_row > spots_sites_last_row ? (<p style={style.label}>Última fila con {spots_sites_last_row} asientos</p>) : null
                        }
                      </Col>
                    )}
                  <Col xs={24} sm={24} md={24} style={{ marginBottom: 10 }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <Tooltip title="Guardar cambios" placement="top">
                      { create !== true  ? (
                        <Button
                          icon="save"
                          type="primary"
                          htmlType="submit"
                          loading={loaderTickets}
                          onClick={() => {
                            const event_id = show.id;
                            updateTickets({
                              type,
                              price,
                              quantity,
                              event_id,
                              ticket_id,
                            });
                            const { modeUpdateTickets } = this.props;
                            modeUpdateTickets({ value: false });
                          }}
                        />
                      ) : (
                        <Button
                          icon="save"
                          type="primary"
                          htmlType="submit"
                          loading={loaderTickets}
                          onClick={() => {
                            const event_id = show.id;
                            this.beforeCreateTicket(
                              type,
                              price,
                              quantity,
                              spots_sites,
                              event_id);
                            const { modeCreateTickets } = this.props;
                            modeCreateTickets({ value: false });
                          }}
                        />
                      ) }
                      </Tooltip>
                      <Tooltip title="Cancelar" placement="top">
                        <Button
                          icon="close-circle"
                          type="primary"
                          htmlType="submit"
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            this.setState({
                              title: '',
                              description: '',
                              ticket_id: null,
                              edit: false,
                              create: false
                            });
                            const { modeUpdateTickets, modeCreateTickets } = this.props;
                            modeUpdateTickets({ value: false });
                            modeCreateTickets({value: false});
                          }}
                        />
                      </Tooltip>
                    </div>
                  </Col>
                </div>
              ) : (
                <Col xs={24} sm={24} md={24}>
                  <b>Total de boletos:</b> {show.tickets_count}
                  <br></br>
                  <b>Boletos disponibles:</b> { Object.values(show.tickets).reduce( (partial_sum, ticket) => partial_sum + ticket.quantity_pending, 0) }
                </Col>
              )}
            </Row>
          </div>
        </Col>
      </Row>
    );
  }
}
