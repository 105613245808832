import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { sequences, state } from 'cerebral';
import { Form, Input, Button, Row, Col, Tooltip, Icon, Upload } from 'antd';
// import ColorPicker from 'rc-color-picker';
import normFile from '@utils/normFile';
import { createValidation } from '@utils';
import 'rc-color-picker/assets/index.css';

export default Form.create()(
  connect(
    {
      // states
      loadingLogin: state.user.loginButtonDisable,
      optionId: state.attributes.father,
      // secuences
      submit: sequences.saveAttribute
    },
    class AttributesFormView extends Component {
      constructor(props) {
        super(props);
        this.state = {
          color: '#000000'
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeHandlerColor = this.changeHandlerColor.bind(this);
      }

      handleSubmit(e) {
        e.preventDefault();
        const { form, submit } = this.props;
        // const { color } = this.state;
        form.validateFields((err, values) => {
          if (!err) {
            // values.hexadecimal = color;
            submit({ values });
          }
        });
      }

      changeHandlerColor(colors) {
        this.colors = colors;
        this.setState({ color: colors.color });
      }

      render() {
        const { form, loadingLogin, optionId } = this.props;
        // const { color } = this.state;
        const { getFieldDecorator } = form;
        const FormItem = Form.Item;
        return (
          <div>
            <Form onSubmit={this.handleSubmit} layout="horizontal">
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24}>
                  <FormItem className="move-content-right">
                    <Tooltip title="Guardar atributo" placement="left">
                      <Button
                        icon="save"
                        type="primary"
                        htmlType="submit"
                        loading={loadingLogin}
                      />
                    </Tooltip>
                    <Tooltip title="Cancelar" placement="left">
                      <a
                        style={{ marginLeft: 10 }}
                        href={`/opciones/${optionId}/atributos`}
                        className="ant-btn ant-btn-primary ant-btn-icon-only"
                        rel="noopener noreferrer"
                      >
                        <Icon type="arrow-left" />
                      </a>
                    </Tooltip>
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <Row gutter={16}>
                    <Col xs={24} sm={8} md={8}>
                      <FormItem label="Nombre">
                        {getFieldDecorator('name', {
                          rules: [
                            createValidation(
                              true,
                              'Por favor ingresa un nombre',
                              ''
                            )
                          ]
                        })(<Input placeholder="Nombre" />)}
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
                {/*  <Col xs={24} sm={24} md={24}>
                  <Row gutter={16}>
                    <Col xs={24} sm={8} md={8}>
                      <p style={{ color: 'rgba(0, 0, 0, 0.85)', marginTop: 8 }}>
                        Color:
                      </p>
                      <ColorPicker
                        enableAlpha={false}
                        animation="slide-up"
                        color={color}
                        onChange={this.changeHandlerColor}
                      />
                    </Col>
                  </Row>
                </Col> */}
                <Col xs={24} sm={24} md={24}>
                  <Row gutter={16}>
                    <Col xs={24} sm={8} md={8}>
                      <FormItem label="Imagen (50x50)">
                        {getFieldDecorator('image', {
                          valuePropName: 'fileList',
                          getValueFromEvent: normFile,
                          rules: [
                            {
                              required: false,
                              message: 'Por favor seleccione una imagen'
                            }
                          ]
                        })(
                          <Upload
                            name="image"
                            beforeUpload={() => false}
                            accept="image/jpeg, image/png"
                          >
                            <Button>
                              <Icon type="upload" /> Subir imagen
                            </Button>
                          </Upload>
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        );
      }
    }
  )
);
