import ReactQuill from 'react-quill';
import React, { Component } from 'react';
import productWhitoutImage from '../../../assets/product_whitout_image.png';
import Pagination from '@component/Elements/Pagination';
import Moment from 'react-moment';
import debounce from 'lodash/debounce';
import { state, sequences } from 'cerebral';
import { formatNumberAmount } from '@utils';
import { connect } from '@cerebral/react';

import {
  Tooltip,
  Table,
  Select,
  Row,
  Modal,
  InputNumber,
  Input,
  Icon,
  Form,
  Col,
  Checkbox,
  Button,
} from 'antd';

const style = {
  imageProduct: {
    width: '35px',
    height: '35px',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  syncCodesButton: {
    display: 'inline-block',
    float: 'right',
    backgroundColor: '#303A4A',
    borderColor: '#303A4A'
  },
  UpdateMultipleButton: {
    display: 'inline-block',
    float: 'right',
    marginRight: '1em'
  },
  CheckAllButton: {
    display: 'inline-block',
    float: 'left',
    marginBottom: '1em',
  },
  CreateButton: {
    display: 'inline-block',
    float: 'right',
    marginLeft: '1em',
    zIndex: 9999,
    marginBottom: '1em',
    marginTop: '1em'
  },
  miniFileCategory: {
    width: '1rem',
    height: '1rem',
    objectFit: 'cover',
    objectPosition: 'center'
  }
};

export default connect(
  {
    // states
    syncCodes: state.syncCodes,
    stateDelete: state.products.delete,
    stateSaveMultiple: state.products.save_multiples,
    loading: state.products.search.loading,
    list: state.products.list,
    brandsAll: state.products.brands,
    categoriesAll: state.products.categories,
    extraBySearch: state.products.search,
    debounceTime: state.debounceTime,
    brands: state.products.search.brands,

    // states for multiple edition of products
    is_multiple_edition:          state.products.update_multiple_products_modal_button_fields,
    is_multiple_edition_fields:   state.products.update_multiple_products_modal_button,
    update_multiple_list:         state.products.update_multiple_list,
    update_multiple_fields:       state.products.update_multiple_fields,
    edit_rows_table_read:         state.products.edit_rows_table_read,
    edit_rows_table_read_preview: state.products.edit_rows_table_read_preview,
    edit_row:                     state.products.edit_row,
    
    // sequences
    updatedSearchSortProduct: sequences.updatedSearchSortProduct,
    updatedSearchReferenceProduct: sequences.updatedSearchReferenceProduct,
    updatedSearchNameProduct: sequences.updatedSearchNameProduct,
    updatedSearchCategoriesIds: sequences.updatedSearchCategoriesIds,
    updatedSearchBrand: sequences.updatedSearchBrand,
    syncCodesProducts: sequences.syncCodesProducts,
    renderEditableTable: sequences.updateMultipleTableProduct,
    deleteProduct: sequences.deleteProduct,

    // modal sequences
    handleClickOpenModalMultipleUpdate: sequences.handleClickOpenModalMultipleUpdate,
    handleClickOpenModalAttributes: sequences.handleClickOpenModalProductAttributes,
    handleClickOpenModal: sequences.handleClickOpenModalProduct,
    handleClickCloseModalAttributes: sequences.handleClickCloseModalProductAttributes,
    handleClickCloseModal: sequences.handleClickCloseModalProduct,
    handleClickCloseModalSaveMultipleConfirm: sequences.handleClickCloseModalSaveMultipleConfirm,
    handleClickCloseModalMultipleUpdate: sequences.handleClickCloseModalMultipleUpdate,
    handleClickOpenModalSaveMultipleConfirm: sequences.handleClickOpenModalSaveMultipleConfirm,

    // sequences for multiple products edition
    checkMultipleUpdateProduct: sequences.updateMultipleProductIds,
    checkedAll: sequences.uncheckAll,
    
    checkMultipleFieldsProduct: sequences.updateMultipleProductFields,
    updateReadNewValue: sequences.updateReadNewValue,
    selectMultipleBrandValue: sequences.selectMultipleValue,
    selectMultipleCategoriesValue: sequences.selectMultipleValue,

    submitMultiplesProducts: sequences.saveMultipleProductsUpdate,
  },
  class ProductListView extends Component {
    constructor(props) {

      super(props);
      this.state = {}

      // bindings
      /**
       * example: class function_name = sequense function_name
       */
      this.handleSort   = this.handleSort.bind(this);
      this.handleSearchByReferenceDebounce = debounce( this.handleSearchByReferenceDebounce, props.debounceTime );
      this.handleSearchByCategories = debounce( this.handleSearchByCategories, props.debounceTime );
      this.handleSearchByReference    = this.handleSearchByReference.bind(this);
      this.handleSearchByNameDebounce = debounce( this.handleSearchByNameDebounce, props.debounceTime );
      this.handleSearchByName         = this.handleSearchByName.bind(this);
      this.handleBrands = this.handleBrands.bind(this);
      this.handleCategories = this.handleCategories.bind(this);

      this.handleClickCloseModalMultipleUpdate = this.handleClickCloseModalMultipleUpdate.bind(this);
      this.checkMultipleUpdateProduct = this.checkMultipleUpdateProduct.bind(this);
      this.checkedAll = this.checkedAll.bind(this);
      this.checkMultipleFieldsProduct = this.checkMultipleFieldsProduct.bind(this);
      this.updateReadNewValue = this.updateReadNewValue.bind(this);
      this.selectMultipleBrandValue = this.selectMultipleBrandValue.bind(this);
      this.selectMultipleCategoriesValue = this.selectMultipleCategoriesValue.bind(this);
      
      this.submitMultiplesProducts = this.submitMultiplesProducts.bind(this);
    }

    // class functions
    handleSearchByName(e) {
      this.e = e;
      const { value } = e.target;
      this.handleSearchByNameDebounce(value);
    }

    handleSearchByReference(e) {
      this.e = e;
      const { value } = e.target;
      this.handleSearchByReferenceDebounce(value);
    }

    handleSort(value) {
      this.value = value;
      const { updatedSearchSortProduct } = this.props;
      updatedSearchSortProduct({ value });
    }

    handleBrands(value) {
      this.value = value;
      const { updatedSearchBrand } = this.props;
      updatedSearchBrand({ value });
    }

    handleCategories(value) {
      this.handleSearchByCategories({ value });
    }

    handleSearchByNameDebounce(value) {
      this.value = value;
      const { updatedSearchNameProduct } = this.props;
      updatedSearchNameProduct({ value });
    }

    handleSearchByReferenceDebounce(value) {
      this.value = value;
      const { updatedSearchReferenceProduct } = this.props;
      updatedSearchReferenceProduct({ value });
    }

    handleSearchByCategories(value)
     {
      const { updatedSearchCategoriesIds } = this.props
      updatedSearchCategoriesIds({value: value})
     }

    calculateQuantityProduct(productCombination) {
      this.productCombination = productCombination;
      if (productCombination.lenght === 0) {
        return 0;
      }
      let quantity = 0;
      productCombination.forEach(element => {
        quantity += element.quantity;
      });

      return quantity;
    }

    /**
     * checkMultipleUpdateProduct
     * check individual product and add to list
     * @param {*} event 
     */
    checkMultipleUpdateProduct(event)
    {
      const isChecked = event.target.checked
      const { checkMultipleUpdateProduct } = this.props
      let productId = event.target.value

      checkMultipleUpdateProduct({ status: isChecked, value: productId });
    }

    isSelected(id)
    {
      const update_multiple_list = this.props.update_multiple_list
      var item = update_multiple_list.filter(function(item){return item.id == id; });
      return !!item.length > 0;
    }

      /**
     * checkedAll
     * check all current products
     * @param {*} event 
     */
    checkedAll(event)
    {
      const { checkedAll } = this.props
      const checkboxes = document.getElementsByName('product-select');
      for(var i=0, n=checkboxes.length;i<n;i++) {
        if ( checkboxes[i].checked != event.target.checked ) {
          checkboxes[i].click();
        }
      }
      if ( !event.target.checked ) {
        checkedAll();
      }
    }

    /**
     * checkMultipleFieldsProduct
     * Check fields before edit products
     * @param {*} event 
     */
    checkMultipleFieldsProduct(event)
    {
      const isChecked = event.target.checked
      const { checkMultipleFieldsProduct } = this.props
      let productId = event.target.value

      checkMultipleFieldsProduct({ status: isChecked, value: productId });
    }

    getBrands(categoryBrand) {
      const bullet = categoryBrand.length > 0 ? ' * ' : '';
      let brands = '(';
      brands += bullet;
      categoryBrand.forEach(element => {
        brands += `${element.brand.name.toUpperCase()}`;
        brands += bullet;
      });
      brands += ')';
      return brands;
    }

    /**
     * renderEditableTable
     * Rederize modal with tables editabled for update multiple products
     */
    renderEditableTable()
    {
      let updateReadNewValue  = this.updateReadNewValue
      let selectMultipleBrandValue  = this.selectMultipleBrandValue
      let selectMultipleCategoriesValue  = this.selectMultipleCategoriesValue
       
      
      const modules = {
        toolbar: [
          [{ header: '1' }, { header: '2' }, { font: [] }],
          [{ size: [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' }
          ],
          ['link', 'image', 'video'],
          ['clean']
        ],
        clipboard: {
          matchVisual: false
        }
      };
      const formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'video'
      ];
      
      if ( this.props.update_multiple_fields.indexOf('name') > -1 ) {
        this.props.edit_rows_table_read.push({
          title: 'NOMBRE',
          dataIndex: 'name',
          key: 'name',
          render(text, element, index) {
            if ( index == 0 ) {
              return (
                <Input
                  placeholder="Nombre"
                  style={{width: '200px'}}
                  onChange={updateReadNewValue}
                  name='name'/>
              );
            } else {
              return (
                <div>
                  <span>{text}</span>
                  { element.new_name ? (
                    <span style={{'color': '#EB0089'}}> {'→ ' + element.new_name}</span>
                  ) : null}
                </div>
              );
            }
          }
        })
      }

      if ( this.props.update_multiple_fields.indexOf('price') > -1 ) {
        this.props.edit_rows_table_read.push({
          title: 'Precio + IVA',
          dataIndex: 'price_tax',
          key: 'price_tax',
          render(text, element, index) {
            if ( index == 0 ) {
              return (
                <InputNumber
                  placeholder="Precio + IVA"
                  step={0.1}
                  precision={6}
                  style={{ width: '200px', marginRight: 5 }}
                  onKeyUp={updateReadNewValue}
                  name='price'/>
              );
            } else {
              return (
                <div>
                  <span>${formatNumberAmount(text, '.', '.', ',')}</span>
                  { element.new_price ? (
                    <span style={{'color': '#EB0089'}}> → ${formatNumberAmount(element.new_price, '.', '.', ',')}</span>
                  ) : null}
                </div>
              );
            }
          }
        });

      }

      if ( this.props.update_multiple_fields.indexOf('show') > -1 ) {
        this.props.edit_rows_table_read.push({
          title: 'VISIBLE',
          dataIndex: 'show',
          key: 'show',
          render(text, element, index) {
            if ( index == 0 ) {
              return (
                <div>
                  <Select
                    placeholder="Por favor seleccione una opción"
                    style={{width: '200px'}}
                    name='show'>
                    <Select.Option key="show-1" value={1} onClick={updateReadNewValue}>
                      <span data='show'>SI</span>
                    </Select.Option>
                    <Select.Option key="show-2" value={0} onClick={updateReadNewValue}>
                      <span data='show'>NO</span>
                    </Select.Option>
                  </Select>
                </div>
              );
            } else {
              return (
                <div>
                  <span>{text === 1 ? 'SI' : 'NO'}</span>
                  { element.new_show ? (
                    <span style={{'color': '#EB0089'}}> → {element.new_show}</span>
                  ) : null}
                </div>
              );
            }
          }
        });
      }

      if ( this.props.update_multiple_fields.indexOf('description') > -1 ) {
        this.props.edit_rows_table_read.push({
          title: 'Descripción',
          dataIndex: 'description',
          key: 'description',
          render(text, element, index) {
            if ( index == 0 ) {
              return (
                <div>
                  <input type="hidden" name="description" />
                  <ReactQuill
                    style={{width: '100%', maxWidth:  '600px'}}
                    placeholder="..."
                    modules={modules}
                    formats={formats}
                    onChange={(e) => updateReadNewValue(e, 'description')} 
                    />
                </div>
              );
            } else {
              // Clean html tags from text to print in front
              let tmp = document.createElement("DIV")
              tmp.innerHTML = text

              let new_tmp = document.createElement("DIV")
              new_tmp.innerHTML = element.new_description

              return (
                <div>
                  <span style={{
                    whiteSpace: 'break-spaces',
                    width: '300px',
                    wordBreak: 'break-word',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'justify',
                    display: '-webkit-inline-box',
                    lineClamp: '2',
                    'WebkitBoxOrient': 'vertical',
                    'WebkitLineClamp': '4',
                  }}>{tmp.textContent}</span>
                  { element.new_description ? (
                    <span style={{
                      whiteSpace: 'break-spaces',
                      width: '300px',
                      wordBreak: 'break-word',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      textAlign: 'justify',
                      display: '-webkit-inline-box',
                      lineClamp: '2',
                      'WebkitBoxOrient': 'vertical',
                      'WebkitLineClamp': '4',
                      'color': '#EB0089',
                      marginLeft: '2em'
                  }}>{new_tmp.textContent}</span>
                  ) : null}
                </div>
              );
            }
          }
        });

      }

      if ( this.props.update_multiple_fields.indexOf('instructions') > -1 ) {
        this.props.edit_rows_table_read.push({
          title: 'Instrucciones',
          dataIndex: 'instructions',
          key: 'instructions',
          render(text, element, index) {
            if ( index == 0 ) {
              return (
                <div>
                  <input type="hidden" name="instructions" />
                  <ReactQuill
                    style={{width: '100%', maxWidth:  '600px'}}
                    placeholder="..."
                    modules={modules}
                    formats={formats}
                    onChange={(e) => updateReadNewValue(e, 'instructions')} />
                </div>
              );
            } else {
              // Clean html tags from text to print in front
              let tmp = document.createElement("DIV")
              tmp.innerHTML = text

              let new_tmp = document.createElement("DIV")
              new_tmp.innerHTML = element.new_instructions

              return (
                <div>
                  <span style={{
                    whiteSpace: 'break-spaces',
                    width: '300px',
                    width: '300px',
                    wordBreak: 'break-word',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'justify',
                    display: '-webkit-inline-box',
                    lineClamp: '2',
                    'WebkitBoxOrient': 'vertical',
                    'WebkitLineClamp': '4',
                  }}>{tmp.textContent}</span>
                  { element.new_instructions ? (
                    <span style={{
                      whiteSpace: 'break-spaces',
                      width: '300px',
                      wordBreak: 'break-word',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      textAlign: 'justify',
                      display: '-webkit-inline-box',
                      lineClamp: '2',
                      'WebkitBoxOrient': 'vertical',
                      'WebkitLineClamp': '4',
                      'color': '#EB0089'
                    }}>{new_tmp.textContent}</span>
                  ) : null}
                </div>
              );
            }
          }
        });

      }

      if ( this.props.update_multiple_fields.indexOf('brand') > -1 ) {
        let brandsAll = this.props.brandsAll;
        this.props.edit_rows_table_read.push({
          title: 'Marca',
          dataIndex: 'product_brand',
          key: 'product_brand',
          render(text, element, index) {
            if ( index == 0 ) {
              return (
                <div style={{maxWidth:'280px', minWidth: '200px'}}>
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Por favor seleccione algunas marcas"
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    onChange={selectMultipleBrandValue}
                    filterOption={(input, option) =>
                      option.props.children[2].props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }>
                      {brandsAll.map(value => (
                          <Select.Option key={`marcas-${value.id}`} value={value.id}>
                            <img
                              src={value.url_photo}
                              alt={value.name}
                              style={style.miniFileCategory}
                            />{' '}
                            <span data='brands'>{value.name}</span>
                          </Select.Option>
                        ))
                      }
                    </Select>
                </div>
              );
            } else {
              return (
                <div>
                  <span>{ text.map(function(product_brand){ return product_brand.brand.name; }).join(', ') }</span>
                  { element.new_brands && element.new_brands.length > 0 ? (
                    <span style={{'color': '#EB0089', maxWidth:'400px', display:'block', wordBreak: 'break-word'}}> {'→ ' + element.new_brands.map(function(brand){ return brand; }).join(', ')}</span>
                  ) : null}
                </div>
              );
            }
          }
        });

      }

      if ( this.props.update_multiple_fields.indexOf('categories') > -1 ) {
        let categoriesAll = this.props.categoriesAll;
        const getBrands = this.getBrands
        
        this.props.edit_rows_table_read.push({
          title: 'Categorías',
          dataIndex: 'product_category',
          key: 'product_category',
          render(text, element, index) {
            if ( index == 0 ) {
              return (
                <div style={{maxWidth:'560px', minWidth: '300px'}}>
                  <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Por favor seleccione algunas categorías"
                  allowClear
                  showSearch
                    onChange={selectMultipleCategoriesValue}
                    optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children[2].props.children[1]
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  } >
                    {categoriesAll.map(value => (
                      <Select.Option
                        key={`categories-${value.id}`}
                        value={value.id}>
                        <img
                          src={
                            value.banner_url === null
                              ? productWhitoutImage
                              : value.banner_url
                          }
                          alt={value.alt}
                          style={style.miniFileCategory}
                        />{' '}
                        <span data='categories'>
                          {value.parent_complete !== null
                            ? `${value.parent_complete.name}->`
                            : ''}
                          {value.name}
                          {value.parent_complete === null
                            ? getBrands(value.category_brand)
                            : ''}
                        </span>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              );
            } else {
              return (
                <div>
                  <span>{text.map(function(product_categories){ return product_categories.category.name; }).join(', ') }</span>
                  { element.new_categories && element.new_categories.length > 0 ? (
                    <span style={{'color': '#EB0089', maxWidth:'400px', display:'block', wordBreak: 'break-word' }}> {'→ ' + element.new_categories.map(function(category){ return category; }).join(', ') }</span>
                  ) : null}
                </div>
              );
            }
          }
        });
      }

      const { handleClickOpenModalMultipleUpdate } = this.props
      handleClickOpenModalMultipleUpdate();
    }

    /**
     * handleClickCloseModalMultipleUpdate
     * 
     * close multiple editable and reset states 
     */
    handleClickCloseModalMultipleUpdate() {
      const {handleClickCloseModalMultipleUpdate} = this.props
      handleClickCloseModalMultipleUpdate();
    }

    /**
     * updateReadNewValue
     * 
     * on change values in edit multiple, update only read and show in front
     * 
     * @param {*} event 
     */
     updateReadNewValue(event, column = '')
     {
       const { updateReadNewValue } = this.props
       var value = ''
       var label = ''
 
       // textareas
       if ( column != '' ) {
         value = event
         column = column
 
       // selects
       } else if (!event.target && event.key ) {
         value   = event.key
         if ( event.domEvent.target.nodeName == 'LI' ) {
           column = event.domEvent.target.getElementsByTagName('span')[0].attributes['data'].value
           label = event.domEvent.target.getElementsByTagName('span')[0].textContent
         }
         if ( event.domEvent.target.nodeName == 'SPAN' ) {
           column = event.domEvent.target.attributes['data'].value
           label = event.domEvent.target.textContent
         }
 
       // inputs
       } else {
         value   = event.target.value
         column  = event.target.name
       }
       updateReadNewValue({newValues: {column: column, value: value, label: label} })
     }

     selectMultipleBrandValue(value, react_item)
     {
       const { selectMultipleBrandValue } = this.props
       var labels   = react_item.map(function(item) {return item.props.children[2].props.children; });
       selectMultipleBrandValue({newValues: {column: 'brands', value: value, labels: labels} })
     }

     selectMultipleCategoriesValue(value, react_item)
     {
       const { selectMultipleCategoriesValue } = this.props
       var labels   = react_item.map(function(item) {
         return Array.isArray(item.props.children[2].props.children) ? item.props.children[2].props.children.join('') : item.props.children[2].props.children;
        });
       selectMultipleCategoriesValue({newValues: {column: 'categories', value: value, labels: labels} })
     }

    submitMultiplesProducts()
    {
      const {submitMultiplesProducts} = this.props

      submitMultiplesProducts()
    }

    render() {

      // add like const general states for this class
      const {
        is_multiple_edition,
        update_multiple_list,
        syncCodesProducts,
        syncCodes,
        stateDelete,
        is_multiple_edition_fields,
        loading,
        list,
        categoriesAll,
        brandsAll,
        stateSaveMultiple,
        handleClickOpenModalAttributes,
        handleClickOpenModal,
        handleClickCloseModalAttributes,
        handleClickCloseModal,
        handleClickOpenModalSaveMultipleConfirm,
        handleClickCloseModalSaveMultipleConfirm,
        extraBySearch,
        deleteProduct,
        brands,
        edit_row,
        getBrands,
      } = this.props;

      // class const

      /**
       * Product table rows
       */
      const columns = [
        {
          title: 'Edición múltiple',
          dataIndex: 'multiple-update',
          key: 'multiple-update-',
          render: (text, element, index) => (
            <Checkbox name="product-select" value={list[index]} onChange={this.checkMultipleUpdateProduct} checked={ this.isSelected(list[index].id) }></Checkbox>
          )
        },
        {
          title: 'Imagen',
          dataIndex: 'image_default_url',
          key: 'image_default_url',
          render: (text, element, index) => (
            <div>
              {text !== null ? (
                <img
                  src={text}
                  alt="Imagen producto"
                  style={style.imageProduct}
                />
              ) : (
                <img
                  src={productWhitoutImage}
                  alt="Imagen producto"
                  style={style.imageProduct}
                />
              )}
            </div>
          )
        },
        {
          title: 'NOMBRE',
          dataIndex: 'name',
          key: 'name',
          render: (text, element, index) => (
            <a href={`/productos/${list[index].uuid}`}>{text}</a>
          )
        },
        {
          title: 'REFERENCIA',
          dataIndex: 'reference',
          key: 'reference',
          render: (text, element, index) => (
            <span>
              {list[index].type === 'REGULAR' ? (
                <span>{text}</span>
              ) : (
                <span>N/A</span>
              )}
            </span>
          )
        },
        {
          title: 'PRECIO',
          dataIndex: 'price',
          key: 'price',
          render: text => (
            <span>${formatNumberAmount(text, '.', '.', ',')}</span>
          )
        },
        {
          title: 'PRECIO +  IVA',
          dataIndex: 'price_tax',
          key: 'price_tax',
          render: text => (
            <span>${formatNumberAmount(text, '.', '.', ',')}</span>
          )
        },
        {
          title: 'CANTIDAD',
          dataIndex: 'quantity',
          key: 'quantity',
          render: (text, element, index) => (
            <span>
              {text === null
                ? this.calculateQuantityProduct(list[index].product_combination)
                : text}
            </span>
          )
        },
        {
          title: 'VISIBLE',
          dataIndex: 'show',
          key: 'show',
          render: text => <span>{text === 1 ? 'SI' : 'NO'}</span>
        },
        {
          title: 'FECHA CREACIÓN',
          dataIndex: 'created_at',
          key: 'created_at',
          render: text => <Moment format="DD-MM-YYYY hh:mm A">{text}</Moment>
        },
        {
          title: 'ACCIONES',
          dataIndex: 'id',
          key: 'id',
          render: (text, element, index) => (
            <Tooltip title="Eliminar producto" placement="top">
              <Button
                icon="delete"
                onClick={() => {
                  handleClickOpenModal(list[index]);
                }}
              />
            </Tooltip>
          )
        }
      ];

      return (
        <div>
          <Modal
            closable={false}
            visible={stateDelete.open}
            onCancel={() => {
              handleClickCloseModal();
            }}
            footer={[
              <Button
                type="primary"
                onClick={() => {
                  deleteProduct();
                }}
                loading={stateDelete.loader} >
                Confirmar
              </Button>,
              <Button
                onClick={() => {
                  handleClickCloseModal();
                }}
              >
                Cancelar
              </Button>
            ]} >
            <h4>¿Desea eliminar el producto '{stateDelete.name}' ?</h4>
            <p>
              Favor de verificar que el producto seleccionado sea el que
              realmente desea eliminar.{' '}
              <span style={{ color: 'orange' }}>
                Solo se pueden eliminar productos que no se encuentren en alguna
                orden.
              </span>
            </p>
          </Modal>

          <Modal
            closable={false}
            width={355}
            visible={is_multiple_edition_fields}
            onCancel={() => {
              handleClickCloseModalAttributes();
            }}
            footer={[
              <Button
                type="primary"
                onClick={() => {
                  this.renderEditableTable()
                }}
                loading={stateDelete.loader} >
                  Confirmar
                </Button>,
                <Button
                  onClick={() => {
                    handleClickCloseModalAttributes();
                  }} >
                  Cancelar
                </Button>
            ]}
          >
            <h4>Selecciona los campos a editar</h4>
              
              <div key="name" style={{padding: '0 6em'}}>
                <Checkbox onChange={this.checkMultipleFieldsProduct} value="name">
                  <span>Nombre</span>
                </Checkbox>
              </div>
              <div key="price" style={{padding: '0 6em'}}>
                <Checkbox onChange={this.checkMultipleFieldsProduct} value="price">
                  <span>Precio + IVA</span>
                </Checkbox>
              </div>
              <div key="show" style={{padding: '0 6em'}}>
                <Checkbox onChange={this.checkMultipleFieldsProduct} value="show">
                  <span>Visible</span>
                </Checkbox>
              </div>
              <div key="instructions" style={{padding: '0 6em'}}>
                <Checkbox onChange={this.checkMultipleFieldsProduct} value="instructions">
                  <span>Instrucciones</span>
                </Checkbox>
              </div>
              <div key="description" style={{padding: '0 6em'}}>
                <Checkbox onChange={this.checkMultipleFieldsProduct} value="description">
                  <span>Descripción</span>
                </Checkbox>
              </div>
              <div key="brand" style={{padding: '0 6em'}}>
                <Checkbox onChange={this.checkMultipleFieldsProduct} value="brand">
                  <span>Marca</span>
                </Checkbox>
              </div>
              <div key="categories" style={{padding: '0 6em'}}>
                <Checkbox onChange={this.checkMultipleFieldsProduct} value="categories">
                  <span>Categorías</span>
                </Checkbox>
              </div>
          </Modal>

          <Modal
            closable={false}
            width={'97%'}
            visible={is_multiple_edition}
            onCancel={() => {
              this.handleClickCloseModalMultipleUpdate();
            }}
            footer={[
              <Button
                type="primary"
                onClick={() => {
                  handleClickOpenModalSaveMultipleConfirm();
                }}
                loading={stateDelete.loader} >
                  Guardar cambios
                </Button>,
                <Button
                  onClick={() => {
                    this.handleClickCloseModalMultipleUpdate();
                  }} >
                  Cancelar
                </Button>
            ]} >
            <h4>Edición multiple</h4>
            <Table
              rowKey={update_multiple_list => 'multiple_edit_list_' + update_multiple_list.id}
              loading={loading}
              bordered
              size="small"
              dataSource={edit_row.concat(update_multiple_list) }
              columns={this.props.edit_rows_table_read}
              pagination={false}
              scroll={{ x: 'max-content' }}
            />
          </Modal>

          <Modal
            closable={false}
            visible={stateSaveMultiple.open}
            onCancel={() => {
              handleClickCloseModalSaveMultipleConfirm();
            }}
            footer={[
              <Button
                type="primary"
                onClick={() => {
                  this.submitMultiplesProducts();
                }}
                loading={stateSaveMultiple.loader}
              >
                Confirmar
              </Button>,
              <Button
                onClick={() => {
                  handleClickCloseModalSaveMultipleConfirm();
                }}
              >
                Cancelar
              </Button>
            ]} >
            <h4>¿Guardar cambios?</h4>
            <p>
              Esta acción afectará los productos seleccionados y no será posible revertir.{' '}
            </p>
          </Modal>
          

          <Row gutter={16} >

          <div style={{ display: 'inline-block', width: '100%' }}>
            <div style={style.syncCodesButton}>
              <Button
                style={{
                  backgroundColor: '#303A4A',
                  borderColor: '#303A4A'
                }}
                type="primary"
                htmlType="submit"
                loading={syncCodes}
                onClick={syncCodesProducts}>
                Sincronizar códigos
              </Button>
            </div>
            {update_multiple_list.length > 0 ? (
              <div style={style.UpdateMultipleButton}>
                <Tooltip title="Editar productos" placement="left">
                  <Button
                    style={{
                      backgroundColor: '#303A4A',
                      borderColor: '#303A4A'
                    }}
                    type="primary"
                    htmlType="submit"
                    onClick={handleClickOpenModalAttributes}>
                    Editar seleccionados
                  </Button>
                </Tooltip>
              </div>
            ) : null }
          </div>
            <div style={style.CreateButton}>
              <Tooltip title="Crear producto" placement="left">
                <a
                  href="/productos/nuevo"
                  className="ant-btn ant-btn-primary ant-btn-icon-only"
                  rel="noopener noreferrer"
                >
                  <Icon type="plus" />
                </a>
              </Tooltip>
            </div>
          </Row>

          
          <Row gutter={16} style={{ marginBottom: 20 }}>
          <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              <Input
                placeholder="Buscar por nombre"
                allowClear
                onChange={this.handleSearchByName}
                style={{ width: '100%' }}
              />
            </Col>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              <Input
                placeholder="Buscar por referencia"
                allowClear
                onChange={this.handleSearchByReference}
                style={{ width: '100%' }}
              />
            </Col>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              <Select
                placeholder="Buscar por marcas"
                style={{ width: '100%' }}
                onChange={this.handleBrands}
                allowClear
              >
                {brands.map(value => (
                  <Select.Option key={`brands-${value.id}`} value={value.id}>
                    {value.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Por favor seleccione algunas categorías"
                allowClear
                showSearch
                optionFilterProp="children"
                onChange={this.handleCategories}
                filterOption={(input, option) =>
                  option.props.children[2].props.children[1]
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                } >
                {categoriesAll.map(value => (
                  <Select.Option
                    key={`categories-${value.id}`}
                    value={value.id}>
                    <img
                      src={
                        value.banner_url === null
                          ? productWhitoutImage
                          : value.banner_url
                      }
                      alt={value.alt}
                      style={style.miniFileCategory}
                    />{' '}
                    <span data='categories'>
                      {value.parent_complete !== null
                        ? `${value.parent_complete.name}->`
                        : ''}
                      {value.name}
                      {value.parent_complete === null
                        ? this.getBrands(value.category_brand)
                        : ''}
                    </span>
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} sm={6} md={6} style={{ marginBottom: 10 }}>
              <Select
                defaultValue="ASC"
                placeholder="Orden"
                style={{ width: '100%' }}
                onChange={this.handleSort}
              >
                <Select.Option key="sort-1" value="ASC">
                  ASC 
                </Select.Option>
                <Select.Option key="sort-2" value="DESC">
                  DESC
                </Select.Option>
              </Select>
            </Col>
          </Row>

          <div style={style.CheckAllButton}>
            <Checkbox value="true" onChange={this.checkedAll}>
              <span>Seleccionar todos</span>
            </Checkbox>
          </div>

          <Table
            loading={loading}
            bordered
            size="small"
            dataSource={list}
            columns={columns}
            pagination={false}
            scroll={{ x: 'max-content' }}
            rowKey="uuid" />

          <Pagination
            list="products.list"
            apiUrl="admin/products"
            extraBySearch={extraBySearch} />
        </div>
      );
    }
  }
);