import { Tag } from 'function-tree'
import Watch from './Watch'
import { isComputedValue } from './utils'

export class NestedComputedValue {
  constructor(computed, nestedPath) {
    this.computed = computed
    this.propsTags = computed.propsTags
    this.nestedPath = nestedPath
  }

  getValue(props) {
    return this.nestedPath.reduce(
      (currentValue, key) => currentValue && currentValue[key],
      this.computed.getValue(props)
    )
  }

  getDependencyMap() {
    return this.computed.getDependencyMap()
  }

  clone() {
    return this.computed.clone()
  }

  destroy() {
    return this.computed.destroy()
  }
}

export class Compute extends Watch {
  constructor(cb) {
    super('Compute')
    this.computedCallback = cb
    this.isDirty = true
    this.value = null
    this.props = null
    this.getters = null
    this.stateTags = []
    this.propsTags = []

    this.onUpdate = this.onUpdate.bind(this)
    this.dynamicGetter = this.dynamicGetter.bind(this)
    this.dynamicGetter.path = this.dynamicPathGetter.bind(this)
  }

  createDependencyMap() {
    return this.controller.createDependencyMap(
      this.stateTags,
      this.props,
      this.modulePath
    )
  }

  getDependencyMap() {
    return this.dependencyMap
  }

  onUpdate() {
    this.isDirty = true
  }

  clone() {
    return new Compute(this.computedCallback).create(
      this.controller,
      this.modulePath,
      this.name + ' (clone)'
    )
  }

  compute() {
    this.executedCount++
    return this.computedCallback(this.getDynamicGetter())
  }

  getDynamicGetter() {
    this.stateTags = []
    this.propsTags = []

    return this.dynamicGetter
  }

  parseDependencies(tag) {
    if (!(tag instanceof Tag)) {
      throw new Error(
        'Cerebral - Only tags are allowed in the dynamic "get" of Compute'
      )
    }
    const allTags = tag.getTags()

    allTags.forEach((tag) => {
      if (tag.type === 'props') {
        this.propsTags.push(tag)
      } else {
        this.stateTags.push(tag)
      }
    })
  }

  dynamicGetter(tag) {
    this.parseDependencies(tag)
    const value = tag.getValue(this.getters)

    if (isComputedValue(value)) {
      return value.getValue(this.props)
    }

    return value
  }

  dynamicPathGetter(tag) {
    return tag.getPath(this.getters)
  }

  hasChangedProps(props) {
    const nextGetters = this.controller.createContext(props)

    return this.propsTags.reduce((hasChanged, tag) => {
      if (hasChanged) {
        return true
      }

      return tag.getValue(this.getters) !== tag.getValue(nextGetters)
    }, false)
  }

  getValue(props) {
    if (!this.controller) {
      throw new Error('This Cerebral Compute has not been added to a module')
    }

    if (!this.isDirty && this.propsTags.length && this.hasChangedProps(props)) {
      this.isDirty = true
    }

    if (this.isDirty) {
      this.getters = this.controller.createContext(props)
      this.props = props
      this.value = this.compute()
      const prevDependencyMap = this.dependencyMap
      this.dependencyMap = this.createDependencyMap()
      this.controller.dependencyStore.updateEntity(
        this,
        prevDependencyMap,
        this.dependencyMap
      )
      if (this.controller.devtools) {
        this.controller.devtools.updateWatchMap(
          this,
          this.dependencyMap,
          prevDependencyMap
        )
        this.controller.devtools.updateComputedState(this.name, this.value)
      }
      this.isDirty = false
    }

    return this.value
  }

  toString() {
    return this.getValue(this.props)
  }
}

export default (cb) => {
  return new Compute(cb)
}
