import BrandListView from './BrandListView';
import BrandFormView from './BrandFormView';
import BrandFormViewUpdate from './BrandFormViewUpdate';
import BrandListFilterView from './BrandListFilterView';
import FilterFormView from './FilterFormView';
import FilterFormUpdateView from './FilterFormUpdateView';
import FilterDetailView from './FilterDetailView';
import BannerListView from './banners/BannerListView';
import BannersFormView from './banners/BannersFormView';
import BannerSecondaryListView from './banner_secondary/BannerSecondaryListView';
import BannerSecondaryFormView from './banner_secondary/BannerSecondaryFormView';

export default {
  BrandListView,
  BrandFormView,
  BrandFormViewUpdate,
  BrandListFilterView,
  FilterFormView,
  FilterFormUpdateView,
  FilterDetailView,
  BannerListView,
  BannersFormView,
  BannerSecondaryListView,
  BannerSecondaryFormView,
};
